import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { ExternalLink } from "components/ExternalLink";
import { NoData } from "components/NoData";
import { PropertyTypeDetails } from "components/PropertyTypeDetails";
import { SocialIcon } from "components/SocialIcon";
import { TruncatedText } from "components/TruncatedText";
import { useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import React from "react";
import messages from "./messages";

const getPropertyTypeDetail = (property, intl) => {
  switch (property.type) {
    case "WEBSITE":
      return (
        <Box direction="column" gap="xs" data-testid="propertyType-website">
          <Text weight="medium" size={14}>
            {intl.formatMessage(globalMessages.URL)}
          </Text>
          <ExternalLink
            className="property-website-url"
            path={property.propertyTypeDetails.websiteUrl}
            newWindow={true}
            text={property.propertyTypeDetails.websiteUrl}
          />
        </Box>
      );
    case "SOCIAL_MEDIA":
      return (
        <Box
          direction="column"
          gap="xs"
          data-testid="propertyType-social-media"
        >
          <Text weight="medium" size={14}>
            {intl.formatMessage(messages.propertyDetails)}
          </Text>
          <Box direction="row" gap="small">
            <SocialIcon
              platform={property.propertyTypeDetails?.socialMediaPlatform}
            />
            <PropertyTypeDetails
              propertyType={property.type}
              propertyTypeDetails={property.propertyTypeDetails}
            />
          </Box>
        </Box>
      );
    case "EMAIL":
      return (
        <Box direction="column" gap="xs">
          <Text weight="medium" size={14}>
            {intl.formatMessage(messages.propertyDetails)}
          </Text>
          <Text color="link" data-testid="propertyType-email">
            <a
              href={`mailto:${property.propertyTypeDetails.emailAddress}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {property.propertyTypeDetails.emailAddress}
            </a>
          </Text>
        </Box>
      );
    case "MOBILE_APP":
      return (
        <>
          <Box
            direction="column"
            gap="xs"
            data-testid="propertyType-mobile-app"
          >
            <Text weight="medium" size={14}>
              {intl.formatMessage(globalMessages.MOBILE_NAME)}
            </Text>
            <Text className="property-website-url">
              {property.propertyTypeDetails.mobileAppName}
            </Text>
          </Box>
          <Box direction="column" gap="medium">
            <Text weight="medium" size={14}>
              {intl.formatMessage(globalMessages.MOBILE_PLATFORM)}
            </Text>
            <Text className="property-website-url">
              {property.propertyTypeDetails.mobileAppDownloadInformation &&
                property.propertyTypeDetails.mobileAppDownloadInformation
                  .map((mobileAppDetails) =>
                    intl.formatMessage(
                      globalMessages[mobileAppDetails.mobileAppPlatform]
                    )
                  )
                  .join(", ")}
            </Text>
          </Box>
          <Box direction="column" gap="small">
            <Text weight="medium" size={14}>
              {intl.formatMessage(globalMessages.MOBILE_DOWNLOAD_LINKS)}
            </Text>
            <Text className="property-website-url" color="link">
              {property.propertyTypeDetails.mobileAppDownloadInformation &&
                property.propertyTypeDetails.mobileAppDownloadInformation.map(
                  (mobileAppDetails, uniqueKey) => {
                    return (
                      <div key={uniqueKey}>
                        <ExternalLink
                          path={mobileAppDetails.mobileAppDownloadLink}
                          text={mobileAppDetails.mobileAppDownloadLink}
                          newWindow={true}
                        />
                      </div>
                    );
                  }
                )}
            </Text>
          </Box>
        </>
      );
    case "BROWSER_EXTENSION":
      return (
        <>
          <Box
            direction="column"
            gap="xs"
            data-testid="propertyType-browser-extension"
          >
            <Text weight="medium" size={14}>
              {intl.formatMessage(globalMessages.BROWSER_NAME)}
            </Text>
            <Text className="property-website-url">
              {property.propertyTypeDetails.browserExtensionName}
            </Text>
          </Box>
          <Box direction="column" gap="small">
            <Text weight="medium" size={14}>
              {intl.formatMessage(globalMessages.BROWSER_PLATFORM)}
            </Text>
            <Text className="property-website-url">
              {property.propertyTypeDetails.browserExtensionDownloadInformation
                .map((browserExtensionDetails) =>
                  intl.formatMessage(
                    globalMessages[
                      browserExtensionDetails.browserExtensionBrowser
                    ]
                  )
                )
                .join(", ")}
            </Text>
          </Box>
          <Box direction="column" gap="small">
            <Text weight="medium" size={14}>
              {intl.formatMessage(globalMessages.BROWSER_DOWNLOAD_LINKS)}
            </Text>
            <Text className="property-website-url" color="link">
              {property.propertyTypeDetails.browserExtensionDownloadInformation.map(
                (browserExtensionDetails, uniqueKey) => {
                  return (
                    <div key={uniqueKey}>
                      <ExternalLink
                        path={
                          browserExtensionDetails.browserExtensionDownloadLink
                        }
                        text={
                          browserExtensionDetails.browserExtensionDownloadLink
                        }
                        newWindow={true}
                      />
                    </div>
                  );
                }
              )}
            </Text>
          </Box>
        </>
      );
    case "SERVICES_AND_TOOLS":
      return (
        <Box direction="column" gap="xs">
          <Text weight="medium" size={14}>
            {intl.formatMessage(messages.propertyDetails)}
          </Text>
          <ExternalLink
            path={property.propertyTypeDetails.servicesAndToolsMarketingSiteUrl}
            newWindow={true}
            text={property.propertyTypeDetails.servicesAndToolsMarketingSiteUrl}
          />
          {!property.propertyTypeDetails.servicesAndToolsMarketingSiteUrl && (
            <NoData />
          )}
        </Box>
      );
    case "PAID_DISPLAY_ADS":
      return (
        <Box direction="column" gap="xs">
          <Text weight="medium" size={14}>
            {intl.formatMessage(messages.propertyDetails)}
          </Text>
          {property.propertyTypeDetails.paidDisplayAdsDescription ? (
            <TruncatedText>
              {property.propertyTypeDetails.paidDisplayAdsDescription}
            </TruncatedText>
          ) : (
            <NoData />
          )}
        </Box>
      );
    case "PAID_SEARCH_ENGINE":
      return (
        <Box direction="column" gap="xs">
          <Text weight="medium" size={14}>
            {intl.formatMessage(messages.propertyDetails)}
          </Text>
          {property.propertyTypeDetails.paidSearchEngineDescription ? (
            <TruncatedText>
              {property.propertyTypeDetails.paidSearchEngineDescription}
            </TruncatedText>
          ) : (
            <NoData />
          )}
        </Box>
      );
    case "OTHER":
      return (
        <Box direction="column" gap="xs">
          <Text weight="medium" size={14}>
            {intl.formatMessage(messages.propertyDetails)}
          </Text>
          {property.propertyTypeDetails.otherDescription ? (
            <TruncatedText>
              {property.propertyTypeDetails.otherDescription}
            </TruncatedText>
          ) : (
            <NoData />
          )}
        </Box>
      );
    default:
      return <></>;
  }
};

export const PropertyTypeInformation = ({ property }) => {
  const intl = useIntl();
  if (!property.type) {
    return (
      <Text color="secondary">
        {intl.formatMessage(messages.noPropertyDetails)}
      </Text>
    );
  }

  return (
    <Box direction="column" gap="large" expand>
      <Box direction="column" gap="xs">
        <Text weight="medium" size={14}>
          {intl.formatMessage(globalMessages.TYPE)}
        </Text>
        <Text>{intl.formatMessage(globalMessages[property.type])}</Text>
      </Box>
      <Box direction="column" gap="xs">
        <Text weight="medium" size={14}>
          {intl.formatMessage(messages.primaryModel)}
        </Text>
        {property.primaryPromotionalModel ? (
          <Text>
            {intl.formatMessage(
              globalMessages[
                `accounts.PromotionalModels.${property.primaryPromotionalModel}`
              ]
            )}
          </Text>
        ) : (
          <NoData />
        )}
      </Box>
      {getPropertyTypeDetail(property, intl)}
    </Box>
  );
};
