import { defineMessages } from "react-intl";
export default defineMessages({
  totalMonthlyVisitors: {
    id: "partners.search.publisher.sortBy.totalMonthlyVisitors",
    defaultMessage: "Website Total Visitors",
  },
  instagramFollowers: {
    id: "partners.search.publisher.sortBy.instagramFollowers",
    defaultMessage: "Instagram Followers",
  },
  tiktokFollowers: {
    id: "partners.search.publisher.sortBy.tiktokFollowers",
    defaultMessage: "TikTok Followers",
  },
  facebookPageLikes: {
    id: "partners.search.publisher.sortBy.facebookPageLikes",
    defaultMessage: "Facebook Page Likes",
  },
  youtubeSubscribers: {
    id: "partners.search.publisher.sortBy.youtubeSubscribers",
    defaultMessage: "Youtube Subscribers",
  },
  twitterFollowers: {
    id: "partners.search.publisher.sortBy.twitterFollowers",
    defaultMessage: "Twitter Followers",
  },
  twitchFollowers: {
    id: "partners.search.publisher.sortBy.twitchFollowers",
    defaultMessage: "Twitch Followers",
  },
  pinterestFollowers: {
    id: "partners.search.publisher.sortBy.pinterestFollowers",
    defaultMessage: "Pinterest Followers",
  },
  snapchatFollowers: {
    id: "partners.search.publisher.sortBy.snapchatFollowers",
    defaultMessage: "Snapchat Followers",
  },
  countryTotalCommissions: {
    id: "partners.search.publisher.sortBy.countryTotalCommissions",
    defaultMessage: "Country Total Commissions",
  },
  categoryTotalCommissions: {
    id: "partners.search.publisher.sortBy.categoryTotalCommissions",
    defaultMessage: "Category Total Commissions",
  },
  keywordRelevance: {
    id: "partners.search.publisher.sortBy.keywordRelevance",
    defaultMessage: "Keyword Relevance",
  },
  networkEarnings: {
    id: "partners.search.publisher.sortBy.networkEarnings",
    defaultMessage: "Network Earnings",
  },
});
