import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { MDIcon } from "@cjdev-internal/visual-stack-x/components/MDIcon";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { AuthenticationStatusByProperty } from "components/AuthenticationStatus";
import { EngagementPopover } from "components/EngagementPopover";
import { Link } from "components/Link";
import { SocialIcon } from "components/SocialIcon";
import { Spinner } from "components/Spinner";
import { TruncatedText } from "components/TruncatedText";
import { publisherProfilePropertyDetailsPath } from "constants/paths";
import {
  TIKTOK_METRICS,
  useFeatures,
  useIntl,
  useLocation,
  USER_CAN_AUTHENTICATE_IN_CIQ,
  useTopPromotionalPropertiesQuery,
} from "hooks";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React from "react";
import {
  getEngagementLabel,
  getEngagementRate,
  getFormattedEngagementLabel,
  getFormattedEngagementMetric,
  getFormattedReachMetric,
  getPlatform,
  getReachMetric,
  getSocialHandle,
} from "utils/ciqUtils";
import {
  NestedTable,
  NestedTableBody,
  NestedTd,
  NestedTh,
  NestedTr,
} from "views/PublishersSearch/PartnersTable/MatchingProperties/NestedTable";
import messages from "./messages";
import "./styles.css";
import { ExternalLink } from "components/ExternalLink";

export const TopSocialProperties = ({ publisherId }) => {
  const intl = useIntl();
  const { location } = useLocation();
  const { search } = location;
  const { data, error, loading } = useTopPromotionalPropertiesQuery(
    publisherId,
    ["SOCIAL_MEDIA"]
  );
  const { hasFeature } = useFeatures();
  const hasUserCanAuthenticateInCiqFeature = hasFeature(
    USER_CAN_AUTHENTICATE_IN_CIQ
  );
  const hasTikTokMetricFeature = hasFeature(TIKTOK_METRICS);

  if (loading) {
    return (
      <Box align="center">
        <Spinner />
      </Box>
    );
  }

  if (error) {
    return null;
  }

  const topProperties = R.pipe(
    R.filter((property) => getReachMetric(property)),
    R.filter((property) =>
      hasTikTokMetricFeature ? property : getPlatform(property) !== "tiktok"
    ),
    R.take(10)
  )(data.topPromotionalProperties.resultList);

  if (R.isEmpty(topProperties)) {
    return null;
  }

  return (
    <Box direction="column" gap="medium">
      <Text weight="medium" size={14}>
        {intl.formatMessage(messages.title)}
      </Text>

      <NestedTable className="top-social-overview-table">
        <NestedTableBody>
          <NestedTr>
            <NestedTh>{intl.formatMessage(messages.name)}</NestedTh>
            {hasUserCanAuthenticateInCiqFeature && (
              <NestedTh className="nested-th-authentication-status"></NestedTh>
            )}
            <NestedTh>{intl.formatMessage(messages.reach)}</NestedTh>
            <NestedTh>{intl.formatMessage(messages.engagementRate)}</NestedTh>
            <NestedTh>{intl.formatMessage(messages.engagementMetric)}</NestedTh>
            <NestedTh></NestedTh>
          </NestedTr>
          {topProperties.map((property) => {
            const platform = getPlatform(property);
            const engagementRate = getEngagementRate(property);
            const engagementLabel = getEngagementLabel(property);
            const socialHandle = getSocialHandle(property);
            const formmattedReachMetric = getFormattedReachMetric(
              property,
              intl,
              hasTikTokMetricFeature
            );
            const formattedEngagementLabel = getFormattedEngagementLabel(
              property,
              intl
            );
            const formattedEngagementMetric = getFormattedEngagementMetric(
              property,
              intl
            );

            return (
              <NestedTr key={property.id}>
                <NestedTd className="top-social-overview-left">
                  <Box direction="row" gap="medium">
                    <SocialIcon
                      platform={
                        property.propertyTypeDetails.socialMediaPlatform
                      }
                    />
                    {property.propertyTypeDetails?.socialMediaUrl ? (
                      <ExternalLink
                        path={property.propertyTypeDetails.socialMediaUrl}
                        title={property.propertyTypeDetails.socialMediaUrl}
                        text={
                          <TruncatedText
                            type={"link"}
                            limit={15}
                            className="top-social-overview-table-text"
                          >
                            {socialHandle}
                          </TruncatedText>
                        }
                      />
                    ) : (
                      <TruncatedText
                        limit={15}
                        className="top-social-overview-table-text"
                      >
                        {socialHandle}
                      </TruncatedText>
                    )}
                  </Box>
                </NestedTd>
                {hasUserCanAuthenticateInCiqFeature && (
                  <NestedTd className="top-social-overview-left">
                    <AuthenticationStatusByProperty
                      property={property}
                      className="nested-table-authentication-status"
                    />
                  </NestedTd>
                )}
                <NestedTd className="top-social-overview-left">
                  {formmattedReachMetric}
                </NestedTd>
                <NestedTd className="top-social-overview-left">
                  {engagementLabel ? (
                    <EngagementPopover
                      label={engagementLabel}
                      rate={engagementRate}
                      socialPlatform={platform}
                    >
                      <Box direction="row" align="center">
                        <Text className="top-social-overview-table-text">
                          {formattedEngagementLabel}
                        </Text>
                        <MDIcon icon="chevron-down" size={24} />
                      </Box>
                    </EngagementPopover>
                  ) : (
                    <Text className="top-social-overview-table-text">
                      {intl.formatMessage(globalMessages["global.na"])}
                    </Text>
                  )}
                </NestedTd>
                <NestedTd className="top-social-overview-left">
                  <Text className="top-social-overview-table-text">
                    {formattedEngagementMetric}
                  </Text>
                </NestedTd>
                <NestedTd className="top-social-overview-right">
                  <Link
                    className="vsx-link top-social-overview-table-text"
                    to={{
                      pathname: publisherProfilePropertyDetailsPath(
                        publisherId,
                        property.id
                      ),
                      search: search,
                    }}
                  >
                    {intl.formatMessage(messages.viewDetails)}
                  </Link>
                </NestedTd>
              </NestedTr>
            );
          })}
        </NestedTableBody>
      </NestedTable>
    </Box>
  );
};
