import { defineMessages } from "react-intl";

export default defineMessages({
  "global.region.019": {
    id: "partners.global.region.019",
    defaultMessage: "Americas",
  },
  "global.region.142": {
    id: "partners.global.region.142",
    defaultMessage: "Asia",
  },
  "global.region.150": {
    id: "partners.global.region.150",
    defaultMessage: "Europe",
  },
  "global.region.002": {
    id: "partners.global.region.002",
    defaultMessage: "Africa",
  },
  "global.region.009": {
    id: "partners.global.region.009",
    defaultMessage: "Oceania",
  },
  "accounts.PromotionalModels.CONTENT_BLOG_MEDIA": {
    id: "partners.accounts.PromotionalModels.CONTENT_BLOG_MEDIA",
    defaultMessage: "Content, Blog, or Media",
  },
  "accounts.PromotionalModels.COUPON_DEAL": {
    id: "partners.accounts.PromotionalModels.COUPON_DEAL",
    defaultMessage: "Coupon, Deal",
  },
  "accounts.PromotionalModels.LOYALTY_CASH_BACK": {
    id: "partners.accounts.PromotionalModels.LOYALTY_CASH_BACK",
    defaultMessage: "Loyalty - Cash Back",
  },
  "accounts.PromotionalModels.LOYALTY_NON_CASH_REWARDS": {
    id: "partners.accounts.PromotionalModels.LOYALTY_NON_CASH_REWARDS",
    defaultMessage: "Loyalty - Non Cash Rewards",
  },
  "accounts.PromotionalModels.LOYALTY_CLOSED_USER_GROUP": {
    id: "partners.accounts.PromotionalModels.LOYALTY_CLOSED_USER_GROUP",
    defaultMessage: "Loyalty - Closed User Group",
  },
  "accounts.PromotionalModels.TRADEMARK_BIDDING": {
    id: "partners.accounts.PromotionalModels.TRADEMARK_BIDDING",
    defaultMessage: "Trademark Bidding",
  },
  "accounts.PromotionalModels.INFLUENCER": {
    id: "partners.accounts.PromotionalModels.INFLUENCER",
    defaultMessage: "Influencer",
  },
  "accounts.PromotionalModels.OTHER": {
    id: "partners.accounts.PromotionalModels.OTHER",
    defaultMessage: "Other",
  },
  "accounts.PromotionalModels.PAY_PER_CALL": {
    id: "partners.accounts.PromotionalModels.PAY_PER_CALL",
    defaultMessage: "Pay Per Call",
  },
  "accounts.PromotionalModels.PRODUCT_COMPARISON_REVIEWS_DISCOVERY": {
    id:
      "partners.accounts.PromotionalModels.PRODUCT_COMPARISON_REVIEWS_DISCOVERY",
    defaultMessage: "Product Comparison, Reviews, or Discovery",
  },
  "global.vertical.HOME_AND_BUSINESS_SERVICES": {
    id: "partners.global.vertical.HOME_AND_BUSINESS_SERVICES",
    defaultMessage: "Home and Business Services",
  },
  "global.vertical.RETAIL": {
    id: "partners.global.vertical.RETAIL",
    defaultMessage: "Retail",
  },
  "global.vertical.TRAVEL": {
    id: "partners.global.vertical.TRAVEL",
    defaultMessage: "Travel",
  },
  "global.vertical.FINANCE": {
    id: "partners.global.vertical.FINANCE",
    defaultMessage: "Finance",
  },
  "global.category.300": {
    id: "global.category.300",
    defaultMessage: "Automotive",
  },
  "global.category.301": {
    id: "global.category.301",
    defaultMessage: "Parts & Accessories",
  },
  "global.category.302": {
    id: "global.category.302",
    defaultMessage: "Cars & Trucks",
  },
  "global.category.303": {
    id: "global.category.303",
    defaultMessage: "Rentals",
  },
  "global.category.304": {
    id: "global.category.304",
    defaultMessage: "Art/Photo/Music",
  },
  "global.category.305": {
    id: "global.category.305",
    defaultMessage: "Art",
  },
  "global.category.306": {
    id: "global.category.306",
    defaultMessage: "Photo",
  },
  "global.category.307": {
    id: "global.category.307",
    defaultMessage: "Music",
  },
  "global.category.309": {
    id: "global.category.309",
    defaultMessage: "Books/Media",
  },
  "global.category.310": {
    id: "global.category.310",
    defaultMessage: "Books",
  },
  "global.category.311": {
    id: "global.category.311",
    defaultMessage: "Magazines",
  },
  "global.category.312": {
    id: "global.category.312",
    defaultMessage: "News",
  },
  "global.category.313": {
    id: "global.category.313",
    defaultMessage: "Videos/Movies",
  },
  "global.category.315": {
    id: "global.category.315",
    defaultMessage: "Business",
  },
  "global.category.316": {
    id: "global.category.316",
    defaultMessage: "Marketing",
  },
  "global.category.317": {
    id: "global.category.317",
    defaultMessage: "Office",
  },
  "global.category.318": {
    id: "global.category.318",
    defaultMessage: "Careers",
  },
  "global.category.319": {
    id: "global.category.319",
    defaultMessage: "Employment",
  },
  "global.category.320": {
    id: "global.category.320",
    defaultMessage: "College",
  },
  "global.category.321": {
    id: "global.category.321",
    defaultMessage: "Clothing/Apparel",
  },
  "global.category.322": {
    id: "global.category.322",
    defaultMessage: "Men's",
  },
  "global.category.323": {
    id: "global.category.323",
    defaultMessage: "Women's",
  },
  "global.category.324": {
    id: "global.category.324",
    defaultMessage: "Children's",
  },
  "global.category.325": {
    id: "global.category.325",
    defaultMessage: "Malls",
  },
  "global.category.326": {
    id: "global.category.326",
    defaultMessage: "Buying and Selling",
  },
  "global.category.327": {
    id: "global.category.327",
    defaultMessage: "Auction",
  },
  "global.category.328": {
    id: "global.category.328",
    defaultMessage: "Classifieds",
  },
  "global.category.329": {
    id: "global.category.329",
    defaultMessage: "E-commerce Solutions/Providers",
  },
  "global.category.330": {
    id: "global.category.330",
    defaultMessage: "Telephone Services",
  },
  "global.category.331": {
    id: "global.category.331",
    defaultMessage: "Computer & Electronics",
  },
  "global.category.332": {
    id: "global.category.332",
    defaultMessage: "Computer HW",
  },
  "global.category.333": {
    id: "global.category.333",
    defaultMessage: "Computer SW",
  },
  "global.category.334": {
    id: "global.category.334",
    defaultMessage: "Peripherals",
  },
  "global.category.335": {
    id: "global.category.335",
    defaultMessage: "Financial Services",
  },
  "global.category.336": {
    id: "global.category.336",
    defaultMessage: "Banking/Trading",
  },
  "global.category.337": {
    id: "global.category.337",
    defaultMessage: "Credit Cards",
  },
  "global.category.339": {
    id: "global.category.339",
    defaultMessage: "Mortgage Loans",
  },
  "global.category.340": {
    id: "global.category.340",
    defaultMessage: "Real Estate Services",
  },
  "global.category.341": {
    id: "global.category.341",
    defaultMessage: "Food & Drinks",
  },
  "global.category.342": {
    id: "global.category.342",
    defaultMessage: "Gourmet",
  },
  "global.category.343": {
    id: "global.category.343",
    defaultMessage: "Wine & Spirits",
  },
  "global.category.345": {
    id: "global.category.345",
    defaultMessage: "Groceries",
  },
  "global.category.346": {
    id: "global.category.346",
    defaultMessage: "Restaurants",
  },
  "global.category.347": {
    id: "global.category.347",
    defaultMessage: "Tobacco",
  },
  "global.category.348": {
    id: "global.category.348",
    defaultMessage: "Gifts & Flowers",
  },
  "global.category.349": {
    id: "global.category.349",
    defaultMessage: "Gifts",
  },
  "global.category.350": {
    id: "global.category.350",
    defaultMessage: "Jewelry",
  },
  "global.category.351": {
    id: "global.category.351",
    defaultMessage: "Flowers",
  },
  "global.category.352": {
    id: "global.category.352",
    defaultMessage: "Greeting Cards",
  },
  "global.category.353": {
    id: "global.category.353",
    defaultMessage: "Games & Toys",
  },
  "global.category.354": {
    id: "global.category.354",
    defaultMessage: "Games",
  },
  "global.category.355": {
    id: "global.category.355",
    defaultMessage: "Electronic Toys",
  },
  "global.category.356": {
    id: "global.category.356",
    defaultMessage: "Toys",
  },
  "global.category.357": {
    id: "global.category.357",
    defaultMessage: "Health and Wellness",
  },
  "global.category.358": {
    id: "global.category.358",
    defaultMessage: "Bath & Body",
  },
  "global.category.359": {
    id: "global.category.359",
    defaultMessage: "Health Food",
  },
  "global.category.360": {
    id: "global.category.360",
    defaultMessage: "Cosmetics",
  },
  "global.category.361": {
    id: "global.category.361",
    defaultMessage: "Nutritional Supplements",
  },
  "global.category.362": {
    id: "global.category.362",
    defaultMessage: "Pharmaceuticals",
  },
  "global.category.363": {
    id: "global.category.363",
    defaultMessage: "Recreation & Leisure",
  },
  "global.category.364": {
    id: "global.category.364",
    defaultMessage: "Astrology",
  },
  "global.category.365": {
    id: "global.category.365",
    defaultMessage: "Betting/Gaming",
  },
  "global.category.366": {
    id: "global.category.366",
    defaultMessage: "Matchmaking",
  },
  "global.category.367": {
    id: "global.category.367",
    defaultMessage: "Communities",
  },
  "global.category.368": {
    id: "global.category.368",
    defaultMessage: "Home & Garden",
  },
  "global.category.369": {
    id: "global.category.369",
    defaultMessage: "Bed & Bath",
  },
  "global.category.370": {
    id: "global.category.370",
    defaultMessage: "Garden",
  },
  "global.category.371": {
    id: "global.category.371",
    defaultMessage: "Pets",
  },
  "global.category.372": {
    id: "global.category.372",
    defaultMessage: "Sports & Fitness",
  },
  "global.category.373": {
    id: "global.category.373",
    defaultMessage: "Sports",
  },
  "global.category.374": {
    id: "global.category.374",
    defaultMessage: "Exercise & Health",
  },
  "global.category.375": {
    id: "global.category.375",
    defaultMessage: "Department Stores/Malls",
  },
  "global.category.376": {
    id: "global.category.376",
    defaultMessage: "Virtual Malls",
  },
  "global.category.377": {
    id: "global.category.377",
    defaultMessage: "Travel",
  },
  "global.category.378": {
    id: "global.category.378",
    defaultMessage: "Air",
  },
  "global.category.379": {
    id: "global.category.379",
    defaultMessage: "Car",
  },
  "global.category.380": {
    id: "global.category.380",
    defaultMessage: "Hotel",
  },
  "global.category.381": {
    id: "global.category.381",
    defaultMessage: "Vacation",
  },
  "global.category.382": {
    id: "global.category.382",
    defaultMessage: "Online Services",
  },
  "global.category.384": {
    id: "global.category.384",
    defaultMessage: "Search Engine",
  },
  "global.category.385": {
    id: "global.category.385",
    defaultMessage: "Web Hosting/Servers",
  },
  "global.category.386": {
    id: "global.category.386",
    defaultMessage: "Web Design",
  },
  "global.category.387": {
    id: "global.category.387",
    defaultMessage: "Web Tools",
  },
  "global.category.388": {
    id: "global.category.388",
    defaultMessage: "Home Appliances",
  },
  "global.category.389": {
    id: "global.category.389",
    defaultMessage: "Commercial",
  },
  "global.category.390": {
    id: "global.category.390",
    defaultMessage: "Investment",
  },
  "global.category.391": {
    id: "global.category.391",
    defaultMessage: "Marketing",
  },
  "global.category.392": {
    id: "global.category.392",
    defaultMessage: "Business-to-Business",
  },
  "global.category.393": {
    id: "global.category.393",
    defaultMessage: "Productivity Tools",
  },
  "global.category.394": {
    id: "global.category.394",
    defaultMessage: "Accessories",
  },
  "global.category.395": {
    id: "global.category.395",
    defaultMessage: "Travel",
  },
  "global.category.396": {
    id: "global.category.396",
    defaultMessage: "Luggage",
  },
  "global.category.397": {
    id: "global.category.397",
    defaultMessage: "Consumer Electronics",
  },
  "global.category.398": {
    id: "global.category.398",
    defaultMessage: "Insurance",
  },
  "global.category.399": {
    id: "global.category.399",
    defaultMessage: "Personal Insurance",
  },
  "global.category.400": {
    id: "global.category.400",
    defaultMessage: "Tools and Supplies",
  },
  "global.category.401": {
    id: "global.category.401",
    defaultMessage: "Utilities",
  },
  "global.category.402": {
    id: "global.category.402",
    defaultMessage: "Collectibles",
  },
  "global.category.403": {
    id: "global.category.403",
    defaultMessage: "Family",
  },
  "global.category.404": {
    id: "global.category.404",
    defaultMessage: "Children",
  },
  "global.category.405": {
    id: "global.category.405",
    defaultMessage: "Teens",
  },
  "global.category.406": {
    id: "global.category.406",
    defaultMessage: "Entertainment",
  },
  "global.category.408": {
    id: "global.category.408",
    defaultMessage: "Equipment",
  },
  "global.category.409": {
    id: "global.category.409",
    defaultMessage: "Wellness",
  },
  "global.category.410": {
    id: "global.category.410",
    defaultMessage: "Network Marketing",
  },
  "global.category.411": {
    id: "global.category.411",
    defaultMessage: "Accessories",
  },
  "global.category.412": {
    id: "global.category.412",
    defaultMessage: "Fragrance",
  },
  "global.category.413": {
    id: "global.category.413",
    defaultMessage: "Handbags",
  },
  "global.category.414": {
    id: "global.category.414",
    defaultMessage: "Shoes",
  },
  "global.category.415": {
    id: "global.category.415",
    defaultMessage: "Babies",
  },
  "global.category.416": {
    id: "global.category.416",
    defaultMessage: "Computer Support",
  },
  "global.category.417": {
    id: "global.category.417",
    defaultMessage: "Real Estate",
  },
  "global.category.418": {
    id: "global.category.418",
    defaultMessage: "Construction",
  },
  "global.category.419": {
    id: "global.category.419",
    defaultMessage: "Education",
  },
  "global.category.420": {
    id: "global.category.420",
    defaultMessage: "Children",
  },
  "global.category.421": {
    id: "global.category.421",
    defaultMessage: "Professional",
  },
  "global.category.441": {
    id: "global.category.441",
    defaultMessage: "Water Sports",
  },
  "global.category.461": {
    id: "global.category.461",
    defaultMessage: "Domain Registrations",
  },
  "global.category.481": {
    id: "global.category.481",
    defaultMessage: "Weddings",
  },
  "global.category.501": {
    id: "global.category.501",
    defaultMessage: "Television",
  },
  "global.category.521": {
    id: "global.category.521",
    defaultMessage: "Military",
  },
  "global.category.541": {
    id: "global.category.541",
    defaultMessage: "Military",
  },
  "global.category.561": {
    id: "global.category.561",
    defaultMessage: "Kitchen",
  },
  "global.category.581": {
    id: "global.category.581",
    defaultMessage: "Business-to-Business",
  },
  "global.category.601": {
    id: "global.category.601",
    defaultMessage: "Languages",
  },
  "global.category.621": {
    id: "global.category.621",
    defaultMessage: "Outdoors",
  },
  "global.category.641": {
    id: "global.category.641",
    defaultMessage: "New/Used Goods",
  },
  "global.category.661": {
    id: "global.category.661",
    defaultMessage: "Furniture",
  },
  "global.category.681": {
    id: "global.category.681",
    defaultMessage: "Party Goods",
  },
  "global.category.701": {
    id: "global.category.701",
    defaultMessage: "Internet Service Providers",
  },
  "global.category.741": {
    id: "global.category.741",
    defaultMessage: "Self Help",
  },
  "global.category.762": {
    id: "global.category.762",
    defaultMessage: "Events",
  },
  "global.category.801": {
    id: "global.category.801",
    defaultMessage: "Legal",
  },
  "global.category.802": {
    id: "global.category.802",
    defaultMessage: "Services",
  },
  "global.category.821": {
    id: "global.category.821",
    defaultMessage: "Telecommunications",
  },
  "global.category.822": {
    id: "global.category.822",
    defaultMessage: "Online/Wireless",
  },
  "global.category.881": {
    id: "global.category.881",
    defaultMessage: "Audio Books",
  },
  "global.category.901": {
    id: "global.category.901",
    defaultMessage: "Motorcycles",
  },
  "global.category.921": {
    id: "global.category.921",
    defaultMessage: "Professional Sports Organizations",
  },
  "global.category.941": {
    id: "global.category.941",
    defaultMessage: "Entertainment",
  },
  "global.category.942": {
    id: "global.category.942",
    defaultMessage: "Memorabilia",
  },
  "global.category.961": {
    id: "global.category.961",
    defaultMessage: "Vision Care",
  },
  "global.category.981": {
    id: "global.category.981",
    defaultMessage: "Email Marketing",
  },
  "global.category.1021": {
    id: "global.category.1021",
    defaultMessage: "Camping and Hiking",
  },
  "global.category.1001": {
    id: "global.category.1001",
    defaultMessage: "Credit Reporting and Repair",
  },
  "global.category.1022": {
    id: "global.category.1022",
    defaultMessage: "Blogs",
  },
  "global.category.1023": {
    id: "global.category.1023",
    defaultMessage: "Broadband",
  },
  "global.category.1024": {
    id: "global.category.1024",
    defaultMessage: "Seasonal",
  },
  "global.category.1025": {
    id: "global.category.1025",
    defaultMessage: "Autumn",
  },
  "global.category.1026": {
    id: "global.category.1026",
    defaultMessage: "Back to School",
  },
  "global.category.1027": {
    id: "global.category.1027",
    defaultMessage: "Christmas",
  },
  "global.category.1028": {
    id: "global.category.1028",
    defaultMessage: "Easter",
  },
  "global.category.1029": {
    id: "global.category.1029",
    defaultMessage: "Father's Day",
  },
  "global.category.1030": {
    id: "global.category.1030",
    defaultMessage: "Halloween",
  },
  "global.category.1031": {
    id: "global.category.1031",
    defaultMessage: "Mother's Day",
  },
  "global.category.1032": {
    id: "global.category.1032",
    defaultMessage: "Spring",
  },
  "global.category.1033": {
    id: "global.category.1033",
    defaultMessage: "Summer",
  },
  "global.category.1034": {
    id: "global.category.1034",
    defaultMessage: "Tax Season",
  },
  "global.category.1035": {
    id: "global.category.1035",
    defaultMessage: "Valentine's Day",
  },
  "global.category.1036": {
    id: "global.category.1036",
    defaultMessage: "Winter",
  },
  "global.category.1037": {
    id: "global.category.1037",
    defaultMessage: "Tax Services",
  },
  "global.category.1038": {
    id: "global.category.1038",
    defaultMessage: "Personal Loans",
  },
  "global.category.1039": {
    id: "global.category.1039",
    defaultMessage: "Guides",
  },
  "global.category.1040": {
    id: "global.category.1040",
    defaultMessage: "Discounts",
  },
  "global.category.1041": {
    id: "global.category.1041",
    defaultMessage: "Mobile Entertainment",
  },
  "global.category.1042": {
    id: "global.category.1042",
    defaultMessage: "Phone Card Services",
  },
  "global.category.1043": {
    id: "global.category.1043",
    defaultMessage: "Non-Profit",
  },
  "global.category.1044": {
    id: "global.category.1044",
    defaultMessage: "Charitable Organizations",
  },
  "global.category.1045": {
    id: "global.category.1045",
    defaultMessage: "Fundraising",
  },
  "global.category.1046": {
    id: "global.category.1046",
    defaultMessage: "Electronic Games",
  },
  "global.category.1047": {
    id: "global.category.1047",
    defaultMessage: "Weight Loss",
  },
  "global.category.1048": {
    id: "global.category.1048",
    defaultMessage: "Apparel",
  },
  "global.category.1049": {
    id: "global.category.1049",
    defaultMessage: "Equipment",
  },
  "global.category.1050": {
    id: "global.category.1050",
    defaultMessage: "Winter Sports",
  },
  "global.category.1051": {
    id: "global.category.1051",
    defaultMessage: "Summer Sports",
  },
  "global.category.1052": {
    id: "global.category.1052",
    defaultMessage: "Golf",
  },
  "global.category.1053": {
    id: "global.category.1053",
    defaultMessage: "Collectibles and Memorabilia",
  },
  "global.category.1054": {
    id: "global.category.1054",
    defaultMessage: "Beauty",
  },
  "global.category.1101": {
    id: "global.category.1101",
    defaultMessage: "Department Stores",
  },
  "global.category.1102": {
    id: "global.category.1102",
    defaultMessage: "Energy Saving",
  },
  "global.category.1103": {
    id: "global.category.1103",
    defaultMessage: "Recycling",
  },
  "global.category.1104": {
    id: "global.category.1104",
    defaultMessage: "Green",
  },
  "global.category.1105": {
    id: "global.category.1105",
    defaultMessage: "Green",
  },
  "global.category.1106": {
    id: "global.category.1106",
    defaultMessage: "Green",
  },
  "global.category.1107": {
    id: "global.category.1107",
    defaultMessage: "Green",
  },
  "global.category.1108": {
    id: "global.category.1108",
    defaultMessage: "Green",
  },
  "global.category.1109": {
    id: "global.category.1109",
    defaultMessage: "Green",
  },
  "global.category.1110": {
    id: "global.category.1110",
    defaultMessage: "Green",
  },
  "global.category.1111": {
    id: "global.category.1111",
    defaultMessage: "Green",
  },
  "global.category.1112": {
    id: "global.category.1112",
    defaultMessage: "Green",
  },
  "global.category.1141": {
    id: "global.category.1141",
    defaultMessage: "Black Friday/Cyber Monday",
  },
  "global.category.1142": {
    id: "global.category.1142",
    defaultMessage: "New Year's Resolution",
  },
  "global.category.9999": {
    id: "global.category.9999",
    defaultMessage: "N/A",
  },
  "global.na": {
    id: "partners.global.NA",
    defaultMessage: "N/A",
  },
  "global.currency.usd": {
    id: "partners.global.currency.USD",
    defaultMessage: "$",
  },
  "global.currency.cad": {
    id: "partners.global.currency.CAD",
    defaultMessage: "$",
  },
  "global.currency.gbp": {
    id: "partners.global.currency.GBP",
    defaultMessage: "£",
  },
  "global.currency.eur": {
    id: "partners.global.currency.EUR",
    defaultMessage: "€",
  },
  "global.currency.sek": {
    id: "partners.global.currency.SEK",
    defaultMessage: "kr",
  },
  "global.currency.czk": {
    id: "partners.global.currency.CZK",
    defaultMessage: "Kč",
  },
  "global.currency.dkk": {
    id: "partners.global.currency.DKK",
    defaultMessage: "kr",
  },
  "global.currency.pln": {
    id: "partners.global.currency.PLN",
    defaultMessage: "zł",
  },
  "global.currency.zar": {
    id: "partners.global.currency.ZAR",
    defaultMessage: "R",
  },
  WEBSITE: {
    id: "partners.global.propertyTypes.WEBSITE",
    defaultMessage: "Website",
  },
  SOCIAL_MEDIA: {
    id: "partners.global.propertyTypes.SOCIAL_MEDIA",
    defaultMessage: "Social",
  },
  EMAIL: {
    id: "partners.global.propertyTypes.EMAIL",
    defaultMessage: "Email",
  },
  MOBILE_APP: {
    id: "partners.global.propertyTypes.MOBILE_APP",
    defaultMessage: "Mobile App",
  },
  BROWSER_EXTENSION: {
    id: "partners.global.propertyTypes.BROWSER_EXTENSION",
    defaultMessage: "Browser Extension",
  },
  SERVICES_AND_TOOLS: {
    id: "partners.global.propertyTypes.SERVICES_AND_TOOLS",
    defaultMessage: "Services and Tools",
  },
  PAID_SEARCH_ENGINE: {
    id: "partners.global.propertyTypes.PAID_SEARCH_ENGINE",
    defaultMessage: "Paid Search Engine Marketing (direct)",
  },
  PAID_DISPLAY_ADS: {
    id: "partners.global.propertyTypes.PAID_DISPLAY_ADS",
    defaultMessage: "Paid Display Ads",
  },
  INVALID_TYPE: {
    id: "partners.global.propertyTypes.INVALID_TYPE",
    defaultMessage: "Invalid Type",
  },
  OTHER: {
    id: "partners.global.OTHER",
    defaultMessage: "Other",
  },
  ERROR: {
    id: "partners.global.ERROR",
    defaultMessage: "Error",
  },
  TYPE: {
    id: "partners.global.TYPE",
    defaultMessage: "Type",
  },
  URL: {
    id: "partners.global.URL",
    defaultMessage: "URL",
  },
  BROWSER_NAME: {
    id: "partners.global.BROWSER_NAME",
    defaultMessage: "Browser Extension App Name",
  },
  BROWSER_PLATFORM: {
    id: "partners.global.BROWSER_PLATFORM",
    defaultMessage: "Platform",
  },
  CHROME: {
    id: "partners.global.CHROME",
    defaultMessage: "Chrome",
  },
  FIREFOX: {
    id: "partners.global.FIREFOX",
    defaultMessage: "Firefox",
  },
  OPERA: {
    id: "partners.global.OPERA",
    defaultMessage: "Opera",
  },
  SAFARI: {
    id: "partners.global.SAFARI",
    defaultMessage: "Safari",
  },
  BROWSER_DOWNLOAD_LINKS: {
    id: "partners.global.BROWSER_DOWNLOAD_LINKS",
    defaultMessage: "Download Links",
  },
  MOBILE_NAME: {
    id: "partners.global.MOBILE_NAME",
    defaultMessage: "Mobile App Name",
  },
  MOBILE_PLATFORM: {
    id: "partners.global.MOBILE_PLATFORM",
    defaultMessage: "Platform",
  },
  ANDROID: {
    id: "partners.global.ANDROID",
    defaultMessage: "Android",
  },
  IOS: {
    id: "partners.global.IOS",
    defaultMessage: "iOS",
  },
  MOBILE_DOWNLOAD_LINKS: {
    id: "partners.global.MOBILE_DOWNLOAD_LINKS",
    defaultMessage: "Download Links",
  },
  TAGS: {
    id: "partners.global.Tags",
    defaultMessage: "Tags",
  },
  DESCRIPTION: {
    id: "partners.global.Description",
    defaultMessage: "Description",
  },
  noData: {
    id: "partners.global.noData",
    defaultMessage: "No data available.",
  },
  locatedIn: {
    id: "partners.global.locatedIn",
    defaultMessage: "Located in {0}, {1}",
  },
  INSTAGRAM: {
    id: "partners.global.INSTAGRAM",
    defaultMessage: "Instagram",
  },
  instagram: {
    id: "partners.global.instagram",
    defaultMessage: "Instagram",
  },
  FACEBOOK: {
    id: "partners.global.FACEBOOK",
    defaultMessage: "Facebook",
  },
  facebook: {
    id: "partners.global.facebook",
    defaultMessage: "Facebook",
  },
  YOUTUBE: {
    id: "partners.global.YOUTUBE",
    defaultMessage: "YouTube",
  },
  youtube: {
    id: "partners.global.youtube",
    defaultMessage: "YouTube",
  },
  TWITTER: {
    id: "partners.global.TWITTER",
    defaultMessage: "Twitter",
  },
  twitter: {
    id: "partners.global.twitter",
    defaultMessage: "Twitter",
  },
  TWITCH: {
    id: "partners.global.TWITCH",
    defaultMessage: "Twitch",
  },
  twitch: {
    id: "partners.global.twitch",
    defaultMessage: "Twitch",
  },
  WHATSAPP: {
    id: "partners.global.WHATSAPP",
    defaultMessage: "WhatsApp",
  },
  whatsapp: {
    id: "partners.global.whatsapp",
    defaultMessage: "WhatsApp",
  },
  PINTEREST: {
    id: "partners.global.PINTEREST",
    defaultMessage: "Pinterest",
  },
  pinterest: {
    id: "partners.global.pinterest",
    defaultMessage: "Pinterest",
  },
  SNAPCHAT: {
    id: "partners.global.SNAPCHAT",
    defaultMessage: "Snapchat",
  },
  snapchat: {
    id: "partners.global.snapchat",
    defaultMessage: "Snapchat",
  },
  TIKTOK: {
    id: "partners.global.TIKTOK",
    defaultMessage: "TikTok",
  },
  pageLikes: {
    id: "partners.global.PAGE_LIKES",
    defaultMessage: "page likes",
  },
  followers: {
    id: "partners.global.FOLLOWERS",
    defaultMessage: "followers",
  },
  subscribers: {
    id: "partners.global.SUBSCRIBERS",
    defaultMessage: "subscribers",
  },
  monthlyUniqueVisitors: {
    id: "partners.global.MONTHLY_UNIQUE_VISITORS",
    defaultMessage: "unique visitors",
  },
  monthlyTotalVisitors: {
    id: "partners.global.MONTHLY_TOTAL_VISITORS",
    defaultMessage: "total visitors",
  },
  pageLikesTitle: {
    id: "partners.global.PAGE_LIKES_TITLIZED",
    defaultMessage: "Page Likes",
  },
  followersTitle: {
    id: "partners.global.FOLLOWERS_TITLIZED",
    defaultMessage: "Followers",
  },
  subscribersTitle: {
    id: "partners.global.SUBSCRIBERS_TITLIZED",
    defaultMessage: "Subscribers",
  },
  monthlyTotalVisitorsTitle: {
    id: "partners.global.MONTHLY_TOTAL_VISITORS_TITLIZED",
    defaultMessage: "Total Visitors",
  },
  desktopUniqueVisitors: {
    id: "partners.global.desktopUniqueVisitors",
    defaultMessage: "Desktop Unique Visitors",
  },
  mobileWebUniqueVisitors: {
    id: "partners.global.mobileWebUniqueVisitors",
    defaultMessage: "Mobile Web Unique Visitors",
  },
  likesPerPost: {
    id: "partners.global.likesPerPost",
    defaultMessage: "likes per post",
  },
  likesPerTweet: {
    id: "partners.global.likesPerTweet",
    defaultMessage: "likes per tweet",
  },
  viewsPerVideo: {
    id: "partners.global.viewsPerVideo",
    defaultMessage: "views per video",
  },
  averageStreamViews: {
    id: "partners.global.averageStreamViews",
    defaultMessage: "avg. stream views",
  },
  averageMonthlyViews: {
    id: "partners.global.averageMonthlyViews",
    defaultMessage: "avg. monthly views",
  },
  send: {
    id: "partners.global.send",
    defaultMessage: "Send",
  },
  cancel: {
    id: "partners.global.cancel",
    defaultMessage: "Cancel",
  },
  confirm: {
    id: "partners.global.confirm",
    defaultMessage: "Confirm",
  },
  SEARCH: {
    id: "partners.global.SEARCH",
    defaultMessage: "Search",
  },
  feedback: {
    id: "partners.global.feedback",
    defaultMessage: "FEEDBACK",
  },
  yes: {
    id: "partners.global.yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "partners.global.no",
    defaultMessage: "No",
  },
  new: {
    id: "partners.global.new",
    defaultMessage: "New",
  },
  sortBy: {
    id: "partners.global.sortBy",
    defaultMessage: "Sort By...",
  },
  serverError: {
    id: "partners.partners.serverError",
    defaultMessage: "There was an error, please refresh and try again.",
  },
  select: {
    id: "partners.global.select",
    defaultMessage: "Select",
  },
  cjAffiliate: {
    id: "partners.global.cjAffiliate",
    defaultMessage: "CJ Affiliate",
  },
  FAIR: {
    id: "partners.global.FAIR",
    defaultMessage: "Fair/Low",
  },
  GOOD: {
    id: "partners.global.GOOD",
    defaultMessage: "Good",
  },
  EXCELLENT: {
    id: "partners.global.EXCELLENT",
    defaultMessage: "Excellent",
  },
  postLikes: {
    id: "partners.global.postLikes",
    defaultMessage: "Post Likes",
  },
  engagementRate20Posts: {
    id: "partners.global.engagementRate20Posts",
    defaultMessage: "Engagement Rate",
  },
  likesPerPost20Posts: {
    id: "partners.global.likesPerPost20Posts",
    defaultMessage: "Likes Per Post",
  },
  commentsPerPost20Posts: {
    id: "partners.global.commentsPerPost20Posts",
    defaultMessage: "Comments Per Post",
  },
  totalComments: {
    id: "partners.global.totalComments",
    defaultMessage: "Total Comments",
  },
  numberOfPosts: {
    id: "partners.global.numberOfPosts",
    defaultMessage: "Total Posts",
  },
  lastPostDate: {
    id: "partners.global.lastPostDate",
    defaultMessage: "Last Post Date",
  },
  engagementLabel: {
    id: "partners.global.engagementLabel",
    defaultMessage: "Engagement",
  },
  accountTotalImages: {
    id: "partners.global.accountTotalImages",
    defaultMessage: "Total Posts",
  },
  accountTotalTweets: {
    id: "partners.global.accountTotalTweets",
    defaultMessage: "Total Tweets",
  },
  sharesPerPost20Posts: {
    id: "partners.global.sharesPerPost20Posts",
    defaultMessage: "Shares Per Post",
  },
  totalLikes: {
    id: "partners.global.totalLikes",
    defaultMessage: "Total Likes",
  },
  accountMonthlyViews: {
    id: "partners.global.accountMonthlyViews",
    defaultMessage: "Average Monthly Views",
  },
  accountTotalVideos: {
    id: "partners.global.accountTotalVideos",
    defaultMessage: "Total Videos",
  },
  accountTotalViews: {
    id: "partners.global.accountTotalViews",
    defaultMessage: "Total Views",
  },
  viewsPerPost20Posts: {
    id: "partners.global.viewsPerPost20Posts",
    defaultMessage: "Views Per Post",
  },
  dateLastVideoUploaded: {
    id: "partners.global.dateLastVideoUploaded",
    defaultMessage: "Last Video Date",
  },
  accountViews: {
    id: "partners.global.accountViews",
    defaultMessage: "Total Views",
  },
  averageVideoViews30Days: {
    id: "partners.global.averageVideoViews30Days",
    defaultMessage: "Average Video Views",
  },
  accountPins: {
    id: "partners.global.accountPins",
    defaultMessage: "Total Pins",
  },
  sharesPerPostLifetime: {
    id: "partners.global.sharesPerPostLifetime",
    defaultMessage: "Shares Per Post",
  },
  accountAverageMonthlyViews: {
    id: "partners.global.accountAverageMonthlyViews",
    defaultMessage: "Average Monthly Views",
  },
  accountAvgStreamViews: {
    id: "partners.global.accountAvgStreamViews",
    defaultMessage: "Average Stream Views",
  },
  accountLikesPerTweet: {
    id: "partners.global.accountLikesPerTweet",
    defaultMessage: "Average Likes Per Tweet",
  },
  accountViewsPerVideo: {
    id: "partners.global.accountViewsPerVideo",
    defaultMessage: "Average Views Per Video",
  },
});
