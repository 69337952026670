import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { MDIcon } from "@cjdev-internal/visual-stack-x/components/MDIcon";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { useIntl } from "hooks";
import React, { useState } from "react";
import Truncate from "react-truncate";
import messages from "./messages";
import "./styles.css";

export const ClampedText = ({ children, lines = 5, ...props }) => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState();
  const [truncated, setTruncated] = useState();

  const onTruncate = (truncated) => {
    setTruncated(truncated);
  };

  const onExpand = () => {
    setExpanded(!expanded);
  };

  const className = ["clamped-content", props.className].join(" ");

  return (
    <Box direction="column" gap="small">
      <Text {...props} className={className}>
        <Truncate lines={!expanded && lines} onTruncate={onTruncate}>
          {children}
        </Truncate>
      </Text>
      {(truncated || expanded) && (
        <Box direction="row" align="center" gap="xs">
          {!expanded ? (
            <>
              <Text
                onClick={onExpand}
                color="secondary"
                className="clamped-text-more"
                size={12}
              >
                {intl.formatMessage(messages.showMore)}
              </Text>
              <MDIcon icon="chevron-down" size={16} />
            </>
          ) : (
            <>
              <Text
                onClick={onExpand}
                color="secondary"
                className="clamped-text-more"
                size={12}
              >
                {intl.formatMessage(messages.showLess)}
              </Text>
              <MDIcon icon="chevron-up" size={16} />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
