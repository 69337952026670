import { defineMessages } from "react-intl";

export default defineMessages({
  appTitle: {
    id: "partners.Layout.appTitle",
    defaultMessage: "Recruit Partners",
  },
  backToCjam: {
    id: "partners.Layout.backToCjam",
    defaultMessage: "Back to Account Manager",
  },
  pendoFeedback: {
    id: "partners.Layout.pendoFeedback",
    defaultMessage: "Help",
  },
});
