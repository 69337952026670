import { defineMessages } from "react-intl";

export default defineMessages({
  hasAllOf: {
    id: "partners.BadgesFilter.hasAllOf",
    defaultMessage: "Has all of",
  },
  hasNoneOf: {
    id: "partners.BadgesFilter.hasNoneOf",
    defaultMessage: "Has none of",
  },
  and: {
    id: "partners.BadgesFilter.and",
    defaultMessage: "AND",
  },
  NEW_TO_NETWORK: {
    id: "partners.BadgesFilter.badges.NEW_TO_NETWORK",
    defaultMessage: "New to Network",
  },
  CJ_PERFORMER: {
    id: "partners.BadgesFilter.badges.CJ_PERFORMER",
    defaultMessage: "CJ Performer",
  },
  SUBAFFILIATE: {
    id: "partners.BadgesFilter.badges.SUBAFFILIATE",
    defaultMessage: "Sub-Affiliate",
  },
  CONTENT_CERTIFIED: {
    id: "partners.BadgesFilter.badges.CONTENT_CERTIFIED",
    defaultMessage: "Content Certified",
  },
  CJ_SPOTLIGHT: {
    id: "partners.BadgesFilter.badges.CJ_SPOTLIGHT",
    defaultMessage: "CJ Spotlight",
  },
});
