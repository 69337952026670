import React from "react";
import { ReactComponent as EarningsSvg } from "./earnings.svg";
import "./styles.css";

export const Earnings = ({ level }) => {
  return (
    <EarningsSvg
      className={`earnings earnings-${level}`}
      role="img"
      aria-label={`network earnings ${level}`}
    />
  );
};
