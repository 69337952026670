import { useQuery } from "@tanstack/react-query";
import { authenticatedFetch } from "utils/clients";

const fetchPublisherProfile = (publisherId) => async () => {
  const response = await authenticatedFetch(
    `${process.env.REACT_APP_MEMBER_URL}/affapi/publisher/${publisherId}/profile`
  );
  return response.json();
};

export const usePublisherProfile = (publisherId, enabled) => {
  return useQuery(
    ["fetch publisher profile", publisherId],
    fetchPublisherProfile(publisherId),
    // Our tests assume that this request gets made at all times, so
    // MSW (mock-service-worker) can intercept them and return custom responses.
    // In production, we don't need to fetch the profile if the user isn't authorized to do so.
    { enabled: process.env.NODE_ENV === "test" ? true : enabled }
  );
};
