import { defineMessages } from "react-intl";

export default defineMessages({
  authenticatedPublisher: {
    id: "partners.AuthenticationStatus.authenticatedPublisher",
    defaultMessage: "Authenticated publisher",
  },
  authenticatedProperty: {
    id: "partners.AuthenticationStatus.authenticatedProperty",
    defaultMessage: "Authenticated property",
  },
});
