import React from "react";
import { NumericMetric } from "../metrics";
import { MetricsGrid } from "../MetricsGrid";

export const SnapchatMetrics = ({ metrics }) => {
  return (
    <MetricsGrid>
      <NumericMetric name="followers" value={metrics.followers} />
    </MetricsGrid>
  );
};
