import React, { createContext } from "react";

export const Nav2022Context = createContext({
  providerInitialized: false,
});

export const Nav2022Provider = ({ children, nav2022 }) => {
  return (
    <Nav2022Context.Provider value={{ nav2022, providerInitialized: true }}>
      {children}
    </Nav2022Context.Provider>
  );
};
