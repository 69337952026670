import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { MDIcon } from "@cjdev-internal/visual-stack-x/components/MDIcon";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { EngagementPopover } from "components/EngagementPopover";
import { NULL_STATE_METRIC_PLACEHOLDER } from "constants/metrics";
import { useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React from "react";
import "../styles.css";

export const EngagementMetric = ({ label, rate, socialPlatform }) => {
  const intl = useIntl();
  return (
    <Box
      direction="column"
      gap="small"
      align="center"
      className="social-stat"
      data-testid="engagementLabel"
    >
      <Box direction="row">
        {R.isNil(label) ? (
          <Text weight="medium" size={18}>
            {NULL_STATE_METRIC_PLACEHOLDER}
          </Text>
        ) : (
          <EngagementPopover
            label={label}
            rate={rate}
            socialPlatform={socialPlatform}
          >
            <Box direction="row" align="center">
              <Text weight="medium" size={18}>
                {intl.formatMessage(globalMessages[label])}
              </Text>
              <MDIcon
                icon="chevron-down"
                data-testid="engagement-chevron"
                size={21.5}
              />
            </Box>
          </EngagementPopover>
        )}
      </Box>
      <Text size={12} className="social-stat-center">
        {intl.formatMessage(globalMessages.engagementLabel)}
      </Text>
    </Box>
  );
};
