import { useParams } from "hooks";
import React from "react";
import "../../styles.css";
import { PropertiesTable } from "./PropertiesTable";
import { PropertyDetails } from "./PropertyDetails";

export const PropertiesTab = ({ publisher, searchTerms }) => {
  const { params } = useParams();
  const { propertyId } = params;

  const propertiesTable = !propertyId && (
    <PropertiesTable publisher={publisher} searchTerms={searchTerms} />
  );

  const propertyDetails = propertyId && (
    <PropertyDetails publisher={publisher} searchTerms={searchTerms} />
  );

  return (
    <React.Fragment>
      {propertiesTable}
      {propertyDetails}
    </React.Fragment>
  );
};
