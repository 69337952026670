import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { useIntl } from "hooks";
import React from "react";
import messages from "../messages";
import { NumericMetric } from "../metrics";
import { MetricsGrid } from "../MetricsGrid";

export const TwitchMetrics = ({ metrics }) => {
  const intl = useIntl();
  return (
    <Box direction="column" gap="2xl">
      <MetricsGrid>
        <NumericMetric name="followers" value={metrics.followers} />
        <NumericMetric
          name="averageVideoViews30Days"
          value={metrics.averageVideoViews30Days}
        />
        <NumericMetric name="accountViews" value={metrics.accountViews} />
        <NumericMetric name="numberOfPosts" value={metrics.numberOfPosts} />
        <NumericMetric
          name="accountAvgStreamViews"
          value={metrics.accountAvgStreamViews}
        />
      </MetricsGrid>
      <Box direction="column" gap="large">
        <Text weight="medium" size={14}>
          {intl.formatMessage(messages.timePeriodLast20Posts)}
        </Text>
        <MetricsGrid>
          <NumericMetric
            name="viewsPerPost20Posts"
            value={metrics.viewsPerPost20Posts}
          />
        </MetricsGrid>
      </Box>
    </Box>
  );
};
