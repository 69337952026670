import { Link } from "@cjdev-internal/visual-stack-x/Link";
import React from "react";

export const ExternalLink = ({ className, path, text, newWindow }) => {
  return (
    <Link
      className={className ? className : ""}
      href={path}
      target={newWindow ? "_blank" : "_self"}
      rel="noopener noreferrer"
    >
      {text}
    </Link>
  );
};
