import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import * as R from "ramda";
import React from "react";

const combinedClasses = (className = "", elementClass = "") =>
  R.join(" ", [className, elementClass]);

export const NestedTableTitle = ({ className, children, ...restProps }) => (
  <Box
    {...restProps}
    className={combinedClasses(className, "partners-nested-table-title")}
  >
    <Text>{children}</Text>
  </Box>
);

export const NestedTable = ({ className, children, ...restProps }) => (
  <div
    {...restProps}
    className={combinedClasses(className, "partners-nested-table-container")}
  >
    {children}
  </div>
);

export const NestedTableBody = ({ className, children, ...restProps }) => (
  <div
    {...restProps}
    className={combinedClasses(className, "partners-nested-table-body")}
  >
    {children}
  </div>
);

export const NestedTableHead = ({ className, children, ...restProps }) => (
  <div
    {...restProps}
    className={combinedClasses(className, "partners-nested-table-header")}
  >
    {children}
  </div>
);

export const NestedTr = ({ className, children, ...restProps }) => (
  <div
    {...restProps}
    className={combinedClasses(className, "partners-nested-table-row")}
  >
    {children}
  </div>
);

export const NestedTh = ({ className, children, ...restProps }) => (
  <div
    {...restProps}
    role="columnheader"
    className={combinedClasses(className, "partners-nested-table-column")}
  >
    {children}
  </div>
);

export const NestedTd = ({ className, children, ...restProps }) => (
  <div
    {...restProps}
    className={combinedClasses(className, "partners-nested-table-column")}
  >
    {children}
  </div>
);
