import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { AuthenticationStatusByPublisher } from "components/AuthenticationStatus";
import { Badges } from "components/Badges";
import { Link } from "components/Link";
import { PropertyTypeDetails } from "components/PropertyTypeDetails";
import { publisherProfilePath } from "constants/paths";
import {
  useFeatures,
  useIntl,
  useLocation,
  useRoutePath,
  USER_CAN_AUTHENTICATE_IN_CIQ,
} from "hooks";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React from "react";
import "../../styles.css";

export const PartnerDetailsCell = ({ publisher }) => {
  const intl = useIntl();
  const { location } = useLocation();
  const { search, pathname } = location;
  const { setRouteHistory } = useRoutePath();
  const { hasFeature } = useFeatures();

  const hasUserCanAuthenticateInCiqFeature = hasFeature(
    USER_CAN_AUTHENTICATE_IN_CIQ
  );

  let snippet = R.path([
    "promotionalProperties",
    0,
    "matchingTerms",
    0,
    "textSnippets",
    0,
  ])(publisher);

  if (!snippet) {
    snippet = publisher.networkDescription
      ? publisher.networkDescription.substr(0, 100)
      : "";
  }

  const propertyType = R.path(["promotionalProperties", 0, "type"])(publisher);
  const propertyTypeDetails = R.path([
    "promotionalProperties",
    0,
    "propertyTypeDetails",
  ])(publisher);

  return (
    <Box direction="column" gap="medium" className="partner-details-cell">
      <Box
        direction="row"
        align="center"
        gap="medium"
        paddingTop="small"
        paddingBottom="small"
      >
        <Link
          onClick={() => setRouteHistory(pathname)}
          to={{
            pathname: publisherProfilePath(publisher.id),
            search: search,
          }}
        >
          <Text id={publisher.id} className="publisher-name" weight="medium">
            {publisher.name}
          </Text>
        </Link>
        <Badges publisher={publisher} />
      </Box>
      <Box direction="row" gap="medium">
        <Text size={12}>{publisher.id}</Text>
        <Text size={12} color="secondary">
          •
        </Text>
        <Text size={12} color="secondary">
          {intl.formatMessage(globalMessages.locatedIn, [
            publisher.countrySubdivision,
            publisher.countryCode,
          ])}
          {hasUserCanAuthenticateInCiqFeature && (
            <AuthenticationStatusByPublisher publisher={publisher} />
          )}
        </Text>
      </Box>
      {snippet && (
        <Text
          className="publisher-list-description"
          dangerouslySetInnerHTML={{
            __html: `${snippet}...`,
          }}
          size={12}
        />
      )}
      <Text size={12}>
        <PropertyTypeDetails
          propertyType={propertyType}
          propertyTypeDetails={propertyTypeDetails}
        />
      </Text>
    </Box>
  );
};
