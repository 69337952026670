import { PUBLISHER_FAVORITES_PATH } from "constants/paths";
import * as R from "ramda";
import { isNullOrEmpty } from "utils/utils";

let defaultGraphqlVariable = {
  propertyLevel: {},
  publisherLevel: {},
};

export const transformToGraphqlVariable = ({
  selectedFilters = {
    propertyLevel: {},
    publisherLevel: {},
  },
  excludedPublisherIds = [],
  favoritePublisherIds = [],
  filteredPublishers,
  path = "/",
}) => {
  let graphqlVariable = R.clone(defaultGraphqlVariable);

  if (filteredPublishers && !R.isEmpty(filteredPublishers)) {
    graphqlVariable.publisherLevel.publisherIdsToInclude = filteredPublishers;
  }

  if (path === PUBLISHER_FAVORITES_PATH && isNullOrEmpty(filteredPublishers)) {
    graphqlVariable.publisherLevel.publisherIdsToInclude = favoritePublisherIds;
  }

  if (path !== PUBLISHER_FAVORITES_PATH && !isNullOrEmpty(filteredPublishers)) {
    graphqlVariable.publisherLevel.publisherIdsToInclude = filteredPublishers;
  }

  if (path === PUBLISHER_FAVORITES_PATH && !isNullOrEmpty(filteredPublishers)) {
    // If there's no intersection, then we need to display an empty list, so we specify a non-existent publisher
    const intersection = R.intersection(
      favoritePublisherIds,
      filteredPublishers
    );
    graphqlVariable.publisherLevel.publisherIdsToInclude = isNullOrEmpty(
      intersection
    )
      ? [-1]
      : intersection;
  }

  if (!isNullOrEmpty(excludedPublisherIds)) {
    graphqlVariable.publisherLevel.publisherIdsToExclude = excludedPublisherIds;
  }

  if (isNullOrEmpty(selectedFilters)) return graphqlVariable;

  if (
    !isNullOrEmpty(selectedFilters.propertyLevel.promotionalModels?.selected)
  ) {
    if (selectedFilters.propertyLevel.promotionalModels.include) {
      graphqlVariable.propertyLevel.promotionalModelsToInclude =
        selectedFilters.propertyLevel.promotionalModels.selected;
    }
    if (!selectedFilters.propertyLevel.promotionalModels.include) {
      graphqlVariable.propertyLevel.promotionalModelsToExclude =
        selectedFilters.propertyLevel.promotionalModels.selected;
    }
  }

  if (!isNullOrEmpty(selectedFilters.propertyLevel.propertyTypes?.selected)) {
    if (selectedFilters.propertyLevel.propertyTypes.include) {
      graphqlVariable.propertyLevel.propertyTypesToInclude =
        selectedFilters.propertyLevel.propertyTypes.selected;
    }

    if (!selectedFilters.propertyLevel.propertyTypes.include) {
      graphqlVariable.propertyLevel.propertyTypesToExclude =
        selectedFilters.propertyLevel.propertyTypes.selected;
    }
  }

  if (
    !isNullOrEmpty(selectedFilters.propertyLevel.propertyTypes?.selectedMetrics)
  ) {
    const platforms = Object.keys(
      selectedFilters.propertyLevel.propertyTypes.selectedMetrics
    );

    const platformsSelected = platforms.filter(
      (platform) =>
        selectedFilters.propertyLevel.propertyTypes.selectedMetrics[platform]
          .platformSelected
    );

    const selectedPropertyTypesWithMetrics = platformsSelected.map(
      (platform) => {
        const platformData =
          selectedFilters.propertyLevel.propertyTypes.selectedMetrics[platform];
        const metricsSelected = platformData.metricsSelected;
        const platformMetrics = platformData.metrics || {};
        const metricNames = Object.keys(platformMetrics);
        const metrics = {};

        if (metricsSelected) {
          metricNames.forEach((metricName) => {
            const min = platformMetrics[metricName].minValue;
            const max = platformMetrics[metricName].maxValue;

            if (
              (min !== 0 && max !== Infinity) ||
              (min !== "0" && max !== "Infinity")
            ) {
              // fix when slider is built
              metrics[metricName] = {};
            }

            if (min !== 0 && min !== "0") {
              // fix when slider is built
              metrics[metricName].minValue = parseInt(min);
            }

            if (max !== Infinity && max !== "Infinity") {
              // fix when slider is built
              metrics[metricName].maxValue = parseInt(max);
            }

            return;
          });
        }

        if (platform === "website") {
          return {
            type: "WEBSITE",
            ...(!isNullOrEmpty(metrics) && {
              websiteFilters: {
                metrics,
              },
            }),
          };
        }

        return {
          type: "SOCIAL_MEDIA",
          socialMediaPlatform: {
            [platform]: isNullOrEmpty(metrics)
              ? {}
              : {
                  metrics,
                },
          },
        };
      }
    );

    if (selectedFilters.propertyLevel.propertyTypes.include) {
      if (graphqlVariable.propertyLevel.propertyTypesToInclude) {
        graphqlVariable.propertyLevel.propertyTypesToInclude = graphqlVariable.propertyLevel.propertyTypesToInclude.concat(
          selectedPropertyTypesWithMetrics
        );
      } else {
        if (!R.isEmpty(selectedPropertyTypesWithMetrics)) {
          graphqlVariable.propertyLevel.propertyTypesToInclude = selectedPropertyTypesWithMetrics;
        }
      }
    }

    if (!selectedFilters.propertyLevel.propertyTypes.include) {
      if (graphqlVariable.propertyLevel.propertyTypesToExclude) {
        graphqlVariable.propertyLevel.propertyTypesToExclude = graphqlVariable.propertyLevel.propertyTypesToExclude.concat(
          selectedPropertyTypesWithMetrics
        );
      } else {
        if (!R.isEmpty(selectedPropertyTypesWithMetrics)) {
          graphqlVariable.propertyLevel.propertyTypesToExclude = selectedPropertyTypesWithMetrics;
        }
      }
    }
  }

  if (
    !isNullOrEmpty(selectedFilters.publisherLevel.publisherLocations?.selected)
  ) {
    if (selectedFilters.publisherLevel.publisherLocations.include) {
      const publisherLocations =
        selectedFilters.publisherLevel.publisherLocations.selected;

      const filteredSubdivisionList = R.pipe(
        R.toPairs,
        R.filter(([value, selected]) => selected === 1 && value !== "root"),
        R.map(([value, _]) => value)
      )(publisherLocations);

      const subdivisionList = R.pipe(
        R.filter(R.includes(".")),
        R.map(R.split(".")),
        R.map(([countryCode, subdivisionCode]) => ({
          countryCode,
          subdivisionCode,
        }))
      )(filteredSubdivisionList);
      const countryList = R.reject(R.includes("."))(filteredSubdivisionList);
      graphqlVariable.publisherLevel.countrySubdivisionsToInclude = subdivisionList;
      graphqlVariable.publisherLevel.countriesToInclude = countryList;
    }
    if (!selectedFilters.publisherLevel.publisherLocations.include) {
      const publisherLocations =
        selectedFilters.publisherLevel.publisherLocations.selected;

      const filteredSubdivisionList = R.pipe(
        R.toPairs,
        R.filter(([value, selected]) => selected === 1 && value !== "root"),
        R.map(([value, _]) => value)
      )(publisherLocations);

      const subdivisionList = R.pipe(
        R.filter(R.includes(".")),
        R.map(R.split(".")),
        R.map(([countryCode, subdivisionCode]) => ({
          countryCode,
          subdivisionCode,
        }))
      )(filteredSubdivisionList);
      const countryList = R.reject(R.includes("."))(filteredSubdivisionList);
      graphqlVariable.publisherLevel.countrySubdivisionsToExclude = subdivisionList;
      graphqlVariable.publisherLevel.countriesToExclude = countryList;
    }
  }

  if (!isNullOrEmpty(selectedFilters.publisherLevel.networkEarningsToInclude)) {
    graphqlVariable.publisherLevel.networkEarningsToInclude =
      selectedFilters.publisherLevel.networkEarningsToInclude;
  }

  if (
    !isNullOrEmpty(selectedFilters.publisherLevel.commissionLocations?.selected)
  ) {
    const commissionLocations =
      selectedFilters.publisherLevel.commissionLocations.selected;

    const commissionLocationPercentage =
      selectedFilters.publisherLevel.commissionLocations.percentage;

    const filteredCountryList = R.pipe(
      R.toPairs,
      R.filter(([value, selected]) => selected === 1 && value !== "root"),
      R.map(([value, _]) => value)
    )(commissionLocations);

    if (selectedFilters.publisherLevel.commissionLocations.include) {
      graphqlVariable.publisherLevel.commissionCountriesToInclude = {
        countries: filteredCountryList,
        percentage: commissionLocationPercentage,
      };
    }

    if (!selectedFilters.publisherLevel.commissionLocations.include) {
      graphqlVariable.publisherLevel.commissionCountriesToExclude = filteredCountryList;
    }
  }

  if (
    !isNullOrEmpty(
      selectedFilters.publisherLevel.advertiserCategories?.selected
    )
  ) {
    const { selected } = selectedFilters.publisherLevel.advertiserCategories;
    const advertiserCategoriesPercentage =
      selectedFilters.publisherLevel.advertiserCategories.percentage;

    const commissionCategories = R.pipe(
      R.toPairs,
      R.map(([value, selected]) => [R.last(R.split(".")(value)), selected]),
      R.filter(([value, selected]) => !isNaN(parseInt(value)) && selected),
      R.map(R.head),
      R.map(parseInt),
      R.uniq
    )(selected);
    if (selectedFilters.publisherLevel.advertiserCategories.include) {
      graphqlVariable.publisherLevel.commissionCategoriesToInclude = {
        categories: commissionCategories,
        percentage: advertiserCategoriesPercentage,
      };
    }
    if (!selectedFilters.publisherLevel.advertiserCategories.include) {
      graphqlVariable.publisherLevel.commissionCategoriesToExclude = commissionCategories;
    }
  }

  if (!isNullOrEmpty(selectedFilters.publisherLevel.badges?.include)) {
    if (
      selectedFilters.publisherLevel.badges.include.includes("CJ_PERFORMER")
    ) {
      graphqlVariable.publisherLevel.cjPerformer = "INCLUDE";
    }

    if (
      selectedFilters.publisherLevel.badges.include.includes("SUBAFFILIATE")
    ) {
      graphqlVariable.publisherLevel.subaffiliate = "INCLUDE";
    }

    if (
      selectedFilters.publisherLevel.badges.include.includes("NEW_TO_NETWORK")
    ) {
      graphqlVariable.publisherLevel.newToNetwork = "INCLUDE";
    }

    if (
      selectedFilters.publisherLevel.badges.include.includes(
        "CONTENT_CERTIFIED"
      )
    ) {
      graphqlVariable.publisherLevel.contentCertified = "INCLUDE";
    }

    if (
      path !== PUBLISHER_FAVORITES_PATH &&
      !isNullOrEmpty(filteredPublishers)
    ) {
      graphqlVariable.publisherLevel.publisherIdsToInclude = isNullOrEmpty(
        filteredPublishers
      )
        ? [-1]
        : filteredPublishers;
    }

    if (
      path === PUBLISHER_FAVORITES_PATH &&
      isNullOrEmpty(filteredPublishers)
    ) {
      graphqlVariable.publisherLevel.publisherIdsToInclude = isNullOrEmpty(
        favoritePublisherIds
      )
        ? [-1]
        : favoritePublisherIds;
    }

    if (
      path === PUBLISHER_FAVORITES_PATH &&
      !isNullOrEmpty(filteredPublishers)
    ) {
      // If there's no intersection, then we need to display an empty list, so we specify a non-existent publisher
      const intersection = R.intersection(
        filteredPublishers,
        favoritePublisherIds
      );
      graphqlVariable.publisherLevel.publisherIdsToInclude = isNullOrEmpty(
        intersection
      )
        ? [-1]
        : intersection;
    }
  }

  if (!isNullOrEmpty(selectedFilters.publisherLevel.badges?.exclude)) {
    if (
      selectedFilters.publisherLevel.badges.exclude.includes("CJ_PERFORMER")
    ) {
      graphqlVariable.publisherLevel.cjPerformer = "EXCLUDE";
    }

    if (
      selectedFilters.publisherLevel.badges.exclude.includes("SUBAFFILIATE")
    ) {
      graphqlVariable.publisherLevel.subaffiliate = "EXCLUDE";
    }

    if (
      selectedFilters.publisherLevel.badges.exclude.includes("NEW_TO_NETWORK")
    ) {
      graphqlVariable.publisherLevel.newToNetwork = "EXCLUDE";
    }

    if (
      selectedFilters.publisherLevel.badges.exclude.includes(
        "CONTENT_CERTIFIED"
      )
    ) {
      graphqlVariable.publisherLevel.contentCertified = "EXCLUDE";
    }
  }

  return graphqlVariable;
};
