import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { MDIcon } from "@cjdev-internal/visual-stack-x/components/MDIcon";
import { Popover } from "@cjdev-internal/visual-stack-x/components/Popover";
import { NoData } from "components/NoData";
import { PieChart } from "components/PieChart";
import { useCountriesByCodeQuery, useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React, { useState } from "react";
import { capitalizeName } from "utils/utils";
import messages from "../../messages";

const formatCountryPercentages = (commissions, countriesByCode, intl) => {
  const countries = R.pipe(
    R.sort(R.descend(R.prop("percentage"))),
    R.map(({ country, percentage }) => ({
      category: countriesByCode[country]
        ? capitalizeName(countriesByCode[country].name)
        : intl.formatMessage(globalMessages["global.na"]),
      value: percentage,
    }))
  )(commissions);

  if (countries.length < 6) {
    return countries;
  }

  const top4 = R.take(4, countries);

  const other = {
    category: intl.formatMessage(messages.pieChartOther),
    value: R.pipe(R.drop(4), R.pluck("value"), R.sum)(countries),
  };

  return [...top4, other];
};

export const TopCountryCell = ({ publisher }) => {
  const intl = useIntl();
  const [visible, setVisible] = useState(false);
  const countriesByCodeQuery = useCountriesByCodeQuery();

  if (countriesByCodeQuery.isLoading) {
    return null;
  }

  if (R.either(R.isNil, R.isEmpty)(publisher.lastMonthCountryCommissions)) {
    return <NoData size={12} />;
  }

  const countryPercentages = formatCountryPercentages(
    publisher.lastMonthCountryCommissions,
    countriesByCodeQuery.data,
    intl
  );

  return (
    <Popover
      placement="bottom"
      trigger="click"
      onShow={() => {
        setVisible(true);
      }}
      onHide={() => {
        setVisible(false);
      }}
      content={
        <Box direction="column" align="start" paddingLeft="large">
          <Text weight="medium" size={12}>
            {intl.formatMessage(messages.topCountries)}
          </Text>
          <PieChart
            chartId={`${publisher.id}-country`}
            data={countryPercentages}
            visible={visible}
          ></PieChart>
        </Box>
      }
    >
      <Box direction="row" align="center">
        <Box direction="row" gap="small" align="center">
          <Text
            className="publisher-list-country-name"
            weight="medium"
            size={12}
          >
            {countryPercentages[0].category}
          </Text>
        </Box>
        <MDIcon icon="chevron-down" color="var(--brownish-grey)" size={24} />
      </Box>
    </Popover>
  );
};
