import { useCountriesQuery } from "hooks";
import * as R from "ramda";
import { useMemo } from "react";

export const useCountriesByCodeQuery = () => {
  const countriesQuery = useCountriesQuery();

  return useMemo(
    () => ({
      ...countriesQuery,
      data:
        countriesQuery.data &&
        R.indexBy(R.prop("isoCode"), countriesQuery.data),
    }),
    [countriesQuery]
  );
};
