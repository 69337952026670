import React, { createContext, useState } from "react";

export const MatchingPropertiesContext = createContext({
  expandedPublisherIds: [],
  setExpandedPublisherIds: () => {},
  providerInitialized: false,
});

export const MatchingPropertiesProvider = ({ children }) => {
  const [expandedPublisherIds, setExpandedPublisherIds] = useState([]);

  return (
    <MatchingPropertiesContext.Provider
      value={{
        expandedPublisherIds,
        setExpandedPublisherIds,
        providerInitialized: true,
      }}
    >
      {children}
    </MatchingPropertiesContext.Provider>
  );
};
