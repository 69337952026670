import React, { createContext, useEffect, useState } from "react";

const defaultLimit = 25;
const defaultOffset = 0;
const defaultPage = 1;

export const PaginationContext = createContext({
  limit: defaultLimit,
  offset: defaultOffset,
  page: defaultPage,
  providerInitialized: false,

  setLimit: () => {},
  updatePage: () => {},
  resetPagination: () => {},
});

export const PaginationProvider = ({ children, ...rest }) => {
  const [limit, setLimit] = useState(defaultLimit);
  const [offset, setOffset] = useState(rest.overrideOffset || defaultOffset);
  const [page, setPage] = useState(defaultPage);

  useEffect(() => {
    const calculatedPage = (offset + limit) / limit;
    setPage(calculatedPage);
  }, [offset, limit]);

  const updatePage = (pageNumber) => {
    const calculatedOffset = (pageNumber - 1) * limit;
    setOffset(calculatedOffset);
  };

  const resetPagination = () => {
    setOffset(defaultOffset);
    setLimit(defaultLimit);
  };

  return (
    <PaginationContext.Provider
      value={{
        limit,
        offset,
        setLimit,
        page,
        updatePage,
        resetPagination,
        providerInitialized: true,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};
