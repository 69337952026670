import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { Pill } from "components/Pill";
import { useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import React from "react";
import "./styles.css";

export const PropertyTags = ({ property }) => {
  const intl = useIntl();
  const hasTags = property.tags?.length > 0;

  return (
    <Box direction="column" gap="xs">
      <Text weight="medium" size={14}>
        {intl.formatMessage(globalMessages.TAGS)}
      </Text>
      {hasTags && (
        <Box
          direction="row"
          gap="small"
          className="property-tags"
          paddingTop="medium"
        >
          {property.tags.map((tag, uniqueKey) => {
            return (
              <Pill inverted key={uniqueKey}>
                {tag}
              </Pill>
            );
          })}
        </Box>
      )}
      {!hasTags && (
        <Text color="secondary" italic>
          {intl.formatMessage(globalMessages.noData)}
        </Text>
      )}
    </Box>
  );
};
