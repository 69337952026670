import { Select } from "@cjdev-internal/visual-stack-x/Select";
import { QueryCache } from "@tanstack/react-query";
import { SearchInput } from "components/SearchInput";
import {
  PUBLISHER_FAVORITES_PATH,
  PUBLISHER_SEARCH_PATH,
} from "constants/paths";
import {
  FAVORITE_PUBLISHERS_REACT_QUERY_KEY,
  useHistory,
  useIntl,
  useLocation,
  usePagination,
  useSearchTerms,
  useUser,
} from "hooks";
import * as R from "ramda";
import React from "react";
import { userIsAdvertiser as userCanSeeFavorites } from "utils/authorizationUtils";
import messages from "./messages";
import "./styles.css";

const pathWithParams = (path, searchParams) => `${path}${searchParams}`;

const ConnectedSearchInput = ({ ...rest }) => {
  const { searchTerms, setSearchTerms } = useSearchTerms();
  const { resetPagination } = usePagination();

  return (
    <SearchInput
      value={searchTerms}
      onChange={(input) => {
        resetPagination();
        setSearchTerms(input);
      }}
      {...rest}
    />
  );
};

const PublisherViewSelector = () => {
  const intl = useIntl();
  const { location } = useLocation();
  const { pathname, search } = location;
  const { navigateTo } = useHistory();
  const { resetPagination } = usePagination();
  const queryCache = new QueryCache();

  const allPublisherOption = {
    label: intl.formatMessage(messages.allPublishers),
    value: "All Publishers",
  };
  const favoritePublisherOption = {
    label: intl.formatMessage(messages.myFavorites),
    value: "My Favorites",
  };

  let selectedOption = allPublisherOption;
  if (pathname === PUBLISHER_FAVORITES_PATH) {
    selectedOption = favoritePublisherOption;
  }

  return (
    <Select
      aria-label="select view"
      options={[allPublisherOption, favoritePublisherOption]}
      value={selectedOption}
      isSearchable={false}
      onChange={(e) => {
        //react query is caching the favorite response causing the favorite icons to be unsynced between views
        queryCache.remove(FAVORITE_PUBLISHERS_REACT_QUERY_KEY);
        resetPagination();
        if (R.equals(e, allPublisherOption)) {
          navigateTo(pathWithParams(PUBLISHER_SEARCH_PATH, search));
        }
        if (R.equals(e, favoritePublisherOption)) {
          navigateTo(pathWithParams(PUBLISHER_FAVORITES_PATH, search));
        }
      }}
    />
  );
};

export const SearchBar = () => {
  const { user } = useUser();
  const intl = useIntl();
  return (
    <>
      {userCanSeeFavorites(user) && <PublisherViewSelector />}
      <ConnectedSearchInput
        placeholder={intl.formatMessage(messages.searchPlaceholder)}
        autoFocus
      />
    </>
  );
};
