import { HistoryContext } from "context/ViewContext/HistoryContext";
import { useNav2022 } from "hooks";
import { useProviderInitializedContext } from "./useProviderInitializedContext";

export const useHistory = () => {
  const { nav2022 } = useNav2022();
  const {
    historyConfiguration,
    navigationType,
  } = useProviderInitializedContext(HistoryContext);
  const { history: historyContext } = historyConfiguration;

  const navigateTo = (path) => {
    nav2022 ? historyContext(path) : historyContext.push(path);
  };

  return {
    navigateTo,
    navigationType,
  };
};
