import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { MDIcon } from "@cjdev-internal/visual-stack-x/components/MDIcon";
import { Popover } from "@cjdev-internal/visual-stack-x/components/Popover";
import { useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import React from "react";
import { formatMetricName, getPrimaryMetricFromPlatform } from "utils/ciqUtils";
import messages from "./messages";

const descriptionForEngagementLevel = {
  FAIR: messages.engagementDescriptionFair,
  GOOD: messages.engagementDescriptionGood,
  EXCELLENT: messages.engagementDescriptionExcellent,
};

const engagementFormulaMessagesForPlatform = {
  instagram: messages.instagramEngagementFormula,
  facebook: messages.facebookEngagementFormula,
  twitter: messages.twitterEngagementFormula,
  youtube: messages.youtubeEngagementFormula,
  twitch: messages.twitchEngagementFormula,
  pinterest: messages.pinterestEngagementFormula,
  tiktok: messages.tiktokEngagementFormula,
};

export const EngagementPopover = ({
  children,
  label,
  rate,
  socialPlatform,
  ...restProps
}) => {
  const intl = useIntl();
  const engagementDescription = descriptionForEngagementLevel[label];
  const engagementFormula =
    engagementFormulaMessagesForPlatform[socialPlatform];

  const formattedEngagementFormula = engagementFormula
    ? intl.formatMessage(engagementFormula)
    : intl.formatMessage(globalMessages["global.na"]);

  return (
    <Popover
      placement="bottom"
      trigger="click"
      content={
        <Box direction="column" gap="medium" align="start">
          <Text weight="medium" size={12}>
            {`${Math.max(rate * 100, 1).toFixed(2)}% ${intl.formatMessage(
              globalMessages.engagementRate20Posts
            )}`}
          </Text>
          <Text>{intl.formatMessage(messages.timePeriodLast20Posts)}</Text>
          <Text>
            {intl.formatMessage(messages.formula)}: {formattedEngagementFormula}
          </Text>
          <Text>
            <MDIcon icon="information-outline" align="text-bottom" size={17} />{" "}
            {socialPlatform &&
              engagementDescription &&
              intl.formatMessage(engagementDescription, [
                intl.formatMessage(
                  globalMessages[socialPlatform.toUpperCase()]
                ),
                formatMetricName(
                  getPrimaryMetricFromPlatform(socialPlatform),
                  intl
                ),
              ])}
          </Text>
        </Box>
      }
      {...restProps}
    >
      {children}
    </Popover>
  );
};
