import { IconButton } from "@cjdev-internal/visual-stack-x/components/Button";
import { Tooltip } from "components/Tooltip";
import { useIntl } from "hooks";
import React from "react";
import messages from "views/PublishersSearch/messages";
import favoriteMessages from "./messages";
import "./styles.css";

export const Favorite = ({
  onClick,
  toggleWarningAlert,
  isFavorite,
  size,
  ...rest
}) => {
  const intl = useIntl();
  const className = isFavorite ? "is-favorited" : "";
  const props = {
    className: `favorite-icon${isFavorite ? "-filled" : ""}`,
    size,
  };

  return (
    <Tooltip content={intl.formatMessage(messages.favoritesToolTip)}>
      <IconButton
        icon={isFavorite ? "heart" : "heart-outline"}
        round
        className={className}
        size={size}
        onClick={async () => {
          try {
            await onClick(isFavorite);
          } catch (e) {
            toggleWarningAlert(
              intl.formatMessage(favoriteMessages.favoriteError)
            );
          }
        }}
        {...rest}
        {...props}
      />
    </Tooltip>
  );
};
