import { defineMessages } from "react-intl";

export default defineMessages({
  noPromotionalModel: {
    id: "partners.PropertyRow.noPromotionalModel",
    defaultMessage: "No promotional model provided",
  },
  matchingProperty: {
    id: "partners.PropertyRow.matchingProperty",
    defaultMessage: "Matching Property",
  },
  topMatchingProperties: {
    id: "partners.PropertyRow.topMatchingProperties",
    defaultMessage: "Top Matching Properties",
  },
  moreDetails: {
    id: "partners.PropertyRow.moreDetails",
    defaultMessage: "More Details",
  },
  viewAllProperties: {
    id: "partners.PropertyRow.viewAllProperties",
    defaultMessage: "View All Properties",
  },
});
