import { useHistory, useLocation, useSelectedPublishers } from "hooks";
import { parse } from "query-string";
import * as R from "ramda";
import React, { useEffect } from "react";

export const SearchTermsContext = React.createContext({
  searchTerms: "",
  setSearchTerms: () => {},
  providerInitialized: false,
});

const setSearchTerms = ({ navigateTo, search }) => (searchTerms) => {
  const params = new URLSearchParams(search);
  if (R.isEmpty(searchTerms)) {
    params.delete("search");
  } else {
    params.set("search", searchTerms);
  }
  navigateTo({ search: params.toString() });
};

export const SearchTermsProvider = ({ children, ...rest }) => {
  const { location } = useLocation();
  const { search } = location;
  const { search: searchTerms } = parse(search);
  const { navigateTo } = useHistory();

  return (
    <SearchTermsContext.Provider
      value={{
        searchTerms: R.defaultTo("")(searchTerms),
        setSearchTerms: setSearchTerms({
          navigateTo,
          search,
        }),
        providerInitialized: true,
        ...rest,
      }}
    >
      {children}
    </SearchTermsContext.Provider>
  );
};

const SearchTermsProviderUpdater = ({ children }) => {
  const { toggleAllPublisherIdsOff } = useSelectedPublishers();
  const { navigationType } = useHistory();
  const { location } = useLocation();
  const { search } = location;
  const { search: searchTerms } = parse(search);

  // resets selected publishers if searchTerms is updated
  // - SearchTermsProvider is required in Dimensions for platform-navigation
  // - Dimensions does not have access to SelectedPublishersContext

  useEffect(() => {
    // does not trigger on app loading, only when react router pushs new url/params within partners app
    if (navigationType === "PUSH") {
      toggleAllPublisherIdsOff();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerms]);

  return <>{children}</>;
};

export const SearchTermsViewProvider = ({ children }) => {
  return (
    <SearchTermsProvider>
      <SearchTermsProviderUpdater>{children}</SearchTermsProviderUpdater>
    </SearchTermsProvider>
  );
};
