import { Box } from "@cjdev-internal/visual-stack-x/Box";
import React, { useEffect, useState } from "react";
import { MinMaxSlider } from "./MinMaxSlider";
import { MinMaxTextInput } from "./MinMaxTextInput";
import "./styles.css";
import { produceRangeSliderEvents } from "./utils/rangeSliderEvents";
import { produceStepMarkers } from "./utils/rangeSliderStepUtils";
import { formatDisplayForTextInput } from "./utils/rangeSliderUtils";

export const RangeSlider = ({
  steps,
  textPlaceholderForMaxThreshold,
  minValue,
  maxValue,
  onMinUpdate,
  onMaxUpdate,
  identifier,
}) => {
  const { maxThreshold } = produceStepMarkers(steps);

  const [minSliderValue, setMinSliderValue] = useState(minValue);
  const [maxSliderValue, setMaxSliderValue] = useState(
    maxValue === Infinity ? maxThreshold : maxValue
  );

  const [minTextValue, setMinTextValue] = useState(minValue);
  const [maxTextValue, setMaxTextValue] = useState(maxValue);

  const updateMin = (newValue) => {
    setMinTextValue(newValue);
    onMinUpdate(newValue);
  };

  const updateMax = (newValue) => {
    setMaxTextValue(newValue);
    onMaxUpdate(newValue);
  };

  useEffect(() => {
    onMinUpdate(minTextValue);
  }, [onMinUpdate, minTextValue]);

  useEffect(() => {
    onMaxUpdate(maxTextValue);
  }, [onMaxUpdate, maxTextValue]);

  const {
    onMinTextAdjustment,
    onMinTextConfirmation,
    onMaxTextAdjustment,
    onMaxTextConfirmation,
    onMinSliderAdjustment,
    onMinSliderConfirmation,
    onMaxSliderAdjustment,
    onMaxSliderConfirmation,
  } = produceRangeSliderEvents({
    steps,
    textPlaceholderForMaxThreshold,
    minSliderValue,
    maxSliderValue,
    setMinSliderValue,
    setMaxSliderValue,
    minTextValue,
    maxTextValue,
    updateMin,
    updateMax,
  });

  return (
    <Box>
      <Box paddingBottom="medium">
        <MinMaxSlider
          minThreshold={0}
          maxThreshold={maxThreshold}
          min={minSliderValue}
          max={maxSliderValue}
          onMinChange={onMinSliderAdjustment}
          onMaxChange={onMaxSliderAdjustment}
          onMinSelection={onMinSliderConfirmation}
          onMaxSelection={onMaxSliderConfirmation}
          step={1000}
        />
      </Box>
      <Box direction="row" className="min-max-slider-input" gap="medium">
        <MinMaxTextInput
          identifier={identifier + "MinValue"}
          value={formatDisplayForTextInput(
            minTextValue,
            textPlaceholderForMaxThreshold
          )}
          onChange={onMinTextAdjustment}
          onSelection={onMinTextConfirmation}
        />
        <MinMaxTextInput
          identifier={identifier + "MaxValue"}
          value={formatDisplayForTextInput(
            maxValue,
            textPlaceholderForMaxThreshold
          )}
          onChange={onMaxTextAdjustment}
          onSelection={onMaxTextConfirmation}
        />
      </Box>
    </Box>
  );
};
