import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import {
  BlankSlate,
  PrimaryActionButton,
} from "@cjdev-internal/visual-stack-x/components/BlankSlate";
import { IconButton } from "@cjdev-internal/visual-stack-x/components/Button";
import {
  Table,
  TableContainer,
  TBody,
  Th,
  THead,
  Tr,
} from "@cjdev-internal/visual-stack-x/components/Table";
import classNames from "classnames";
import {
  GenerateSimilarwebData,
  getDataById,
} from "components/GenerateSimilarwebData";
import { Spinner } from "components/Spinner";
import { Tooltip } from "components/Tooltip";
import {
  TIKTOK_METRICS,
  useFeatures,
  useIntl,
  usePropertyListQuery,
  USER_CAN_AUTHENTICATE_IN_CIQ,
  useSearchTerms,
} from "hooks";
import * as R from "ramda";
import React from "react";
import messages from "../../../messages";
import { PropertyRow } from "./PropertyRow";
import "./styles.css";

export const PropertiesTable = ({ publisher }) => {
  const intl = useIntl();
  const { searchTerms } = useSearchTerms();
  const { publisherId } = publisher;
  const containsSearchTerm = !R.isNil(searchTerms) && !R.isEmpty(searchTerms);
  const { hasFeature } = useFeatures();

  const { data, loading, error } = usePropertyListQuery(publisherId);

  if (loading) {
    return (
      <Box align="center">
        <Spinner />
      </Box>
    );
  }

  const hasUserCanAuthenticateInCiqFeature = hasFeature(
    USER_CAN_AUTHENTICATE_IN_CIQ
  );
  const hasTikTokMetrics = hasFeature(TIKTOK_METRICS);

  if (error) {
    return (
      <BlankSlate title={intl.formatMessage(messages.serverError)}>
        <PrimaryActionButton
          label={"refresh"}
          handler={() => window.location.reload()}
        ></PrimaryActionButton>
      </BlankSlate>
    );
  }

  const prodUrl = `https://accounts.api.cj.com/download-promotional-properties?publisherId=${publisherId}`;
  const downloadButton = (
    <a
      href={prodUrl}
      rel="noopener noreferrer nofollow"
      aria-label="download promotional properties"
    >
      <IconButton
        icon="download"
        outline
        size={16}
        data-testid="promotional-properties-download"
        className="property-download-button"
      />
    </a>
  );

  const numberOfProperties =
    R.path(["topPromotionalProperties", "resultList", "length"], data) || 0;

  const totalProperties =
    R.path(["topPromotionalProperties", "totalCount"], data) || 0;

  const matchingPropertiesNumber = data
    ? data.topPromotionalProperties.resultList.filter(
        (property) => property.isMatchingSearchTerm
      ).length
    : 0;

  const properties = data.topPromotionalProperties.resultList || [];

  return (
    <TableContainer className="table-container-promotional-properties">
      <Box
        direction="row"
        paddingTop="medium"
        paddingBottom="medium"
        align="center"
        justify="space-between"
        data-testid="properties-tab-table"
      >
        <Text weight="medium">
          {intl.formatMessage(messages.placementPromotionalProperties)}
        </Text>
        <Box direction="row" align="center" gap="large">
          <Text color="secondary" size={13}>
            {numberOfProperties > 1
              ? intl.formatMessage(messages.totalProperties, [
                  numberOfProperties,
                  totalProperties,
                ])
              : intl.formatMessage(messages.totalProperty, [
                  numberOfProperties,
                  totalProperties,
                ])}
          </Text>

          <Tooltip
            className="download-properties-button-tooltip"
            content={intl.formatMessage(messages.downloadProperties)}
          >
            {downloadButton}
          </Tooltip>
        </Box>
      </Box>
      {containsSearchTerm && (
        <Box
          direction="row"
          paddingBottom="large"
          gap="large"
          align="center"
          data-testid="number-of-matching-properties"
        >
          {matchingPropertiesNumber > 0 && (
            <Text
              color="secondary"
              className={classNames({
                "match-search-query": !hasUserCanAuthenticateInCiqFeature,
              })}
              data-testid="subheading-dot"
            >
              {`${matchingPropertiesNumber} `}
              {matchingPropertiesNumber > 1
                ? `${intl.formatMessage(messages.propertiesMatching)} `
                : `${intl.formatMessage(messages.propertyMatching)}`}
              <Text
                color="secondary"
                style={{ padding: "3px 6px", backgroundColor: "#fff3d2" }}
              >
                {searchTerms}
              </Text>
            </Text>
          )}
        </Box>
      )}
      <GenerateSimilarwebData
        properties={properties}
        calledFrom="profile"
        similarwebDependantComponent={({ similarwebData }) => (
          <Table className="truncate-table">
            <colgroup>
              <col className="promotional-property-column-pid" />
              <col className="promotional-property-column-name" />
              <col className="promotional-property-column-type" />
              <col className="promotional-property-column-promotional-model" />
              <col className="promotional-property-column-details" />
              <col />
            </colgroup>
            <THead>
              <Tr>
                <Th>{intl.formatMessage(messages.propertyId)}</Th>
                <Th>{intl.formatMessage(messages.propertyName)}</Th>
                <Th>{intl.formatMessage(messages.propertyType)}</Th>
                <Th>{intl.formatMessage(messages.promotionalModel)}</Th>
                <Th>{intl.formatMessage(messages.propertyDetails)}</Th>
                <Th>{intl.formatMessage(messages.reach)}</Th>
              </Tr>
            </THead>
            <TBody>
              {properties.map((property) => (
                <PropertyRow
                  key={property.id}
                  similarwebData={getDataById(similarwebData, property.id)}
                  {...{
                    property,
                    publisherId,
                    hasUserCanAuthenticateInCiqFeature,
                  }}
                  hasTikTokMetrics={hasTikTokMetrics}
                />
              ))}
            </TBody>
          </Table>
        )}
      />
    </TableContainer>
  );
};
