import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useCountriesByCodeQuery } from "hooks";
import globalMessages from "messages/globalMessages";
import { useEffect } from "react";
import { capitalizeName } from "utils/utils";

am4core.options.commercialLicense = true;
am4core.useTheme(am4themes_animated);

// functions to generate colors for dynamic range

export const hexToRGBArray = (hex) => {
  const redDecimalValue = parseInt("0x" + hex[1] + hex[2]);
  const greenDecimalValue = parseInt("0x" + hex[3] + hex[4]);
  const blueDecimalValue = parseInt("0x" + hex[5] + hex[6]);

  const rgbArray = [redDecimalValue, greenDecimalValue, blueDecimalValue];
  return rgbArray;
};

export const rgbArrayToHex = (rgbArray) => {
  const redHexSegment = rgbArray[0].toString(16).padStart(2, "0");
  const greenHexSegment = rgbArray[1].toString(16).padStart(2, "0");
  const blueHexSegment = rgbArray[2].toString(16).padStart(2, "0");

  const hexCode = "#" + redHexSegment + greenHexSegment + blueHexSegment;
  return hexCode;
};

export const translateColorStepByFactor = (color1, color2, factor) => {
  const combinedRGBArray = color1.map((_, index) =>
    Math.round(color1[index] + factor * (color2[index] - color1[index]))
  );
  return rgbArrayToHex(combinedRGBArray);
};

export const generateColorsBetween = (color1, color2, steps) => {
  const usableSteps = steps > 2 ? steps : 2;
  const stepFactor = 1 / (usableSteps - 1);
  const generatedColorArray = [];

  for (let i = 0; i < steps; i++) {
    generatedColorArray.push(
      translateColorStepByFactor(
        hexToRGBArray(color1),
        hexToRGBArray(color2),
        stepFactor * i
      )
    );
  }

  return generatedColorArray;
};

const setLocationHover = (mapPolygonSeries, hoverState) => {
  if (mapPolygonSeries.name !== "Other") {
    const location = mapPolygonSeries.mapPolygons.values[0];
    if (hoverState) {
      mapPolygonSeries.chart.zoomToMapObject(location);
    } else {
      location.tooltip.hide();
    }

    // add delay to allow map time to zoom to correct country before showing tooltip

    setTimeout(() => {
      location.isHover = hoverState;
    }, 800);
  }
};

export const useCountryMapAmChart = (mapId, data, intl) => {
  const countriesByCodeQuery = useCountriesByCodeQuery();

  useEffect(() => {
    if (countriesByCodeQuery.isLoading) {
      return;
    }

    // create map container
    let map = am4core.create(`map-div-${mapId}`, am4maps.MapChart);
    map.height = am4core.percent(100);
    map.geodata = am4geodata_worldLow;
    map.projection = new am4maps.projections.EqualEarth();

    // zoom control disabled
    map.seriesContainer.draggable = false;
    map.seriesContainer.resizable = false;
    map.chartContainer.wheelable = false;

    // add data to map
    const dataPercentages = data.map((country) =>
      Math.round(country.percentOfTotal)
    );
    const highRange = Math.max(...dataPercentages);
    const lowRange = Math.min(...dataPercentages);
    const range = highRange - lowRange;
    const colorListBasedOnRange = generateColorsBetween(
      "#00004A",
      "#43C8F9",
      range + 1
    );

    data.forEach((country) => {
      let seriesInstance = map.series.push(new am4maps.MapPolygonSeries());
      seriesInstance.useGeodata = true;

      const countryName = capitalizeName(
        countriesByCodeQuery.data[country.countryCode]
          ? countriesByCodeQuery.data[country.countryCode].name
          : intl.formatMessage(globalMessages["global.na"])
      );

      seriesInstance.name = countryName;
      const displayPercentage = Math.round(country.percentOfTotal);
      seriesInstance.value = displayPercentage > 1 ? displayPercentage : "<1";

      const colorPercentage = Math.round(country.percentOfTotal);
      const assignedColor =
        colorListBasedOnRange[range + lowRange - colorPercentage];
      seriesInstance.fill = am4core.color(assignedColor);

      seriesInstance.include =
        country.countryCode === "other" ? [] : [country.countryCode];
      seriesInstance.mapPolygons.template.fill = am4core.color(assignedColor);
      seriesInstance.mapPolygons.template.tooltipText = `${countryName} {value}%`;
      seriesInstance.legendSettings.labelText = "{value}% {name}";
      seriesInstance.calculateVisualCenter = true;
      seriesInstance.mapPolygons.template.tooltipPosition = "fixed";
    });

    // Unmarked Country Definitions

    // Create untouched series of countries and load country names from GeoJSON

    let untouchedSeries = map.series.push(new am4maps.MapPolygonSeries());
    untouchedSeries.useGeodata = true;
    untouchedSeries.hiddenInLegend = true;
    untouchedSeries.fill = am4core.color("#DBDEE2");

    // Remove countries from being displayed as untouched

    const activeCountries = data.map((country) => country.countryCode);
    untouchedSeries.exclude = activeCountries.concat(["AQ"]); // removes Antarctica as well

    // legend

    // seperate legend container

    let legendContainer = am4core.create(
      `map-legend-${mapId}`,
      am4core.Container
    );

    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);
    legendContainer.paddingLeft = -10;

    // legend object

    map.legend = new am4charts.Legend();
    let legend = map.legend;
    legend.useDefaultMarker = true;
    legend.scrollable = true;
    legend.parent = legendContainer;

    let legendTemplate = legend.itemContainers.template;
    legendTemplate.paddingTop = 4;
    legendTemplate.paddingBottom = 4;
    legendTemplate.clickable = false;
    legendTemplate.focusable = false;
    legendTemplate.cursorOverStyle = am4core.MouseCursorStyle.default;

    // legend sizing

    legend.maxHeight = 100;
    legend.position = "left";
    legend.valign = "top";
    legend.fontSize = "11px";

    let labelTemplate = legend.labels.template;
    labelTemplate.maxWidth = 116;
    labelTemplate.truncate = true;

    // add hover events to legend

    legend.itemContainers.template.events.on("over", (event) =>
      setLocationHover(event.target.dataItem.dataContext, true)
    );

    legend.itemContainers.template.events.on("out", (event) =>
      setLocationHover(event.target.dataItem.dataContext, false)
    );

    // when hovering off legend, zoom out map

    legend.events.on("out", (event) => map.goHome());

    // legend marker

    let markerTemplate = map.legend.markers.template;
    markerTemplate.width = 10;
    markerTemplate.height = 10;

    let marker = markerTemplate.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);

    return () => {
      map.dispose();
      legendContainer.dispose();
    };
  }, [mapId, data, intl, countriesByCodeQuery]);
};
