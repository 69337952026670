import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { NoData } from "components/NoData";
import * as R from "ramda";
import React from "react";

export const IndustryRank = ({ categoryRank }) => {
  if (!categoryRank || R.isNil(categoryRank.category)) {
    return <NoData />;
  }

  const categories = categoryRank.category.replace(/_/g, " ").split("/");
  const parentCategory = categories[0];
  const childCategory = categories[1];

  return (
    <Box
      direction="column"
      gap="small"
      paddingTop="medium"
      paddingBottom="medium"
    >
      <Box>
        <Text weight="medium" size={18}>
          #{categoryRank.rank}
        </Text>
      </Box>
      <Box>
        <Text size={12}>{`${parentCategory} > ${childCategory}`}</Text>
      </Box>
    </Box>
  );
};
