import { MDIcon } from "@cjdev-internal/visual-stack-x/components/MDIcon";
import { useIntl } from "hooks";
import React from "react";
import messages from "./messages";
import "./styles.css";

const size = 13;

const AuthenticatedIcon = ({ title, ...restProps }) => (
  <MDIcon
    icon="check-decagram"
    size={size}
    role="img"
    title={title}
    color="#757575"
    {...restProps}
  />
);

export const AuthenticationStatusByPublisher = ({
  publisher,
  ...restProps
}) => {
  const intl = useIntl();
  const publisherAuthenticatedCount =
    publisher && publisher.authenticatedSocialMediaCount;

  return publisherAuthenticatedCount ? (
    <AuthenticatedIcon
      title={intl.formatMessage(messages.authenticatedPublisher)}
      {...restProps}
    />
  ) : null;
};

export const AuthenticationStatusByProperty = ({ property, ...restProps }) => {
  const intl = useIntl();
  const authenticatedStatus =
    property && property.authenticationStatus === "AUTHENTICATED";
  const socialMediaType = property && property.type === "SOCIAL_MEDIA";

  return socialMediaType && authenticatedStatus ? (
    <AuthenticatedIcon
      title={intl.formatMessage(messages.authenticatedProperty)}
      {...restProps}
    />
  ) : null;
};
