import { useQuery } from "@tanstack/react-query";
import { generateBrowserToken } from "utils/browserToken";
import { authenticatedFetch } from "utils/clients";

const fetchSimilarwebData = (propertyIds, calledFrom) => async () => {
  const salt = "53696d696c6172776562";
  const lifespan = "5m";
  const tbst = generateBrowserToken(salt, lifespan);
  const options = {
    headers: {
      tbst,
    },
  };

  const propertyIdsQuery = `propertyIds=${propertyIds.join(",")}`;
  const calledFromQuery = `&calledFrom=${calledFrom}`;

  const response = await authenticatedFetch(
    `${process.env.REACT_APP_ACCOUNTS_REST_API_URL}/internal/similarweb-metrics?${propertyIdsQuery}${calledFromQuery}`,
    options
  );

  return await response.json();
};

export const useSimilarwebMetrics = (
  propertyIds = [],
  calledFrom = "partnersFrontend"
) => {
  const orderedIds = propertyIds.map(Number).sort((a, b) => a - b);
  return useQuery(
    ["fetch Similarweb data by property ids", ...orderedIds],
    fetchSimilarwebData(propertyIds, calledFrom),
    {
      retry: 0,
    }
  );
};
