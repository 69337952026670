import { Button } from "@cjdev-internal/visual-stack-x/Button.js";
import { useIntl } from "hooks";
import React from "react";
import { copyTextUsingDomElement } from "../copyUtil";
import messages from "../messages";

export const CopyButton = ({ text, onClick }) => {
  const intl = useIntl();
  const copy = (text) => {
    copyTextUsingDomElement(text);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          copy(text);
          onClick();
        }}
      >
        {intl.formatMessage(messages.copy)}
      </Button>
    </>
  );
};
