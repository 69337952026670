import { IconButton } from "@cjdev-internal/visual-stack-x/components/Button";
import { useMatchingProperties } from "hooks";
import React from "react";
import "./styles.css";

export const MatchingPropertiesExpandCollapseAllButton = ({
  allPublisherIds,
}) => {
  const {
    expandedPublisherIds,
    setExpandedPublisherIds,
  } = useMatchingProperties();

  const expandCollapseAllMatchingProperties = () => {
    if (allPublisherIds.length === expandedPublisherIds.length) {
      setExpandedPublisherIds([]);
    } else {
      setExpandedPublisherIds(allPublisherIds);
    }
  };

  return (
    <IconButton
      icon="arrow-expand-vertical"
      outline
      className="matching-properties-expand-collapse-all-button"
      onClick={expandCollapseAllMatchingProperties}
      aria-label="Show Matching Properties"
    />
  );
};
