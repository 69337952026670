import { useQuery } from "@tanstack/react-query";
import { useCompany, useUser } from "hooks";
import { userIsAdvertiser } from "utils/authorizationUtils";
import { authenticatedFetch } from "utils/clients";
import { MAX_OPPORTUNITIES_TO_DISPLAY } from "views/PublishersProfile/PublisherTabs/OverviewTab/PlacementsTable";

export const useOpportunitiesQuery = (publisher) => {
  const { user } = useUser();
  const company = useCompany();
  const companyId = company.id;
  const publisherId = publisher.publisherId;

  const queryParams = userIsAdvertiser(user)
    ? `visibleTo=${companyId}&status=AVAILABLE&companySearchParameter=${publisherId}&rpp=${MAX_OPPORTUNITIES_TO_DISPLAY}&page=1`
    : `owner=${publisherId}&status=AVAILABLE&includeSetupPublisher=true&rpp=${MAX_OPPORTUNITIES_TO_DISPLAY}&page=1`;

  return useQuery(
    ["opportunities", companyId, publisherId],
    async () => {
      const response = await authenticatedFetch(
        `${process.env.REACT_APP_MEMBER_URL}/affapi/placements/opportunities?${queryParams}`
      );
      return await response.json();
    },
    {
      retry: 0,
      enabled: !!user,
    }
  );
};
