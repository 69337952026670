import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { useIntl } from "hooks";
import React from "react";
import messages from "../messages";
import { DateMetric, NumericMetric, RateMetric } from "../metrics";
import { MetricsGrid } from "../MetricsGrid";

export const PinterestMetrics = ({ metrics }) => {
  const intl = useIntl();
  return (
    <Box direction="column" gap="2xl">
      <MetricsGrid>
        <NumericMetric name="followers" value={metrics.followers} />
        <DateMetric name="lastPostDate" value={metrics.lastPostDate} />
        <NumericMetric
          name="sharesPerPostLifetime"
          value={metrics.sharesPerPostLifetime}
        />
        <NumericMetric name="accountPins" value={metrics.accountPins} />
        <NumericMetric
          name="accountAverageMonthlyViews"
          value={metrics.accountAverageMonthlyViews}
        />
      </MetricsGrid>
      <Box direction="column" gap="large">
        <Text weight="medium" size={14}>
          {intl.formatMessage(messages.timePeriodLast20Posts)}
        </Text>
        <MetricsGrid>
          <RateMetric
            name="engagementRate20Posts"
            value={metrics.engagementRate20Posts}
          />
        </MetricsGrid>
      </Box>
    </Box>
  );
};
