import React from "react";

export const MinMaxBar = ({ minPercentage, maxPercentage }) => {
  const buffer = 2;

  const caluculateMinWidth = (percentage) => {
    const percentageWithBuffer =
      percentage > buffer ? percentage + buffer : percentage;
    return percentageWithBuffer;
  };

  const caluculateMaxWidth = (percentage) => {
    const percentageWithBuffer =
      percentage > buffer ? percentage - buffer : percentage;
    return 100 - percentageWithBuffer;
  };

  return (
    <>
      <div
        className="inverse-left"
        style={{ width: `${caluculateMinWidth(minPercentage)}%` }}
      />
      <div
        className="inverse-right"
        style={{ width: `${caluculateMaxWidth(maxPercentage)}%` }}
      />
      <div
        className="range"
        style={{
          left: `${minPercentage}%`,
          right: `${100 - maxPercentage}%`,
        }}
      />
    </>
  );
};
