import { CountryMap } from "components/CountryMap";
import { NoData } from "components/NoData";
import { numericToAlpha2 } from "i18n-iso-countries";
import * as R from "ramda";
import React from "react";
import { isNullOrEmpty } from "utils/utils";

export const CountryMapChart = ({ mapId, topCountryShares }) => {
  if (isNullOrEmpty(topCountryShares)) {
    return <NoData />;
  }

  const countryMapData = R.defaultTo([])(topCountryShares).map(
    convertToCountryMapFormat
  ); // TODO: inline, useMemo

  return <CountryMap mapId={mapId} data={countryMapData} />;
};

const convertToCountryMapFormat = (countryShare) => {
  return {
    countryCode: numericToAlpha2(countryShare.country),
    percentOfTotal: Math.round(countryShare.value * 100),
  };
};
