import {
  SlidingPanelDropdown as VSDropdown,
  SlidingPanelHeader as VSHeader,
} from "@cjdev-internal/visual-stack-x/SlidingPanel";
import { Popover } from "@cjdev-internal/visual-stack-x/components/Popover";
import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Button } from "@cjdev-internal/visual-stack-x/Button";
import { MDIcon } from "@cjdev-internal/visual-stack-x/components/MDIcon";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { useAppliedFilters, useIntl, usePagination } from "hooks";
import * as R from "ramda";
import React, { useState } from "react";
import messages from "../messages";
import { AdvertiserCategoriesFilter } from "./AdvertiserCategoriesFilter";
import { BadgesFilter } from "./BadgesFilter";
import { CommissionsLocationFilter } from "./CommissionsLocationFilter";
import { NetworkEarningsFilter } from "./NetworkEarningsFilter";
import { PromotionalModelFilter } from "./PromotionalModelsFilter";
import { PropertyReachFilter } from "./PropertyReachFilter";
import { PropertyTypesFilter } from "./PropertyTypesFilter";
import { PublisherLocationFilter } from "./PublisherLocationFilter";
import "./styles.css";

export const NewNavFilters = ({ user }) => {
  return (
    <span className="filters-panel">
      <FiltersPanel user={user} />
    </span>
  );
};

export const FiltersPanel = ({ user }) => {
  const intl = useIntl();

  const {
    numberOfAppliedFilters,
    appliedFiltersInformation,
    appliedFilters,
    updateAppliedFilters,
    defaultFilters,
  } = useAppliedFilters();

  const [filters, setFilters] = useState(appliedFilters);
  const { resetPagination } = usePagination();

  const getIncludeOrExcludeText = (include) => {
    switch (include) {
      case false:
        return intl.formatMessage(messages.excluded);
      case true:
        return intl.formatMessage(messages.included);
      default:
        return "";
    }
  };

  const formatPillText = (appliedFilterDetails) => {
    const text =
      appliedFilterDetails.number > 0
        ? R.trim(
            `${appliedFilterDetails.number} ${getIncludeOrExcludeText(
              appliedFilterDetails.include
            )}`
          )
        : "";

    if (text) return text;
  };

  const clearFilters = () => {
    setFilters(defaultFilters);
    resetPagination();
    updateAppliedFilters(defaultFilters);
  };

  return (
    <>
      <VSHeader>
        <Box direction="row" align="center" justify="space-between">
          <Text>
            {numberOfAppliedFilters === 1
              ? intl.formatMessage(messages.activeFilter, [
                  numberOfAppliedFilters,
                ])
              : intl.formatMessage(messages.activeFilters, [
                  numberOfAppliedFilters,
                ])}
          </Text>
          <Button
            data-testid="clear-all-filters-button"
            type="tertiary"
            onClick={() => {
              clearFilters();
            }}
          >
            {intl.formatMessage(messages.clearAll)}
          </Button>
        </Box>
        <Box expand direction="column" paddingTop="small">
          <Button
            id="apply-filter-button"
            data-testid="apply-filter-button"
            type="primary"
            onClick={() => {
              resetPagination();
              updateAppliedFilters(filters);
            }}
          >
            {intl.formatMessage(messages.apply)}
          </Button>
        </Box>
        <Box
          direction="column"
          paddingTop="medium"
          align="center"
          data-testid="filters-tooltip"
          id="filters-tooltip"
        >
          <Popover
            trigger="click"
            content={
              <Box direction="column" gap="medium">
                <Text
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      messages.promotionalModelsToolTip,
                      { b: (s) => `<b>${s}</b>` }
                    ),
                  }}
                />
                <Text
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(messages.propertyTypesToolTip, {
                      b: (s) => `<b>${s}</b>`,
                    }),
                  }}
                />
                <Text
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      messages.commissionLocationsToolTip,
                      { b: (s) => `<b>${s}</b>` }
                    ),
                  }}
                />
                <Text
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      messages.publisherLocationsToolTip,
                      { b: (s) => `<b>${s}</b>` }
                    ),
                  }}
                />
                <Text
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      messages.networkEarningsToolTip,
                      { b: (s) => `<b>${s}</b>` }
                    ),
                  }}
                />
                <Text
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      messages.advertiserCategoryToolTip,
                      { b: (s) => `<b>${s}</b>` }
                    ),
                  }}
                />
              </Box>
            }
          >
            <MDIcon icon="information-outline" size={24} />
            <span className="popover-dashed-underline">
              {intl.formatMessage(messages.filterDefinitions)}
            </span>
          </Popover>
        </Box>
      </VSHeader>
      <VSDropdown
        id="promotional-models-filter"
        data-testid="promotional-models-filter"
        label={intl.formatMessage(messages.promotionalModels)}
        badge={formatPillText(appliedFiltersInformation.promotionalModels)}
      >
        <PromotionalModelFilter filters={filters} setFilters={setFilters} />
      </VSDropdown>
      <VSDropdown
        id="property-types-filter"
        data-testid="property-types-filter"
        label={intl.formatMessage(messages.propertyTypes)}
        badge={formatPillText(appliedFiltersInformation.promotionalTypes)}
      >
        <PropertyTypesFilter
          filters={filters}
          setFilters={setFilters}
          user={user}
        />
      </VSDropdown>
      <VSDropdown
        id="property-reach-filter"
        data-testid="property-reach-filter"
        label={intl.formatMessage(messages.propertyReach)}
        badge={formatPillText(appliedFiltersInformation.propertyReach)}
      >
        <PropertyReachFilter
          filters={filters}
          setFilters={setFilters}
          user={user}
        />
      </VSDropdown>
      <VSDropdown
        id="publisher-locations-filter"
        data-testid="publisher-locations-filter"
        label={intl.formatMessage(messages.publisherLocations)}
        badge={formatPillText(appliedFiltersInformation.publisherLocations)}
      >
        <PublisherLocationFilter filters={filters} setFilters={setFilters} />
      </VSDropdown>
      <VSDropdown
        id="commission-locations-filter"
        data-testid="commission-locations-filter"
        label={intl.formatMessage(messages.commissionLocations)}
        badge={formatPillText(appliedFiltersInformation.commissionLocations)}
      >
        <CommissionsLocationFilter filters={filters} setFilters={setFilters} />
      </VSDropdown>
      <VSDropdown
        id="network-earnings-filter"
        data-testid="network-earnings-filter"
        label={intl.formatMessage(messages.networkEarnings)}
        badge={formatPillText(appliedFiltersInformation.networkEarnings)}
      >
        <NetworkEarningsFilter filters={filters} setFilters={setFilters} />
      </VSDropdown>
      <VSDropdown
        id="advertiser-categories-filter"
        data-testid="advertiser-categories-filter"
        label={intl.formatMessage(messages.advertiserCategories)}
        badge={formatPillText(appliedFiltersInformation.advertiserCategories)}
      >
        <AdvertiserCategoriesFilter filters={filters} setFilters={setFilters} />
      </VSDropdown>
      <VSDropdown
        id="badges-filter"
        data-testid="badges-filter"
        label={intl.formatMessage(messages.badges)}
        badge={formatPillText(appliedFiltersInformation.badges)}
      >
        <BadgesFilter filters={filters} setFilters={setFilters} />
      </VSDropdown>
    </>
  );
};
