import { Link as LinkFromPlatform } from "@platform/cj-platform-navigation";
import { useNav2022 } from "hooks/useNav2022";
import React from "react";
import { Link as LinkFromRouter } from "react-router-dom";

export const Link = ({ ...rest }) => {
  const { nav2022 } = useNav2022();
  return nav2022 ? (
    <LinkFromPlatform {...rest} />
  ) : (
    <LinkFromRouter {...rest} />
  );
};
