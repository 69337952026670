import { defineMessages } from "react-intl";

export default defineMessages({
  sharePublishersTitle: {
    id: "partners.SharePublishersButton.sharePublishersTitle",
    defaultMessage: "Share Publishers",
  },
  copyPrivateUrl: {
    id: "partners.SharePublishersButton.copyPrivateUrl",
    defaultMessage: "Copy Private URL",
  },
  sendByEmail: {
    id: "partners.SharePublishersButton.sendByEmail",
    defaultMessage: "Send by Email",
  },
  urlTabLabel: {
    id: "partners.SharePublishersButton.urlTabLabel",
    defaultMessage: "URL",
  },
  emailTabLabel: {
    id: "partners.SharePublishersButton.emailTabLabel",
    defaultMessage: "Email",
  },
  copyUrlLabel: {
    id: "partners.SharePublishersButton.copyUrlLabel",
    defaultMessage: "Copy this private URL to share:",
  },
  emailAddressLabel: {
    id: "partners.SharePublishersButton.emailAddressLabel",
    defaultMessage: "Enter comma separated email addresses:",
  },
  emailAddressError: {
    id: "partners.SharePublishersButton.emailAddressError",
    defaultMessage: "Address(es) must be properly formatted.",
  },
  emailMessageLabel: {
    id: "partners.SharePublishersButton.emailMessageLabel",
    defaultMessage: "Add Note (optional)",
  },
  messageHelpText: {
    id: "partners.SharePublishersButton.messageHelpText",
    defaultMessage: "{0} character limit, No HTML",
  },
  copy: {
    id: "partners.SharePublishersButton.copy",
    defaultMessage: "Copy URL",
  },
  send: {
    id: "partners.SharePublishersButton.send",
    defaultMessage: "Send Email",
  },
  openInNewWindow: {
    id: "partners.SharePublishersButton.openInNewWindow",
    defaultMessage: "Open in new window",
  },
  copiedAlert: {
    id: "partners.SharePublishersButton.copiedAlert",
    defaultMessage: "URL copied to clipboard.",
  },
  emailSentAlert: {
    id: "partners.SharePublishersButton.emailSentAlert",
    defaultMessage: "Email sent.",
  },
});
