import { defineMessages } from "react-intl";

export default defineMessages({
  noPromotionalModel: {
    id: "partners.MatchingProperties.noPromotionalModel",
    defaultMessage: "No promotional model provided",
  },
  matchingProperty: {
    id: "partners.MatchingProperties.matchingProperty",
    defaultMessage: "Matching Property",
  },
  topMatchingProperties: {
    id: "partners.MatchingProperties.topMatchingProperties",
    defaultMessage: "Top Matching Properties",
  },
  moreDetails: {
    id: "partners.MatchingProperties.moreDetails",
    defaultMessage: "More Details",
  },
  viewAllProperties: {
    id: "partners.MatchingProperties.viewAllProperties",
    defaultMessage: "View All Properties",
  },
  name: {
    id: "partners.MatchingProperties.name",
    defaultMessage: "Name",
  },
  type: {
    id: "partners.MatchingProperties.type",
    defaultMessage: "Type",
  },
  propertyDetails: {
    id: "partners.MatchingProperties.propertyDetails",
    defaultMessage: "Details",
  },
  promotionalModel: {
    id: "partners.MatchingProperties.promotionalModel",
    defaultMessage: "Promotional Model",
  },
  reach: {
    id: "partners.MatchingProperties.reach",
    defaultMessage: "Reach",
  },
});
