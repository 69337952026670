import Tippy from "@tippyjs/react";
import classNames from "classnames";
import React from "react";
import "tippy.js/dist/tippy.css";
import "./styles.css";

export const Tooltip = ({
  "data-testid": dataTestId,
  children,
  className,
  content,
  ...restProps
}) => (
  <Tippy content={content} animation={false} {...restProps}>
    <div
      className={classNames(className, "tooltip")}
      data-testid={dataTestId}
      data-content={content}
    >
      {children}
    </div>
  </Tippy>
);
