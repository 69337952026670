import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { SocialIcon as VSXSocialIcon } from "@cjdev-internal/visual-stack-x/components/SocialIcon";
import React from "react";

const platformIsOtherOrWebsite = (platform) =>
  platform === "website" || platform === "other";

export const SocialIcon = ({ platform }) => {
  platform = platform?.toLowerCase();
  return (
    <Box direction="row" align="center">
      <VSXSocialIcon
        icon={platformIsOtherOrWebsite(platform) ? "web" : platform}
        size={20}
        data-testid={`vsx-social-icon-${platform}`}
      />
    </Box>
  );
};
