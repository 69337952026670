import { useQuery } from "@tanstack/react-query";
import { useCompany, useUser } from "hooks";
import { userIsAdvertiser } from "utils/authorizationUtils";
import { authenticatedFetch } from "utils/clients";

const emptyResponse = [];

export const useExcludedPublisherIds = () => {
  const { user } = useUser();
  const company = useCompany();

  const fetchExcludedPublisherIds = async () => {
    const url = `${process.env.REACT_APP_MEMBER_URL}/affapi/advertisers/${company.id}/publishers?relationshipStatus=active,pending`;
    if (userIsAdvertiser(user)) {
      const response = await authenticatedFetch(url);
      return await response.json();
    }
    return emptyResponse;
  };

  return useQuery(
    ["fetch excluded publisher ids", company.id],
    fetchExcludedPublisherIds,
    {
      enabled: !!user,
      retry: 0,
    }
  );
};
