import { gql } from "@apollo/client";

export const GET_PUBLISHERS = gql`
  query publishers(
    $searchTerms: String
    $filters: IndexedPublisherFilters
    $sortOption: SortOptions
    $limit: Int
    $offset: Int
  ) {
    indexedPublishers(
      searchTerms: $searchTerms
      filters: $filters
      sortOption: $sortOption
      limit: $limit
      offset: $offset
    ) {
      resultList {
        searchTerms
        id
        name
        networkDescription
        countryCode
        countrySubdivision
        isCjp
        isContent
        isSubaffiliate
        networkEarnings
        activationDate
        sevenDayEpc
        threeMonthEpc
        partnerContactName
        partnerContactEmail
        authenticatedSocialMediaCount
        lastMonthCategoryCommissions {
          category
          percentage
        }
        lastMonthCountryCommissions {
          country
          percentage
        }
        promotionalProperties {
          searchTerms
          id
          type
          name
          isPrimary
          primaryPromotionalModel
          authenticationStatus
          propertyTypeDetails {
            websiteUrl
            socialMediaHandle
            socialMediaUrl
            socialMediaPlatform
            emailAddress
            mobileAppName
            browserExtensionName
            browserExtensionDownloadInformation {
              browserExtensionBrowser
              browserExtensionDownloadLink
            }
            mobileAppDownloadInformation {
              mobileAppPlatform
              mobileAppDownloadLink
            }
            ciqMetrics {
              instagram {
                accountTotalImages
                followers
                engagementRate20Posts
                likesPerPost20Posts
                commentsPerPost20Posts
                lastPostDate
                engagementLabel
              }
              facebook {
                pageLikes
                postLikes
                postComments
                engagementRate20Posts
                likesPerPost20Posts
                commentsPerPost20Posts
                totalComments
                numberOfPosts
                lastPostDate
                engagementLabel
              }
              tiktok {
                followers
                lastPostDate
                totalLikes
                numberOfPosts
                engagementLabel
                likesPerPost20Posts
                commentsPerPost20Posts
                viewsPerPost20Posts
                engagementRate20Posts
              }
              twitter {
                accountTotalTweets
                accountLikesPerTweet
                followers
                engagementRate20Posts
                likesPerPost20Posts
                commentsPerPost20Posts
                sharesPerPost20Posts
                totalLikes
                lastPostDate
                engagementLabel
              }
              youtube {
                accountMonthlyViews
                accountTotalVideos
                accountTotalViews
                accountViewsPerVideo
                subscribers
                engagementRate20Posts
                likesPerPost20Posts
                commentsPerPost20Posts
                viewsPerPost20Posts
                dateLastVideoUploaded
                engagementLabel
              }
              twitch {
                accountAvgStreamViews
                accountViews
                followers
                averageVideoViews30Days
                viewsPerPost20Posts
                numberOfPosts
              }
              snapchat {
                followers
              }
              pinterest {
                accountAverageMonthlyViews
                accountPins
                followers
                engagementRate20Posts
                sharesPerPostLifetime
                lastPostDate
              }
            }
            webMetrics {
              totalMonthlyVisitors
              metricSource
            }
          }
          matchingTerms {
            field
            textSnippets
          }
        }
      }
      totalCount
      approximateCount
    }
  }
`;
