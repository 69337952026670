import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Clickable } from "@cjdev-internal/visual-stack-x/Clickable";
import {
  Tree,
  TreeSelector,
  createFilter,
  createHighlighter,
  useTree,
} from "@cjdev-internal/visual-stack-x/Tree";
import { TriState } from "@cjdev-internal/visual-stack-x/TriStateCheckbox";
import { Input } from "@cjdev-internal/visual-stack-x/components/Input";
import { ChoiceInput } from "@cjdev-internal/visual-stack-x/legacy/Form";
import { Spinner } from "components/Spinner";
import { useCountriesQuery, useIntl } from "hooks";
import { produce } from "immer";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React, { useEffect, useMemo, useState } from "react";
import { capitalizeName } from "utils/utils";
import messages from "../../messages";

const toTreeProps = (countryList, intl) => {
  const parents = {};
  const children = {};
  const countryLookup = {};

  R.forEach((country) => {
    const countryIsoCode = country.isoCode;
    const countrySubdivisions = country.subdivisions || [];
    const region = country.region;

    parents[region] = "root";
    children["root"] = R.uniq(
      R.append(region, R.defaultTo([])(children["root"]))
    );

    parents[countryIsoCode] = region;
    children[region] = R.append(
      countryIsoCode,
      R.defaultTo([])(children[region])
    );

    countryLookup[region] = capitalizeName(
      intl.formatMessage(globalMessages[`global.region.${region}`])
    );
    countryLookup[countryIsoCode] = capitalizeName(country.name);

    if (!R.isEmpty(countrySubdivisions)) {
      R.forEach((subdivision) => {
        const formattedSubdivisionName = `${countryIsoCode}.${subdivision.subdivisionCode}`;
        if (!R.isNil(subdivision.subdivisionName)) {
          parents[formattedSubdivisionName] = countryIsoCode;
          children[countryIsoCode] = R.append(
            formattedSubdivisionName,
            R.defaultTo([])(children[countryIsoCode])
          );
          countryLookup[formattedSubdivisionName] = subdivision.subdivisionName;
        }
      })(countrySubdivisions);
    }
  })(countryList || []);

  return {
    parents,
    children,
    countryLookup,
  };
};

let PublisherTreeFilter = ({ filteredCountries, filters, setFilters }) => {
  const intl = useIntl();
  const [highlight, setHighlight] = useState("");
  const {
    parents: treeParents,
    children: treeChildren,
    countryLookup: countryLookupMap,
  } = toTreeProps(filteredCountries, intl);

  const treeSelector = useMemo(
    () =>
      new TreeSelector({
        rootId: () => "root",
        parent: (id) => treeParents[id],
        children: (id) => treeChildren[id],
        name: (id) => countryLookupMap[id],
      }),
    [treeParents, treeChildren, countryLookupMap]
  );

  const treeProps = useTree(treeSelector);

  const {
    setFilteredOut,
    filterOut,
    setExpanded,
    setSelected,
    selected,
  } = treeProps;

  useEffect(() => {
    setFilters((previousFilters) =>
      produce(previousFilters, (draftFilters) => {
        draftFilters.publisherLevel.publisherLocations.selected = selected;
      })
    );
  }, [selected, setFilters]);

  const areFiltersEmpty = R.isEmpty(
    filters.publisherLevel.publisherLocations.selected
  );

  useEffect(() => {
    if (areFiltersEmpty) {
      setSelected(treeSelector.emptySelection());
    }
  }, [areFiltersEmpty, setSelected, treeSelector]);

  const setInclude = (include) => {
    setFilters((previousFilters) =>
      produce(previousFilters, (draftFilters) => {
        draftFilters.publisherLevel.publisherLocations.include = include;
      })
    );
  };

  const filter = createFilter(treeSelector, 3);
  const labelContent = createHighlighter(
    treeSelector.name,
    "tree-highlight",
    3
  );

  const isFiltering = !R.isEmpty(R.filter(R.identity, filterOut));

  return (
    <Box direction="column" gap="medium-large">
      <Box direction="row" gap="large">
        <ChoiceInput
          data-testid="includeLocation"
          name="includeLocation"
          label={intl.formatMessage(messages.include)}
          value="include"
          checked={filters.publisherLevel.publisherLocations.include}
          onChange={() => {
            setInclude(true);
          }}
        />
        <ChoiceInput
          data-testid="excludeLocation"
          name="excludeLocation"
          label={intl.formatMessage(messages.exclude)}
          value="exclude"
          checked={!filters.publisherLevel.publisherLocations.include}
          onChange={() => {
            setInclude(false);
          }}
        />
      </Box>

      <Box>
        <Input
          type="search"
          placeholder={`${intl.formatMessage(globalMessages.SEARCH)}`}
          value={highlight}
          className="search-input"
          onChange={(event) => {
            const [filteredOut, expanded] = filter(event.target.value);
            setFilteredOut(filteredOut);
            setExpanded(expanded);
            setHighlight(event.target.value);
          }}
        />
      </Box>
      <Box direction="row" gap="large" justify="space-between">
        {isFiltering && (
          <Clickable
            onClick={(_) => {
              setSelected(
                treeSelector.selectAllLeaves(
                  (nodeId) => !filterOut[nodeId],
                  TriState.Full,
                  selected
                )
              );
            }}
          >
            {intl.formatMessage(messages.selectAllVisible)}
          </Clickable>
        )}
        {isFiltering && (
          <Clickable
            onClick={(_) => {
              setSelected(
                treeSelector.selectAllLeaves(
                  (nodeId) => !filterOut[nodeId],
                  TriState.Empty,
                  selected
                )
              );
            }}
          >
            {intl.formatMessage(messages.unselectVisible)}
          </Clickable>
        )}
        {!isFiltering && (
          <>
            <Box />
            <Clickable
              onClick={() => {
                setSelected(treeSelector.emptySelection());
              }}
              data-testid="publisherLocationsUnselectAll"
            >
              {intl.formatMessage(messages.unselectAll)}
            </Clickable>
          </>
        )}
      </Box>
      <Box className="tree-container">
        <Tree
          labelContent={labelContent}
          highlight={highlight}
          {...treeProps}
        />
      </Box>
    </Box>
  );
};

PublisherTreeFilter = React.memo(
  PublisherTreeFilter,
  (previousProps, props) => {
    return (
      R.equals(previousProps.filteredCountries, props.filteredCountries) &&
      R.equals(
        previousProps.filters.publisherLevel.publisherLocations,
        props.filters.publisherLevel.publisherLocations
      )
    );
  }
);

export const PublisherLocationFilter = ({ filters, setFilters }) => {
  const countriesQuery = useCountriesQuery();

  return (
    <>
      {countriesQuery.isLoading ? (
        <Box padding="xl" align="center">
          <Spinner />
        </Box>
      ) : (
        <PublisherTreeFilter
          filteredCountries={countriesQuery.data}
          filters={filters}
          setFilters={setFilters}
        />
      )}
    </>
  );
};
