import { gql, useQuery } from "@apollo/client";
import { useClients, useSearchTerms } from "hooks";

export const useTopPromotionalPropertiesQuery = (
  publisherId,
  propertyTypes = []
) => {
  const { partnersClient } = useClients();
  const { searchTerms } = useSearchTerms();

  return useQuery(gql(GET_TOP_PROMOTIONAL_PROPERTIES_QUERY), {
    client: partnersClient,
    variables: {
      publisherId,
      searchTerms,
      propertyTypes,
    },
  });
};

export const GET_TOP_PROMOTIONAL_PROPERTIES_QUERY = `
  query topPromotionalProperties(
    $publisherId: ID!
    $searchTerms: String
    $propertyTypes: [PropertyType!]
  ) {
    topPromotionalProperties(
      publisherId: $publisherId
      searchTerms: $searchTerms
      propertyTypes: $propertyTypes
    ) {
      resultList {
        id
        type
        authenticationStatus
        propertyTypeDetails {
          socialMediaHandle
          socialMediaPlatform
          socialMediaUrl
          ciqMetrics {
            instagram {
              accountTotalImages
              followers
              engagementRate20Posts
              likesPerPost20Posts
              commentsPerPost20Posts
              lastPostDate
              engagementLabel
            }
            facebook {
              pageLikes
              postLikes
              postComments
              engagementRate20Posts
              likesPerPost20Posts
              commentsPerPost20Posts
              totalComments
              numberOfPosts
              lastPostDate
              engagementLabel
            }
            tiktok {
              followers
              lastPostDate
              totalLikes
              numberOfPosts
              engagementRate20Posts
              likesPerPost20Posts
              commentsPerPost20Posts
              viewsPerPost20Posts
              engagementLabel
            }
            twitter {
              accountLikesPerTweet
              accountTotalTweets
              followers
              engagementRate20Posts
              likesPerPost20Posts
              commentsPerPost20Posts
              sharesPerPost20Posts
              totalLikes
              lastPostDate
              engagementLabel
            }
            youtube {
              accountMonthlyViews
              accountTotalVideos
              accountTotalViews
              accountViewsPerVideo
              subscribers
              engagementRate20Posts
              likesPerPost20Posts
              commentsPerPost20Posts
              viewsPerPost20Posts
              dateLastVideoUploaded
              engagementLabel
            }
            twitch {
              accountAvgStreamViews
              accountViews
              followers
              averageVideoViews30Days
              viewsPerPost20Posts
              numberOfPosts
            }
            snapchat {
              followers
            }
            pinterest {
              accountAverageMonthlyViews
              accountPins
              followers
              engagementRate20Posts
              sharesPerPostLifetime
              lastPostDate
            }
          }
          webMetrics {
            metricSource
          }
        }
      }
    }
  }
`;
