import * as R from "ramda";
import { createIntl, createIntlCache } from "react-intl";
import cs from "../translations/cs.json";
import de from "../translations/de.json";
import es from "../translations/es.json";
import fr from "../translations/fr.json";
import ja from "../translations/ja.json";
import pl from "../translations/pl.json";
import pt from "../translations/pt.json";
import zh from "../translations/zh.json";

export const translations = { cs, de, es, fr, ja, pl, pt, zh };

const cache = createIntlCache();

const locale = R.toLower(R.take(2, navigator.language));

const messages = { cs, de, es, fr, ja, pl, pt, zh }[locale];

const intl = createIntl(
  {
    locale,
    messages,
  },
  cache
);

export default intl;
