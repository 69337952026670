import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Grid } from "@cjdev-internal/visual-stack-x/Grid";
import { ChoiceInput } from "@cjdev-internal/visual-stack-x/legacy/Form";
import { Earnings } from "components/Earnings";
import { produce } from "immer";
import * as R from "ramda";
import React from "react";

export const NetworkEarningsFilter = ({ filters, setFilters }) => {
  const { networkEarningsToInclude } = filters.publisherLevel;
  const networkEarningsState = new Set(networkEarningsToInclude);

  const setSelected = (networkEarning) => (e) => {
    let nextNetworkEarnings;
    const isChecked = e.target.checked;

    if (isChecked) {
      nextNetworkEarnings = [...networkEarningsToInclude, networkEarning];
    } else {
      nextNetworkEarnings = R.without([networkEarning])(
        networkEarningsToInclude
      );
    }

    setFilters((previousFilters) => {
      const nextFilters = produce(previousFilters, (draftFilters) => {
        draftFilters.publisherLevel.networkEarningsToInclude = nextNetworkEarnings;
      });
      return nextFilters;
    });
  };

  return (
    <Grid columns="1fr 1fr 1fr" gap="medium">
      <Box direction="column" gap="small" align="center">
        <Earnings level={0} />
        <ChoiceInput
          name="networkEarnings"
          type="checkbox"
          value="ZERO"
          aria-label="zero bar network earnings"
          onChange={setSelected("ZERO")}
          checked={networkEarningsState.has("ZERO")}
        />
      </Box>
      <Box direction="column" gap="small" align="center">
        <Earnings level={1} />
        <ChoiceInput
          name="networkEarnings"
          type="checkbox"
          value="ONE"
          aria-label="one bar network earnings"
          onChange={setSelected("ONE")}
          checked={networkEarningsState.has("ONE")}
        />
      </Box>
      <Box direction="column" gap="small" align="center">
        <Earnings level={2} />
        <ChoiceInput
          name="networkEarnings"
          type="checkbox"
          value="TWO"
          aria-label="two bars network earnings"
          onChange={setSelected("TWO")}
          checked={networkEarningsState.has("TWO")}
        />
      </Box>
      <Box direction="column" gap="small" align="center">
        <Earnings level={3} />
        <ChoiceInput
          name="networkEarnings"
          type="checkbox"
          value="THREE"
          aria-label="three bars network earnings"
          onChange={setSelected("THREE")}
          checked={networkEarningsState.has("THREE")}
        />
      </Box>
      <Box direction="column" gap="small" align="center">
        <Earnings level={4} />
        <ChoiceInput
          name="networkEarnings"
          type="checkbox"
          value="FOUR"
          aria-label="four bars network earnings"
          onChange={setSelected("FOUR")}
          checked={networkEarningsState.has("FOUR")}
        />
      </Box>
      <Box direction="column" gap="small" align="center">
        <Earnings level={5} />
        <ChoiceInput
          name="networkEarnings"
          type="checkbox"
          value="FIVE"
          aria-label="five bars network earnings"
          onChange={setSelected("FIVE")}
          checked={networkEarningsState.has("FIVE")}
        />
      </Box>
    </Grid>
  );
};
