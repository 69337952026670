import { ExportCSVButton } from "components/ExportButton";
import * as R from "ramda";
import React from "react";

export const ExportPublishersCSVButton = React.memo(
  ({ publishers, disabled, toggleWarningAlert }) => (
    <ExportCSVButton
      publishers={publishers}
      disabled={disabled}
      fileName="publishers"
      toggleWarningAlert={toggleWarningAlert}
    />
  ),
  (previousProps, props) => {
    return (
      R.equals(previousProps.publishers, props.publishers) &&
      R.equals(previousProps.disabled, props.disabled)
    );
  }
);
