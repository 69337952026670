import { defineMessages } from "react-intl";

export default defineMessages({
  blankSlate: {
    id: "partners.PublisherSearch.blankSlate",
    defaultMessage: "Search for new publishers to extend your network.",
  },
  blankSlateSubTitle: {
    id: "partners.PublisherSearch.blankSlateSubTitle",
    defaultMessage:
      "Enter search keywords or use the filters to narrow your search or browse categories for matching publishers.",
  },
  filterDefinitions: {
    id: "partners.PublisherSearch.filterDefinitions",
    defaultMessage: "Filter Definitions",
  },
  noResults: {
    id: "partners.PublisherSearch.noResults",
    defaultMessage: "No results found",
  },
  noResultsSubTitle: {
    id: "partners.PublisherSearch.noResultsSubTitle",
    defaultMessage: "Try broadening your filters for better results",
  },
  contentCertified: {
    id: "partners.PublisherSearch.contentCertified",
    defaultMessage: "Content Certified",
  },
  error: {
    id: "partners.PublisherSearch.error",
    defaultMessage: "There was an error, please refresh and try again.",
  },
  partnerDetails: {
    id: "partners.PublisherSearch.partnerDetails",
    defaultMessage: "Partner Details",
  },
  propertyDetails: {
    id: "partners.PublisherSearch.Home.propertyDetails",
    defaultMessage: "Property Details",
  },
  searchRelevancy: {
    id: "partners.PublisherSearch.searchRelevancy",
    defaultMessage: "Search Relevancy",
  },
  topCategory: {
    id: "partners.PublisherSearch.Home.topCategory",
    defaultMessage: "Top Category",
  },
  topCountry: {
    id: "partners.PublisherSearch.Home.topCountry",
    defaultMessage: "Top Country",
  },
  epc: {
    id: "partners.PublisherSearch.epc",
    defaultMessage: "EPC",
  },
  earnings: {
    id: "partners.PublisherSearch.earnings",
    defaultMessage: "Earnings",
  },
  favorites: {
    id: "partners.PublisherSearch.favorites",
    defaultMessage: "Favorites",
  },
  publisherSince: {
    id: "partners.PublisherSearch.publisherSince",
    defaultMessage: "Publisher since {0}",
  },
  searchPlaceholder: {
    id: "partners.PublisherSearch.searchPlaceholder",
    defaultMessage: "Enter search keywords",
  },
  search: {
    id: "partners.PublisherSearch.search",
    defaultMessage: "Search",
  },
  tableTitle: {
    id: "partners.PublisherSearch.tableTitle",
    defaultMessage: "Partners",
  },
  totalPublisherResults: {
    id: "partners.PublisherSearch.totalPublisherResults",
    defaultMessage: "{0}-{1} of {2} total results.",
  },
  showingTotalPublisherResults: {
    id: "partners.PublisherSearch.showingTotalPublisherResults",
    defaultMessage: "Showing {0}-{1} of {2} results.",
  },
  showingTotalApproximatePublisherResults: {
    id: "partners.PublisherSearch.showingTotalApproximatePublisherResults",
    defaultMessage: "Showing {0}-{1} of over {2} results.",
  },
  rowsPerPageTemplate: {
    id: "partners.PublisherSearch.rowsPerPageTemplate",
    defaultMessage: "{0} per page",
  },
  refineYourSearchNote: {
    id: "partners.PublisherSearch.refineYourSearchNote",
    defaultMessage: "Use filters to refine your search.",
  },
  percentageOfTotalCommission: {
    id: "partners.PublisherSearch.percentageOfTotalCommission",
    defaultMessage: "% of Total Commission",
  },
  pieChartOther: {
    id: "partners.PublisherSearch.pieChartOther",
    defaultMessage: "Other",
  },
  topCountries: {
    id: "partners.PublisherSearch.topCountries",
    defaultMessage: "Top Countries",
  },
  topCategories: {
    id: "partners.PublisherSearch.topCategories",
    defaultMessage: "Top Categories",
  },
  cjPerformer: {
    id: "partners.PublisherSearch.cjPerformer",
    defaultMessage: "CJ Performer",
  },
  newToNetwork: {
    id: "partners.PublisherSearch.newToNetwork",
    defaultMessage: "New to Network",
  },
  subAffiliate: {
    id: "partners.PublisherSearch.subAffiliate",
    defaultMessage: "Sub-Affiliate",
  },
  sevenDayEpc: {
    id: "partners.PublisherSearch.sevenDayEpc",
    defaultMessage: "7d",
  },
  threeMonthEpc: {
    id: "partners.PublisherSearch.threeMonthEpc",
    defaultMessage: "3m",
  },
  promotionalMethod: {
    id: "partners.PublisherSearch.promotionalMethod",
    defaultMessage: "Promotional Method",
  },
  promotionalMethods: {
    id: "partners.PublisherSearch.promotionalMethods",
    defaultMessage: "Promotional Methods",
  },
  na: {
    id: "partners.PublisherSearch.NA",
    defaultMessage: "N/A",
  },
  model: {
    id: "partners.PublisherSearch.model",
    defaultMessage: "Model:",
  },
  type: {
    id: "partners.PublisherSearch.type",
    defaultMessage: "Type:",
  },
  clearAll: {
    id: "partners.PublisherSearch.filter.clearAll",
    defaultMessage: "clear all",
  },
  apply: {
    id: "partners.PublisherSearch.filter.apply",
    defaultMessage: "Apply",
  },
  activeFilters: {
    id: "partners.PublisherSearch.filter.activeFilters",
    defaultMessage: "{0} Active Filters",
  },
  activeFilter: {
    id: "partners.PublisherSearch.filter.activeFilter",
    defaultMessage: "{0} Active Filter",
  },
  promotionalModels: {
    id: "partners.PublisherSearch.filter.promotionalModels",
    defaultMessage: "Promotional Models",
  },
  promotionalTypes: {
    id: "partners.PublisherSearch.filter.promotionalTypes",
    defaultMessage: "Promotional Types",
  },
  propertyTypes: {
    id: "partners.PublisherSearch.filter.propertyTypes",
    defaultMessage: "Property Types",
  },
  propertyReach: {
    id: "partners.PublisherSearch.filter.propertyReach",
    defaultMessage: "Property Reach",
  },
  networkEarnings: {
    id: "partners.PublisherSearch.filter.networkEarnings",
    defaultMessage: "Network Earnings",
  },
  commissionLocations: {
    id: "partners.PublisherSearch.filter.commissionLocations",
    defaultMessage: "Customer Conversion Area",
  },
  publisherLocations: {
    id: "partners.PublisherSearch.filter.publisherLocations",
    defaultMessage: "Publisher Location",
  },
  advertiserCategories: {
    id: "partners.PublisherSearch.filter.advertiserCategories",
    defaultMessage: "Advertiser Categories",
  },
  badges: {
    id: "partners.PublisherSearch.filter.badges",
    defaultMessage: "Badges",
  },
  selectAll: {
    id: "partners.PublisherSearch.filter.selectAll",
    defaultMessage: "Select All",
  },
  unselectAll: {
    id: "partners.PublisherSearch.filter.unselectAll",
    defaultMessage: "Clear All",
  },
  include: {
    id: "partners.PublisherSearch.filter.include",
    defaultMessage: "Include",
  },
  exclude: {
    id: "partners.PublisherSearch.filter.exclude",
    defaultMessage: "Exclude",
  },
  included: {
    id: "partners.PublisherSearch.filter.included",
    defaultMessage: "included",
  },
  excluded: {
    id: "partners.PublisherSearch.filter.excluded",
    defaultMessage: "excluded",
  },
  partnerSelected: {
    id: "partners.PublisherSearch.partnerSelected",
    defaultMessage: "Partner Selected",
  },
  partnersSelected: {
    id: "partners.PublisherSearch.partnersSelected",
    defaultMessage: "Partners Selected",
  },
  partnersExceededMaxSelected: {
    id: "partners.PublisherSearch.partnersExceededMaxSelected",
    defaultMessage: "You can only select up to {0} publishers at a time.",
  },
  makeOffer: {
    id: "partners.PublisherSearch.makeOffer",
    defaultMessage: "MAKE OFFER",
  },
  unselectVisible: {
    id: "partners.PublisherSearch.unselectVisible",
    defaultMessage: "Clear All Visible",
  },
  selectAllVisible: {
    id: "partners.PublisherSearch.selectAllVisible",
    defaultMessage: "Select All Visible",
  },
  toggleFilterToolTip: {
    id: "partners.PublisherSearch.toggleFilterToolTip",
    defaultMessage: "Toggle filters",
  },
  topCategoryToolTip: {
    id: "partners.PublisherSearch.Home.topCategoryToolTip",
    defaultMessage: "Top category by commissions in the last 30 days",
  },
  topCountryToolTip: {
    id: "partners.PublisherSearch.Home.topCountryToolTip",
    defaultMessage: "Top country by commissions in the last 30 days",
  },
  epcToolTip: {
    id: "partners.PublisherSearch.epcToolTip",
    defaultMessage: "Commissions per 100 clicks over 7 days or 3 months",
  },
  earningsToolTip: {
    id: "partners.PublisherSearch.earningsToolTip",
    defaultMessage:
      "The size of the publisher’s program over the last 30 days compared to the rest of the network",
  },
  favoritesHeaderToolTip: {
    id: "partners.PublisherSearch.favoritesHeaderToolTip",
    defaultMessage: "Add to Favorites (This is not visible to the publisher)",
  },
  favoritesToolTip: {
    id: "partners.PublisherSearch.favoritesToolTip",
    defaultMessage: "Add to Favorites",
  },
  expandCollapseToolTip: {
    id: "partners.PublisherSearch.expandCollapseToolTip",
    defaultMessage: "Expand/Collapse rows",
  },
  promotionalModelsToolTip: {
    id: "partners.PublisherSearch.promotionalModelsToolTip",
    defaultMessage:
      "<b>Promotional Models:</b> The business models of the publisher’s promotional properties.",
  },
  propertyTypesToolTip: {
    id: "partners.PublisherSearch.propertyTypesToolTip",
    defaultMessage:
      "<b>Property Types:</b> The property types of the publisher's promotional properties.",
  },
  commissionLocationsToolTip: {
    id: "partners.PublisherSearch.commissionLocationsToolTip",
    defaultMessage:
      "<b>Customer Conversion Area:</b> Countries where the publisher has recorded commissions in the last 30 days.",
  },
  publisherLocationsToolTip: {
    id: "partners.PublisherSearch.publisherLocationsToolTip",
    defaultMessage:
      "<b>Publisher Location:</b> The location of the publisher’s physical address.",
  },
  networkEarningsToolTip: {
    id: "partners.PublisherSearch.Home.networkEarningsToolTip",
    defaultMessage:
      "<b>Network Earnings:</b> The size of the publisher’s program over the last 30 days compared to the rest of the network.",
  },
  advertiserCategoryToolTip: {
    id: "partners.PublisherSearch.advertiserCategoryToolTip",
    defaultMessage:
      "<b>Advertiser Categories:</b> The categories of the advertisers that the publisher has recorded commissions with in the last 30 days.",
  },
  allPublishers: {
    id: "partners.PublisherSearch.allPublishers",
    defaultMessage: "All Publishers",
  },
  myFavorites: {
    id: "partners.PublisherSearch.myFavorites",
    defaultMessage: "My Favorites",
  },
  cjSpotlight: {
    id: "partners.PublisherSearch.cjSpotlight",
    defaultMessage: "CJ Spotlight",
  },
  "tooltip.CONTENT_BLOG_MEDIA": {
    id: "partners.PublisherSearch.CONTENT_BLOG_MEDIA.description",
    defaultMessage:
      "Customers read original content or articles that the publisher writes about topics that interest them.",
  },
  "tooltip.COUPON_DEAL": {
    id: "partners.PublisherSearch.COUPON_DEAL.description",
    defaultMessage:
      "Customers come to discover coupons, vouchers, or discounts.",
  },
  "tooltip.LOYALTY_CASH_BACK": {
    id: "partners.PublisherSearch.LOYALTY_CASH_BACK.description",
    defaultMessage:
      "Customers receive rewards in the form of cash back as a percentage of their purchase.",
  },
  "tooltip.LOYALTY_NON_CASH_REWARDS": {
    id: "partners.PublisherSearch.LOYALTY_NON_CASH_REWARDS.description",
    defaultMessage:
      "Customers receive non-cash rewards for their purchase in the form of points, airline miles, charity donations, etc.",
  },
  "tooltip.LOYALTY_CLOSED_USER_GROUP": {
    id: "partners.PublisherSearch.LOYALTY_CLOSED_USER_GROUP.description",
    defaultMessage:
      "Publisher provides a membership service where customers must have an exclusive login to access benefits, such as employee benefits, credit card rewards, or airline rewards malls.",
  },
  "tooltip.TRADEMARK_BIDDING": {
    id: "partners.PublisherSearch.TRADEMARK_BIDDING.description",
    defaultMessage:
      "Publisher purchases search engine ads by bidding on advertiser brand names, but display their own brand in the ad, and on the click customers are directed to the publisher's own website.",
  },
  "tooltip.INFLUENCER": {
    id: "partners.PublisherSearch.INFLUENCER.description",
    defaultMessage:
      "Customers follow this publisher because they are a personality or figure they are interested in following.",
  },
  "tooltip.PAY_PER_CALL": {
    id: "partners.PublisherSearch.PAY_PER_CALL.description",
    defaultMessage:
      "Publisher uses call tracking to drive customer transactions over the telephone.",
  },
  "tooltip.PRODUCT_COMPARISON_REVIEWS_DISCOVERY": {
    id:
      "partners.PublisherSearch.PRODUCT_COMPARISON_REVIEWS_DISCOVERY.description",
    defaultMessage:
      "Publisher provides links to specific products that customers can browse, compare, or search for.",
  },
  "tooltip.OTHER": {
    id: "partners.PublisherSearch.promoModel.OTHER.description",
    defaultMessage:
      "The publisher's promotional model does not fit into any of the other promotional models.",
  },
  "tooltip.WEBSITE": {
    id: "partners.PublisherSearch.WEBSITE.description",
    defaultMessage:
      "Customers view the publisher's content on a website at a URL that can be viewed on a desktop or mobile browser.",
  },
  "tooltip.SOCIAL_MEDIA": {
    id: "partners.PublisherSearch.SOCIAL_MEDIA.description",
    defaultMessage:
      "Customers view the content posted by publishers on social media or other social platforms, such as Facebook, Instagram or Youtube.",
  },
  "tooltip.EMAIL": {
    id: "partners.PublisherSearch.EMAIL",
    defaultMessage:
      "Customers view content the publisher sends to them in email campaigns or newsletters.",
  },
  "tooltip.MOBILE_APP": {
    id: "partners.PublisherSearch.MOBILE_APP.description",
    defaultMessage:
      "Customers view the publisher's content on an app they download onto their phone or tablet through a store like the Google Play or Apple App store.",
  },
  "tooltip.BROWSER_EXTENSION": {
    id: "partners.PublisherSearch.BROWSER_EXTENSION.description",
    defaultMessage:
      "Customers view the publisher's content on an extension, add-on or toolbar that they download onto their web browser.",
  },
  "tooltip.SERVICES_AND_TOOLS": {
    id: "partners.PublisherSearch.SERVICES_AND_TOOLS.description",
    defaultMessage:
      "Customers view the publisher's content through some technology the publisher built that is not a mobile or browser app, such as a desktop app, hardware integration, or some other piece of technology.",
  },
  "tooltip.PAID_SEARCH_ENGINE": {
    id: "partners.PublisherSearch.PAID_SEARCH_ENGINE.description",
    defaultMessage:
      "The publisher runs search engine ads, but customers see an ad directly for the merchant, not the publisher's brand, and when they click are taken to the merchant site, not the publisher's site.",
  },
  "tooltip.PAID_DISPLAY_ADS": {
    id: "partners.PublisherSearch.PAID_DISPLAY_ADS.description",
    defaultMessage:
      "Customers view display ads the publisher purchases through a third party exchange, such as retargeting or real-time bidding.",
  },
  "tooltip.propertyType.OTHER": {
    id: "partners.PublisherSearch.propertyType.OTHER.description",
    defaultMessage:
      "The publisher's promotional property type does not fit into any of the other property types.",
  },
  unfavoritePublisherNameAlert: {
    id: "partners.PublisherSearch.unfavoritePublisherNameAlert",
    defaultMessage: "Publisher {0} removed from favorites",
  },
  unfavoritePublishersAlert: {
    id: "partners.PublisherSearch.unfavoritePublishersAlert",
    defaultMessage: "{0} publishers removed from favorites.",
  },
  unfavoritePublisherAlert: {
    id: "partners.PublisherSearch.unfavoritePublisherAlert",
    defaultMessage: "{0} publisher removed from favorites.",
  },
  undoText: {
    id: "partners.PublisherSearch.undoText",
    defaultMessage: "Undo",
  },
  favoriteNoResultSlateTitle: {
    id: "partners.PublisherSearch.favoriteNoResultSlateTitle",
    defaultMessage:
      "Add publishers to your Favorites for quick viewing access.",
  },
  favoriteNoResultSlateMessage: {
    id: "partners.PublisherSearch.favoriteNoResultSlateMessage",
    defaultMessage:
      "You can save your frequently viewed publishers by adding them as Favorites, so you can get to them with just a click.",
  },
  favoritesRetreivalAlert: {
    id: "partners.PublisherSearch.favoritesRetrievalAlert",
    defaultMessage: "Failed to retrieve Favorites. Please try again later.",
  },
  featuredNoResultSlateTitle: {
    id: "partners.PublisherSearch.featuredNoResultSlateTitle",
    defaultMessage:
      "You are already joined to all current CJ Spotlight publishers!",
  },
  featuredNoResultSlateMessage: {
    id: "partners.PublisherSearch.featuredNoResultSlateMessage",
    defaultMessage: "View joined publishers in the Account Manager.",
  },
  fuzzyOverMessage: {
    id: "partners.PublisherSearch.fuzzyOverMessage",
    defaultMessage: "over {0}",
  },
  propertyReachMetricToolTip: {
    id: "partners.PublisherSearch.propertyReachMetricToolTip",
    defaultMessage: "Select a social property type to enable this filter",
  },
});
