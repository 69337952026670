import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { useIntl } from "hooks";
import React from "react";
import { formatEpc } from "utils/utils";

export const EpcCell = ({ publisher, dailyEpc, monthlyEpc }) => {
  const intl = useIntl();
  return (
    <div className="epc-container">
      <Text size={12} color="secondary">
        {dailyEpc}
      </Text>
      <Text
        size={12}
        weight="medium"
        data-testid={`seven-day-epc-${publisher.id}`}
      >
        {formatEpc(publisher.sevenDayEpc, intl)}
      </Text>
      <Text size={12} color="secondary">
        {monthlyEpc}
      </Text>
      <Text
        size={12}
        weight="medium"
        data-testid={`three-month-epc-${publisher.id}`}
      >
        {formatEpc(publisher.threeMonthEpc, intl)}
      </Text>
    </div>
  );
};
