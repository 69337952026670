import { ExpandableList } from "@cjdev-internal/visual-stack-x/ExpandableList";
import React from "react";
import { PropertyReachPlatformFilter } from "./PropertyReachPlatformFilter";
import "./styles.css";
import { TIKTOK_METRICS, selectActingCompany, useFeatures } from "hooks";
import { Spinner } from "@cjdev-internal/visual-stack-x/components/Spinner";
import { Box } from "@cjdev-internal/visual-stack-x/Box";

export const PropertyReachFilter = ({ filters, setFilters, user }) => {
  const companyId = selectActingCompany(user).id;
  const { isLoading, hasFeature } = useFeatures(companyId);
  const hasTikTokMetricFeature = hasFeature(TIKTOK_METRICS);

  if (isLoading) {
    return (
      <Box align="center">
        <Spinner />
      </Box>
    );
  }

  const platformFilters = [
    { platform: "website", metrics: ["totalMonthlyVisitors"] },
    { platform: "instagram", metrics: ["followers"] },
    { platform: "facebook", metrics: ["pageLikes"] },
    { platform: "youtube", metrics: ["subscribers"] },
    { platform: "twitter", metrics: ["followers"] },
    { platform: "pinterest", metrics: ["followers"] },
    { platform: "twitch", metrics: ["followers"] },
    { platform: "snapchat", metrics: ["followers"] },
  ];

  const tikTokFilter = { platform: "tiktok", metrics: ["followers"] };
  if (hasTikTokMetricFeature) {
    // Intentionally place TikTok after Instagram as it is our third most popular platform
    platformFilters.splice(2, 0, tikTokFilter);
  }

  return (
    <ExpandableList gap="small" className="property-reach-filter">
      {platformFilters.map((platformFilter) => (
        <PropertyReachPlatformFilter
          key={platformFilter.platform}
          filters={filters}
          setFilters={setFilters}
          platform={platformFilter.platform}
          metrics={platformFilter.metrics}
        />
      ))}
    </ExpandableList>
  );
};
