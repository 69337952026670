import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { NoData } from "components/NoData";
import { useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import React from "react";

export const PropertyDescription = ({ property }) => {
  const intl = useIntl();
  return (
    <Box expand direction="column" gap="xs">
      <Text weight="medium" size={14}>
        {intl.formatMessage(globalMessages.DESCRIPTION)}
      </Text>
      {property.description && <Text>{property.description}</Text>}
      {!property.description && <NoData />}
    </Box>
  );
};
