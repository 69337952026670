import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { Select } from "@cjdev-internal/visual-stack-x/Select";
import { useIntl } from "hooks";
import { produce } from "immer";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React from "react";
import messages from "./messages";
import "./styles.css";

const toOptions = (intl, options) => {
  return options?.map((value) => ({
    value,
    label: intl.formatMessage(messages[value]),
  }));
};

export const BadgesFilter = ({ filters, setFilters }) => {
  const intl = useIntl();
  const { include, exclude } = filters.publisherLevel.badges;
  const allOptions = [
    "CJ_PERFORMER",
    "NEW_TO_NETWORK",
    "SUBAFFILIATE",
    "CONTENT_CERTIFIED",
  ];

  return (
    <Box direction="column" gap="medium" className="badges-filter">
      <Box direction="column" gap="small" data-testid="all-container">
        <Text>{intl.formatMessage(messages.hasAllOf)}</Text>
        <Select
          isMulti
          placeholder={intl.formatMessage(globalMessages.select)}
          menuPlacement="auto"
          options={toOptions(intl, R.difference(allOptions, exclude))}
          value={toOptions(intl, include)}
          onChange={(selectedOptions) => {
            setFilters((previousFilter) => {
              const nextFilters = produce(previousFilter, (draftFilters) => {
                draftFilters.publisherLevel.badges.include = R.pluck(
                  "value",
                  selectedOptions || []
                );
              });
              return nextFilters;
            });
          }}
        />
      </Box>

      <Text color="secondary">{intl.formatMessage(messages.and)}</Text>

      <Box direction="column" gap="small" data-testid="none-container">
        <Text>{intl.formatMessage(messages.hasNoneOf)}</Text>
        <Select
          isMulti
          placeholder={intl.formatMessage(globalMessages.select)}
          menuPlacement="auto"
          options={toOptions(intl, R.difference(allOptions, include))}
          value={toOptions(intl, exclude)}
          onChange={(selectedOptions) => {
            const nextFilters = produce(filters, (draftFilters) => {
              draftFilters.publisherLevel.badges.exclude = R.pluck(
                "value",
                selectedOptions || []
              );
            });
            setFilters(nextFilters);
          }}
        />
      </Box>
    </Box>
  );
};
