import * as R from "ramda";

export const hasNextProperty = (
  totalNumberOfProperties,
  currentPropertyPosition
) => {
  if (
    R.equals(totalNumberOfProperties, 1) ||
    R.equals(currentPropertyPosition, totalNumberOfProperties)
  ) {
    return false;
  }
};

export const hasPreviousProperty = (
  totalNumberOfProperties,
  currentPropertyPosition
) => {
  if (
    R.equals(totalNumberOfProperties, 1) ||
    R.equals(currentPropertyPosition, 1)
  ) {
    return false;
  }
};
