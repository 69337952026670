import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Modal } from "@cjdev-internal/visual-stack-x/Modal.js";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { Button } from "@cjdev-internal/visual-stack-x/components/Button";
import { TextArea } from "@cjdev-internal/visual-stack-x/components/TextArea";
import { Select } from "@cjdev-internal/visual-stack-x/Select";
import { useCompany, useIntl } from "hooks";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { authenticatedFetch } from "utils/clients";
import messages from "./messages";
import { OfferMade } from "./OfferMade";
import "./styles.css";

const CUSTOM_MESSAGE_LIMIT = 2000;

const states = {
  INITIAL: "INITIAL",
  MAKING_OFFER: "MAKING_OFFER",
  OFFER_MADE: "OFFER_MADE",
  ERROR: "ERROR",
};

export const formatMakeOfferToText = (publisherIds, publisher, intl) => {
  if (!publisherIds) {
    return intl.formatMessage(messages.makeOfferTo, [publisher.organization]);
  } else if (publisherIds.length > 1) {
    return intl.formatMessage(messages.makeOfferToMultiplePublishers, [
      publisherIds.length,
    ]);
  } else {
    return intl.formatMessage(messages.makeOfferWithoutOrganization);
  }
};

export const OfferModal = ({
  onClose,
  onMultipleOfferSuccess,
  onOfferMade,
  publisher,
  publisherIds,
}) => {
  const intl = useIntl();
  const [modalState, setModalState] = useState(states.INITIAL);
  const [programTerms, setProgramTerms] = useState();
  const [publisherGroups, setPublisherGroups] = useState();
  const [customMessage, setCustomMessage] = useState("");
  const [selectedProgramTermsId, setSelectedProgramTermsId] = useState();
  const [selectedPublisherGroupId, setSelectedPublisherGroupId] = useState();
  const company = useCompany();

  const customMessageLimitError =
    customMessage && customMessage.length > CUSTOM_MESSAGE_LIMIT;

  useEffect(() => {
    const fetchProgramTerms = async () => {
      const response = await authenticatedFetch(
        `${process.env.REACT_APP_MEMBER_URL}/affapi/advertiser/${company.id}/programterms`
      );
      const programTerms = await response.json();
      setProgramTerms(programTerms);

      const defaultProgramTerms = R.find(R.prop("isDefault"))(programTerms);

      if (defaultProgramTerms) {
        setSelectedProgramTermsId(defaultProgramTerms.id);
      }
    };
    fetchProgramTerms();
  }, [company.id]);

  useEffect(() => {
    const fetchPublisherGroups = async () => {
      const response = await authenticatedFetch(
        `${process.env.REACT_APP_MEMBER_URL}/affapi/advertiser/${company.id}/publishergroups`
      );
      const publisherGroups = await response.json();
      setPublisherGroups(publisherGroups);

      const defaultPublisherGroup = R.find(
        R.propEq("groupType", "Default Group")
      )(publisherGroups);

      if (defaultPublisherGroup) {
        setSelectedPublisherGroupId(defaultPublisherGroup.groupId);
      }
    };
    fetchPublisherGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!programTerms || !publisherGroups) {
    return null;
  }

  const makeOffer = async () => {
    setModalState(states.MAKING_OFFER);

    try {
      const response = await authenticatedFetch(
        `${process.env.REACT_APP_MEMBER_URL}/affapi/advertiser/${company.id}/contracts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customMessage: customMessage,
            programTermsId: selectedProgramTermsId,
            publisherGroupId: selectedPublisherGroupId,
            publisherIds: publisherIds ? publisherIds : [publisher.publisherId],
          }),
        }
      );

      if (response.ok) {
        onOfferMade(true);
        setModalState(states.OFFER_MADE);
      } else {
        setModalState(states.ERROR);
      }
    } catch (e) {
      setModalState(states.ERROR);
    }
  };

  if (modalState === states.OFFER_MADE) {
    const selectedProgramTermName = R.pipe(
      R.find(R.propEq("id", selectedProgramTermsId)),
      R.prop("name")
    )(programTerms);

    const selectedPublisherGroupName = R.pipe(
      R.find(R.propEq("groupId", selectedPublisherGroupId)),
      R.prop("groupName")
    )(publisherGroups);

    return (
      <OfferMade
        numberOfOffers={publisherIds && publisherIds.length}
        customMessage={customMessage}
        onClose={onClose}
        selectedProgramTermName={selectedProgramTermName}
        selectedPublisherGroupName={selectedPublisherGroupName}
        onMultipleOfferSuccess={onMultipleOfferSuccess}
      />
    );
  }

  const programTermsOptions = R.map((programTerm) => ({
    label: programTerm.isDefault
      ? intl.formatMessage(messages.defaultProgramTermName, [programTerm.name])
      : programTerm.name,
    value: programTerm.id,
  }))(programTerms);

  const selectedProgramTermsOption = R.find(
    R.propEq("value", selectedProgramTermsId)
  )(programTermsOptions);

  const publisherGroupsOptions = R.map((publisherGroup) => ({
    label:
      publisherGroup.groupType === "Default Group"
        ? intl.formatMessage(messages.defaultPublisherGroupName, [
            publisherGroup.groupName,
          ])
        : publisherGroup.groupName,
    value: publisherGroup.groupId,
  }))(publisherGroups);

  const selectedPublisherGroupOption = R.find(
    R.propEq("value", selectedPublisherGroupId)
  )(publisherGroupsOptions);

  const modalProps = {
    onBackgroundClick: onClose,
    onEscapeKeyUp: onClose,
    onCloseIconClick: onClose,
    headerTitle: formatMakeOfferToText(publisherIds, publisher, intl),
    body: (
      <>
        <Box direction="column" gap="large">
          <Box direction="column" gap="medium">
            <Text>{intl.formatMessage(messages.selectProgramTerms)}</Text>
            <Select
              className="program-terms-select"
              options={programTermsOptions}
              value={selectedProgramTermsOption}
              onChange={({ value }) => {
                setSelectedProgramTermsId(value);
              }}
            />
          </Box>
          <Box direction="column" gap="medium">
            <Text>
              {intl.formatMessage(messages.addPublisherToGroupOptional)}
            </Text>
            <Select
              className="publisher-groups-select"
              options={publisherGroupsOptions}
              value={selectedPublisherGroupOption}
              onChange={({ value }) => {
                setSelectedPublisherGroupId(value);
              }}
            />
          </Box>
          <Box direction="column" gap="medium">
            <label htmlFor="make-offers-custom-message">
              <Text>{intl.formatMessage(messages.customMessage)}</Text>
            </label>
            <TextArea
              value={customMessage}
              data-testid="custom-message-textarea"
              id="make-offers-custom-message"
              onChange={(event) => {
                setCustomMessage(event.target.value);
              }}
            />
            {customMessageLimitError ? (
              <Text
                color="critical"
                size={12}
                data-testid="custom-message-limit-error"
              >
                {intl.formatMessage(messages.customMessageLimit, [
                  CUSTOM_MESSAGE_LIMIT,
                ])}
              </Text>
            ) : (
              <Text color="secondary" size={12}>
                {intl.formatMessage(messages.customMessageLimit, [
                  CUSTOM_MESSAGE_LIMIT,
                ])}
              </Text>
            )}
          </Box>
          {modalState === states.ERROR && (
            <Text color="critical">
              {intl.formatMessage(messages.serverError)}
            </Text>
          )}
        </Box>
        <Box direction="row" justify="end" gap="medium">
          <Button type="tertiary" onClick={() => onClose()}>
            {intl.formatMessage(messages.cancel)}
          </Button>
          <Button
            type="primary"
            data-testid="submit-button"
            onClick={() => {
              makeOffer();
            }}
            disabled={
              modalState === states.MAKING_OFFER || customMessageLimitError
            }
            spinner={modalState === states.MAKING_OFFER}
          >
            {intl.formatMessage(messages.submitOffer)}
          </Button>
        </Box>
      </>
    ),
  };

  return <Modal {...modalProps} />;
};
