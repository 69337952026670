import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { ExpandableItem } from "@cjdev-internal/visual-stack-x/ExpandableList";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { Row } from "@cjdev-internal/visual-stack-x/components/Row";
import { SocialIcon } from "components/SocialIcon";
import { Tooltip } from "components/Tooltip";
import { useIntl } from "hooks";
import produce from "immer";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import messages from "../../messages";
import { PropertyReachMetricFilter } from "./PropertyReachMetricFilter";
import "./styles.css";

const getPlatformSelectedOnFilters = (filters, platform) =>
  filters.propertyLevel.propertyTypes.selectedMetrics[platform]
    .platformSelected;

const getPropertyTypeIncludedOnFilters = (filters) =>
  filters.propertyLevel.propertyTypes.include;

export const PropertyReachPlatformFilter = ({
  platform,
  metrics,
  filters,
  setFilters,
}) => {
  const intl = useIntl();
  const getDisabledStatus = useCallback(
    () =>
      !getPlatformSelectedOnFilters(filters, platform) ||
      !getPropertyTypeIncludedOnFilters(filters),
    [filters, platform]
  );

  const [disabledPlatform, setDisabledPlatform] = useState(getDisabledStatus());

  const updateMetricsSelectedOnFilters = useCallback(
    (isChecked) => {
      setFilters((previousFilters) =>
        produce(previousFilters, (draftFilters) => {
          draftFilters.propertyLevel.propertyTypes.selectedMetrics[
            platform
          ].metricsSelected = isChecked;
        })
      );
    },
    [platform, setFilters]
  );

  useEffect(() => {
    const currentlyDisabledStatus = getDisabledStatus();
    setDisabledPlatform(currentlyDisabledStatus);
    currentlyDisabledStatus && updateMetricsSelectedOnFilters(false);
  }, [filters, platform, getDisabledStatus, updateMetricsSelectedOnFilters]);

  const metricsSelectedOnFilters =
    filters.propertyLevel.propertyTypes.selectedMetrics[platform]
      .metricsSelected;

  return (
    <ExpandableItem
      data-testid={platform + "ReachDropDown"}
      disabled={disabledPlatform}
      disabledClassName="disabled-platform"
      label={
        <Tooltip
          content={intl.formatMessage(messages["propertyReachMetricToolTip"])}
          disabled={!disabledPlatform}
        >
          <Row gap="small" className="platform-reach-dropdown">
            <Box justify="center">
              <SocialIcon platform={platform} />
            </Box>
            <Text>
              {intl.formatMessage(globalMessages[R.toUpper(platform)])}
            </Text>
          </Row>
        </Tooltip>
      }
      expanded={metricsSelectedOnFilters}
      onChange={updateMetricsSelectedOnFilters}
    >
      {metrics.map((metric) => (
        <PropertyReachMetricFilter
          key={platform + metric}
          platform={platform}
          metric={metric}
          filters={filters}
          setFilters={setFilters}
        />
      ))}
    </ExpandableItem>
  );
};
