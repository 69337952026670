import { defineMessages } from "react-intl";

export default defineMessages({
  backToResults: {
    id: "partners.PublishersProfile.backToResults",
    defaultMessage: "Go to Partners Search",
  },
  backToPlacements: {
    id: "partners.PublishersProfile.backToPlacements",
    defaultMessage: "Back to Placements",
  },
  locatedIn: {
    id: "partners.PublishersProfile.locatedIn",
    defaultMessage: "Located in {0}, {1}",
  },
  description: {
    id: "partners.PublishersProfile.description",
    defaultMessage: "Description",
  },
  publisherDetails: {
    id: "partners.PublishersProfile.publisherDetails",
    defaultMessage: "Publisher Details",
  },
  primaryCategory: {
    id: "partners.PublishersProfile.primaryCategory",
    defaultMessage: "Primary Category",
  },
  country: {
    id: "partners.PublishersProfile.country",
    defaultMessage: "Country",
  },
  overview: {
    id: "partners.PublishersProfile.overview",
    defaultMessage: "Overview",
  },
  placements: {
    id: "partners.PublishersProfile.placements",
    defaultMessage: "Placements",
  },
  properties: {
    id: "partners.PublishersProfile.properties",
    defaultMessage: "Properties",
  },
  manage: {
    id: "partners.PublishersProfile.manage",
    defaultMessage: "Manage",
  },
  social: {
    id: "partners.PublishersProfile.social",
    defaultMessage: "Social",
  },
  placementId: {
    id: "partners.placement.PublishersProfile.id",
    defaultMessage: "ID",
  },
  placementName: {
    id: "partners.placement.PublishersProfile.name",
    defaultMessage: "Name",
  },
  placementType: {
    id: "partners.placement.PublishersProfile.type",
    defaultMessage: "Type",
  },
  placementCommission: {
    id: "partners.placement.PublishersProfile.property",
    defaultMessage: "Commission",
  },
  placementFee: {
    id: "partners.placement.PublishersProfile.fee",
    defaultMessage: "Fee",
  },
  placementDurationHeader: {
    id: "partners.placement.PublishersProfile.placement",
    defaultMessage: "Duration",
  },
  placementStart: {
    id: "partners.placement.PublishersProfile.start",
    defaultMessage: "Start",
  },
  placementEnd: {
    id: "partners.placement.PublishersProfile.end",
    defaultMessage: "End",
  },
  placementDuration: {
    id: "partners.placement.PublishersProfile.duration",
    defaultMessage: "{0} to {1}",
  },
  placementEmail: {
    id: "partners.placement.PublishersProfile.email",
    defaultMessage: "Email",
  },
  placementContactName: {
    id: "partners.placement.PublishersProfile.contactName",
    defaultMessage: "Contact Name",
  },
  placementPromotionalProperties: {
    id: "partners.placement.PublishersProfile.promotionalProperties",
    defaultMessage: "Promotional Properties",
  },
  placementDocuments: {
    id: "partners.placement.PublishersProfile.documents",
    defaultMessage: "Documents",
  },
  "placement.SINGLE": {
    id: "partners.placement.PublishersProfile.SINGLE",
    defaultMessage: "Single Placement",
  },
  "placement.PACKAGE": {
    id: "partners.placement.PublishersProfile.PACKAGE",
    defaultMessage: "Package",
  },
  "placement.RATE_CARD": {
    id: "partners.placement.PublishersProfile.RATE_CARD",
    defaultMessage: "Full Ratecard",
  },
  placementCaption: {
    id: "partners.placement.PublishersProfile.caption",
    defaultMessage: "Placements",
  },
  singleOppportunityType: {
    id: "partners.placement.PublishersProfile.singleOppportunityType",
    defaultMessage: "Single",
  },
  packageOppportunityType: {
    id: "partners.placement.PublishersProfile.packageOppportunityType",
    defaultMessage: "Package",
  },
  rateCardOppportunityType: {
    id: "partners.placement.PublishersProfile.rateCardOppportunityType",
    defaultMessage: "Rate Card",
  },
  customCreativeType: {
    id: "partners.placement.PublishersProfile.customCreativeType",
    defaultMessage: "Custom",
  },
  textCreativeType: {
    id: "partners.placement.PublishersProfile.textCreativeType",
    defaultMessage: "Text",
  },
  opportunitiesTitle: {
    id: "partners.PublishersProfile.opportunitiesTitle",
    defaultMessage: "Placement Opportunities",
  },
  viewPlacementsMarketplace: {
    id: "partners.PublishersProfile.viewPlacementsMarketplace",
    defaultMessage: "View All in Placements Marketplace",
  },
  noOpportunities: {
    id: "partners.PublishersProfile.noOpportunities",
    defaultMessage: "There are no placement opportunities",
  },
  downloadPromotionalProperties: {
    id: "partners.PublishersProfile.downloadPromotionalProperties",
    defaultMessage: "Download properties",
  },
  propertyId: {
    id: "partners.PublishersProfile.propertyId",
    defaultMessage: "PID",
  },
  propertyName: {
    id: "partners.PublishersProfile.propertyName",
    defaultMessage: "Name",
  },
  propertyType: {
    id: "partners.PublishersProfile.propertyType",
    defaultMessage: "Type",
  },
  propertyDetails: {
    id: "partners.PublishersProfile.Profile.propertyDetails",
    defaultMessage: "Details",
  },
  promotionalModel: {
    id: "partners.PublishersProfile.promotionalModel",
    defaultMessage: "Promotional Model",
  },
  reach: {
    id: "partners.PublishersProfile.reach",
    defaultMessage: "Reach",
  },
  totalProperties: {
    id: "partners.PublishersProfile.totalProperties",
    defaultMessage: "{0} out of {1} total properties",
  },
  downloadProperties: {
    id: "partners.PublishersProfile.downloadProperties",
    defaultMessage: "Download Properties",
  },
  totalProperty: {
    id: "partners.PublishersProfile.totalProperty",
    defaultMessage: "{0} out of {1} total property",
  },
  propertiesMatching: {
    id: "partners.PublishersProfile.propertiesMatching",
    defaultMessage: "properties matching",
  },
  propertyMatching: {
    id: "partners.PublishersProfile.propertyMatching",
    defaultMessage: "property matching",
  },
  noDescription: {
    id: "partners.PublishersProfile.noDescription",
    defaultMessage: "No description provided.",
  },
  noPlacements: {
    id: "partners.PublishersProfile.noPlacements",
    defaultMessage: "No placements provided.",
  },
  backToProperties: {
    id: "partners.PublishersProfile.backToProperties",
    defaultMessage: "Back to properties",
  },
  primaryProperty: {
    id: "partners.PublishersProfile.primaryProperty",
    defaultMessage: "PRIMARY PROPERTY",
  },
  lastUpdatedOn: {
    id: "partners.PublishersProfile.lastUpdatedOn",
    defaultMessage: "Last updated on",
  },
  propertyPositionMessage: {
    id: "partners.PublishersProfile.propertyPositionMessage",
    defaultMessage: "Property {0} of {1}",
  },
  active: {
    id: "partners.PublishersProfile.active",
    defaultMessage: "Active",
  },
  primaryModel: {
    id: "partners.PublishersProfile.primaryModel",
    defaultMessage: "Primary model",
  },
  platform: {
    id: "partners.PublishersProfile.platform",
    defaultMessage: "Platform",
  },
  downloadLinks: {
    id: "partners.PublishersProfile.downloadLinks",
    defaultMessage: "Download Links",
  },
  close: {
    id: "partners.PublishersProfile.close",
    defaultMessage: "CLOSE",
  },
  cancel: {
    id: "partners.PublishersProfile.cancel",
    defaultMessage: "CANCEL",
  },
  submitOffer: {
    id: "partners.PublishersProfile.submitOffer",
    defaultMessage: "SUBMIT OFFER",
  },
  serverError: {
    id: "partners.PublishersProfile.serverError",
    defaultMessage: "There was an error, please try again.",
  },
  reportPublisher: {
    id: "partners.PublishersProfile.reportPublisher",
    defaultMessage: "Report Publisher",
  },
  reportPublisherReason: {
    id: "partners.PublishersProfile.reportPublisherReason",
    defaultMessage: "Describe the nature of the offense here",
  },
  reportedPublisherConfirmationMessage: {
    id: "partners.PublishersProfile.repotedPublisherConfirmationMessage",
    defaultMessage: "A ticket has been submitted and will be reviewed.",
  },
  reportedPublisherFailedMessage: {
    id: "partners.PublishersProfile.reportedPublisherFailedMessage",
    defaultMessage:
      "There was a problem, please try again. If the problem continues, please contact client support.",
  },
  reportPublisherEmptyReasonMessage: {
    id: "partners.PublishersProfile.reportPublisherEmptyReasonMessage",
    defaultMessage: "Please describe this publisher's suspicious activity.",
  },
  fileAttached: {
    id: "partners.PublishersProfile.fileAttached",
    defaultMessage: "1 file attached",
  },
  fileSizeExceededMaximumSize: {
    id: "partners.PublishersProfile.fileSizeExceededMaximumSize",
    defaultMessage: "The attachment size exceeds the allowable limit.",
  },
  fileSizeWarning: {
    id: "partners.PublishersProfile.fileSizeWarning",
    defaultMessage: "10MB attachment size limit",
  },
  reportPublisherDescrption: {
    id: "partners.PublishersProfile.reportPublisherDescription",
    defaultMessage:
      "Our Network Quality Team reviews suspicious publishers and removes those publishers from the CJ Network who are found to be violating the terms of our {0}. If you think this publisher should be reviewed by Network Quality, please describe the offense in the text box below.",
  },
  reportPublisherDescription2: {
    id: "partners.PublishersProfile.reportPublisherDescription2",
    defaultMessage:
      "The Network Quality team carefully reviews all reports and may take corrective action if necessary, up to deactivating the publisher from the Network. You will get a notification by email of their decision within a few days.",
  },
  publisherServiceAgreement: {
    id: "partners.PublishersProfile.publisherServiceAgreement",
    defaultMessage: "Publisher Service Agreement",
  },
  fileUploadInstruction: {
    id: "partners.PublishersProfile.fileUploadInstruction",
    defaultMessage:
      "You are also encouraged to attach a file such as a screenshot with evidence of the potential violation. For example if you think a publisher is bidding on your brand keywords, attach a screenshot of the ad.",
  },
  reportableBehaviorTitle: {
    id: "partners.PublishersProfile.reportableBehavior",
    defaultMessage: "Examples of reportable behavior include:",
  },
  reportableBehavior1: {
    id: "partners.PublishersProfile.reportableBehavior1",
    defaultMessage:
      "Publisher's promotional properties contain objectionable content",
  },
  reportableBehavior2: {
    id: "partners.PublishersProfile.reportableBehavior2",
    defaultMessage: "Publisher is violating copyright or privacy laws",
  },
  reportableBehavior3: {
    id: "partners.PublishersProfile.reportableBehavior3",
    defaultMessage: "Publisher is committing fraud",
  },
  reportableBehavior4: {
    id: "partners.PublishersProfile.reportableBehavior4",
    defaultMessage:
      "Publisher's program or promotional property descriptions are misleading or wrong",
  },
  reportableBehavior5: {
    id: "partners.PublishersProfile.reportableBehavior5",
    defaultMessage: "Publisher's behavior is abusive or harassing",
  },
  topAdvertiserCategories: {
    id: "partners.PublishersProfile.topAdvertiserCategories",
    defaultMessage: "Top Advertiser Categories",
  },
});
