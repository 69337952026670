import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { SocialIcon } from "@cjdev-internal/visual-stack-x/SocialIcon";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { PureLayoutSection } from "@cjdev-internal/visual-stack-x/LayoutSection";
import { AuthenticationStatusByProperty } from "components/AuthenticationStatus";
import {
  GenerateSimilarwebData,
  getDataById,
} from "components/GenerateSimilarwebData";
import { LinkWithQuery } from "components/LinkWithQuery";
import { PropertyTypeDetails } from "components/PropertyTypeDetails";
import {
  publisherProfilePropertiesListPath,
  publisherProfilePropertyDetailsPath,
} from "constants/paths";
import {
  TIKTOK_METRICS,
  useFeatures,
  useIntl,
  useMatchingProperties,
  USER_CAN_AUTHENTICATE_IN_CIQ,
} from "hooks";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React from "react";
import { formattedPropertyReach, propertyReach } from "utils/propertyUtils";
import { formatPropertyType } from "utils/utils";
import { isWebsite } from "utils/webUtils";
import messages from "./messages";
import {
  NestedTable,
  NestedTableBody,
  NestedTableHead,
  NestedTd,
  NestedTh,
  NestedTr,
} from "./NestedTable";
import "./styles.css";

export const MatchingProperties = ({ publisher }) => {
  const intl = useIntl();
  const { hasFeature } = useFeatures();
  const hasUserCanAuthenticateInCiqFeature = hasFeature(
    USER_CAN_AUTHENTICATE_IN_CIQ
  );
  const hasTikTokFeature = hasFeature(TIKTOK_METRICS);

  const {
    expandedPublisherIds,
    setExpandedPublisherIds,
  } = useMatchingProperties();

  const isExpanded = expandedPublisherIds.includes(publisher.id);

  const toggleExpanded = (expanded) =>
    setExpandedPublisherIds(
      expanded
        ? [...expandedPublisherIds, publisher.id]
        : R.without([publisher.id], expandedPublisherIds)
    );

  const titleHeader =
    R.length(publisher.promotionalProperties || []) > 1
      ? intl.formatMessage(messages.topMatchingProperties)
      : intl.formatMessage(messages.matchingProperty);

  const top5Properties = R.take(5, publisher.promotionalProperties || []);

  return (
    <Box
      direction="column"
      gap="large"
      padding="medium"
      paddingBottom="medium-large"
    >
      <PureLayoutSection
        label={titleHeader}
        expanded={isExpanded}
        setExpanded={toggleExpanded}
        disableFormPageBlockAdjust
        className="matching-properties-section"
      >
        <GenerateSimilarwebData
          properties={top5Properties}
          calledFrom="search"
          similarwebDependantComponent={({ similarwebData }) => {
            return (
              <NestedTable>
                <NestedTableHead>
                  <NestedTh className="partners-nested-name">
                    {intl.formatMessage(messages.name)}
                  </NestedTh>
                  <NestedTh className="partners-nested-type">
                    {intl.formatMessage(messages.type)}
                  </NestedTh>
                  <NestedTh className="partners-nested-details">
                    {intl.formatMessage(messages.propertyDetails)}
                  </NestedTh>
                  <NestedTh className="partners-nested-promotional-model">
                    {intl.formatMessage(messages.promotionalModel)}
                  </NestedTh>
                  <NestedTh className="partners-nested-reach">
                    {intl.formatMessage(messages.reach)}
                  </NestedTh>
                </NestedTableHead>
                <NestedTableBody>
                  {publisher.promotionalProperties &&
                    top5Properties.map((property) => {
                      const propertyIsWebsite = isWebsite(property);
                      const similarwebWebsiteData = getDataById(
                        similarwebData,
                        property.id
                      );

                      const reach = propertyReach(
                        property,
                        similarwebWebsiteData
                      );

                      const formattedReachMetric = formattedPropertyReach(
                        property,
                        similarwebWebsiteData,
                        intl,
                        hasTikTokFeature
                      );

                      return (
                        <NestedTr key={property.id}>
                          <NestedTd className="partners-nested-name">
                            {hasUserCanAuthenticateInCiqFeature && (
                              <AuthenticationStatusByProperty
                                property={property}
                                className="nested-table-authentication-status"
                              />
                            )}
                            <LinkWithQuery
                              to={publisherProfilePropertyDetailsPath(
                                publisher.id,
                                property.id
                              )}
                            >
                              <Text size={12} color="link">
                                {property.name}
                              </Text>
                            </LinkWithQuery>
                          </NestedTd>
                          <NestedTd className="partners-nested-type">
                            <Text size={12}>
                              {formatPropertyType(
                                property.type,
                                property.propertyTypeDetails,
                                intl
                              )}
                            </Text>
                          </NestedTd>
                          <NestedTd className="partners-nested-details">
                            <Text size={12}>
                              <PropertyTypeDetails
                                propertyType={property.type}
                                propertyTypeDetails={
                                  property.propertyTypeDetails
                                }
                              />
                            </Text>
                          </NestedTd>
                          <NestedTd className="partners-nested-promotional-model">
                            {property.primaryPromotionalModel ? (
                              <Text size={12}>
                                {intl.formatMessage(
                                  globalMessages[
                                    `accounts.PromotionalModels.${property.primaryPromotionalModel}`
                                  ]
                                )}
                              </Text>
                            ) : (
                              <Text color="secondary" size={12}>
                                {intl.formatMessage(
                                  messages.noPromotionalModel
                                )}
                              </Text>
                            )}
                          </NestedTd>
                          <NestedTd className="partners-nested-reach">
                            <Box direction="row" align="center">
                              <SocialIcon
                                size={20}
                                icon={
                                  propertyIsWebsite
                                    ? "web"
                                    : property?.propertyTypeDetails?.socialMediaPlatform?.toLowerCase()
                                }
                              />
                              <Text
                                color={`${reach ? "primary" : "secondary"}`}
                                italic={!reach}
                                size={12}
                              >
                                {formattedReachMetric}
                              </Text>
                            </Box>
                          </NestedTd>
                        </NestedTr>
                      );
                    })}
                </NestedTableBody>
              </NestedTable>
            );
          }}
        />
        <Box
          direction="row"
          gap="xl"
          className="more-details-links"
          paddingTop="large"
        >
          <LinkWithQuery to={publisherProfilePropertiesListPath(publisher.id)}>
            <Text color="link" size={12}>
              {intl.formatMessage(messages.viewAllProperties)}
            </Text>
          </LinkWithQuery>
        </Box>
      </PureLayoutSection>
    </Box>
  );
};
