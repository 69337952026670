import Case from "case";
import * as R from "ramda";
import globalMessages from "../messages/globalMessages";
import homeMessages from "../views/PublishersSearch/messages";

export const getBrowserLanguage = () => R.take(2, navigator.language);

export const capitalizeName = ["en", "es", "de", "fr", "pl", "pt"].includes(
  getBrowserLanguage()
)
  ? Case.capital
  : getBrowserLanguage() === "cs"
  ? Case.sentence
  : R.identity;

export const formatPropertyType = (propertyType, propertyTypeDetails, intl) =>
  propertyType === "SOCIAL_MEDIA"
    ? intl.formatMessage(
        globalMessages[propertyTypeDetails.socialMediaPlatform]
      )
    : intl.formatMessage(globalMessages[`${propertyType}`]);

export const formatCategory = (category, intl) => {
  const message = globalMessages[`global.category.${category}`];

  if (!message) {
    console.error(`global.category.${category} message not found`);
    return "";
  }

  return intl.formatMessage(message);
};

export const formatCommissionInfo = (type, amount, currency) => {
  switch (type) {
    case "PERCENT":
      return `${amount}%`;
    case "FIXED":
      return formatCurrency(amount, currency);
    default:
      return "";
  }
};

export const formatCategoryPercentages = (commissions, intl) => {
  const categories = R.pipe(
    R.defaultTo([]),
    R.sort(R.descend(R.prop("percentage"))),
    R.map(({ category, percentage }) => ({
      category: formatCategory(category, intl),
      value: percentage,
    }))
  )(commissions);

  if (categories.length < 6) {
    return categories;
  }

  const top4 = R.take(4, categories);

  const other = {
    category: intl.formatMessage(homeMessages.pieChartOther),
    value: R.pipe(R.drop(4), R.pluck("value"), R.sum)(categories),
  };

  return [...top4, other];
};

export const formatCurrency = (amount, currencyCode) => {
  switch (currencyCode) {
    case "USD":
      return `${Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currencyCode,
      }).format(amount)} ${currencyCode}`;
    case "CAD":
      return `${Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: currencyCode,
      }).format(amount)} ${currencyCode}`;
    case "GBP":
      return `${Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: currencyCode,
      }).format(amount)} ${currencyCode}`;
    case "EUR":
      return `${Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: currencyCode,
      }).format(amount)} ${currencyCode}`;
    case "SEK":
      return `${Intl.NumberFormat("sv-SE", {
        style: "currency",
        currency: currencyCode,
      }).format(amount)} ${currencyCode}`;
    case "CZK":
      return `${Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: currencyCode,
      }).format(amount)} ${currencyCode}`;
    case "DKK":
      return `${Intl.NumberFormat("da-DK", {
        style: "currency",
        currency: currencyCode,
      }).format(amount)} ${currencyCode}`;
    case "PLN":
      return `${Intl.NumberFormat("pl-PL", {
        style: "currency",
        currency: currencyCode,
      }).format(amount)} ${currencyCode}`;
    case "ZAR":
      return `${Intl.NumberFormat("en-ZA", {
        style: "currency",
        currency: currencyCode,
      }).format(amount)} ${currencyCode}`;
    default:
      return `${Intl.NumberFormat({
        style: "currency",
        currency: currencyCode,
      }).format(amount)} ${currencyCode}`;
  }
};

export const formatEpc = (epc, intl) => {
  const formattedEpc = R.pipe(parseFloat, (parsedEpc) =>
    Math.abs(parsedEpc) === 9999999 || isNaN(parsedEpc)
      ? intl.formatMessage(globalMessages["global.na"])
      : formatCurrency(parsedEpc, "USD")
  )(epc);
  return formattedEpc;
};

export const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

export const DAY_IN_MS = 1000 * 60 * 60 * 24;

export const isNewToNetwork = (publisher) => {
  const age =
    new Date().getTime() - new Date(publisher.activationDate).getTime();
  return age / DAY_IN_MS <= 90;
};

const supportedLanguages = [
  "en",
  "es",
  "fr",
  "de",
  "zh",
  "cs",
  "pl",
  "pt",
  "ja",
];

export const getEmailLanguage = () => {
  const lang = R.take(2, navigator.language);
  return R.includes(lang, supportedLanguages) ? lang : "en";
};

export const removeUnprioritizedCountries = (country) => {
  const prioritizedCountries = new Set([
    "US",
    "CA",
    "GB",
    "FR",
    "DE",
    "ES",
    "SE",
  ]);

  const isPrioritizedCountry = (country) => prioritizedCountries.has(country);

  const omitKeys = (...args) => {
    return R.omit([...args]);
  };
  const omitSpecificKeys = R.curry(omitKeys);

  return !isPrioritizedCountry(country.isoCode)
    ? omitSpecificKeys("subdivisions")(country)
    : country;
};

const surroundWithDoubleBrackets = (value) => `[${value}]`;

export const stringify = (value) => {
  if (Array.isArray(value)) {
    return R.pipe(
      R.map(stringify),
      R.join(";"),
      surroundWithDoubleBrackets
    )(value);
  }
  if (typeof value === "object") {
    return R.pipe(R.values, R.map(stringify), R.join(","))(value);
  }
  return value;
};

export const stringifyHtmlClasses = (objectOfStyleClasses) =>
  R.pipe(
    R.filter((value) => value),
    R.keys,
    R.join(" ")
  )(objectOfStyleClasses);

export const formatCamelToConstantCase = R.pipe(
  (word) => R.split(/(?=[A-Z])/, word),
  (letters) => R.join("_", letters),
  R.toUpper
);

export const formatIntToStringWithCommas = (number) =>
  number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
export const cleanNumberStringToInt = (string) =>
  parseInt(string.replace(/\D/g, ""));

export const ramdaPrint = (data) => {
  console.log(data);
  return data;
};

export const roundToSepcficDecimalPoint = (float, numOfPlaces) =>
  +`${Math.round(`${float}e+${numOfPlaces}`)}e-${numOfPlaces}`;

export const modifyFunctionToAcceptObjectsInsteadOfIds = (callback) =>
  R.pipe(R.pluck("id"), callback);

export const returnTrue = () => true;
export const returnFalse = () => false;

export const checkFavoritePublisher = (favoritesMap, publisherId) =>
  favoritesMap[publisherId];

export const checkFavoritePublishers = (favoritesMap, publisherIds) =>
  R.all((selectedPublisher) => favoritesMap[selectedPublisher])(publisherIds);

export const isPublisherHiddenFunctionBuilder = (hiddenIdMap) => (
  publisherId
) => {
  return Boolean(hiddenIdMap[publisherId]);
};

export const setPublisherVisibilityBuilder = (setHiddenIdMap) =>
  R.forEach((publisherId) => {
    setHiddenIdMap((prevMap) => {
      return {
        ...prevMap,
        [publisherId]: true,
      };
    });
  });
