import { Button } from "@cjdev-internal/visual-stack-x/Button";
import React from "react";
import intl from "utils/intl";
import messages from "../messages";

export const UndoSelectedPublishersButton = ({
  unfavoritedPublishersRef,
  unsetPublisherVisibility,
  setIndexedPublishers,
  produceUndoPublishers,
  postFavorite,
}) => (
  <Button
    type="tertiary"
    data-testid="undo-btn"
    onClick={() => {
      const publisherIdsToUndo = unfavoritedPublishersRef.current;
      unsetPublisherVisibility(publisherIdsToUndo);
      setIndexedPublishers(produceUndoPublishers(publisherIdsToUndo));
      postFavorite(publisherIdsToUndo);
      unfavoritedPublishersRef.current = [];
    }}
  >
    {intl.formatMessage(messages.undoText)}
  </Button>
);
