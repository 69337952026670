import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { ClampedText } from "components/ClampedText";
import { useIntl } from "hooks";
import React from "react";
import { isNullOrEmpty } from "utils/utils";
import messages from "../../../messages";
import "./styles.css";

export const PublisherDescription = ({ publisher }) => {
  const intl = useIntl();
  return (
    <Box direction="column" gap="medium" className="publisher-description">
      <Text weight="medium" size={14}>
        {intl.formatMessage(messages.description)}
      </Text>
      {isNullOrEmpty(publisher.description) ? (
        <Text color="secondary" className="description-text" italic>
          {intl.formatMessage(messages.noDescription)}
        </Text>
      ) : (
        <ClampedText className="description-text">
          {publisher.description}
        </ClampedText>
      )}
    </Box>
  );
};
