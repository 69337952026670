import React, { useState } from "react";
import { MinMaxBar } from "./MinMaxBar";
import { MinMaxRangeInput } from "./MinMaxRangeInput";
import { MinMaxThumb } from "./MinMaxThumb";

export const MinMaxSlider = ({
  minThreshold = 0,
  maxThreshold = 100,
  min,
  max,
  onMinChange,
  onMaxChange,
  onMinSelection,
  onMaxSelection,
  step = 1,
}) => {
  const relativePercentageOnSlider = 100 / (maxThreshold - minThreshold);
  const relativePercentageOffset = relativePercentageOnSlider * minThreshold;

  const minPercentage = Math.round(
    relativePercentageOnSlider * min - relativePercentageOffset
  );
  const maxPercentage = Math.round(
    relativePercentageOnSlider * max - relativePercentageOffset
  );

  const [minHover, setMinHover] = useState(false);
  const [maxHover, setMaxHover] = useState(false);

  return (
    <div className="min-max-slider">
      <div>
        <MinMaxBar
          minPercentage={minPercentage}
          maxPercentage={maxPercentage}
        />
        <MinMaxThumb
          percentage={minPercentage}
          hovered={minHover}
          zIndex={min >= maxThreshold ? 2 : 1}
        />
        <MinMaxThumb
          percentage={maxPercentage}
          hovered={maxHover}
          zIndex={min >= maxThreshold ? 1 : 2}
        />
      </div>

      <MinMaxRangeInput
        value={min}
        max={maxThreshold}
        min={minThreshold}
        step={step}
        onChange={onMinChange}
        onSelection={onMinSelection}
        zIndex={min === maxThreshold ? 4 : 3}
        onHover={setMinHover}
      />

      <MinMaxRangeInput
        value={max}
        max={maxThreshold}
        min={minThreshold}
        step={step}
        onChange={onMaxChange}
        onSelection={onMaxSelection}
        zIndex={min === maxThreshold ? 3 : 4}
        onHover={setMaxHover}
      />
    </div>
  );
};
