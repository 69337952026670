import { getFormattedReachMetric, getReachMetric } from "./ciqUtils";
import { getFormattedMonthlyTotalVisitors, isWebsite } from "./webUtils";

export const propertyReach = (property, similarwebWebsiteData) =>
  isWebsite(property)
    ? similarwebWebsiteData?.totalMonthlyVisitors
    : getReachMetric(property);

export const formattedPropertyReach = (
  property,
  similarwebWebsiteData,
  intl,
  hasTikTokFeature
) =>
  isWebsite(property)
    ? getFormattedMonthlyTotalVisitors(similarwebWebsiteData, intl)
    : getFormattedReachMetric(property, intl, hasTikTokFeature);
