import { defineMessages } from "react-intl";

export default defineMessages({
  contentCertified: {
    id: "partners.Badges.contentCertified",
    defaultMessage: "CONTENT CERTIFIED",
  },
  cjPerformer: {
    id: "partners.Badges.cjPerformer",
    defaultMessage: "CJ PERFORMER",
  },
  featured: {
    id: "partners.Badges.featured",
    defaultMessage: "CJ SPOTLIGHT",
  },
  newToNetwork: {
    id: "partners.Badges.newToNetwork",
    defaultMessage: "NEW TO NETWORK",
  },
  subAffiliate: {
    id: "partners.Badges.subAffiliate",
    defaultMessage: "SUB-AFFILIATE",
  },
});
