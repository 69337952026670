export const defaultSelectedMetrics = {
  selectedMetrics: {
    website: {
      platformSelected: false,
      metricsSelected: false,
      metrics: {
        totalMonthlyVisitors: {
          minValue: 0,
          maxValue: Infinity,
        },
      },
    },
    instagram: {
      platformSelected: false,
      metricsSelected: false,
      metrics: {
        followers: {
          minValue: 0,
          maxValue: Infinity,
        },
      },
    },
    facebook: {
      platformSelected: false,
      metricsSelected: false,
      metrics: {
        pageLikes: {
          minValue: 0,
          maxValue: Infinity,
        },
      },
    },
    youtube: {
      platformSelected: false,
      metricsSelected: false,
      metrics: {
        subscribers: {
          minValue: 0,
          maxValue: Infinity,
        },
      },
    },
    tiktok: {
      platformSelected: false,
      metricsSelected: false,
      metrics: {
        followers: {
          minValue: 0,
          maxValue: Infinity,
        },
      },
    },
    twitter: {
      platformSelected: false,
      metricsSelected: false,
      metrics: {
        followers: {
          minValue: 0,
          maxValue: Infinity,
        },
      },
    },
    twitch: {
      platformSelected: false,
      metricsSelected: false,
      metrics: {
        followers: {
          minValue: 0,
          maxValue: Infinity,
        },
      },
    },
    pinterest: {
      platformSelected: false,
      metricsSelected: false,
      metrics: {
        followers: {
          minValue: 0,
          maxValue: Infinity,
        },
      },
    },
    whatsapp: {
      platformSelected: false,
    },
    snapchat: {
      platformSelected: false,
      metricsSelected: false,
      metrics: {
        followers: {
          minValue: 0,
          maxValue: Infinity,
        },
      },
    },
  },
};

export const modifySelectedMetrics = (overrideSelectedMetrics) => {
  return {
    selectedMetrics: {
      ...defaultSelectedMetrics.selectedMetrics,
      ...overrideSelectedMetrics,
    },
  };
};
