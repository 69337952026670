import { useAppliedFilters, useIntl, useSearchTerms } from "hooks";
import * as R from "ramda";
import React, { useState } from "react";
import { getSortByNamesFromCurrentFilters } from "./utils/currentSortByNames";
import {
  getSortByOptionFromName,
  getSortByOptionsFromNames,
} from "./utils/sortByOptionsUtils";

export const SortByContext = React.createContext({
  providerInitialized: false,
});

export const SortByProvider = ({ children, ...rest }) => {
  const intl = useIntl();
  const [sortBySelection, setSortBySelection] = useState();
  const { appliedFiltersInformation } = useAppliedFilters();
  const { searchTerms } = useSearchTerms();
  const {
    currentSortByNames,
    defaultSortByName,
  } = getSortByNamesFromCurrentFilters(appliedFiltersInformation, searchTerms);
  const sortByOptions = getSortByOptionsFromNames(currentSortByNames, intl);
  const defaultSelection = getSortByOptionFromName(defaultSortByName, intl);

  const isSelectionStillAvailable = () => {
    return R.includes(sortBySelection, sortByOptions);
  };

  const currentSelection = isSelectionStillAvailable()
    ? sortBySelection
    : defaultSelection;

  return (
    <SortByContext.Provider
      value={{
        sortByOptions,
        sortBySelection: currentSelection,
        setSortBySelection,
        providerInitialized: true,
        ...rest,
      }}
    >
      {children}
    </SortByContext.Provider>
  );
};
