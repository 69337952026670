import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { MDIcon } from "@cjdev-internal/visual-stack-x/components/MDIcon";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { ChoiceInput } from "@cjdev-internal/visual-stack-x/legacy/Form";
import { Tooltip } from "components/Tooltip";
import { useIntl } from "hooks";
import { produce } from "immer";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React from "react";
import accountsSchema from "schemas/accountsSchema.json";
import messages from "../../messages";
import "./styles.css";
import { Clickable } from "@cjdev-internal/visual-stack-x/Clickable";
import { Stack } from "@cjdev-internal/visual-stack-x/components/Stack";

const PromotionalModelsFilter = ({ filters, setFilters }) => {
  const intl = useIntl();
  const { selected } = filters.propertyLevel.promotionalModels;

  const setSelected = (selectedPromotionalModelNames = []) => {
    setFilters((previousFilters) => {
      const nextFilters = produce(previousFilters, (draftFilters) => {
        draftFilters.propertyLevel.promotionalModels.selected = selectedPromotionalModelNames;
      });
      return nextFilters;
    });
  };

  const onCheck = (selectedPromotionalModelName) => (e) => {
    if (e.target.checked) {
      setSelected([...selected, selectedPromotionalModelName]);
    } else {
      setSelected(R.without(selectedPromotionalModelName)(selected));
    }
  };

  const onClick = (selectedPromotionalModelNames = []) => {
    setSelected(selectedPromotionalModelNames);
  };

  const promotionalModelNames = R.pipe(
    R.path(["__schema", "types"]),
    R.find(R.propEq("name", "PromotionalModelType")),
    R.prop("enumValues"),
    R.pluck("name")
  )(accountsSchema);

  return (
    <Stack gap="medium-large">
      <Box direction="row" gap="large">
        <Clickable
          data-testid="promotionalModelSelectAll"
          onClick={() => onClick(promotionalModelNames)}
        >
          {intl.formatMessage(messages.selectAll)}
        </Clickable>

        <Clickable
          data-testid="promotionalModelUnselectAll"
          onClick={() => onClick([])}
        >
          {intl.formatMessage(messages.unselectAll)}
        </Clickable>
      </Box>
      <Box direction="column">
        {promotionalModelNames.map((promotionalModelName) => (
          <Box
            direction="row"
            paddingBottom="small"
            justify="space-between"
            key={promotionalModelName}
            className="promotional-model-filter-row"
          >
            <ChoiceInput
              name="promotionalModel"
              type="checkbox"
              value={promotionalModelName}
              onChange={onCheck(promotionalModelName)}
              checked={R.includes(promotionalModelName, selected)}
              label={
                <Text className="promotional-models-input">
                  {intl.formatMessage(
                    globalMessages[
                      `accounts.PromotionalModels.${promotionalModelName}`
                    ]
                  )}
                </Text>
              }
            />
            <Tooltip
              className="promotional-model-filter-tooltip"
              content={intl.formatMessage(
                messages[`tooltip.${promotionalModelName}`]
              )}
            >
              <MDIcon
                icon="information-outline"
                className="information-icon"
                size={16}
              />
            </Tooltip>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

export const PromotionalModelFilter = ({ filters, setFilters }) => {
  const intl = useIntl();
  const { selected, include } = filters.propertyLevel.promotionalModels;

  const setInclude = (include) => {
    setFilters((previousFilters) => {
      const nextFilters = produce(previousFilters, (draftFilters) => {
        draftFilters.propertyLevel.promotionalModels = { selected, include };
      });
      return nextFilters;
    });
  };

  return (
    <Box direction="column" gap="medium-large">
      <Box direction="row" gap="large">
        <ChoiceInput
          name="includePromotionalModel"
          label={intl.formatMessage(messages.include)}
          value="include"
          checked={include}
          data-testid="includePromotionalModel"
          onChange={() => setInclude(true)}
        />

        <ChoiceInput
          name="excludePromotionalModel"
          label={intl.formatMessage(messages.exclude)}
          value="exclude"
          checked={!include}
          data-testid="excludePromotionalModel"
          onChange={() => setInclude(false)}
        />
      </Box>
      <PromotionalModelsFilter filters={filters} setFilters={setFilters} />
    </Box>
  );
};
