import { defineMessages } from "react-intl";

export default defineMessages({
  defaultProgramTermName: {
    id: "partners.OfferModal.defaultProgramTermName",
    defaultMessage: "{0} (default)",
  },
  defaultPublisherGroupName: {
    id: "partners.OfferModal.defaultPublisherGroupName",
    defaultMessage: "{0} (default)",
  },
  selectProgramTerms: {
    id: "partners.OfferModal.selectProgramTerms",
    defaultMessage: "Select Program Terms",
  },
  addPublisherToGroupOptional: {
    id: "partners.OfferModal.addPublisherToGroupOptional",
    defaultMessage: "Add Publisher to Group (optional)",
  },
  customMessage: {
    id: "partners.OfferModal.customMessage",
    defaultMessage: "Custom Message (optional)",
  },
  customMessageLimit: {
    id: "partners.OfferModal.customMessageLimit",
    defaultMessage: "{0} character limit, No HTML",
  },
  managePublishers: {
    id: "partners.OfferModal.managePublishers",
    defaultMessage: "Manage Publishers",
  },
  offerMadeDescription: {
    id: "partners.OfferModal.offerMadeDescription",
    defaultMessage:
      "You will be notified by email if the publisher accepts your offer. View the status of your offers in the {0} area.",
  },
  offerMadeToMultiplePublishersDescription: {
    id: "partners.OfferModal.offerMadeToMultiplePublishersDescription",
    defaultMessage:
      "You will be notified by email if any of the publishers accept your offers. View the status of your offers in the {0} area.",
  },
  offersMadeToMultiplePublishers: {
    id: "partners.OfferModal.offersMadeToMultiplePublishers",
    defaultMessage: "{0} offers have been successfully sent!",
  },
  offerMade: {
    id: "partners.OfferModal.offerMade",
    defaultMessage: "Your offer has been sent!",
  },
  programTerms: {
    id: "partners.OfferModal.programTerms",
    defaultMessage: "Program Terms",
  },
  addPublisherToGroup: {
    id: "partners.OfferModal.addPublisherToGroup",
    defaultMessage: "Add Publisher to Group",
  },
  close: {
    id: "partners.OfferModal.close",
    defaultMessage: "CLOSE",
  },
  cancel: {
    id: "partners.OfferModal.cancel",
    defaultMessage: "CANCEL",
  },
  submitOffer: {
    id: "partners.OfferModal.submitOffer",
    defaultMessage: "SUBMIT OFFER",
  },
  serverError: {
    id: "partners.OfferModal.serverError",
    defaultMessage: "There was an error, please try again.",
  },
  makeOfferTo: {
    id: "partners.OfferModal.makeOfferTo",
    defaultMessage: "Make an offer to {0}",
  },
  makeOfferWithoutOrganization: {
    id: "partners.OfferModal.makeOfferWithoutOrganization",
    defaultMessage: "Make an offer",
  },
  makeOfferToMultiplePublishers: {
    id: "partners.OfferModal.makeOfferToMultiplePublishers",
    defaultMessage: "Make {0} offers",
  },
});
