import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Button, IconButton } from "@cjdev-internal/visual-stack-x/Button.js";
import { useModal } from "@cjdev-internal/visual-stack-x/Modal.js";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { AuthenticationStatusByPublisher } from "components/AuthenticationStatus";
import { Badges } from "components/Badges";
import { Favorite } from "components/Favorite";
import { Spinner } from "components/Spinner";
import { Tooltip } from "components/Tooltip";
import {
  useAlerts,
  useAuthenticatedSocialMediaCount,
  useExcludedPublisherIds,
  useFeatures,
  useIntl,
  USER_CAN_AUTHENTICATE_IN_CIQ,
  useUser,
} from "hooks";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React, { useState } from "react";
import { userCanMakeOffer } from "utils/authorizationUtils";
import { OfferModal } from "views/OfferModal";
import { ReportPublisherModal } from "views/ReportPublisherModal";
import "../styles.css";
import messages from "./messages";
import "./styles.css";

export const PublisherDetails = ({
  publisher,
  favoritePublishers,
  postFavorite,
  postUnfavorite,
  shouldRenderFavorite,
  isAdvertiserOrEmployee,
}) => {
  const intl = useIntl();
  const excludePublisherIds = useExcludedPublisherIds();
  const [mount, openModal, closeModal] = useModal();
  const { toastMount, toggleSuccessAlert, toggleWarningAlert } = useAlerts();
  const {
    data: authenticatedSocialMediaCount,
    loading: isLoadingAuthenticatedSocialMediaCount,
  } = useAuthenticatedSocialMediaCount(publisher.publisherId);

  const [isFavorite, setIsFavorite] = useState(
    R.includes(publisher.publisherId)(favoritePublishers || [])
  );

  const [offerMade, setOfferMade] = useState(false);

  const { user } = useUser();
  const canMakeOffer = userCanMakeOffer(user);

  const { hasFeature } = useFeatures();
  const hasUserCanAuthenticateInCiqFeature = hasFeature(
    USER_CAN_AUTHENTICATE_IN_CIQ
  );

  if (isLoadingAuthenticatedSocialMediaCount) {
    return (
      <Box padding="xl" align="center">
        <Spinner />
      </Box>
    );
  }

  const openReportPublisherModal = () =>
    openModal(
      <ReportPublisherModal
        onClose={closeModal}
        publisherId={publisher.publisherId}
        onEscapeKeyUp={closeModal}
        toggleSuccessAlert={toggleSuccessAlert}
        toggleWarningAlert={toggleWarningAlert}
      />
    );

  const openOfferModal = () =>
    openModal(
      <OfferModal
        onClose={closeModal}
        publisher={publisher}
        onOfferMade={setOfferMade}
      />
    );

  const returnedResultList =
    authenticatedSocialMediaCount?.indexedPublishers?.resultList;

  const publisherWithAuthStatus = returnedResultList && returnedResultList[0];
  return (
    <>
      {mount}
      {toastMount}
      <Box direction="row" padding="xl" justify="space-between" align="start">
        <Box direction="column">
          <Text weight="medium" size={16}>
            {publisher.organization}
          </Text>

          <Box
            direction="row"
            className="publisher-subheader"
            gap="medium"
            align="center"
          >
            <Text size={12} weight="medium" className="publisher-id">
              {publisher.publisherId}
            </Text>
            <Text color="secondary">•</Text>
            <Text size={12} color="secondary">
              {intl.formatMessage(globalMessages.locatedIn, [
                publisher.countrySubdivision,
                publisher.countryCode,
              ])}
            </Text>
            <Text color="secondary">•</Text>
            <Text size={12} color="secondary">
              {intl.formatMessage(messages.publisherSince, [
                intl.formatDate(publisher.activationDate, {
                  month: "long",
                  year: "numeric",
                }),
              ])}
            </Text>
          </Box>

          <Box direction="row" paddingTop="medium-small">
            <Badges
              publisher={{
                isCjp: publisher.cjp,
                isSubaffiliate: publisher.subAffiliate,
                networkEarnings: publisher.rating,
                isContent: publisher.contentCertified,
                activationDate: publisher.activationDate,
              }}
            />

            {hasUserCanAuthenticateInCiqFeature && (
              <AuthenticationStatusByPublisher
                publisher={publisherWithAuthStatus}
              />
            )}
          </Box>
        </Box>
        <Box direction="row" gap="medium">
          {shouldRenderFavorite && (
            <Favorite
              data-testid={`favorite-icon-${publisher.publisherId}`}
              isFavorite={isFavorite}
              toggleWarningAlert={toggleWarningAlert}
              onClick={async (isFavorite) => {
                if (!isFavorite) {
                  setIsFavorite(true);
                  return postFavorite([publisher.publisherId]);
                } else {
                  setIsFavorite(false);
                  return postUnfavorite([publisher.publisherId]);
                }
              }}
            />
          )}
          {isAdvertiserOrEmployee && (
            <Tooltip
              className="report-publisher-button-tooltip"
              content={intl.formatMessage(messages.reportPublisher)}
            >
              <IconButton
                type="tertiary"
                aria-label="report publisher"
                onClick={openReportPublisherModal}
                icon="flag"
                round
              />
            </Tooltip>
          )}
          {canMakeOffer &&
            !R.includes(publisher.publisherId, excludePublisherIds) && (
              <Button
                disabled={offerMade}
                type="primary"
                data-testid="make-offer"
                onClick={openOfferModal}
              >
                {offerMade
                  ? intl.formatMessage(messages.pendingOffer)
                  : intl.formatMessage(messages.makeOffer)}
              </Button>
            )}
        </Box>
      </Box>
    </>
  );
};
