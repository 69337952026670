import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import {
  BlankSlate,
  PrimaryActionButton,
} from "@cjdev-internal/visual-stack-x/components/BlankSlate";
import { ChoiceInput } from "@cjdev-internal/visual-stack-x/legacy/Form";
import { Pagination } from "@cjdev-internal/visual-stack-x/components/Pagination";
import {
  Table,
  TableContainer,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "@cjdev-internal/visual-stack-x/components/Table";
import { TriStateCheckbox } from "@cjdev-internal/visual-stack-x/TriStateCheckbox";
import classNames from "classnames";
import { Earnings } from "components/Earnings";
import { EpcCell } from "components/EpcCell";
import { Favorite } from "components/Favorite";
import { Spinner } from "components/Spinner";
import { Tooltip } from "components/Tooltip";
import {
  MAX_NUMBER_OF_SELECTED_PUBLISHERS,
  PUBLISHERS_ALL_SELECTED,
  PUBLISHERS_EXCEEDED_MAX_SELECTED,
  PUBLISHERS_PARTIALLY_SELECTED,
} from "constants/selectedPublisherStatus";
import {
  OBSCURE_TOTAL_PUBLISHER_COUNT,
  useAlerts,
  useExcludedPublisherIds,
  useFeatures,
  useIntl,
  useLocation,
  usePagination,
  useSelectedPublishers,
  useUser,
} from "hooks";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { userIsAdvertiser } from "utils/authorizationUtils";
import { isNullOrEmpty } from "utils/utils";
import { Drawer } from "../Drawer";
import messages from "../messages";
import { MatchingProperties } from "./MatchingProperties";
import { MatchingPropertiesExpandCollapseAllButton } from "./MatchingPropertiesExpandCollapseAllButton";
import { PartnerDetailsCell } from "./PartnerDetailsCell";
import { SortBySelector } from "./SortBySelector";
import "./styles.css";
import { TopCategoryCell } from "./TopCategoryCell";
import { TopCountryCell } from "./TopCountryCell";

const getTopCheckboxValue = (selectedPublisherStatus) => {
  if (selectedPublisherStatus === PUBLISHERS_PARTIALLY_SELECTED) return -1;
  if (selectedPublisherStatus === PUBLISHERS_ALL_SELECTED) return 1;
  return 0;
};

const SelectAllPublishersCheckbox = ({
  publishers,
  hiddenPublishersIdsMap,
}) => {
  const {
    togglePublishersOn,
    togglePublishersOff,
    getSelectedPublisherStatus,
  } = useSelectedPublishers();
  const publisherIds = R.pipe(
    R.pluck("id"),
    R.filter((id) => !hiddenPublishersIdsMap[id])
  )(publishers);

  const checkboxValue = getTopCheckboxValue(
    getSelectedPublisherStatus(publisherIds)
  );

  const handleCheckboxOnChange = () => {
    if (checkboxValue === 1) {
      togglePublishersOff(publishers);
    } else {
      togglePublishersOn(publishers);
    }
  };

  return (
    <TriStateCheckbox
      aria-label="select all publishers"
      data-testid="select-all-publishers"
      value={getTopCheckboxValue(getSelectedPublisherStatus(publisherIds))}
      checked={getTopCheckboxValue(getSelectedPublisherStatus(publisherIds))}
      onChange={handleCheckboxOnChange}
      alwaysUpdateInnerCheckboxState
    />
  );
};

const PublisherCheckbox = ({ publisher }) => {
  const { togglePublishers, isPublisherSelected } = useSelectedPublishers();

  return (
    <ChoiceInput
      aria-label={`select publisher: ${publisher.id}`}
      name="isPublisherChecked"
      type="checkbox"
      value={publisher.id}
      checked={isPublisherSelected(publisher.id)}
      onChange={() => togglePublishers([publisher])}
    />
  );
};

const toFavoritesMap = R.pipe(
  R.defaultTo([]),
  R.map((publisher) => [publisher, true]),
  R.fromPairs
);
const toUnfavoritesMap = R.pipe(
  R.defaultTo([]),
  R.map((publisher) => [publisher, false]),
  R.fromPairs
);

const formatResultCount = (resultCount) => {
  const countWithCommas = resultCount
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return resultCount < 1000 ? resultCount : countWithCommas;
};

export const PartnersTableView = ({
  checkFavoritePublishers,
  checkFavoritePublisher,
  emptySlate,
  favoritePublishers,
  hiddenPublishersIdsMap = {},
  isLoading,
  isPublisherHidden,
  noResultSlate,
  postFavorite,
  postUnfavorite,
  partnersReactQueryResponse: { data, loading, error },
  shouldRenderEmptySlate,
}) => {
  const intl = useIntl();
  const alerts = useAlerts();
  const [favorites, setFavorites] = useState(
    toFavoritesMap(favoritePublishers)
  );
  const { limit, offset, setLimit, page, updatePage } = usePagination();
  useEffect(() => {
    setFavorites(toFavoritesMap(favoritePublishers));
  }, [favoritePublishers]);

  const publisherId = R.path(["location", "state", "publisherId"])(
    useLocation()
  );

  useEffect(() => {
    const publisherRow = document.querySelector(`span[id='${publisherId}']`);
    if (publisherRow) {
      publisherRow.scrollIntoView({ block: "center" });
    }
  }, [loading, publisherId]);

  const { getSelectedPublishersErrors } = useSelectedPublishers();
  useEffect(() => {
    const errors = getSelectedPublishersErrors();
    R.forEach((error) => {
      if (error === PUBLISHERS_EXCEEDED_MAX_SELECTED) {
        alerts.toggleWarningAlert(
          intl.formatMessage(messages.partnersExceededMaxSelected, [
            MAX_NUMBER_OF_SELECTED_PUBLISHERS,
          ])
        );
      }
    })(errors);
    // TODO: alerts should be included but useToast is unstable and causes an infinite render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSelectedPublishersErrors, intl]);

  const { user } = useUser();
  const { hasFeature } = useFeatures();

  const { error: excludedPublisherIdsError } = useExcludedPublisherIds();

  if (isLoading) {
    return (
      <Box align="center">
        <Spinner />
      </Box>
    );
  }

  if (error || excludedPublisherIdsError) {
    return (
      <BlankSlate title={intl.formatMessage(messages.error)}>
        <PrimaryActionButton
          label={"refresh"}
          handler={() => window.location.reload()}
        ></PrimaryActionButton>
      </BlankSlate>
    );
  }

  const allowToSeeFavorites = userIsAdvertiser(user);

  const obscureTotalPublisherCount = hasFeature(OBSCURE_TOTAL_PUBLISHER_COUNT);

  const drawer = (
    <Drawer
      favorite={({ selectedPublishers }) => {
        const selectedPublisherIds = R.pluck("id")(selectedPublishers);
        return (
          <>
            {allowToSeeFavorites && (
              <Favorite
                isFavorite={checkFavoritePublishers(
                  favorites,
                  selectedPublisherIds
                )}
                data-testid="favorite-button"
                onClick={async (isFavorite) => {
                  if (!isFavorite) {
                    setFavorites((favorites) =>
                      R.mergeRight(
                        favorites,
                        toFavoritesMap(selectedPublisherIds)
                      )
                    );
                    return postFavorite(selectedPublishers);
                  } else {
                    setFavorites((favorites) =>
                      R.mergeRight(
                        favorites,
                        toUnfavoritesMap(selectedPublisherIds)
                      )
                    );
                    return postUnfavorite(selectedPublishers);
                  }
                }}
              />
            )}
          </>
        );
      }}
    />
  );

  if (shouldRenderEmptySlate) {
    return (
      <>
        {emptySlate}
        {drawer}
      </>
    );
  }

  const indexedPublisherList = data.indexedPublishers.resultList;
  const publisherResultCount = data.indexedPublishers.totalCount;
  const publisherApproximateResultCount =
    data.indexedPublishers.approximateCount;
  const nonHiddenPublishersCount = indexedPublisherList.reduce(
    (totalCount, publisher) => {
      const count = Boolean(publisher.hidden) ? 0 : 1;
      return totalCount + count;
    },
    0
  );

  const containsNoFavorites = R.all(
    (publisher) => hiddenPublishersIdsMap[publisher.id]
  )(indexedPublisherList);

  if (isNullOrEmpty(indexedPublisherList) || containsNoFavorites) {
    return (
      <>
        {noResultSlate}
        {drawer}
      </>
    );
  }

  const publisherIds = R.pluck("id")(indexedPublisherList);

  const pageResultStart = offset + 1;
  const pageResultEnd = offset + nonHiddenPublishersCount;

  let pageResultCountMessage;

  if (!obscureTotalPublisherCount) {
    pageResultCountMessage = intl.formatMessage(
      messages.totalPublisherResults,
      [pageResultStart, pageResultEnd, publisherResultCount]
    );
  } else if (publisherApproximateResultCount < 1000) {
    pageResultCountMessage = intl.formatMessage(
      messages.showingTotalPublisherResults,
      [
        pageResultStart,
        pageResultEnd,
        formatResultCount(publisherApproximateResultCount),
      ]
    );
  } else {
    pageResultCountMessage = intl.formatMessage(
      messages.showingTotalApproximatePublisherResults,
      [
        pageResultStart,
        pageResultEnd,
        formatResultCount(publisherApproximateResultCount),
      ]
    );
  }
  return (
    <>
      {alerts.toastMount}
      <TableContainer className="vsx-table-container-condensed shadow">
        <Box direction="row" padding="large" justify="space-between">
          <Box direction="column" gap="medium-small">
            <Text weight="medium" size={16} className="table-title">
              {intl.formatMessage(messages.tableTitle)}
            </Text>
            <Text color="secondary">
              {pageResultCountMessage}
              {publisherResultCount > 100 &&
                ` ${intl.formatMessage(messages.refineYourSearchNote)}`}
            </Text>
          </Box>
          <Box
            className="publisher-search-action-toolbar"
            direction="row"
            gap="medium"
          >
            <Tooltip
              data-testid="expand-collapse-tooltip"
              content={intl.formatMessage(messages.expandCollapseToolTip)}
            >
              <MatchingPropertiesExpandCollapseAllButton
                allPublisherIds={publisherIds}
              />
            </Tooltip>
            <SortBySelector />
          </Box>
        </Box>
        <Table>
          <THead>
            <Tr>
              <Th>
                <div className="publisher-checkbox-container">
                  <SelectAllPublishersCheckbox
                    publishers={indexedPublisherList}
                    hiddenPublishersIdsMap={hiddenPublishersIdsMap}
                  />
                </div>
              </Th>
              <Th className="partners-search-header">
                {intl.formatMessage(messages.partnerDetails)}
              </Th>
              <Th center>
                <div className="fit-content">
                  <Tooltip
                    data-testid="top-category-tooltip"
                    content={intl.formatMessage(messages.topCategoryToolTip)}
                  >
                    {intl.formatMessage(messages.topCategory)}
                  </Tooltip>
                </div>
              </Th>
              <Th center>
                <div className="fit-content">
                  <Tooltip
                    data-testid="top-country-tooltip"
                    content={intl.formatMessage(messages.topCountryToolTip)}
                  >
                    {intl.formatMessage(messages.topCountry)}
                  </Tooltip>
                </div>
              </Th>
              <Th center>
                <div className="fit-content">
                  <Tooltip
                    data-testid="epc-tooltip"
                    content={intl.formatMessage(messages.epcToolTip)}
                  >
                    {intl.formatMessage(messages.epc)}
                  </Tooltip>
                </div>
              </Th>
              <Th center>
                <Tooltip
                  data-testid="earnings-tooltip"
                  content={intl.formatMessage(messages.earningsToolTip)}
                >
                  {intl.formatMessage(messages.earnings)}
                </Tooltip>
              </Th>
              {allowToSeeFavorites && (
                <Th center>
                  <Tooltip
                    data-testid="favorites-tooltip"
                    content={intl.formatMessage(
                      messages.favoritesHeaderToolTip
                    )}
                  >
                    {intl.formatMessage(messages.favorites)}
                  </Tooltip>
                </Th>
              )}
            </Tr>
          </THead>
          {indexedPublisherList.map((publisher) => {
            const rowClassName = classNames({
              "publisher-result-row": true,
              "publisher-result-row-hidden": isPublisherHidden(publisher.id),
            });

            return (
              <TBody
                className={rowClassName}
                key={publisher.id}
                data-testid={`publisher row: ${publisher.id}`}
              >
                <Tr className="more-details-row">
                  <Td>
                    <div className="publisher-checkbox-container">
                      <PublisherCheckbox publisher={publisher} />
                    </div>
                  </Td>
                  <Td>
                    <Box direction="column">
                      <PartnerDetailsCell publisher={publisher} />
                    </Box>
                  </Td>
                  <Td>
                    <TopCategoryCell publisher={publisher} />
                  </Td>
                  <Td>
                    <TopCountryCell publisher={publisher} />
                  </Td>
                  <Td>
                    <EpcCell
                      publisher={publisher}
                      dailyEpc={intl.formatMessage(messages.sevenDayEpc)}
                      monthlyEpc={intl.formatMessage(messages.threeMonthEpc)}
                    />
                  </Td>
                  <Td center>
                    <Earnings level={publisher.networkEarnings} />
                  </Td>
                  {allowToSeeFavorites && (
                    <Td center>
                      <Favorite
                        data-testid={`favorite-icon-${publisher.id}`}
                        isFavorite={checkFavoritePublisher(
                          favorites,
                          publisher.id
                        )}
                        onClick={async (isFavorite) => {
                          if (!isFavorite) {
                            setFavorites((favorites) =>
                              R.mergeRight(
                                favorites,
                                toFavoritesMap([publisher.id])
                              )
                            );

                            return postFavorite([publisher]);
                          } else {
                            setFavorites((favorites) =>
                              R.mergeRight(
                                favorites,
                                toUnfavoritesMap([publisher.id])
                              )
                            );
                            return postUnfavorite([publisher]);
                          }
                        }}
                      />
                    </Td>
                  )}
                </Tr>
                <Tr className="more-details-row">
                  <Td />
                  <Td colSpan={6}>
                    <MatchingProperties publisher={publisher} />
                  </Td>
                </Tr>
              </TBody>
            );
          })}
        </Table>
        <Box padding="large" direction="column">
          <Pagination
            numberOfRows={publisherApproximateResultCount}
            rowsPerPage={limit}
            page={page}
            onChange={(paginationValue) => {
              updatePage(paginationValue.page);
              setLimit(paginationValue.rowsPerPage);
            }}
            totalRecordsTemplate={pageResultCountMessage}
            rowsPerPageTemplate={intl.formatMessage(
              messages.rowsPerPageTemplate,
              ["{0}"]
            )}
          />
        </Box>
        {drawer}
      </TableContainer>
    </>
  );
};
