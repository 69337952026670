import React, { createContext } from "react";

export const LocationContext = createContext({
  providerInitialized: false,
});

export const LocationProvider = ({ children, location }) => {
  return (
    <LocationContext.Provider value={{ location, providerInitialized: true }}>
      {children}
    </LocationContext.Provider>
  );
};
