import { useHistory, useLocation, useSelectedPublishers } from "hooks";
import { parse } from "query-string";
import * as R from "ramda";
import React from "react";

export const PublisherIdFilterContext = React.createContext({
  publisherIds: [],
  providerInitialized: false,
  setPublisherIds: () => {},
});

const setPublisherIds = ({ search, navigateTo, toggleAllPublisherIdsOff }) => (
  publisherIds
) => {
  const params = new URLSearchParams(search);
  if (R.isEmpty(publisherIds)) {
    params.delete("publisherIds");
  } else {
    params.set("publisherIds", publisherIds);
  }
  navigateTo({ search: params.toString() });
  toggleAllPublisherIdsOff();
};

export const PublisherIdFilterProvider = ({ children, ...rest }) => {
  const { location } = useLocation();
  const { search } = location;
  const { publisherIds: queryParamValue } = parse(search);
  const { navigateTo } = useHistory();

  const { toggleAllPublisherIdsOff } = useSelectedPublishers();

  const paramValueToNumberArray = (queryParamValue) => {
    return queryParamValue
      ? R.map((value) => parseInt(value), queryParamValue.split(","))
      : [];
  };

  return (
    <PublisherIdFilterContext.Provider
      value={{
        publisherIds: R.defaultTo([])(paramValueToNumberArray(queryParamValue)),
        setPublisherIds: setPublisherIds({
          search,
          navigateTo,
          toggleAllPublisherIdsOff,
        }),
        providerInitialized: true,
        ...rest,
      }}
    >
      {children}
    </PublisherIdFilterContext.Provider>
  );
};
