import { defineMessages } from "react-intl";

export default defineMessages({
  timePeriodLast20Posts: {
    id: "partners.EngagementPopover.timePeriodLast20Posts",
    defaultMessage: "Time Period: Last 20 Posts",
  },
  instagramEngagementFormula: {
    id: "partners.EngagementPopover.instagramEngagementFormula",
    defaultMessage:
      "Replies + Taps Back Swipe Ups + Sticker Taps + Votes / Followers",
  },
  facebookEngagementFormula: {
    id: "partners.EngagementPopover.facebookEngagementFormula",
    defaultMessage: "Likes + Comments + Shares + Clicks / Page Likes",
  },
  twitterEngagementFormula: {
    id: "partners.EngagementPopover.twitterEngagementFormula",
    defaultMessage: "Likes + Replies + Retweets / Followers",
  },
  youtubeEngagementFormula: {
    id: "partners.EngagementPopover.youtubeEngagementFormula",
    defaultMessage: "Likes + Comments + Shares / Views",
  },
  pinterestEngagementFormula: {
    id: "partners.EngagementPopover.pinterestEngagementFormula",
    defaultMessage: "Saves + Closeups + Clicks / Followers",
  },
  twitchEngagementFormula: {
    id: "partners.EngagementPopover.twitchEngagementFormula",
    defaultMessage: "Chatters / Followers",
  },
  tiktokEngagementFormula: {
    id: "partners.EngagementPopover.tiktokEngagementFormula",
    defaultMessage: "Likes + Comments + Shares + Saves / Views",
  },
  engagementDescriptionExcellent: {
    id: "partners.EngagementPopover.engagementDescriptionExcellent",
    defaultMessage:
      "This {0} account has a higher engagement rate compared to other {0} accounts with a similar number of {1}",
  },
  engagementDescriptionGood: {
    id: "partners.EngagementPopover.engagementDescriptionGood",
    defaultMessage:
      "This {0} account has an average engagement rate compared to other {0} accounts with a similar number of {1}",
  },
  engagementDescriptionFair: {
    id: "partners.EngagementPopover.engagementDescriptionFair",
    defaultMessage:
      "This {0} account has a lower engagement rate compared to other {0} accounts with a similar number of {1}. This may be a new {0} account for this partner.",
  },
  formula: {
    id: "partners.EngagementPopover.formula",
    defaultMessage: "Formula",
  },
});
