import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { ExternalLink } from "components/ExternalLink";
import { useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import React from "react";
import { isNullOrEmpty, stringify } from "../../utils/utils";
import messages from "./messages";
import { Link } from "@cjdev-internal/visual-stack-x/Link";

const NoTypeDetails = () => {
  const intl = useIntl();
  return (
    <Text color="secondary" size={12}>
      {intl.formatMessage(messages.noPropertyDetails)}
    </Text>
  );
};

const stripTypeInfo = (array) => {
  return array.map((graphqlObject) => {
    const { __typename, ...noTypeInfo } = graphqlObject;
    return noTypeInfo;
  });
};

export const transformForDownload = (promotionalProperty) => {
  switch (promotionalProperty.type) {
    case "WEBSITE":
      return promotionalProperty.propertyTypeDetails.websiteUrl;
    case "SOCIAL_MEDIA":
      return (
        promotionalProperty.propertyTypeDetails.socialMediaUrl ||
        promotionalProperty.propertyTypeDetails.socialMediaHandle
      );
    case "EMAIL":
      return promotionalProperty.propertyTypeDetails.emailAddress;
    case "MOBILE_APP":
      return stringify(
        stripTypeInfo(
          promotionalProperty.propertyTypeDetails.mobileAppDownloadInformation
        )
      );
    case "BROWSER_EXTENSION":
      return stringify(
        stripTypeInfo(
          promotionalProperty.propertyTypeDetails
            .browserExtensionDownloadInformation
        )
      );
    default:
      return null;
  }
};

export const PropertyTypeDetails = ({
  propertyType,
  propertyTypeDetails = {},
  className,
}) => {
  const intl = useIntl();

  switch (propertyType) {
    case "WEBSITE":
      if (!propertyTypeDetails.websiteUrl) {
        return <NoTypeDetails />;
      }

      return (
        <ExternalLink
          className={className}
          path={propertyTypeDetails.websiteUrl}
          text={propertyTypeDetails.websiteUrl}
          newWindow
        />
      );
    case "SOCIAL_MEDIA":
      if (
        !propertyTypeDetails.socialMediaUrl &&
        !propertyTypeDetails.socialMediaHandle
      ) {
        return <NoTypeDetails />;
      }

      if (!propertyTypeDetails.socialMediaUrl) {
        return (
          <Text className={className} size={12}>
            {propertyTypeDetails.socialMediaHandle}
          </Text>
        );
      }
      return (
        <ExternalLink
          className={className}
          path={propertyTypeDetails.socialMediaUrl}
          text={propertyTypeDetails.socialMediaUrl}
          newWindow
        />
      );
    case "EMAIL":
      if (isNullOrEmpty(propertyTypeDetails.emailAddress)) {
        return <NoTypeDetails />;
      }
      return (
        <Link
          className={className}
          href={`mailto:${propertyTypeDetails.emailAddress}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {propertyTypeDetails.emailAddress}
        </Link>
      );
    case "MOBILE_APP":
      if (isNullOrEmpty(propertyTypeDetails.mobileAppDownloadInformation)) {
        return <NoTypeDetails />;
      }
      const sortedMobileAppDownloadInformation = [
        ...propertyTypeDetails.mobileAppDownloadInformation,
      ].sort((a, b) => a.mobileAppPlatform.localeCompare(b.mobileAppPlatform));
      return (
        <>
          {sortedMobileAppDownloadInformation.map(
            (mobileAppDetails, uniqueKey) => (
              <div key={uniqueKey}>
                <ExternalLink
                  className={className}
                  path={mobileAppDetails.mobileAppDownloadLink}
                  text={intl.formatMessage(
                    globalMessages[mobileAppDetails.mobileAppPlatform]
                  )}
                  newWindow
                />
              </div>
            )
          )}
        </>
      );
    case "BROWSER_EXTENSION":
      if (
        isNullOrEmpty(propertyTypeDetails.browserExtensionDownloadInformation)
      ) {
        return <NoTypeDetails />;
      }
      const sortedBrowserExtensionDownloadInformation = [
        ...propertyTypeDetails.browserExtensionDownloadInformation,
      ].sort((a, b) =>
        a.browserExtensionBrowser.localeCompare(b.browserExtensionBrowser)
      );
      return (
        <Box direction="row" gap="small">
          {sortedBrowserExtensionDownloadInformation.map(
            (browserExtensionDetails, uniqueKey) => (
              <div key={uniqueKey}>
                <ExternalLink
                  className={className}
                  path={browserExtensionDetails.browserExtensionDownloadLink}
                  text={intl.formatMessage(
                    globalMessages[
                      browserExtensionDetails.browserExtensionBrowser
                    ]
                  )}
                  newWindow
                />
              </div>
            )
          )}
        </Box>
      );
    default:
      return <NoTypeDetails />;
  }
};
