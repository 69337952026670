import React from "react";

export const MinMaxTextInput = ({
  identifier,
  value,
  onChange,
  onSelection,
}) => {
  return (
    <>
      <input
        type="text"
        data-testid={identifier}
        value={value}
        onChange={onChange}
        onBlur={onSelection}
      />
    </>
  );
};
