import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import {
  Table,
  TableContainer,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "@cjdev-internal/visual-stack-x/components/Table";
import { Spinner } from "components/Spinner";
import { ExternalLink } from "components/ExternalLink";
import { useCompany, useIntl, useOpportunitiesQuery, useUser } from "hooks";
import React from "react";
import { userIsAdvertiser } from "utils/authorizationUtils";
import {
  formatCommissionInfo,
  formatCurrency,
  isNullOrEmpty,
} from "utils/utils";
import messages from "../../../messages";
import "./styles.css";

export const MAX_OPPORTUNITIES_TO_DISPLAY = 10;

export const PlacementsTable = ({ publisher }) => {
  const intl = useIntl();
  const { user } = useUser();
  const company = useCompany();
  const {
    data: opportunities,
    isLoading: isOpportunitiesLoading,
  } = useOpportunitiesQuery(publisher);

  const opportunitiesLink = userIsAdvertiser(user)
    ? `${process.env.REACT_APP_MEMBER_URL}/member/advertiser/placement/opportunity?companySearchParameter=${publisher.publisherId}`
    : `${process.env.REACT_APP_MEMBER_URL}/member/publisher/placement/opportunity`;

  const tableOrSpinner = isOpportunitiesLoading ? (
    <Box align="center">
      <Spinner />
    </Box>
  ) : (
    <PlacementsTableContent opportunities={opportunities} />
  );

  return (
    <Box direction="column" gap="small">
      <Box direction="row" align="center" justify="space-between">
        <Text weight="medium" size={14}>
          {intl.formatMessage(messages.opportunitiesTitle)}
        </Text>
        {!isNullOrEmpty(opportunities) && !isNullOrEmpty(company) && (
          <ExternalLink
            path={opportunitiesLink}
            newWindow
            text={intl.formatMessage(messages.viewPlacementsMarketplace)}
          />
        )}
      </Box>
      {tableOrSpinner}
    </Box>
  );
};

const PlacementsTableContent = ({ opportunities }) => {
  const intl = useIntl();
  if (isNullOrEmpty(opportunities)) {
    return (
      <Text color="secondary" italic>
        {intl.formatMessage(messages.noPlacements)}
      </Text>
    );
  }

  return (
    <TableContainer
      className="vsx-table-container-condensed placements-table"
      data-testid="placement-table"
    >
      <Table>
        <THead>
          <Tr>
            <Th>{intl.formatMessage(messages.placementName)}</Th>
            <Th>{intl.formatMessage(messages.placementType)}</Th>
            <Th>{intl.formatMessage(messages.placementCommission)}</Th>
            <Th>{intl.formatMessage(messages.placementFee)}</Th>
            <Th>{intl.formatMessage(messages.placementDurationHeader)}</Th>
          </Tr>
        </THead>
        <TBody>
          {isNullOrEmpty(opportunities) ? (
            <Tr>
              <Td colSpan={5}>
                <Text>{intl.formatMessage(messages.noOpportunities)}</Text>
              </Td>
            </Tr>
          ) : (
            opportunities.map((opportunity) => (
              <Tr key={opportunity.id} data-testid={opportunity.id}>
                <Td>
                  <Text>{opportunity.title}</Text>
                </Td>
                <Td nowrap>
                  <Text>
                    {opportunity.type &&
                      intl.formatMessage(
                        messages[`placement.${opportunity.type}`]
                      )}
                  </Text>
                </Td>
                <Td>
                  <Text>
                    {formatCommissionInfo(
                      opportunity.commissionIncreaseType,
                      opportunity.commissionIncrease,
                      opportunity.owner.functionalCurrency
                    )}
                  </Text>
                </Td>
                <Td nowrap>
                  <Text>
                    {opportunity.flatFee &&
                      formatCurrency(
                        opportunity.flatFee,
                        opportunity.owner.functionalCurrency
                      )}
                  </Text>
                </Td>
                <Td nowrap>
                  <Text>
                    {intl.formatMessage(messages.placementDuration, [
                      opportunity.startDate,
                      opportunity.endDate,
                    ])}
                  </Text>
                </Td>
              </Tr>
            ))
          )}
        </TBody>
      </Table>
    </TableContainer>
  );
};
