import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Clickable } from "@cjdev-internal/visual-stack-x/Clickable";
import {
  Tree,
  TreeSelector,
  createFilter,
  createHighlighter,
  useTree,
} from "@cjdev-internal/visual-stack-x/Tree";
import { TriState } from "@cjdev-internal/visual-stack-x/TriStateCheckbox";
import { Input } from "@cjdev-internal/visual-stack-x/components/Input";
import { PercentSlider } from "@cjdev-internal/visual-stack-x/legacy/PercentSlider";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "components/Spinner";
import { useIntl } from "hooks";
import { produce } from "immer";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import messages from "../../messages";
import { useMemo } from "react";

const toTreeProps = (data, intl) => {
  let treeParents = {};
  const treeChildren = {};
  let advertiserCategoryLookupMap = {};

  R.forEachObjIndexed((value, key) => {
    const vertical = key;
    const categories = value;
    treeParents[vertical] = "root";
    treeChildren["root"] = R.append(
      vertical,
      R.defaultTo([])(treeChildren["root"])
    );
    advertiserCategoryLookupMap[vertical] = intl.formatMessage(
      globalMessages[`global.vertical.${vertical}`]
    );

    R.forEachObjIndexed((value, key) => {
      const category = `${vertical}.${key}`;
      const categoryItems = value;
      treeParents[category] = vertical;
      treeChildren[vertical] = R.append(
        category,
        R.defaultTo([])(treeChildren[vertical])
      );
      advertiserCategoryLookupMap[category] = intl.formatMessage(
        globalMessages[`global.category.${key}`]
      );

      R.forEach((categoryItemFromList) => {
        const categoryItem = `${category}.${categoryItemFromList}`;
        treeParents[categoryItem] = category;
        treeChildren[category] = R.append(
          categoryItem,
          R.defaultTo([])(treeChildren[category])
        );
        advertiserCategoryLookupMap[categoryItem] = intl.formatMessage(
          globalMessages[`global.category.${categoryItemFromList}`]
        );
      })(categoryItems);
    })(categories);
  })(data);

  return { treeParents, treeChildren, advertiserCategoryLookupMap };
};

const useAdvertiserCategoriesQuery = () =>
  useQuery(
    ["fetch advertiser categories"],
    () =>
      fetch(
        `${process.env.REACT_APP_MEMBER_URL}/member/api/category-verticals`
      ).then((response) => response.json()),
    {
      refetchOnWindowFocus: false,
    }
  );

export const AdvertiserCategoriesFilter = (props) => {
  const advertiserCategoriesQuery = useAdvertiserCategoriesQuery();

  if (advertiserCategoriesQuery.isLoading) {
    return null;
  }

  return <InnerAdvertiserCategoriesFilter {...props} />;
};

const InnerAdvertiserCategoriesFilter = React.memo(
  ({ filters, setFilters }) => {
    const intl = useIntl();
    const [highlight, setHighlight] = useState("");

    const { data, isLoading } = useAdvertiserCategoriesQuery();

    const {
      treeParents,
      treeChildren,
      advertiserCategoryLookupMap,
    } = useMemo(() => toTreeProps(data, intl), [data, intl]);

    const treeSelector = useMemo(
      () =>
        new TreeSelector({
          rootId: () => "root",
          parent: (id) => treeParents[id],
          children: (id) => treeChildren[id],
          name: (id) => advertiserCategoryLookupMap[id],
        }),
      [treeParents, treeChildren, advertiserCategoryLookupMap]
    );

    const treeProps = useTree(treeSelector);

    const {
      filterOut,
      setFilteredOut,
      selected,
      setSelected,
      setExpanded,
    } = treeProps;

    useEffect(() => {
      setFilters((previousFilters) =>
        produce(previousFilters, (draftFilters) => {
          draftFilters.publisherLevel.advertiserCategories.selected = selected;
        })
      );
    }, [selected, setFilters]);

    const areFiltersEmpty = R.isEmpty(
      filters.publisherLevel.advertiserCategories.selected
    );

    useEffect(() => {
      if (areFiltersEmpty) {
        setSelected(treeSelector.emptySelection());
      }
    }, [areFiltersEmpty, setSelected, treeSelector]);

    const filter = createFilter(treeSelector, 3);
    const labelContent = createHighlighter(
      treeSelector.name,
      "tree-highlight",
      3
    );

    const isFiltering = !R.isEmpty(R.filter(R.identity, filterOut));

    return (
      <Box direction="column" gap="medium-large">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Input
              type="search"
              placeholder={`${intl.formatMessage(globalMessages.SEARCH)}`}
              value={highlight}
              className="search-input"
              onChange={(event) => {
                const [filteredOut, expanded] = filter(event.target.value);
                setFilteredOut(filteredOut);
                setExpanded(expanded);
                setHighlight(event.target.value);
              }}
            />
            <Box direction="row" gap="large" justify="space-between">
              {isFiltering && (
                <Clickable
                  onClick={(_) => {
                    setSelected(
                      treeSelector.selectAllLeaves(
                        (nodeId) => !filterOut[nodeId],
                        TriState.Full,
                        selected
                      )
                    );
                  }}
                >
                  {intl.formatMessage(messages.selectAllVisible)}
                </Clickable>
              )}
              {isFiltering && (
                <Clickable
                  onClick={(_) => {
                    setSelected(
                      treeSelector.selectAllLeaves(
                        (nodeId) => !filterOut[nodeId],
                        TriState.Empty,
                        selected
                      )
                    );
                  }}
                >
                  {intl.formatMessage(messages.unselectVisible)}
                </Clickable>
              )}
              {!isFiltering && (
                <>
                  <Box />
                  <Clickable
                    onClick={() => {
                      setSelected(treeSelector.emptySelection());
                    }}
                    data-testid="advertiserCategoriesUnselectAll"
                  >
                    {intl.formatMessage(messages.unselectAll)}
                  </Clickable>
                </>
              )}
            </Box>
            <Box direction="column" gap="xl">
              <Box
                data-testid="advertiser-categories-selections"
                className="tree-container"
              >
                <Tree
                  labelContent={labelContent}
                  highlight={highlight}
                  {...treeProps}
                />
              </Box>

              <PercentSlider
                label={intl.formatMessage(messages.percentageOfTotalCommission)}
                id="slider-advertiser-categories"
                value={filters.publisherLevel.advertiserCategories.percentage}
                onChange={(percentage) =>
                  setFilters((previousFilters) => {
                    return produce(previousFilters, (draftFilters) => {
                      draftFilters.publisherLevel.advertiserCategories.percentage = percentage;
                    });
                  })
                }
              />
            </Box>
          </>
        )}
      </Box>
    );
  },
  (previousProps, props) => {
    return R.equals(
      previousProps.filters.publisherLevel.advertiserCategories,
      props.filters.publisherLevel.advertiserCategories
    );
  }
);
