import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "partners.NetworkPerformance.title",
    defaultMessage: "Network Performance",
  },
  topAdvertiserCategories: {
    id: "partners.NetworkPerformance.topAdvertiserCategories",
    defaultMessage: "Top Advertiser Categories",
  },
});
