import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import {
  GenerateSimilarwebData,
  getDataById,
} from "components/GenerateSimilarwebData";
import { Tooltip } from "components/Tooltip";
import { useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import React from "react";
import { NumericMetric } from "../SocialStats/metrics";
import { CountryMapChart } from "./CountryMapChart";
import { DeviceDistributionChart } from "./DeviceDistributionChart";
import { IndustryRank } from "./IndustryRank";
import messages from "./messages";
import "./styles.css";
import { ExternalLink } from "components/ExternalLink";

export const WebsiteTrafficSection = ({ property }) => {
  const intl = useIntl();
  if (property.type !== "WEBSITE") {
    return null;
  }

  return (
    <GenerateSimilarwebData
      properties={[property]}
      similarwebDependantComponent={({ similarwebData }) => {
        const similarwebMetrics = getDataById(similarwebData, property.id);
        const desktopVisitors = similarwebMetrics?.monthlyDesktopVisitors;
        const mobileVisitors = similarwebMetrics?.monthlyMobileVisitors;
        const totalMonthlyVisitors = similarwebMetrics?.totalMonthlyVisitors;
        const topCountryShares = similarwebMetrics?.topCountryShares;

        return (
          <Box direction="column" gap="medium" data-testid="web-traffic">
            <Box
              direction="row"
              justify="space-between"
              className="website-performance-title"
              paddingBottom="medium"
            >
              <Text weight="medium" size={14}>
                {intl.formatMessage(messages.websiteTrafficAndEngagement)}
              </Text>
              <Box direction="row" gap="small" align="center">
                <Text color="secondary" size={12}>
                  {intl.formatMessage(messages.dataProvidedBy)}
                  <ExternalLink
                    newWindow
                    text={intl.formatMessage(messages.similarWeb)}
                    path="https://www.similarweb.com/"
                  />
                </Text>
              </Box>
            </Box>
            <Text weight="medium" size={14}>{`${intl.formatMessage(
              messages.timePeriod
            )}: ${intl.formatMessage(messages.lastMonth)}`}</Text>
            <Box
              direction="row"
              gap="large"
              paddingBottom="large"
              justify="space-between"
            >
              <Box direction="column" align="center" justify="end">
                <NumericMetric value={totalMonthlyVisitors} />
                <Box direction="column">
                  <Text color="secondary" size={12}>
                    {intl.formatMessage(
                      globalMessages.monthlyTotalVisitorsTitle
                    )}
                  </Text>
                </Box>
              </Box>
              <Box direction="column" align="center" justify="end">
                <NumericMetric value={desktopVisitors} />
                <Box direction="column">
                  <Text color="secondary" size={12}>
                    {intl.formatMessage(globalMessages.desktopUniqueVisitors)}
                  </Text>
                </Box>
              </Box>
              <Box direction="column" align="center" justify="end">
                <NumericMetric value={mobileVisitors} />
                <Text color="secondary" size={12}>
                  {intl.formatMessage(globalMessages.mobileWebUniqueVisitors)}
                </Text>
              </Box>
            </Box>
            <Box direction="row" gap="4xl" className="wrap">
              <Box gap="medium" expand>
                <Text weight="medium" size={14}>
                  {intl.formatMessage(messages.deviceDistribution)}
                </Text>
                <DeviceDistributionChart
                  chartId="first"
                  desktopVisitors={desktopVisitors}
                  mobileVisitors={mobileVisitors}
                />
              </Box>
              <Box expand>
                <Tooltip
                  content={intl.formatMessage(messages.topCountriesTooltip)}
                  data-testid="topCountries-tooltip"
                >
                  <Box gap="medium">
                    <Text weight="medium" size={14}>
                      {intl.formatMessage(messages.topCountries)}
                    </Text>
                    <CountryMapChart
                      mapId="fourth"
                      topCountryShares={topCountryShares}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
            <Box direction="row" gap="4xl" className="wrap">
              <Box expand>
                <Text weight="medium" size={14}>
                  {intl.formatMessage(messages.industryRank)}
                </Text>
                <IndustryRank categoryRank={similarwebMetrics?.categoryRank} />
              </Box>
            </Box>
          </Box>
        );
      }}
    />
  );
};
