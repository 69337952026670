import { gql, useLazyQuery } from "@apollo/client";
import { IconButton } from "@cjdev-internal/visual-stack-x/Button";
import { transformForDownload } from "components/PropertyTypeDetails";
import { publisherProfilePath } from "constants/paths";
import * as FileSaver from "file-saver";
import { useClients, useCountriesByCodeQuery, useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React from "react";
import { getFormattedReachMetricName, getReachMetric } from "utils/ciqUtils";
import {
  capitalizeName,
  formatCategory,
  isNewToNetwork,
  isNullOrEmpty,
} from "utils/utils";
import * as XLSX from "xlsx";
import messages from "./messages";
import "./styles.css";

const FETCH_DISTINCT_PROPERTY_VALUES = gql`
  query distinctPropertyValuesFor($publisherIds: [ID!]!) {
    distinctPropertyValuesFor(publisherIds: $publisherIds) {
      publisherId
      promotionalModels
      propertyTypes
    }
  }
`;

const formatMessage = R.prop("defaultMessage");

const getJoinedStringCSVFormat = (stringArray) => {
  const stringArrayOrEmpty = stringArray || [];
  return R.join(",")(stringArrayOrEmpty);
};

const getBooleanCSVFormat = (boolean) => {
  if (boolean) return formatMessage(globalMessages.yes);
  return formatMessage(globalMessages.no);
};

const getEPCCsvFormat = (epc) => {
  if (epc >= 9999999) {
    return formatMessage(globalMessages.new);
  } else if (epc <= -9999999) {
    return formatMessage(globalMessages["global.na"]);
  } else {
    return epc;
  }
};

const transformNetworkEarnings = (networkEarnings) => {
  if (networkEarnings === 6) {
    return formatMessage(globalMessages.new);
  } else {
    return networkEarnings;
  }
};

const topCategory = (performanceArray, intl) => {
  if (isNullOrEmpty(performanceArray)) {
    return formatMessage(globalMessages["global.na"]);
  } else {
    return formatCategory(performanceArray[0].category, intl);
  }
};
// CHECK
const topCountry = (performanceArray, countriesByCode) => {
  if (isNullOrEmpty(performanceArray)) {
    return formatMessage(globalMessages["global.na"]);
  } else {
    const countryName = countriesByCode[performanceArray[0].country].name;
    return countryName
      ? capitalizeName(countryName)
      : formatMessage(globalMessages["global.na"]);
  }
};

const getFirstPropertyName = (promotionalProperties) => {
  if (isNullOrEmpty(promotionalProperties)) {
    return null;
  } else {
    return promotionalProperties[0].name;
  }
};

const getFirstPropertyDetails = (promotionalProperties) => {
  if (isNullOrEmpty(promotionalProperties)) {
    return null;
  } else {
    return transformForDownload(promotionalProperties[0]);
  }
};

const getFirstPropertyReachNumber = (promotionalProperties) =>
  getReachMetric(promotionalProperties[0]);
const getFirstPropertyReachMetricName = (promotionalProperties, intl) =>
  getFormattedReachMetricName(promotionalProperties[0], intl);

// prettier-ignore
const getPublisherCSVFormat = (publisher, distinctPropertyValues, countriesByCode, intl) => {
  let publisherInCSVFormat = {};
  publisherInCSVFormat[formatMessage(messages.publisherIdColumnHeader)] = publisher.id;
  publisherInCSVFormat[formatMessage(messages.publisherNameColumnHeader)] = publisher.name;
  publisherInCSVFormat[formatMessage(messages.countryCodeColumnHeader)] = publisher.countryCode;
  publisherInCSVFormat[formatMessage(messages.countrySubdivisionColumnHeader)] = publisher.countrySubdivision;
  publisherInCSVFormat[formatMessage(messages.distinctPromotionalModelsColumnHeader)] =
    getJoinedStringCSVFormat(distinctPropertyValues.promotionalModels);
  publisherInCSVFormat[formatMessage(messages.distinctPropertyTypesColumnHeader)] =
    getJoinedStringCSVFormat(distinctPropertyValues.propertyTypes);
  publisherInCSVFormat[formatMessage(messages.cjPerformerColumnHeader)] =
    getBooleanCSVFormat(publisher.isCjp);
  publisherInCSVFormat[formatMessage(messages.contentCertifiedColumnHeader)] =
    getBooleanCSVFormat(publisher.isContent);
  publisherInCSVFormat[formatMessage(messages.subAffiliateColumnHeader)] =
    getBooleanCSVFormat(publisher.isSubaffiliate);
  publisherInCSVFormat[formatMessage(messages.newToNetworkColumnHeader)] =
    getBooleanCSVFormat(isNewToNetwork(publisher));
  publisherInCSVFormat[formatMessage(messages.networkEarningsColumnHeader)] =
    transformNetworkEarnings(publisher.networkEarnings);
  publisherInCSVFormat[formatMessage(messages.topCategoryColumnHeader)] =
    topCategory(publisher.lastMonthCategoryCommissions, intl);
  publisherInCSVFormat[formatMessage(messages.topCountryColumnHeader)] =
    topCountry(publisher.lastMonthCountryCommissions, countriesByCode);
  publisherInCSVFormat[formatMessage(messages.sevenDayEpcColumnHeader)] =
    getEPCCsvFormat(publisher.sevenDayEpc);
  publisherInCSVFormat[formatMessage(messages.threeMonthEpcColumnHeader)] =
    getEPCCsvFormat(publisher.threeMonthEpc);
  publisherInCSVFormat[formatMessage(messages.propertyNameColumnHeader)] =
    getFirstPropertyName(publisher.promotionalProperties);
  publisherInCSVFormat[formatMessage(messages.propertyDetailsColumnHeader)] =
    getFirstPropertyDetails(publisher.promotionalProperties);
  publisherInCSVFormat[formatMessage(messages.reachNumberHeader)] =
    getFirstPropertyReachNumber(publisher.promotionalProperties);
  publisherInCSVFormat[formatMessage(messages.reachMetricNameHeader)] =
    getFirstPropertyReachMetricName(publisher.promotionalProperties, intl);
  publisherInCSVFormat[formatMessage(messages.publisherProfileColumnHeader)] =
    `${window.location.protocol}//${window.location.host}${publisherProfilePath(publisher.id)}`;
  publisherInCSVFormat[formatMessage(messages.partnerContactNameColumnHeader)] =
    publisher.partnerContactName;
  publisherInCSVFormat[formatMessage(messages.partnerContactEmailColumnHeader)] =
    publisher.partnerContactEmail;
  return publisherInCSVFormat;
};

const getCSVFormat = (
  publishers,
  allDistinctPropertyValues,
  countriesByCode,
  intl
) => {
  return publishers.map((publisher) => {
    const distinctPropertyValues = R.find(
      R.propEq("publisherId", publisher.id.toString())
    )(R.prop("distinctPropertyValuesFor", allDistinctPropertyValues));
    return getPublisherCSVFormat(
      publisher,
      distinctPropertyValues,
      countriesByCode,
      intl
    );
  });
};

export const ExportCSVButton = ({
  publishers,
  fileName,
  disabled = false,
  sheetNames = ["data"],
  toggleWarningAlert,
}) => {
  const intl = useIntl();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const { accountsClient } = useClients();
  const [
    fetchDistinctPropertyValues,
    { data: allDistinctPropertyValues, loading: distinctPropertyValuesLoading },
  ] = useLazyQuery(FETCH_DISTINCT_PROPERTY_VALUES, {
    client: accountsClient,
    fetchPolicy: "no-cache", // https://github.com/apollographql/react-apollo/issues/3923
    onCompleted: () => exportToCSV(publishers, fileName, intl),
    onError: () =>
      toggleWarningAlert(
        intl.formatMessage(messages.distinctPropertyValuesApiError)
      ),
  });
  const countriesByCodeQuery = useCountriesByCodeQuery();

  const issueFetch = async (publishers) => {
    const publisherIds = R.pluck("id")(publishers);
    await fetchDistinctPropertyValues({ variables: { publisherIds } });
  };

  const exportToCSV = (publishers, fileName, intl) => {
    const csvData = getCSVFormat(
      publishers,
      allDistinctPropertyValues,
      countriesByCodeQuery.data,
      intl
    );
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: sheetNames };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <IconButton
      aria-label="export csv"
      disabled={disabled}
      className="export-button"
      type="tertiary"
      icon="download"
      color="#FFFFFF"
      round
      spinner={distinctPropertyValuesLoading || countriesByCodeQuery.isLoading}
      onClick={() => issueFetch(publishers)}
    ></IconButton>
  );
};
