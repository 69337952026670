import { useIntl } from "hooks";
import "./styles.css";

import { TreeSelector } from "@cjdev-internal/visual-stack-x/Tree";
import globalMessages from "messages/globalMessages";
import { useMemo } from "react";
import messages from "../../messages";

export const usePropertyTypesTreeSelector = (isTikTokMetricsFeatureEnabled) => {
  const intl = useIntl();

  return useMemo(() => {
    const treeOptions = {
      root: {
        children: [
          "WEBSITE",
          "SOCIAL_MEDIA",
          "EMAIL",
          "MOBILE_APP",
          "BROWSER_EXTENSION",
          "SERVICES_AND_TOOLS",
          "PAID_SEARCH_ENGINE",
          "PAID_DISPLAY_ADS",
          "OTHER",
        ],
      },
      WEBSITE: {
        parentId: "root",
      },
      SOCIAL_MEDIA: {
        parentId: "root",
        children: [
          "INSTAGRAM",
          ...(isTikTokMetricsFeatureEnabled ? ["TIKTOK"] : []),
          "FACEBOOK",
          "YOUTUBE",
          "TWITTER",
          "PINTEREST",
          "TWITCH",
          "WHATSAPP",
          "SNAPCHAT",
        ],
      },
      EMAIL: {
        parentId: "root",
      },
      MOBILE_APP: {
        parentId: "root",
        children: ["IOS", "ANDROID"],
      },
      BROWSER_EXTENSION: {
        parentId: "root",
        children: ["CHROME", "FIREFOX", "SAFARI", "OPERA"],
      },
      SERVICES_AND_TOOLS: {
        parentId: "root",
      },
      PAID_SEARCH_ENGINE: {
        parentId: "root",
      },
      PAID_DISPLAY_ADS: {
        parentId: "root",
      },
      OTHER: {
        parentId: "root",
      },
      INSTAGRAM: {
        parentId: "SOCIAL_MEDIA",
      },
      TIKTOK: {
        parentId: "SOCIAL_MEDIA",
      },
      FACEBOOK: {
        parentId: "SOCIAL_MEDIA",
      },
      YOUTUBE: {
        parentId: "SOCIAL_MEDIA",
      },
      TWITTER: {
        parentId: "SOCIAL_MEDIA",
      },
      PINTEREST: {
        parentId: "SOCIAL_MEDIA",
      },
      TWITCH: {
        parentId: "SOCIAL_MEDIA",
      },
      WHATSAPP: {
        parentId: "SOCIAL_MEDIA",
      },
      SNAPCHAT: {
        parentId: "SOCIAL_MEDIA",
      },
      IOS: {
        parentId: "MOBILE_APP",
      },
      ANDROID: {
        parentId: "MOBILE_APP",
      },
      CHROME: {
        parentId: "BROWSER_EXTENSION",
      },
      FIREFOX: {
        parentId: "BROWSER_EXTENSION",
      },
      SAFARI: {
        parentId: "BROWSER_EXTENSION",
      },
      OPERA: {
        parentId: "BROWSER_EXTENSION",
      },
    };

    return new TreeSelector({
      rootId: () => "root",
      parent: (id) => treeOptions[id].parentId,
      children: (id) => treeOptions[id].children,
      name: (id) => intl.formatMessage(globalMessages[id]),
      info: (id) =>
        messages[`tooltip.${id}`] &&
        intl.formatMessage(messages[`tooltip.${id}`]),
    });
  }, [intl, isTikTokMetricsFeatureEnabled]);
};
