import { PublisherIdFilterProvider } from "context/PublisherIdFilterContext";
import { PlatformNavigationProvider } from "context/ViewContext/PlatformNavigationContext";
import { RoutePathProvider } from "context/ViewContext/RoutePathContext";
import { SearchTermsViewProvider } from "context/ViewContext/SearchTermsContext";
import { SelectedPublishersProvider } from "context/ViewContext/SelectedPublishersContext";
import { SortByProvider } from "context/ViewContext/SortByContext";
import React from "react";

export const Nav2022ViewProvider = ({ children, user }) => {
  return (
    <Nav2022ViewProviderContent user={user}>
      {children}
    </Nav2022ViewProviderContent>
  );
};

export const Nav2022ViewProviderContent = ({ children, user }) => (
  <PlatformNavigationProvider user={user}>
    <PublisherIdFilterProvider>
      <SelectedPublishersProvider>
        <SearchTermsViewProvider>
          <SortByProvider>
            <RoutePathProvider>{children}</RoutePathProvider>
          </SortByProvider>
        </SearchTermsViewProvider>
      </SelectedPublishersProvider>
    </PublisherIdFilterProvider>
  </PlatformNavigationProvider>
);
