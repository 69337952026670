import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Spinner } from "components/Spinner";
import { useSimilarwebMetrics } from "hooks";
import * as R from "ramda";
import React from "react";
import { websiteIdsFromProperties } from "utils/webUtils";

export const getDataById = (indexedData, idKey, nullState = {}) =>
  (indexedData && indexedData[idKey]) || nullState;

const SimilarwebDataComponentWithSimilarwebData = ({
  websiteIds,
  calledFrom,
  nullStateOnError,
  SimilarwebDependantComponent,
  properties,
}) => {
  const { data: similarwebData, isLoading, error } = useSimilarwebMetrics(
    websiteIds,
    calledFrom
  );

  if (isLoading) {
    return (
      <Box align="center">
        <Spinner />
      </Box>
    );
  }

  if (nullStateOnError && error) {
    return nullStateOnError;
  }

  const indexedSimilarwebData = R.indexBy(R.prop("propertyId"))(
    similarwebData || []
  );

  return (
    <SimilarwebDependantComponent
      properties={properties}
      similarwebData={indexedSimilarwebData}
    />
  );
};

export const GenerateSimilarwebData = ({
  similarwebDependantComponent: SimilarwebDependantComponent,
  properties = [],
  calledFrom,
  nullStateOnError,
}) => {
  const websiteIds = websiteIdsFromProperties(properties);
  return R.isEmpty(websiteIds) ? (
    <SimilarwebDependantComponent
      {...{
        properties,
      }}
    />
  ) : (
    <SimilarwebDataComponentWithSimilarwebData
      {...{
        websiteIds,
        calledFrom,
        nullStateOnError,
        SimilarwebDependantComponent,
        properties,
      }}
    />
  );
};
