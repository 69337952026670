import { Badge } from "@cjdev-internal/visual-stack-x/Badge";
import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { useIntl } from "hooks";
import React from "react";
import { isNewToNetwork } from "utils/utils";
import messages from "./messages";

export const Badges = ({ className, publisher }) => {
  const intl = useIntl();
  return (
    <Box direction="row" gap="medium" className={className}>
      {publisher.isSubaffiliate && (
        <Badge variant="1">{intl.formatMessage(messages.subAffiliate)}</Badge>
      )}
      {publisher.isCjp && (
        <Badge variant="2">{intl.formatMessage(messages.cjPerformer)}</Badge>
      )}
      {publisher.isContent && (
        <Badge variant="3">
          {intl.formatMessage(messages.contentCertified)}
        </Badge>
      )}
      {isNewToNetwork(publisher) && (
        <Badge variant="5">{intl.formatMessage(messages.newToNetwork)}</Badge>
      )}
    </Box>
  );
};
