import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { NoData } from "components/NoData";
import { PieChart } from "components/PieChart";
import { useIntl } from "hooks";
import * as R from "ramda";
import React from "react";
import { formatCategoryPercentages } from "utils/utils";
import messages from "./messages";
import "./styles.css";

export const NetworkPerformance = ({ property }) => {
  const intl = useIntl();
  return (
    <Box gap="large">
      <Box paddingBottom="medium" className="network-performance-title">
        <Text weight="medium" size={16}>
          {intl.formatMessage(messages.title)}
        </Text>
      </Box>
      <Box gap="large">
        <Text weight="medium" size={14}>
          {intl.formatMessage(messages.topAdvertiserCategories)}
        </Text>
        {R.isEmpty(property.lastMonthCategoryCommissions) ? (
          <NoData />
        ) : (
          <PieChart
            chartId={`${property.id}-category`}
            data={formatCategoryPercentages(
              property.lastMonthCategoryCommissions,
              intl
            )}
          />
        )}
      </Box>
    </Box>
  );
};
