import { useToast } from "@cjdev-internal/visual-stack-x/Toast";
import { useMemo } from "react";
import { useCallback } from "react";

export const useAlerts = () => {
  const [toastMount, show] = useToast();

  const toggleWarningAlert = useCallback(
    (message, options = {}) => {
      show({
        message,
        contentStyle: { textAlign: "center" },
        type: "warning",
        duration: 5000,
        ...options,
      });
    },
    [show]
  );

  const toggleSuccessAlert = useCallback(
    (message, options = {}) => {
      show({
        message,
        contentStyle: { textAlign: "center" },
        type: "success",
        duration: 5000,
        ...options,
      });
    },
    [show]
  );

  return useMemo(
    () => ({ toastMount, toggleSuccessAlert, toggleWarningAlert }),
    [toastMount, toggleSuccessAlert, toggleWarningAlert]
  );
};
