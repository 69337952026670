import { defineMessages } from "react-intl";

export default defineMessages({
  noPromotionalModel: {
    id: "partners.PropertyTypeDetails.matchingProperties.noPromotionalModel",
    defaultMessage: "No promotional model provided",
  },
  noPropertyDetails: {
    id: "partners.PropertyTypeDetails.matchingProperties.noPropertyDetails",
    defaultMessage: "No property details provided",
  },
});
