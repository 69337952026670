import React, { createContext } from "react";

export const HistoryContext = createContext({
  providerInitialized: false,
});

export const HistoryProvider = ({ children, historyConfiguration }) => {
  return (
    <HistoryContext.Provider
      value={{ historyConfiguration, providerInitialized: true }}
    >
      {children}
    </HistoryContext.Provider>
  );
};
