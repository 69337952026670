import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Button } from "@cjdev-internal/visual-stack-x/Button";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { IconSuccess } from "@cjdev-internal/visual-stack-x/Icons";
import { useIntl } from "hooks";
import React from "react";
import { isNullOrEmpty } from "utils/utils";
import messages from "../messages";

const managePublisherLink = (numberOfOffers, intl) => {
  const MANAGE_PUBLISHERS_MESSAGE = intl.formatMessage(
    messages.managePublishers
  );

  return intl.formatMessage(
    numberOfOffers > 1
      ? messages.offerMadeToMultiplePublishersDescription
      : messages.offerMadeDescription,
    [
      `<a class="vsx-link" href="${process.env.REACT_APP_MEMBER_URL}/member/accounts/advertiser/listmypublishers.do?contractState=pending_pub&sortKey=pending_date&sortOrder=ASC" target="_blank" rel="noopener noreferrer">
      ${MANAGE_PUBLISHERS_MESSAGE}
    </a>`,
    ]
  );
};

export const OfferMade = ({
  numberOfOffers,
  customMessage,
  onClose,
  onMultipleOfferSuccess,
  selectedProgramTermName,
  selectedPublisherGroupName,
}) => {
  const intl = useIntl();
  return (
    <Box direction="column" gap="large" padding="xl" paddingTop="2xl">
      <Box direction="row" gap="medium" align="center">
        <IconSuccess />
        <Text weight="medium" size={18}>
          {numberOfOffers && numberOfOffers > 1
            ? intl.formatMessage(messages.offersMadeToMultiplePublishers, [
                numberOfOffers,
              ])
            : intl.formatMessage(messages.offerMade)}
        </Text>
      </Box>
      <Text
        data-testid="offer-made-description"
        dangerouslySetInnerHTML={{
          __html: managePublisherLink(numberOfOffers || 0, intl),
        }}
      />
      <Box direction="column" gap="large">
        <Box direction="column">
          <Text weight="bold">{intl.formatMessage(messages.programTerms)}</Text>
          <Text>{selectedProgramTermName}</Text>
        </Box>
        <Box direction="column">
          <Text weight="bold">
            {intl.formatMessage(messages.addPublisherToGroup)}
          </Text>
          <Text>{selectedPublisherGroupName}</Text>
        </Box>
        {!isNullOrEmpty(customMessage) && (
          <Box direction="column">
            <Text weight="bold">
              {intl.formatMessage(messages.customMessage)}
            </Text>
            <Text>{customMessage}</Text>
          </Box>
        )}
      </Box>
      <Box direction="row" justify="end" gap="medium">
        <Button
          type="tertiary"
          data-testid="offer-made-button"
          onClick={() => {
            if (onMultipleOfferSuccess) {
              onMultipleOfferSuccess();
            }

            onClose();
          }}
        >
          {intl.formatMessage(messages.close)}
        </Button>
      </Box>
    </Box>
  );
};
