import * as R from "ramda";
import intl from "utils/intl";
import messages from "../../messages";

export const alertContent = (publishers) => {
  return publishers.length > 1
    ? intl.formatMessage(messages.unfavoritePublishersAlert, [
        publishers.length,
      ])
    : intl.formatMessage(messages.unfavoritePublisherNameAlert, [
        R.head(publishers).name,
      ]);
};
