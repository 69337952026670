import { gql, useQuery } from "@apollo/client";
import { useClients } from "hooks";

export const useIndexedPromotionalPropertyQuery = (promotionalPropertyId) => {
  const { partnersClient } = useClients();

  return useQuery(gql(GET_INDEXED_PROMOTIONAL_PROPERTY_QUERY), {
    client: partnersClient,
    variables: {
      promotionalPropertyId,
    },
  });
};

export const GET_INDEXED_PROMOTIONAL_PROPERTY_QUERY = `
  query indexedPromotionalProperty($promotionalPropertyId: ID!) {
    indexedPromotionalProperty(promotionalPropertyId: $promotionalPropertyId) {
      name
      description
      id
      searchTerms
      status
      isPrimary
      authenticationStatus
      updatedAt
      createdAt
      primaryPromotionalModel
      promotionalModels
      type
      propertyTypeDetails {
        browserExtensionName
        browserExtensionDownloadInformation {
          browserExtensionBrowser
          browserExtensionDownloadLink
        }
        emailAddress
        mobileAppName
        mobileAppDownloadInformation {
          mobileAppPlatform
          mobileAppDownloadLink
        }
        socialMediaHandle
        socialMediaUrl
        socialMediaPlatform
        paidDisplayAdsDescription
        websiteUrl
        ciqMetrics {
          instagram {
            accountTotalImages
            followers
            engagementRate20Posts
            likesPerPost20Posts
            commentsPerPost20Posts
            lastPostDate
            engagementLabel
          }
          facebook {
            pageLikes
            postLikes
            postComments
            engagementRate20Posts
            likesPerPost20Posts
            commentsPerPost20Posts
            totalComments
            numberOfPosts
            lastPostDate
            engagementLabel
          }
          twitter {
            accountLikesPerTweet
            accountTotalTweets
            followers
            engagementRate20Posts
            likesPerPost20Posts
            commentsPerPost20Posts
            sharesPerPost20Posts
            totalLikes
            lastPostDate
            engagementLabel
          }
          youtube {
            accountMonthlyViews
            accountTotalVideos
            accountTotalViews
            accountViewsPerVideo
            subscribers
            engagementRate20Posts
            likesPerPost20Posts
            commentsPerPost20Posts
            viewsPerPost20Posts
            dateLastVideoUploaded
            engagementLabel
          }
          tiktok {
            followers
            lastPostDate
            totalLikes
            numberOfPosts
            engagementRate20Posts
            likesPerPost20Posts
            commentsPerPost20Posts
            viewsPerPost20Posts
            engagementLabel
          }
          twitch {
            accountAvgStreamViews
            accountViews
            followers
            averageVideoViews30Days
            viewsPerPost20Posts
            numberOfPosts
          }
          snapchat {
            followers
          }
          pinterest {
            accountAverageMonthlyViews
            accountPins
            followers
            engagementRate20Posts
            sharesPerPostLifetime
            lastPostDate
          }
        }
        webMetrics {
          metricSource
        }
      }
      tags
      lastMonthCategoryCommissions {
        category
        percentage
      }
    }
  }
`;
