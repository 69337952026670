import { Text } from "@cjdev-internal/visual-stack-x/Text";
import {
  BlankSlate,
  SubTitle,
} from "@cjdev-internal/visual-stack-x/components/BlankSlate";
import { useIntl } from "hooks";
import React from "react";
import messages from "../messages";
import { ReactComponent as FavoritesBlankSlateSvg } from "./heart.svg";

export const FavoritesEmptySlate = () => {
  const intl = useIntl();
  return (
    <BlankSlate
      title={intl.formatMessage(messages.favoriteNoResultSlateTitle)}
      headerGraphic={
        <div className="favoritesBlankSlateWrapper">
          <FavoritesBlankSlateSvg />
        </div>
      }
    >
      <SubTitle>
        <Text className="blank-slate-subtitle">
          {intl.formatMessage(messages.favoriteNoResultSlateMessage)}
        </Text>
      </SubTitle>
    </BlankSlate>
  );
};
