import PendoLibrary from "@cjdev-internal/pendo";
import { useUserQuery } from "hooks";
import { useEffect } from "react";

const env = process.env.NODE_ENV;

export const Pendo = ({
  getUser = useUserQuery,
  install = PendoLibrary.install,
}) => {
  const { data: user } = getUser();

  useEffect(() => {
    const initializePendo = async () => {
      const initializer = install(env);
      const userId = user.userId;
      const email = user.emailAddress;
      const isInternal = user.isEmployee;

      // Initialize
      initializer.initialize(userId, email, isInternal);
    };

    if (user) {
      initializePendo();
    }
  }, [user, install]);

  return null;
};
