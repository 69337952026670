import { Button } from "@cjdev-internal/visual-stack-x/Button";
import cn from "classnames";
import React from "react";
import "./styles.css";

export const ButtonWithDropdown = ({
  expanded,
  doExpand,
  buttonContent,
  children,
  className = "",
  direction = "down",
  renderButton = (props) => <Button {...props} type="primary" />,
  ...restProps
}) => {
  const elements = [
    renderButton({
      expanded,
      className: `vs-dropdown-with-button-button ${className}-button`,
      onClick: doExpand,
      children: buttonContent,
      key: "render-button",
    }),
    <div
      key="drop-up"
      className={cn({
        "vs-dropdown": true,
        "vs-visible": expanded,
        "vs-direction-up": direction === "up",
      })}
    >
      {children}
    </div>,
  ];

  return (
    <div
      {...restProps}
      className={cn({
        "vs-dropdown-expanded": expanded,
        [`vs-dropdown-with-button ${className}`]: true,
      })}
    >
      {direction === "down" ? elements : elements.reverse()}
    </div>
  );
};
