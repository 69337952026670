import { Stack } from "@cjdev-internal/visual-stack-x/components/Stack";
import { Row } from "@cjdev-internal/visual-stack-x/components/Row";
import { Button } from "@cjdev-internal/visual-stack-x/Button";
import {
  useCJForm,
  CJForm,
  CJField,
  TextAreaInput,
  SingleFileInput,
} from "@cjdev-internal/visual-stack-x/CJForm";
import { Modal } from "@cjdev-internal/visual-stack-x/Modal.js";
import { useCompany, useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import React from "react";
import { authenticatedFetch } from "utils/clients";
import { getBrowserLanguage } from "utils/utils";
import messages from "../PublishersProfile/messages";
import "./styles.css";

const defaultPSALink = "https://www.cj.com/legal/psa-us";
const languageToPSALink = {
  fr: "https://www.cj.com/legal/psa-fr",
  de: "https://www.cj.com/legal/psa-de",
  es: "https://www.cj.com/legal/psa-es",
  zh: "https://www.cj.com/legal/psa-cn",
};

const getPSALink = () => {
  const language = getBrowserLanguage();
  return languageToPSALink[language] || defaultPSALink;
};

const fileSizeInMegabytes = (fileSizeInBytes) => fileSizeInBytes / 1024 / 1024;

const getBase64EncodedString = (file) => {
  const fileReader = new FileReader();
  const filePromise = new Promise((resolve) => {
    fileReader.onload = (file) => {
      const binary = file.target.result;
      resolve(btoa(binary));
    };
  });
  fileReader.readAsBinaryString(file);
  return filePromise;
};

const prepareFile = async (file) => ({
  fileName: file.name,
  fileContent: await getBase64EncodedString(file),
});

export const ReportPublisherModal = ({
  onClose,
  publisherId,
  maxFileSizeLimitInMegabytes = 10,
  toggleSuccessAlert,
  toggleWarningAlert,
}) => {
  const intl = useIntl();
  const company = useCompany();
  const config = useCJForm();
  const {
    submitForm,
    formState: { isSubmitting },
  } = config;

  const reportPublisherUrl = `${process.env.REACT_APP_MEMBER_URL}/affapi/advertiser/${company.id}/reportPublisher`;
  const supportCenterUrl = `${process.env.REACT_APP_MEMBER_URL}/affapi/company/${company.id}/supportCenter`;

  const sendToCustomerSupport = (reason, file) =>
    authenticatedFetch(supportCenterUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        reason: "Partners",
        "sub-reason": "Concerns With Partners",
        subject: `Advertiser ${company.id} reports publisher ${publisherId} for non-compliance`,
        description: reason,
        ...file,
      }),
    }).then((res) => {
      if (!res.ok) throw new Error("failed to send support center case");
    });

  const reportPublisher = () =>
    authenticatedFetch(reportPublisherUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        publisherId,
      }),
    }).then((res) => {
      if (!res.ok) throw new Error("failed to assign fraud role to publisher");
    });

  const handlePublisherReport = async ({
    reportPublisherReason,
    reportPublisherFileUpload,
  }) => {
    const preparedFile = reportPublisherFileUpload
      ? await prepareFile(reportPublisherFileUpload)
      : null;
    try {
      await Promise.all([
        sendToCustomerSupport(reportPublisherReason, preparedFile),
        reportPublisher(),
      ]);
      toggleSuccessAlert(
        intl.formatMessage(messages.reportedPublisherConfirmationMessage)
      );
      onClose();
    } catch (e) {
      toggleWarningAlert(
        intl.formatMessage(messages.reportedPublisherFailedMessage)
      );
    }
  };

  const modalProps = {
    onBackgroundClick: onClose,
    onEscapeKeyUp: onClose,
    onCloseIconClick: onClose,
    headerTitle: intl.formatMessage(messages.reportPublisher),
    body: (
      <CJForm {...config} onSubmit={(values) => handlePublisherReport(values)}>
        <Stack className="report-publisher-description" gap="medium">
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(messages.reportPublisherDescrption, [
                `<a class="vsx-link" href="${getPSALink()}" target="_blank" rel="noopener noreferrer">${intl.formatMessage(
                  messages.publisherServiceAgreement
                )}</a>`,
              ]),
            }}
          />
          <div>{intl.formatMessage(messages.fileUploadInstruction)}</div>
          <div>
            <span>{intl.formatMessage(messages.reportableBehaviorTitle)}</span>
            <ul>
              <li>{intl.formatMessage(messages.reportableBehavior1)}</li>
              <li>{intl.formatMessage(messages.reportableBehavior2)}</li>
              <li>{intl.formatMessage(messages.reportableBehavior3)}</li>
              <li>{intl.formatMessage(messages.reportableBehavior4)}</li>
              <li>{intl.formatMessage(messages.reportableBehavior5)}</li>
            </ul>
          </div>
          <div>{intl.formatMessage(messages.reportPublisherDescription2)}</div>
        </Stack>
        <CJField required aria-label="Reason" name="reportPublisherReason">
          <TextAreaInput
            required={intl.formatMessage(
              messages.reportPublisherEmptyReasonMessage
            )}
            name="reportPublisherReason"
            style={{ height: "12vh" }}
            placeholder={intl.formatMessage(messages.reportPublisherReason)}
            data-testid="report-publisher-textarea"
          />
        </CJField>
        <CJField
          name="reportPublisherFileUpload"
          aria-label="Upload File"
          help={intl.formatMessage(messages.fileSizeWarning)}
        >
          <SingleFileInput
            name="reportPublisherFileUpload"
            data-testid="report-publisher-file-upload"
            validate={(file) => {
              if (
                file &&
                fileSizeInMegabytes(file.size) > maxFileSizeLimitInMegabytes
              ) {
                return intl.formatMessage(messages.fileSizeExceededMaximumSize);
              }
            }}
          />
        </CJField>
      </CJForm>
    ),
    footer: (
      <Row justify="end" gap="medium">
        <Button type="tertiary" onClick={() => onClose()}>
          {intl.formatMessage(globalMessages.cancel)}
        </Button>
        <Button
          type="primary"
          disabled={isSubmitting}
          spinner={isSubmitting}
          onClick={() => submitForm()}
          data-testid="report-publisher-send-button"
        >
          {intl.formatMessage(globalMessages.send)}
        </Button>
      </Row>
    ),
  };

  return <Modal {...modalProps} />;
};
