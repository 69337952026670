import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "partners.TopSocialProperties.title",
    defaultMessage: "Top Social Properties",
  },
  viewDetails: {
    id: "partners.TopSocialProperties.viewDetails",
    defaultMessage: "View details",
  },
  name: {
    id: "partners.TopSocialProperties.name",
    defaultMessage: "Name",
  },
  reach: {
    id: "partners.TopSocialProperties.reach",
    defaultMessage: "Reach",
  },
  engagementRate: {
    id: "partners.TopSocialProperties.engagementRate",
    defaultMessage: "Engagement Rate",
  },
  engagementMetric: {
    id: "partners.TopSocialProperties.engagementMetric",
    defaultMessage: "Engagement Metric",
  },
});
