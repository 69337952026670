import { Tab, TabLayout } from "@cjdev-internal/visual-stack-x/TabLayout";
import { useIntl, useReactRouterUtil, useSearchTerms } from "hooks";
import React from "react";
import messages from "../messages";
import {
  publisherProfilePath,
  publisherProfilePropertiesListPath,
} from "constants/paths";
import { OverviewTab } from "./OverviewTab";
import { PropertiesTab } from "./PropertiesTab";
import "./styles.css";
import { OVERVIEW_TAB_INDEX, PROPERTIES_TAB_INDEX } from "./tabIndex";

export const PublisherTabs = ({
  distinctPropertyValues,
  publisher,
  tabIndex,
}) => {
  const intl = useIntl();
  const { searchTerms } = useSearchTerms();
  const { pushWithQuery } = useReactRouterUtil();

  const profileTabRouter = {
    [OVERVIEW_TAB_INDEX]: publisherProfilePath,
    [PROPERTIES_TAB_INDEX]: publisherProfilePropertiesListPath,
  };

  const pushToSelectedPathWithPublisherId = (path) =>
    pushWithQuery(path(publisher.publisherId));

  return (
    <TabLayout
      lazy
      selectTab={(_, index) =>
        pushToSelectedPathWithPublisherId(profileTabRouter[index])
      }
      selectedId={tabIndex}
    >
      <Tab
        id={OVERVIEW_TAB_INDEX}
        label={intl.formatMessage(messages.overview)}
        data-testid="overview-tab"
      >
        <OverviewTab
          publisher={publisher}
          distinctPropertyValues={distinctPropertyValues}
        />
      </Tab>
      <Tab
        id={PROPERTIES_TAB_INDEX}
        label={intl.formatMessage(messages.properties)}
        data-testid="properties-tab"
      >
        <PropertiesTab searchTerms={searchTerms} publisher={publisher} />
      </Tab>
    </TabLayout>
  );
};
