import { Button } from "@cjdev-internal/visual-stack-x/Button.js";
import { validate as validateEmail } from "email-validator";
import { useIntl } from "hooks";
import * as R from "ramda";
import React from "react";
import messages from "../messages";

export const isValidEmailAddressInput = (input) => {
  const addresses = input.split(",");
  const trim = (input) => {
    return input.trim();
  };
  return R.all(R.pipe(trim, validateEmail))(addresses);
};

export const EmailButton = ({
  addresses,
  onClick,
  setAddressInputError,
  disabled,
}) => {
  const intl = useIntl();
  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          if (isValidEmailAddressInput(addresses)) {
            onClick();
          } else {
            setAddressInputError(true);
          }
        }}
        disabled={disabled}
      >
        {intl.formatMessage(messages.send)}
      </Button>
    </>
  );
};
