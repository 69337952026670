import { TopNavLayout } from "@cjdev-internal/visual-stack-x/TopNavLayout";
import { useIntl } from "hooks";
import React from "react";
import messages from "./messages";
import "./styles.css";

export const Layout = ({ onCancel, contentSize, ...restProps }) => {
  const intl = useIntl();
  return (
    <TopNavLayout
      {...restProps}
      title={intl.formatMessage(messages.appTitle)}
      contentProps={{ gap: "large" }}
      actions={{
        back: {
          content: intl.formatMessage(messages.backToCjam),
          onClick: onCancel,
        },
        help: () =>
          window.open(
            "https://members.cj.com/member/contactSupport.cj?id=kA11C0000005oTC",
            "_blank"
          ),
      }}
    />
  );
};
