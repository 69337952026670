import * as R from "ramda";

export const unsetPublisherVisibilityBuilder = (setHiddenIdMap) =>
  R.forEach((publisherId) => {
    setHiddenIdMap((prevMap) => {
      return {
        ...prevMap,
        [publisherId]: false,
      };
    });
  });
