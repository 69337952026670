import * as R from "ramda";
import React from "react";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import { translations } from "utils/intl";

const browserLanguage = R.toLower(
  R.take(2, window.navigator.language || window.navigator.userLanguage || "en")
);

export const IntlProvider = ({ children }) => (
  <ReactIntlProvider
    locale={browserLanguage}
    messages={translations[browserLanguage]}
  >
    {children}
  </ReactIntlProvider>
);
