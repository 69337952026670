import React, { createContext } from "react";

export const UserContext = createContext({
  providerInitialized: false,
});

export const UserProvider = ({ children, user }) => {
  return (
    <UserContext.Provider value={{ user, providerInitialized: true }}>
      {children}
    </UserContext.Provider>
  );
};
