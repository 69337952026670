import { gql, useQuery } from "@apollo/client";
import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { CountryMap } from "components/CountryMap";
import { Earnings } from "components/Earnings";
import { NoData } from "components/NoData";
import { PieChart } from "components/PieChart";
import { Pill } from "components/Pill";
import { Spinner } from "components/Spinner";
import { Tooltip } from "components/Tooltip";
import { useClients, useCommissionsByCountry, useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React from "react";
import {
  formatCategoryPercentages,
  formatCurrency,
  formatEpc,
  isNullOrEmpty,
} from "utils/utils";
import { Attachments } from "./Attachments";
import { ContactInfo } from "./ContactInfo";
import messages from "./messages";
import { PlacementsTable } from "./PlacementsTable";
import { PublisherDescription } from "./PublisherDescription";
import "./styles.css";
import { TopSocialProperties } from "./TopSocialProperties";
import { TopWebsiteProperties } from "./TopWebsiteProperties";

const mapElementDisplay = (commissionsByCountry, publisher, intl) => {
  if (isNullOrEmpty(commissionsByCountry)) {
    return <p></p>;
  } else if (isNullOrEmpty(commissionsByCountry.countryMetrics)) {
    return <NoData dataTestId="no-data-map" />;
  } else {
    return (
      <CountryMap
        mapId={publisher.publisherId}
        data={commissionsByCountry.countryMetrics}
        message={intl.formatMessage(messages["mapContextMessage"])}
      />
    );
  }
};

const GET_AOV_CR = gql`
  query publisherAovCr($publisherId: ID!) {
    publisherAovCr(publisherId: $publisherId) {
      aov
      cr
    }
  }
`;

export const OverviewTab = ({ publisher, distinctPropertyValues }) => {
  const intl = useIntl();
  const { partnersClient } = useClients();
  const promotionalModels = R.path(
    ["distinctPropertyValues", "promotionalModels"],
    distinctPropertyValues
  );
  const propertyTypes = R.path(
    ["distinctPropertyValues", "propertyTypes"],
    distinctPropertyValues
  );
  const {
    data: commissionsByCountry,
    isLoading: isLoadingCommissionsByCountry,
  } = useCommissionsByCountry(publisher.publisherId);

  const { data: aovCr, loading: isLoadingAovCr } = useQuery(GET_AOV_CR, {
    client: partnersClient,
    variables: {
      publisherId: publisher.publisherId,
    },
  });

  if (isLoadingCommissionsByCountry || isLoadingAovCr) {
    return (
      <Box align="center">
        <Spinner />
      </Box>
    );
  }

  const aov = aovCr?.publisherAovCr?.aov;
  const cr = aovCr?.publisherAovCr?.cr;

  return (
    <Box direction="column" padding="medium" paddingTop="xl" gap="4xl">
      <Box direction="row" gap="xl">
        <PublisherDescription publisher={publisher} />
        <ContactInfo publisher={publisher} />
      </Box>

      <Attachments attachments={publisher.attachments} />

      {promotionalModels && promotionalModels.length > 0 && (
        <Box direction="column" gap="medium-large">
          <>
            <Text size={14} weight="medium">
              {intl.formatMessage(messages.promotionalModels)}
            </Text>
            <Box
              direction="row"
              className="promotional-models-container"
              gap="medium"
            >
              {promotionalModels.map((model) => (
                <Box direction="row" gap="medium" key={model}>
                  <Pill>
                    {intl.formatMessage(
                      globalMessages[`accounts.PromotionalModels.${model}`]
                    )}
                  </Pill>
                </Box>
              ))}
            </Box>
          </>
        </Box>
      )}

      {propertyTypes && propertyTypes.length > 0 && (
        <Box direction="column" gap="medium-large">
          <>
            <Text size={14} weight="medium">
              {intl.formatMessage(messages.propertyTypes)}
            </Text>
            <Box
              direction="row"
              className="promotional-models-container"
              gap="medium"
            >
              {propertyTypes.map((type) => (
                <Box direction="row" gap="medium" key={type}>
                  <Pill>{intl.formatMessage(globalMessages[`${type}`])}</Pill>
                </Box>
              ))}
            </Box>
          </>
        </Box>
      )}

      <Box direction="column" gap="medium">
        <Text weight="medium" size={14}>
          {intl.formatMessage(messages.performanceOverview)}
        </Text>
        <Box
          direction="row"
          gap="medium"
          justify="space-between"
          className="performance-overview-content"
        >
          <Tooltip
            content={intl.formatMessage(messages.aovTooltip)}
            data-testid="aov-stat-tooltip"
          >
            <Box
              direction="column"
              gap="small"
              align="center"
              justify="end"
              data-testid="aov-stat"
            >
              {Boolean(aov) && (
                <Text weight="medium" size={14}>
                  {formatCurrency(aov, "USD")}
                </Text>
              )}
              {!Boolean(aov) && <NoData />}
              <Box direction="column" className="aov-cr-label">
                <Text color="secondary" size={12}>
                  {intl.formatMessage(messages.aov)}
                </Text>
                <Text color="secondary" size={12}>
                  {intl.formatMessage(messages.lastThirtyDays)}
                </Text>
              </Box>
            </Box>
          </Tooltip>
          <Tooltip
            content={intl.formatMessage(messages.crTooltip)}
            data-testid="aov-stat-tooltip"
          >
            <Box
              direction="column"
              gap="small"
              align="center"
              justify="end"
              data-testid="cr-stat"
            >
              {cr && (
                <Text weight="medium" size={14}>
                  {(cr * 100).toFixed(2)}%
                </Text>
              )}
              {!cr && <NoData />}
              <Box direction="column" className="aov-cr-label">
                <Text color="secondary" size={12}>
                  {intl.formatMessage(messages.cr)}
                </Text>
                <Text color="secondary" size={12}>
                  {intl.formatMessage(messages.lastThirtyDays)}
                </Text>
              </Box>
            </Box>
          </Tooltip>
          <Tooltip
            content={intl.formatMessage(messages.sevenDayEpcToolTip)}
            data-testid="seven-day-epc-stat-tooltip"
          >
            <Box direction="column" gap="small" align="center" justify="end">
              <Text weight="medium" size={14}>
                {formatEpc(publisher.sevenDayEpc, intl)}
              </Text>
              <Text color="secondary" size={12}>
                {intl.formatMessage(messages.sevenDayEpc)}
              </Text>
            </Box>
          </Tooltip>
          <Tooltip
            content={intl.formatMessage(messages.threeDayEpcToolTip)}
            data-testid="three-month-epc-stat-tooltip"
          >
            <Box direction="column" gap="small" align="center" justify="end">
              <Text weight="medium" size={14}>
                {formatEpc(publisher.threeMonthEpc, intl)}
              </Text>
              <Text color="secondary" size={12}>
                {intl.formatMessage(messages.threeMonthEpc)}
              </Text>
            </Box>
          </Tooltip>
          <Tooltip
            content={intl.formatMessage(messages.networkEarningsToolTip)}
            data-testid="network-earnings-stat-tooltip"
          >
            <Box direction="column" gap="small" align="center" justify="end">
              <Earnings level={publisher.rating} />
              <Text color="secondary" size={12}>
                {intl.formatMessage(messages.networkEarnings)}
              </Text>
            </Box>
          </Tooltip>
        </Box>
      </Box>

      <Box direction="row" gap="medium" className="top-advertiser-categories">
        <Box
          className="piechart-partner-detail"
          direction="column"
          gap="medium"
        >
          <Tooltip
            content={intl.formatMessage(
              messages.topAdvertiserCategoriesToolTip
            )}
            data-testid="top-category-graph-tooltip"
          >
            <Text weight="medium" size={14}>
              {intl.formatMessage(messages.topAdvertiserCategories)}
            </Text>
          </Tooltip>
          {isNullOrEmpty(publisher.lastMonthCategoryCommissions) ? (
            <NoData dataTestId="no-data-chart" />
          ) : (
            <PieChart
              data={formatCategoryPercentages(
                publisher.lastMonthCategoryCommissions,
                intl
              )}
              chartId={publisher.id}
            />
          )}
        </Box>

        <Box direction="column" gap="medium">
          <Tooltip
            content={intl.formatMessage(messages.topAudienceLocationToolTip)}
            data-testid="audience-location-graph-tooltip"
          >
            <Text weight="medium" size={14}>
              {intl.formatMessage(messages.topAudienceLocation)}
            </Text>
          </Tooltip>
          {mapElementDisplay(commissionsByCountry, publisher, intl)}
        </Box>
      </Box>
      <TopSocialProperties publisherId={publisher.publisherId} />
      <TopWebsiteProperties publisherId={publisher.publisherId} />
      <PlacementsTable publisher={publisher} />
    </Box>
  );
};
