import { useQuery } from "@tanstack/react-query";
import { useCompany, useUser } from "hooks";
import * as R from "ramda";
import { userIsAdvertiser as userCanSeeFavorites } from "utils/authorizationUtils";
import { authenticatedFetch } from "../utils/clients";

const postToFavoriteAPIBuilder = (userId, advertiserId) => async (
  url,
  publisherIds
) => {
  return await authenticatedFetch(url, {
    method: "POST",
    body: JSON.stringify({
      favoritedByUser: userId,
      favoritedForAdvertiser: advertiserId?.toString(),
      favoritedPublishers: publisherIds,
    }),
    headers: {},
  });
};

const makeFavoriteAPICall = (userId, advertiserId, url) => async (
  publisherIds
) => {
  const postToFavoriteAPI = postToFavoriteAPIBuilder(userId, advertiserId);
  const postResponse = await postToFavoriteAPI(url, publisherIds);
  return postResponse;
};

export const FAVORITE_PUBLISHERS_REACT_QUERY_KEY = "favorite-publishers";

export const useFavoritedPublishers = () => {
  const { user } = useUser();
  const advertiser = useCompany();
  const userId = user.impersonator?.userId || user.userId;

  const getFavoritePublishers = async () => {
    if (userCanSeeFavorites(user)) {
      const favoritePublishersUrl = `${process.env.REACT_APP_MEMBER_URL}/affapi/favorited-publishers?favoritedByUser=${userId}&favoritedForAdvertiser=${advertiser.id}`;
      const response = await authenticatedFetch(favoritePublishersUrl);
      const json = await response.json();
      return json.favoritedPublishers;
    } else {
      return [];
    }
  };

  const favoritePublishersQuery = useQuery(
    [FAVORITE_PUBLISHERS_REACT_QUERY_KEY, user?.currentCompany?.id],
    getFavoritePublishers,
    { enabled: !!user }
  );

  return {
    ...favoritePublishersQuery,
    postFavorite: makeFavoriteAPICall(
      userId,
      advertiser.id,
      `${process.env.REACT_APP_MEMBER_URL}/affapi/favorited-publishers`
    ),
    postUnfavorite: makeFavoriteAPICall(
      userId,
      advertiser.id,
      `${process.env.REACT_APP_MEMBER_URL}/affapi/unfavorited-publishers`
    ),
  };
};

export const isFavorite = (publisherId, favoritePublishers = []) => {
  return R.includes(parseInt(publisherId, 10))(favoritePublishers);
};

export const isAllFavorited = (publisherIds, favoritePublishers = []) => {
  return R.all((publisherId) => isFavorite(publisherId, favoritePublishers))(
    publisherIds
  );
};
