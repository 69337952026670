import { defineMessages } from "react-intl";

export default defineMessages({
  publisherIdColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.publisherId",
    defaultMessage: "Id",
  },
  publisherNameColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.publisherName",
    defaultMessage: "Name",
  },
  countryCodeColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.countryCode",
    defaultMessage: "Country Code",
  },
  countrySubdivisionColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.countrySubdivision",
    defaultMessage: "Country Subdivision",
  },
  distinctPromotionalModelsColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.distinctPromotionalModels",
    defaultMessage: "Distinct Promotional Models",
  },
  distinctPropertyTypesColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.distinctPropertyTypes",
    defaultMessage: "Distinct Property Types",
  },
  cjPerformerColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.cjPerformer",
    defaultMessage: "CJ Performer",
  },
  contentCertifiedColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.contentCertifed",
    defaultMessage: "Content Certified",
  },
  subAffiliateColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.subAffiliate",
    defaultMessage: "Sub-Affiliate",
  },
  newToNetworkColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.newToNetwork",
    defaultMessage: "New to Network",
  },
  networkEarningsColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.networkEarnings",
    defaultMessage: "Network Earnings",
  },
  topCategoryColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.topCategory",
    defaultMessage: "Top Category",
  },
  topCountryColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.topCountry",
    defaultMessage: "Top Country",
  },
  sevenDayEpcColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.sevenDayEpc",
    defaultMessage: "Seven Day EPC",
  },
  threeMonthEpcColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.threeMonthEpc",
    defaultMessage: "Three Month EPC",
  },
  propertyNameColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.propertyName",
    defaultMessage: "Property Name",
  },
  propertyDetailsColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.propertyDetails",
    defaultMessage: "Property Details",
  },
  publisherProfileColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.publisherProfile",
    defaultMessage: "Profile",
  },
  partnerContactNameColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.partnerContactName",
    defaultMessage: "Contact Name",
  },
  partnerContactEmailColumnHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.partnerContactEmail",
    defaultMessage: "Contact Email",
  },
  reachNumberHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.reachNumber",
    defaultMessage: "Social Reach",
  },
  reachMetricNameHeader: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.columnHeader.reachMetricNameHeader",
    defaultMessage: "Reach Label",
  },
  distinctPropertyValuesApiError: {
    id:
      "partners.ExportPublishersCSVButton.export.publisher.distinctPropertyValues.error",
    defaultMessage:
      "There was an error downloading the selected publishers, please try again.",
  },
});
