import { defineMessages } from "react-intl";

export default defineMessages({
  websiteTrafficAndEngagement: {
    id:
      "partners.OverviewTab.WebsiteTrafficSection.websiteTrafficAndEngagement",
    defaultMessage: "Website Traffic and Engagement",
  },
  dataProvidedBy: {
    id: "partners.OverviewTab.WebsiteTrafficSection.dataProvidedBySimilarWeb",
    defaultMessage: "Data provided by ",
  },
  timePeriod: {
    id: "partners.OverviewTab.WebsiteTrafficSection.timePeriod",
    defaultMessage: "Time period",
  },
  lastMonth: {
    id: "partners.OverviewTab.WebsiteTrafficSection.lastMonth",
    defaultMessage: "Last Month",
  },
  deviceDistribution: {
    id: "partners.OverviewTab.WebsiteTrafficSection.deviceDistribution",
    defaultMessage: "Device Distribution",
  },
  industryRank: {
    id: "partners.OverviewTab.WebsiteTrafficSection.industryRank",
    defaultMessage: "Industry Rank",
  },
  topCountries: {
    id: "partners.OverviewTab.WebsiteTrafficSection.topCountries",
    defaultMessage: "Top Countries",
  },
  topCountriesTooltip: {
    id: "partners.OverviewTab.WebsiteTrafficSection.topCountriesTooltip",
    defaultMessage:
      "Top 5 countries/regions where publishers have the most traffic",
  },
  similarWeb: {
    id: "partners.OverviewTab.WebsiteTrafficSection.similarWeb",
    defaultMessage: "Similarweb",
  },
});
