import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { useIntl } from "hooks";
import React from "react";
import { TIKTOK_METRICS, useFeatures } from "hooks";
import { isNullOrEmpty } from "utils/utils";
import messages from "./messages";
import {
  FacebookMetrics,
  InstagramMetrics,
  PinterestMetrics,
  SnapchatMetrics,
  TikTokMetrics,
  TwitchMetrics,
  TwitterMetrics,
  YouTubeMetrics,
} from "./platformMetrics";
import "./styles.css";

export const SocialStats = ({ property }) => {
  const intl = useIntl();
  const socialMediaPlatform = property.propertyTypeDetails?.socialMediaPlatform?.toLowerCase();
  const metrics =
    property?.propertyTypeDetails?.ciqMetrics?.[socialMediaPlatform];

  const { hasFeature } = useFeatures();
  const shouldHideTikTokStats =
    !hasFeature(TIKTOK_METRICS) &&
    property.type === "SOCIAL_MEDIA" &&
    socialMediaPlatform === "tiktok";

  if (
    property.type !== "SOCIAL_MEDIA" ||
    !property?.propertyTypeDetails?.socialMediaPlatform ||
    shouldHideTikTokStats ||
    isNullOrEmpty(metrics)
  ) {
    return null;
  }

  return (
    <Box gap="xl">
      <Box
        direction="row"
        paddingBottom="medium"
        className="social-stats-title"
        justify="space-between"
        align="center"
      >
        <Text weight="medium" size={16}>
          {intl.formatMessage(messages.title)}
        </Text>
        <Box direction="row" align="center" gap="small">
          <Text color="secondary" size={12}>
            {intl.formatMessage(messages.dataProvidedBy)}
          </Text>
        </Box>
      </Box>
      {"facebook" === socialMediaPlatform ? (
        <FacebookMetrics metrics={metrics} />
      ) : "instagram" === socialMediaPlatform ? (
        <InstagramMetrics metrics={metrics} />
      ) : "pinterest" === socialMediaPlatform ? (
        <PinterestMetrics metrics={metrics} />
      ) : "snapchat" === socialMediaPlatform ? (
        <SnapchatMetrics metrics={metrics} />
      ) : "tiktok" === socialMediaPlatform ? (
        <TikTokMetrics metrics={metrics} />
      ) : "twitch" === socialMediaPlatform ? (
        <TwitchMetrics metrics={metrics} />
      ) : "twitter" === socialMediaPlatform ? (
        <TwitterMetrics metrics={metrics} />
      ) : "youtube" === socialMediaPlatform ? (
        <YouTubeMetrics metrics={metrics} />
      ) : null}
    </Box>
  );
};
