import { Badge } from "@cjdev-internal/visual-stack-x/Badge";
import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { IconButton } from "@cjdev-internal/visual-stack-x/Button";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import {
  BlankSlate,
  PrimaryActionButton,
} from "@cjdev-internal/visual-stack-x/components/BlankSlate";
import { AuthenticationStatusByProperty } from "components/AuthenticationStatus";
import { Pill } from "components/Pill";
import { Spinner } from "components/Spinner";
import {
  publisherProfilePropertiesListPath,
  publisherProfilePropertyDetailsPath,
} from "constants/paths";
import {
  useFeatures,
  useIndexedPromotionalPropertyQuery,
  useIntl,
  useParams,
  useReactRouterUtil,
  USER_CAN_AUTHENTICATE_IN_CIQ,
  useTopPromotionalPropertiesQuery,
} from "hooks";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React from "react";
import messages from "../../../messages";
import "../../../styles.css";
import {
  hasNextProperty,
  hasPreviousProperty,
} from "./helpers/propertyNavigation";
import { NetworkPerformance } from "./NetworkPerformance";
import { PropertyDescription } from "./PropertyDescription";
import { PropertyTags } from "./PropertyTags";
import { PropertyTypeInformation } from "./PropertyTypeInformation";
import { SocialStats } from "./SocialStats";
import { WebsiteTrafficSection } from "./WebsiteTrafficSection";

const getPropertyIndex = (
  indexedPromotionalPropertyData,
  indexedPromotionalPropertiesData
) => {
  const property = indexedPromotionalPropertyData.indexedPromotionalProperty;
  const propertyIds = R.pluck("id")(
    indexedPromotionalPropertiesData.topPromotionalProperties.resultList
  );
  const totalNumberOfProperties = propertyIds.length;
  const indexOfCurrentProperty = R.indexOf(property.id, propertyIds);
  const previousPropertyIndex = Math.max(0, indexOfCurrentProperty - 1);
  const previousPropertyId = propertyIds[previousPropertyIndex];
  const nextPropertyIndex = Math.min(
    totalNumberOfProperties - 1,
    indexOfCurrentProperty + 1
  );
  const nextPropertyId = propertyIds[nextPropertyIndex];

  return {
    previousPropertyId,
    nextPropertyId,
    currentPropertyPosition: indexOfCurrentProperty + 1,
    totalNumberOfProperties,
  };
};

export const PropertyDetails = ({ publisher }) => {
  const intl = useIntl();
  const { pushWithQuery } = useReactRouterUtil();
  const { params } = useParams();
  const { propertyId } = params;
  const {
    data: indexedPromotionalPropertyData,
    loading: loadingPromotionalProperty,
    error: errorOnPromotionalProperty,
  } = useIndexedPromotionalPropertyQuery(propertyId);

  const {
    data: indexedPromotionalPropertiesData,
    loading: loadingPromotionalProperties,
    error: errorOnPromotionalProperties,
  } = useTopPromotionalPropertiesQuery(publisher.publisherId);

  const { hasFeature } = useFeatures();
  const hasUserCanAuthenticateInCiqFeature = hasFeature(
    USER_CAN_AUTHENTICATE_IN_CIQ
  );

  if (loadingPromotionalProperties || loadingPromotionalProperty) {
    return (
      <Box align="center">
        <Spinner />
      </Box>
    );
  }

  if (errorOnPromotionalProperties || errorOnPromotionalProperty) {
    return (
      <BlankSlate title={intl.formatMessage(messages.error)}>
        <PrimaryActionButton
          label={"refresh"}
          handler={() => window.location.reload()}
        ></PrimaryActionButton>
      </BlankSlate>
    );
  }

  const property = indexedPromotionalPropertyData.indexedPromotionalProperty;

  const {
    currentPropertyPosition,
    nextPropertyId,
    previousPropertyId,
    totalNumberOfProperties,
  } = getPropertyIndex(
    indexedPromotionalPropertyData,
    indexedPromotionalPropertiesData
  );

  return (
    <Box
      direction="column"
      data-testid="promotional-property-details"
      padding="medium"
      gap="xl"
    >
      <Box direction="row" justify="space-between">
        <Box direction="row" gap="medium" align="center">
          <IconButton
            aria-label="go back to properties"
            icon="chevron-left"
            outline
            onClick={() => {
              pushWithQuery(
                publisherProfilePropertiesListPath(publisher.publisherId)
              );
            }}
          />
          <Text color="secondary">
            {intl.formatMessage(messages.backToProperties)}
          </Text>
        </Box>
        <Box direction="row" gap="medium" align="center">
          <Text color="secondary">
            {intl.formatMessage(messages.propertyPositionMessage, [
              currentPropertyPosition,
              totalNumberOfProperties,
            ])}
          </Text>
          <Box direction="row" gap="small">
            <IconButton
              icon="chevron-left"
              outline
              aria-label="go to previous property"
              onClick={() => {
                hasPreviousProperty(
                  totalNumberOfProperties,
                  currentPropertyPosition
                ) !== false &&
                  pushWithQuery(
                    publisherProfilePropertyDetailsPath(
                      publisher.publisherId,
                      previousPropertyId
                    )
                  );
              }}
            />
            <IconButton
              icon="chevron-right"
              outline
              onClick={() => {
                hasNextProperty(
                  totalNumberOfProperties,
                  currentPropertyPosition
                ) !== false &&
                  pushWithQuery(
                    publisherProfilePropertyDetailsPath(
                      publisher.publisherId,
                      nextPropertyId
                    )
                  );
              }}
              aria-label="go to next property"
            />
          </Box>
        </Box>
      </Box>

      <Box direction="column" gap="small">
        <Box direction="row" align="center">
          <Text weight="medium" size={16}>
            {property.name}
          </Text>
          {property.isPrimary && (
            <Badge variant="1">
              {intl.formatMessage(messages.primaryProperty)}
            </Badge>
          )}
          {hasUserCanAuthenticateInCiqFeature && (
            <AuthenticationStatusByProperty property={property} />
          )}
        </Box>
        <Box direction="row" className="property-subheader">
          <Text size={12} className="publisher-id">
            {property.id}
          </Text>
          <Text size={12} className="property-updated-at">
            {intl.formatMessage(messages.lastUpdatedOn)}{" "}
            {intl.formatDate(property.updatedAt || property.createdAt, {
              month: "long",
              day: "2-digit",
              year: "numeric",
            })}
          </Text>
        </Box>
        <Box direction="row" className="property-details-promo-models">
          {(property.promotionalModels || []).map((model) => (
            <Pill key={model}>
              {intl.formatMessage(
                globalMessages[`accounts.PromotionalModels.${model}`]
              )}
            </Pill>
          ))}
        </Box>
      </Box>

      <Box expand direction="column" gap="xl">
        <Box direction="row" gap="xl">
          <PropertyTypeInformation property={property} />
          <PropertyDescription property={property} />
        </Box>
        <PropertyTags property={property} />
      </Box>

      <NetworkPerformance property={property} />
      <SocialStats property={property} />
      <WebsiteTrafficSection property={property} />
    </Box>
  );
};
