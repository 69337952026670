import { PUBLISHER_SEARCH_PATH } from "constants/paths";
import React, { useState } from "react";

export const RoutePathContext = React.createContext({
  routeHistory: PUBLISHER_SEARCH_PATH,
  providerInitialized: false,
});

export const RoutePathProvider = ({ children, ...rest }) => {
  const [routeHistory, setRouteHistory] = useState(PUBLISHER_SEARCH_PATH);

  return (
    <RoutePathContext.Provider
      value={{
        routeHistory,
        setRouteHistory,
        providerInitialized: true,
        ...rest,
      }}
    >
      {children}
    </RoutePathContext.Provider>
  );
};
