import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Tooltip } from "components/Tooltip";
import { useIntl, useNav2022 } from "hooks";
import React from "react";
import { SlidingPanelButton } from "views/PublishersSearch/FiltersPanel/SlidingPanel";
import { SearchBar } from "views/PublishersSearch/SearchBar";
import messages from "../messages";

export const Header = ({ onClick }) => {
  const { nav2022 } = useNav2022();
  const intl = useIntl();

  return (
    <Box
      direction="row"
      padding="medium"
      justify="space-between"
      className={{
        "publishers-search-header": !nav2022,
        "publishers-search-header-outside-dimensions": nav2022,
      }}
    >
      {nav2022 ? undefined : (
        <>
          <Box direction="row" gap="medium">
            <SearchBar />
          </Box>
          <Box direction="row">
            <Tooltip
              placement="bottom"
              content={intl.formatMessage(messages.toggleFilterToolTip)}
              data-testid="toggle-filters-tooltip"
            >
              <SlidingPanelButton setActive={onClick} />
            </Tooltip>
          </Box>
        </>
      )}
    </Box>
  );
};
