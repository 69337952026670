import {
  MAX_NUMBER_OF_SELECTED_PUBLISHERS,
  PUBLISHERS_ALL_SELECTED,
  PUBLISHERS_EXCEEDED_MAX_SELECTED,
  PUBLISHERS_NON_SELECTED,
  PUBLISHERS_PARTIALLY_SELECTED,
} from "constants/selectedPublisherStatus";
import { SELECTED_PUBLISHERS } from "constants/sessionStorageKeys";
import * as R from "ramda";
import React, { createContext, useEffect, useState } from "react";

const maxNumberOfSelectedPublishers = MAX_NUMBER_OF_SELECTED_PUBLISHERS;
const defaultSelectedPublishersState = {};

const getInitialSelectedPublisherState = () => {
  const selectedPublishersSessionStorage =
    window.sessionStorage.getItem(SELECTED_PUBLISHERS) || "";
  let initialSelectedPublishers = defaultSelectedPublishersState;
  if (!R.isEmpty(selectedPublishersSessionStorage)) {
    initialSelectedPublishers = JSON.parse(selectedPublishersSessionStorage);
  }
  return initialSelectedPublishers;
};

export const SelectedPublishersContext = createContext({
  providerInitialized: false,
});

export const SelectedPublishersProvider = ({ children }) => {
  const [selectedPublishers, setSelectedPublishers] = useState(
    getInitialSelectedPublisherState()
  );
  const [errorOnSelectedPublishers, setErrorOnSelectedPublishers] = useState(
    []
  );

  useEffect(() => {
    window.sessionStorage.setItem(
      SELECTED_PUBLISHERS,
      JSON.stringify(selectedPublishers)
    );
  }, [selectedPublishers]);

  const isSelectingPublishers = !R.isEmpty(selectedPublishers);

  const setNextStateOrEvaulateErrors = (nextState) => {
    if (Object.keys(nextState).length > maxNumberOfSelectedPublishers) {
      setErrorOnSelectedPublishers([PUBLISHERS_EXCEEDED_MAX_SELECTED]);
    } else {
      setSelectedPublishers(nextState);
      clearSelectedPublishersErrors();
    }
  };

  const togglePublishers = (publishers) => {
    const nextState = R.clone(selectedPublishers);

    R.forEach((publisher) => {
      if (nextState[publisher.id]) {
        delete nextState[publisher.id];
      } else {
        nextState[publisher.id] = publisher;
      }
    })(publishers);

    setNextStateOrEvaulateErrors(nextState);
  };

  const togglePublishersOn = (publishers) => {
    const nextState = R.clone(selectedPublishers);
    R.forEach((publisher) => {
      nextState[publisher.id] = publisher;
    })(publishers);

    setNextStateOrEvaulateErrors(nextState);
  };

  const togglePublishersOff = (publishers) => {
    const nextState = R.clone(selectedPublishers);
    R.forEach((publisher) => {
      delete nextState[publisher.id];
    })(publishers);
    setNextStateOrEvaulateErrors(nextState);
  };

  const toggleAllPublisherIdsOff = () => {
    setSelectedPublishers({});
    clearSelectedPublishersErrors();
  };

  const isPublisherSelected = (publisherId) =>
    publisherId in selectedPublishers;

  const getSelectedPublisherStatus = (publisherIds) => {
    const getNumberOfPublishers = (selected) =>
      R.pipe(
        R.filter((publisherId) => {
          const target = isPublisherSelected(publisherId);
          return target === selected;
        }),
        R.length
      )(publisherIds);
    const numberOfSelected = getNumberOfPublishers(true);
    const numberOfUnselected = getNumberOfPublishers(false);
    if (R.isEmpty(publisherIds)) {
      return PUBLISHERS_NON_SELECTED;
    }
    if (numberOfSelected === 0) {
      return PUBLISHERS_NON_SELECTED;
    }
    if (numberOfUnselected === 0) {
      return PUBLISHERS_ALL_SELECTED;
    }
    return PUBLISHERS_PARTIALLY_SELECTED;
  };

  const getSelectedPublishersErrors = () => {
    return errorOnSelectedPublishers;
  };

  const clearSelectedPublishersErrors = () => {
    setErrorOnSelectedPublishers([]);
  };

  return (
    <SelectedPublishersContext.Provider
      value={{
        selectedPublisherIds: R.keys(selectedPublishers),
        selectedPublishers: R.values(selectedPublishers),
        isSelectingPublishers,
        providerInitialized: true,
        setSelectedPublishers,
        togglePublishers,
        isPublisherSelected,
        togglePublishersOn,
        togglePublishersOff,
        toggleAllPublisherIdsOff,
        getSelectedPublisherStatus,
        getSelectedPublishersErrors,
      }}
    >
      {children}
    </SelectedPublishersContext.Provider>
  );
};
