import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import React from "react";

export const NoData = ({ dataTestId, ...props }) => {
  const intl = useIntl();
  return (
    <Text {...props} color="secondary" data-testid={dataTestId} italic>
      {intl.formatMessage(globalMessages.noData)}
    </Text>
  );
};
