/* global BigInt */

import { NULL_STATE_METRIC_PLACEHOLDER } from "constants/metrics";
import globalMessages from "messages/globalMessages";
import millify from "millify";

export const primaryMetricsByPlatform = {
  facebook: "pageLikes",
  instagram: "followers",
  tiktok: "followers",
  pinterest: "followers",
  snapchat: "followers",
  twitch: "followers",
  twitter: "followers",
  youtube: "subscribers",
};

export const engagementMetricsPerPlatform = {
  instagram: "likesPerPost20Posts",
  facebook: "likesPerPost20Posts",
  twitter: "accountLikesPerTweet",
  youtube: "accountViewsPerVideo",
  twitch: "postAvgStreamViews",
  pinterest: "accountAverageMonthlyViews",
  tiktok: "likesPerPost20Posts",
};

const engagementMetricMessagePerPlatform = {
  instagram: globalMessages.likesPerPost,
  facebook: globalMessages.likesPerPost,
  twitter: globalMessages.likesPerTweet,
  youtube: globalMessages.viewsPerVideo,
  twitch: globalMessages.averageStreamViews,
  pinterest: globalMessages.averageMonthlyViews,
  tiktok: globalMessages.likesPerPost,
};

export const getPrimaryMetricFromPlatform = (socialMediaPlatform) =>
  socialMediaPlatform
    ? primaryMetricsByPlatform[socialMediaPlatform.toLowerCase()]
    : undefined;

export const formatMetricNumber = (number) => {
  if (!number) {
    return NULL_STATE_METRIC_PLACEHOLDER;
  }

  try {
    return millify(BigInt(number), {
      lowercase: true,
    });
  } catch (e) {
    return number.toString();
  }
};

export const formatMetricName = (metricName, intl) =>
  metricName && intl.formatMessage(globalMessages[`${metricName}`]);

export const formatMetric = (number, metricName, intl) =>
  metricName
    ? `${formatMetricNumber(number)} ${formatMetricName(metricName, intl)}`
    : intl.formatMessage(globalMessages["global.na"]);

export const getPlatform = (property) =>
  (property?.propertyTypeDetails?.socialMediaPlatform ?? "").toLowerCase();

export const getCiqMetricsForPlatform = (property) =>
  property?.propertyTypeDetails?.ciqMetrics?.[getPlatform(property)] ?? {};

export const getReachMetricName = (property) =>
  primaryMetricsByPlatform[getPlatform(property)];

export const getEngagementMetricName = (property) =>
  engagementMetricsPerPlatform[getPlatform(property)];

export const getReachMetric = (property) =>
  getCiqMetricsForPlatform(property)?.[getReachMetricName(property)];

export const getEngagementMetric = (property) => {
  const ciqMetricDetails = getCiqMetricsForPlatform(property);
  const engagementMetricName = getEngagementMetricName(property);
  return ciqMetricDetails[engagementMetricName];
};

export const getEngagementRate = (property) =>
  getCiqMetricsForPlatform(property)?.engagementRate20Posts;

export const getEngagementLabel = (property) =>
  getCiqMetricsForPlatform(property)?.engagementLabel;

export const getFormattedReachMetricName = (property, intl) => {
  const reachMetricName = getReachMetricName(property);
  return (
    reachMetricName && intl.formatMessage(globalMessages[`${reachMetricName}`])
  );
};

export const getFormattedEngagementMetricName = (property, intl) => {
  const engagementMetricMessage =
    engagementMetricMessagePerPlatform[getPlatform(property)];
  return engagementMetricMessage && intl.formatMessage(engagementMetricMessage);
};

export const getFormattedEngagementLabel = (property, intl) => {
  const engagementLabel = getEngagementLabel(property);
  return engagementLabel && intl.formatMessage(globalMessages[engagementLabel]);
};

export const getFormattedReachMetric = (property, intl, hasTikTokFeature) => {
  if (
    !hasTikTokFeature &&
    property?.propertyTypeDetails?.socialMediaPlatform === "TIKTOK"
  ) {
    return intl.formatMessage(globalMessages["global.na"]);
  }

  const reachMetric = getReachMetric(property);
  const reachMetricName = getFormattedReachMetricName(property, intl);
  return reachMetricName
    ? `${formatMetricNumber(reachMetric)} ${reachMetricName}`
    : intl.formatMessage(globalMessages["global.na"]);
};

export const getFormattedEngagementMetric = (property, intl) => {
  const engagementMetric = getEngagementMetric(property);
  const engagementMetricName = getFormattedEngagementMetricName(property, intl);
  return engagementMetricName
    ? `${formatMetricNumber(engagementMetric)} ${engagementMetricName}`
    : intl.formatMessage(globalMessages["global.na"]);
};

const platformHandlePatterns = {
  // eslint-disable-next-line
  instagram: /^(?:(?:http(?:s|)?:\/\/)?(?:(?:\w+\.|)(?:instagram\.\w+)\/)|(?:@))?(?<handle>[^\/\?\#\:]+)(?:\/.*|\?.*|\#.*|)$/,
  // eslint-disable-next-line
  tiktok: /^(?:(?:http(?:s|)?:\/\/)?(?:(?:\w+\.|)(?:tiktok\.\w+)\/)|(?:@))?(?<handle>[^\/\?\#\:]+)(?:\/.*|\?.*|\#.*|)$/,
  // eslint-disable-next-line
  facebook: /^(?:(?:http(?:s|)?:\/\/)?(?:(?:\w+\.|)(?:facebook\.\w+|fb\.\w+)\/)|(?:@))?(?<handle>[^\/\?\#\:]+)(?:\/.*|\?.*|\#.*|)$/,
  // eslint-disable-next-line
  twitter: /^(?:(?:http(?:s|)?:\/\/)?(?:(?:\w+\.|)(?:twitter\.\w+)\/)|(?:@))?(?<handle>[^\/\?\#\:]+)(?:\/.*|\?.*|\#.*|)$/,
  // eslint-disable-next-line
  youtube: /^(?:(?:http(?:s|)?:\/\/)?(?:(?:\w+\.|)(?:youtube\.\w+\/channel\/|youtube\.\w+\/c\/|youtube\.\w+\/user\/|youtube\.\w+\/))|(?:@))?(?<handle>[^\/\?\#\:]+)(?:\/.*|\?.*|\#.*|)$/,
  // eslint-disable-next-line
  pinterest: /^(?:(?:http(?:s|)?:\/\/)?(?:(?:\w+\.|)(?:pinterest\.\w+|pin\.it)\/)|(?:@))?(?<handle>[^\/\?\#\:]+)(?:\/.*|\?.*|\#.*|)$/,
  // eslint-disable-next-line
  twitch: /^(?:(?:http(?:s|)?:\/\/)?(?:(?:\w+\.|)(?:twitch\.tv)\/)|(?:@))?(?<handle>[^\/\?\#\:]+)(?:\/.*|\?.*|\#.*|)$/,
  // eslint-disable-next-line
  snapchat: /^(?:(?:http(?:s|)?:\/\/)?(?:(?:\w+\.|)(?:snapchat\.\w+)\/)|(?:@))?(?<handle>[^\/\?\#\:]+)(?:\/.*|\?.*|\#.*|)$/,
};

export const getSocialHandle = (property) => {
  const platform = getPlatform(property);
  const rawUrl =
    property.propertyTypeDetails.socialMediaUrl ||
    property.propertyTypeDetails.socialMediaHandle;
  const pattern = platformHandlePatterns[platform];

  if (!pattern) {
    return rawUrl;
  }

  try {
    const handleMatches = pattern.exec(rawUrl);

    if (!handleMatches) {
      return rawUrl;
    }

    const { handle } = handleMatches.groups;

    if (platform === "youtube" && rawUrl.indexOf("channel/") !== -1) {
      return `Channel: ${handle}`;
    }

    return handle;
  } catch (error) {
    return rawUrl;
  }
};
