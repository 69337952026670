import { useHistory, useLocation } from "hooks";

export const useReactRouterUtil = () => {
  const { location } = useLocation();
  const { search } = location;
  const { navigateTo } = useHistory();
  return {
    pushWithQuery: (path, state) => {
      if (search) {
        navigateTo(`${path}${search}`, state);
      } else {
        navigateTo(path, state);
      }
    },
  };
};
