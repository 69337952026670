import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "partners.SocialStats.newTitle",
    defaultMessage: "Social Stats",
  },
  followers: {
    id: "partners.SocialStats.followers",
    defaultMessage: "Followers",
  },
  pageLikes: {
    id: "partners.SocialStats.pageLikes",
    defaultMessage: "Page Likes",
  },
  subscribers: {
    id: "partners.SocialStats.subscribers",
    defaultMessage: "Subscribers",
  },
  dataProvidedBy: {
    id: "partners.SocialStats.dataProvidedBy",
    defaultMessage: "Data provided by CreatorIQ",
  },
  timePeriodLast20Posts: {
    id: "partners.SocialStats.timePeriodLast20Posts",
    defaultMessage: "Time Period: Last 20 Posts",
  },
  facebookEngagementCalculation: {
    id: "partners.SocialStats.facebookEngagementCalculation",
    defaultMessage:
      " Total Engagement = Likes/Reactions + Comments + Shares + Clicks",
  },
  instagramEngagementCalculation: {
    id: "partners.SocialStats.instagramEngagementCalculation",
    defaultMessage: " Total Engagement = Likes + Comments + Saves",
  },
  youtubeEngagementCalculation: {
    id: "partners.SocialStats.youtubeEngagementCalculation",
    defaultMessage: " Total Engagement = Likes + Comments + Shares",
  },
  twitterEngagementCalculation: {
    id: "partners.SocialStats.twitterEngagementCalculation",
    defaultMessage: " Total Engagement = Likes + Replies + Retweets",
  },
  pinterestEngagementCalculation: {
    id: "partners.SocialStats.pinterestEngagementCalculation",
    defaultMessage: " Total Engagement = Saves + Closeups + Clicks",
  },
  twitchEngagementCalculation: {
    id: "partners.SocialStats.twitchEngagementCalculation",
    defaultMessage: " Total Engagement = Chatters",
  },
});
