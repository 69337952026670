import { defineMessages } from "react-intl";

export default defineMessages({
  partnerSelected: {
    id: "partners.Drawer.partnerSelected",
    defaultMessage: "Partner Selected",
  },
  partnersSelected: {
    id: "partners.Drawer.partnersSelected",
    defaultMessage: "Partners Selected",
  },
  downloadCSVToolTip: {
    id: "partners.Drawer.downloadCSVToolTip",
    defaultMessage: "Download to CSV",
  },
  shareTooltip: {
    id: "partners.Drawer.shareTooltip",
    defaultMessage: "Share partners",
  },
  remove: {
    id: "partners.Drawer.remove",
    defaultMessage: "Remove",
  },
  unselectAll: {
    id: "partners.Drawer.unselectAll",
    defaultMessage: "Unselect All",
  },
});
