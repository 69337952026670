import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { Td, Tr } from "@cjdev-internal/visual-stack-x/components/Table";
import classNames from "classnames";
import { AuthenticationStatusByProperty } from "components/AuthenticationStatus";
import { Link } from "components/Link";
import { PropertyTypeDetails } from "components/PropertyTypeDetails";
import { SocialIcon } from "@cjdev-internal/visual-stack-x/SocialIcon";
import { publisherProfilePropertyDetailsPath } from "constants/paths";
import { useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import React from "react";
import { formattedPropertyReach, propertyReach } from "utils/propertyUtils";
import { formatPropertyType } from "utils/utils";
import { isWebsite } from "utils/webUtils";
import messages from "./messages";

export const PropertyRow = ({
  property,
  similarwebData,
  publisherId,
  hasUserCanAuthenticateInCiqFeature,
  hasTikTokMetrics,
}) => {
  const intl = useIntl();
  const {
    id,
    isMatchingSearchTerm,
    type: propertyType,
    propertyTypeDetails,
    name,
    primaryPromotionalModel,
  } = property;

  const isWebsiteProperty = isWebsite(property);
  const isSocialProperty = propertyType === "SOCIAL_MEDIA";

  const reach = propertyReach(property, similarwebData);

  return (
    <Tr
      key={id}
      data-testid={publisherId}
      className={classNames({
        "property-row": true,
        "property-row-with-user-can-authenticate-in-ciq-feature": hasUserCanAuthenticateInCiqFeature,
        "match-search-query-row":
          hasUserCanAuthenticateInCiqFeature && isMatchingSearchTerm,
      })}
    >
      <Td className="truncate-table-data">
        <Text size={12}>{id}</Text>
      </Td>
      <Td className="truncate-table-data clickable-property-name">
        <Link
          className="vsx-link"
          to={publisherProfilePropertyDetailsPath(publisherId, id)}
        >
          <Text
            size={12}
            className={classNames({
              "match-search-query":
                !hasUserCanAuthenticateInCiqFeature && isMatchingSearchTerm,
            })}
            data-testid={"property-matching-dot"}
          >
            {hasUserCanAuthenticateInCiqFeature && (
              <AuthenticationStatusByProperty
                property={property}
                className="property-table-authentication-status"
              />
            )}
            {name}
          </Text>
        </Link>
      </Td>
      <Td className="truncate-table-data">
        <Text size={12}>
          {formatPropertyType(propertyType, propertyTypeDetails, intl)}
        </Text>
      </Td>
      <Td className="truncate-table-data">
        {primaryPromotionalModel ? (
          <Text size={12}>
            {intl.formatMessage(
              globalMessages[
                `accounts.PromotionalModels.${primaryPromotionalModel}`
              ]
            )}
          </Text>
        ) : (
          <Text color="secondary" size={12}>
            {intl.formatMessage(messages.noPromotionalModel)}
          </Text>
        )}
      </Td>
      <Td className="truncate-table-data">
        <PropertyTypeDetails
          className="small-property-type-details"
          additionalStyles={{ fontSize: "12px" }}
          propertyType={propertyType}
          propertyTypeDetails={propertyTypeDetails}
        />
      </Td>
      <Td className="truncate-table-data">
        <Box direction="row" align="center">
          {isSocialProperty && (
            <SocialIcon
              size={20}
              icon={propertyTypeDetails.socialMediaPlatform.toLowerCase()}
            />
          )}
          {isWebsiteProperty && (
            <SocialIcon
              size={20}
              icon={"web"}
              className={"partners-nested-website-icon"}
            />
          )}
          <Text
            size={12}
            color={reach ? undefined : "secondary"}
            italic={!reach}
            className="property-reach-metrics"
          >
            {formattedPropertyReach(
              property,
              similarwebData,
              intl,
              hasTikTokMetrics
            )}
          </Text>
        </Box>
      </Td>
    </Tr>
  );
};
