export const PUBLISHER_SEARCH_PATH = "/publishers/search";
export const PUBLISHER_FAVORITES_PATH = "/publishers/favorites";
export const PUBLISHER_PROFILE_PATH_PATTERN = "/publishers/:id/profile";
export const PUBLISHER_PROFILE_PROPERTIES_LIST_PATH_PATTERN =
  "/publishers/:id/profile/properties";
export const PUBLISHER_PROFILE_PROPERTY_DETAIL_PATH_PATTERN =
  "/publishers/:id/profile/properties/:propertyId";
export const DEFAULT_PATH = PUBLISHER_SEARCH_PATH;

export const publisherProfilePath = (publisherId) => {
  return PUBLISHER_PROFILE_PATH_PATTERN.replace(":id", publisherId);
};

export const publisherProfilePropertiesListPath = (publisherId) => {
  return PUBLISHER_PROFILE_PROPERTIES_LIST_PATH_PATTERN.replace(
    ":id",
    publisherId
  );
};

export const publisherProfilePropertyDetailsPath = (
  publisherId,
  propertyId
) => {
  return PUBLISHER_PROFILE_PROPERTY_DETAIL_PATH_PATTERN.replace(
    ":id",
    publisherId
  ).replace(":propertyId", propertyId);
};
