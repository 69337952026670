import * as R from "ramda";
import { isNullOrEmpty } from "utils/utils";

export const getSortByNamesFromCurrentFilters = (
  appliedFiltersInformation,
  searchTerms
) => {
  const metricFilterConstructor = (platform) =>
    R.pipe(
      R.path(["promotionalTypes", "sortableSortOptions"]),
      R.includes(platform)
    )(appliedFiltersInformation);

  const currentFilters = {
    keywordRelevance: !isNullOrEmpty(searchTerms),
    totalMonthlyVisitors: metricFilterConstructor("website"),
    instagramFollowers: metricFilterConstructor("instagram"),
    tiktokFollowers: metricFilterConstructor("tiktok"),
    facebookPageLikes: metricFilterConstructor("facebook"),
    youtubeSubscribers: metricFilterConstructor("youtube"),
    twitterFollowers: metricFilterConstructor("twitter"),
    pinterestFollowers: metricFilterConstructor("pinterest"),
    twitchFollowers: metricFilterConstructor("twitch"),
    snapchatFollowers: metricFilterConstructor("snapchat"),
    countryCommissions: Boolean(
      R.path(["commissionLocations", "number"], appliedFiltersInformation)
    ),
    categoryCommissions: Boolean(
      R.path(["advertiserCategories", "number"], appliedFiltersInformation)
    ),
    unsortablePropertyType: R.path(
      ["promotionalTypes", "unsortableSelected"],
      appliedFiltersInformation
    ),
  };

  const filterSortByNames = R.pipe(
    R.omit(["unsortablePropertyType"]),
    R.filter(Boolean),
    R.keys,
    R.append("networkEarnings")
  );

  const pickDefaultSelection = (currentFilters) => {
    if (!currentFilters.unsortablePropertyType) {
      if (currentFilters.totalMonthlyVisitors) return "totalMonthlyVisitors";
      if (currentFilters.instagramFollowers) return "instagramFollowers";
      if (currentFilters.tiktokFollowers) return "tiktokFollowers";
      if (currentFilters.facebookPageLikes) return "facebookPageLikes";
      if (currentFilters.youtubeSubscribers) return "youtubeSubscribers";
      if (currentFilters.twitterFollowers) return "twitterFollowers";
      if (currentFilters.pinterestFollowers) return "pinterestFollowers";
      if (currentFilters.twitchFollowers) return "twitchFollowers";
      if (currentFilters.snapchatFollowers) return "snapchatFollowers";
    }

    return currentFilters.keywordRelevance
      ? "keywordRelevance"
      : currentFilters.categoryCommissions
      ? "categoryCommissions"
      : currentFilters.countryCommissions
      ? "countryCommissions"
      : "networkEarnings";
  };

  return {
    currentSortByNames: filterSortByNames(currentFilters),
    defaultSortByName: pickDefaultSelection(currentFilters),
  };
};
