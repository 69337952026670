import React from "react";

export const MinMaxRangeInput = ({
  value,
  min,
  max,
  step,
  onChange,
  onSelection,
  zIndex,
  onHover,
}) => (
  <input
    type="range"
    tabIndex="0"
    value={value}
    max={max}
    min={min}
    step={step}
    onChange={onChange}
    onLostPointerCapture={onSelection}
    style={{ zIndex }}
    onMouseOver={() => onHover(true)}
    onMouseOut={() => onHover(false)}
  />
);
