import {
  SlidingPanel as VSPanel,
  ToggleButton as VSRToggleIcon,
} from "@cjdev-internal/visual-stack-x/SlidingPanel";
import React from "react";
import { FiltersPanel } from ".";
import "./styles.css";

export const SlidingPanelButton = VSRToggleIcon;

export const SlidingPanel = ({ isActive }) => {
  return (
    <VSPanel active={isActive}>
      <FiltersPanel />
    </VSPanel>
  );
};
