import { defineMessages } from "react-intl";

export default defineMessages({
  noPromotionalModel: {
    id: "partners.PropertyTypeInformation.noPromotionalModel",
    defaultMessage: "No promotional model provided",
  },
  noPropertyDetails: {
    id: "partners.PropertyTypeInformation.noPropertyDetails",
    defaultMessage: "No property details provided",
  },
  propertyDetails: {
    id: "partners.PropertyTypeInformation.propertyDetails",
    defaultMessage: "Details",
  },
  primaryModel: {
    id: "partners.PropertyTypeInformation.primaryModel",
    defaultMessage: "Primary model",
  },
});
