import { useQuery } from "@tanstack/react-query";
import { EMPLOYEE_COMPANY_ID } from "constants/companyIds";
import { authenticatedFetch } from "utils/clients";
import { useCompany } from "./useCompany";

export const useFeatures = (overRideCompanyId, options = {}) => {
  const company = useCompany();
  const companyId = overRideCompanyId || company.id || EMPLOYEE_COMPANY_ID;

  const result = useQuery(
    ["features", companyId],
    async () => {
      const response = await authenticatedFetch(
        `${process.env.REACT_APP_MEMBER_URL}/affapi/company/${companyId}/features`,
        {}
      );
      return await response.json();
    },
    {
      retry: 0,
      enabled: !!companyId,
      refetchOnWindowFocus: false,
      ...options,
    }
  );

  return {
    ...result,
    hasFeature: (feature) => {
      return result.data?.includes(feature);
    },
  };
};

export const OBSCURE_TOTAL_PUBLISHER_COUNT = "OBSCURE_TOTAL_PUBLISHER_COUNT";
export const USER_CAN_AUTHENTICATE_IN_CIQ = "USER_CAN_AUTHENTICATE_IN_CIQ";
export const TIKTOK_SUPPORT = "TIKTOK_SUPPORT";
export const TIKTOK_METRICS = "TIKTOK_METRICS";
