import { Text } from "@cjdev-internal/visual-stack-x/Text";
import {
  BlankSlate,
  SubTitle,
} from "@cjdev-internal/visual-stack-x/components/BlankSlate";
import {
  useAppliedFilters,
  useFavoritedPublishers,
  useIntl,
  usePublisherIdFilter,
  usePublishersSearchQuery,
  useSearchTerms,
} from "hooks";
import React from "react";
import {
  checkFavoritePublisher,
  checkFavoritePublishers,
  isNullOrEmpty,
  modifyFunctionToAcceptObjectsInsteadOfIds,
  returnFalse,
} from "utils/utils";
import messages from "../messages";
import { ReactComponent as BlankSlateSvg } from "./partners-empty-state.svg";
import { PartnersTableView } from "./PartnersTableView";
import "./styles.css";

export const PartnersTable = () => {
  const intl = useIntl();
  const { searchTerms } = useSearchTerms();
  const { isEmptyFilter } = useAppliedFilters();
  const { publisherIds: filteredPublishers } = usePublisherIdFilter();
  const {
    data: favoritePublishers,
    isLoading: isLoadingFavoritePublishers,
    postFavorite,
    postUnfavorite,
  } = useFavoritedPublishers();

  const shouldRenderEmptySlate =
    isNullOrEmpty(searchTerms) &&
    isEmptyFilter &&
    isNullOrEmpty(filteredPublishers);

  const partnersReactQueryResponse = usePublishersSearchQuery({
    skip: shouldRenderEmptySlate,
  });

  return (
    <>
      <PartnersTableView
        noResultSlate={
          <BlankSlate title={intl.formatMessage(messages.noResults)}>
            <SubTitle>
              <Text>{intl.formatMessage(messages.noResultsSubTitle)}</Text>
            </SubTitle>
          </BlankSlate>
        }
        emptySlate={
          <BlankSlate
            headerGraphic={<BlankSlateSvg />}
            title={intl.formatMessage(messages.blankSlate)}
          >
            <SubTitle>
              <Text className="blank-slate-subtitle">
                {intl.formatMessage(messages.blankSlateSubTitle)}
              </Text>
            </SubTitle>
          </BlankSlate>
        }
        checkFavoritePublisher={checkFavoritePublisher}
        checkFavoritePublishers={checkFavoritePublishers}
        favoritePublishers={favoritePublishers}
        isLoading={
          partnersReactQueryResponse.loading || isLoadingFavoritePublishers
        }
        isPublisherHidden={returnFalse}
        partnersReactQueryResponse={partnersReactQueryResponse}
        postFavorite={modifyFunctionToAcceptObjectsInsteadOfIds(postFavorite)}
        postUnfavorite={modifyFunctionToAcceptObjectsInsteadOfIds(
          postUnfavorite
        )}
        shouldRenderEmptySlate={shouldRenderEmptySlate}
      />
    </>
  );
};
