import { defineMessages } from "react-intl";

export default defineMessages({
  showMore: {
    id: "partners.ClampedText.showMore",
    defaultMessage: "Show More",
  },
  showLess: {
    id: "partners.ClampedText.showLess",
    defaultMessage: "Show Less",
  },
});
