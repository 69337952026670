import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React, { useEffect } from "react";

am4core.options.commercialLicense = true;

am4core.useTheme(am4themes_animated);

export const PieChart = ({ chartId, data, visible = true }) => {
  useEffect(() => {
    if (!visible) {
      return;
    }

    let chart = am4core.create(`chartdiv-${chartId}`, am4charts.PieChart);

    chart.data = data;

    // chart formatting

    chart.paddingLeft = -19;
    chart.numberFormatter.numberFormat = "##.##";
    chart.radius = am4core.percent(85);

    // series

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "category";
    series.calculatePercent = true;

    // slices

    series.colors.list = [
      am4core.color("#122F7C"),
      am4core.color("#49C5B1"),
      am4core.color("#EA4B93"),
      am4core.color("#FF9203"),
      am4core.color("#4BC9F7"),
    ];

    series.ticks.template.disabled = true;
    series.labels.template.disabled = true;
    series.slices.template.tooltipText = "{category} ({value.percent}%)"; // when we receive data, is percent necassary?

    // legend

    chart.legend = new am4charts.Legend();
    chart.legend.itemContainers.template.paddingTop = 3;
    chart.legend.itemContainers.template.paddingBottom = 3;
    chart.legend.useDefaultMarker = true;
    chart.legend.position = "left";
    chart.legend.valign = "top";
    chart.legend.fontSize = "12px";

    chart.legend.labels.template.maxWidth = 135;
    chart.legend.labels.template.truncate = true;

    // legend marker

    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);

    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 10;
    markerTemplate.height = 10;

    return () => {
      chart.dispose();
    };
  }, [chartId, data, visible]);

  return (
    <div
      className="am-chart-element"
      data-testid={`chartdiv-${chartId}`}
      id={`chartdiv-${chartId}`}
    ></div>
  );
};
