import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { MDIcon } from "@cjdev-internal/visual-stack-x/components/MDIcon";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import React from "react";
import { isNullOrEmpty } from "utils/utils";
import "./styles.css";
import { ExternalLink } from "components/ExternalLink";

export const ContactInfo = ({ publisher }) => {
  if (isNullOrEmpty(publisher.directContacts)) {
    return null;
  }

  const { name, email } = publisher.directContacts[0];

  return (
    <Box direction="row" gap="medium" className="contact-info">
      <Box paddingTop="small">
        <MDIcon
          icon="email-outline"
          align="middle"
          color="var(--brownish-grey)"
          size={14}
        />
      </Box>
      <Box direction="column" gap="small">
        {name && <Text>{name}</Text>}
        {email && (
          <ExternalLink
            path={`mailto:${email}`}
            text={
              <Text color="link" size={13}>
                {email}
              </Text>
            }
            newWindow
          />
        )}
      </Box>
    </Box>
  );
};
