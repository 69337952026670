import * as R from "ramda";
import React from "react";
import { alertContent } from "views/PublishersSearch/PartnersTable/helpers/alertContent";
import { unsetPublisherVisibilityBuilder } from "views/PublishersSearch/PartnersTable/helpers/unsetPublisherVisibilityBuilder";
import { UndoSelectedPublishersButton } from "views/PublishersSearch/PartnersTable/UndoSelectedPublishersButton";

export const toggleAlertBuilder = ({
  unfavoriteAlert,
  setHiddenIdMap,
  toggleSuccessAlert,
  unfavoritedPublishersRef,
  setIndexedPublishers,
  produceUndoPublishers,
  postFavorite,
}) => (publishers = []) => {
  if (R.isEmpty(publishers)) {
    return;
  }

  clearTimeout(unfavoriteAlert.current);

  const unsetPublisherVisibility = unsetPublisherVisibilityBuilder(
    setHiddenIdMap
  );

  const undoButton = (
    <UndoSelectedPublishersButton
      unfavoritedPublishersRef={unfavoritedPublishersRef}
      unsetPublisherVisibility={unsetPublisherVisibility}
      setIndexedPublishers={setIndexedPublishers}
      produceUndoPublishers={produceUndoPublishers}
      postFavorite={postFavorite}
    />
  );

  toggleSuccessAlert(
    <>
      {alertContent(publishers)}
      {undoButton}
    </>
  );
};
