import { defineMessages } from "react-intl";

export default defineMessages({
  makeOfferToolTip: {
    id: "partners.PublisherDetails.makeOfferToolTip",
    defaultMessage: "Invite this publisher into your program",
  },
  publisherSince: {
    id: "partners.PublisherDetails.publisherSince",
    defaultMessage: "Publisher since {0}",
  },
  reportPublisher: {
    id: "partners.PublisherDetails.reportPublisher",
    defaultMessage: "Report Publisher",
  },
  pendingOffer: {
    id: "partners.PublisherDetails.pendingOffer",
    defaultMessage: "PENDING OFFER",
  },
  makeOffer: {
    id: "partners.PublisherDetails.makeOffer",
    defaultMessage: "MAKE OFFER",
  },
});
