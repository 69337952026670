import { gql, useQuery } from "@apollo/client";
import { useClients } from "hooks";

export const GET_DISTINCT_PROPERTY_VALUES = `
  query distinctPropertyValues($publisherId: ID!) {
    distinctPropertyValues(publisherId: $publisherId) {
      promotionalModels
      propertyTypes
    }
  }
`;

export const useDistinctPropertyValues = (
  publisherId,
  userAllowedToSeePage
) => {
  const { accountsClient } = useClients();

  return useQuery(gql(GET_DISTINCT_PROPERTY_VALUES), {
    client: accountsClient,
    variables: { publisherId },
    skip: !userAllowedToSeePage,
  });
};
