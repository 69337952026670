import { useSelectedPublishers } from "hooks";
import * as R from "ramda";
import React, { createContext, useState } from "react";
import { defaultSelectedMetrics } from "utils/metricsUtils";
import { getCurrentAppliedFiltersInformation } from "./utils/currentAppliedFiltersInformationUtils";

export const AppliedFiltersContext = createContext({
  providerInitialized: false,
});

export const defaultFilters = {
  publisherLevel: {
    networkEarningsToInclude: [],
    publisherLocations: {
      selected: {},
      include: true,
    },
    commissionLocations: {
      selected: {},
      include: true,
      percentage: 0,
    },
    advertiserCategories: {
      selected: {},
      include: true,
      percentage: 0,
    },
    badges: {
      include: [],
      exclude: [],
    },
  },
  propertyLevel: {
    promotionalModels: {
      selected: [],
      include: true,
    },
    propertyTypes: {
      include: true,
      selected: [],
      ...defaultSelectedMetrics,
    },
  },
};

export const AppliedFiltersProvider = ({ children, ...rest }) => {
  const { toggleAllPublisherIdsOff } = useSelectedPublishers();

  const [
    rawAppliedFiltersInformation,
    setRawAppliedFiltersInformation,
  ] = useState({});

  const [appliedFilters, setAppliedFilters] = useState(defaultFilters);
  const {
    currentAppliedFiltersInformation,
  } = getCurrentAppliedFiltersInformation(rawAppliedFiltersInformation);

  const updateAppliedFilters = (filters) => {
    setAppliedFilters(filters);
    setRawAppliedFiltersInformation(filters);
  };

  return (
    <AppliedFiltersContext.Provider
      value={{
        appliedFilters,
        rawAppliedFiltersInformation,
        appliedFiltersInformation: currentAppliedFiltersInformation,
        defaultFilters,
        updateAppliedFilters,
        reapplyFilters: R.pipe(
          () => setRawAppliedFiltersInformation(rawAppliedFiltersInformation),
          toggleAllPublisherIdsOff
        ),
        numberOfAppliedFilters: R.pipe(
          R.toPairs,
          R.filter(([_, { number }]) => number > 0),
          R.length
        )(currentAppliedFiltersInformation),
        isEmptyFilter:
          R.isEmpty(rawAppliedFiltersInformation) ||
          R.equals(appliedFilters, defaultFilters),
        providerInitialized: true,
        ...rest,
      }}
    >
      {children}
    </AppliedFiltersContext.Provider>
  );
};
