import { useQuery } from "@apollo/client";
import {
  useAppliedFilters,
  useClients,
  useExcludedPublisherIds,
  useFavoritedPublishers,
  useLocation,
  usePagination,
  usePublisherIdFilter,
  useSearchTerms,
  useSortBy,
} from "hooks";
import { GET_PUBLISHERS } from "mocks/queries/getPublishersGQL";
import { transformToGraphqlVariable } from "./transformToGraphqlVariable";

export const FIVE_MINUTES = 300000;

export const usePublishersSearchQuery = (
  { skip = false } = { skip: false }
) => {
  const { searchTerms } = useSearchTerms();
  const { rawAppliedFiltersInformation } = useAppliedFilters();
  const { data: excludedPublisherIds } = useExcludedPublisherIds();

  const { data: favoritePublisherIds } = useFavoritedPublishers();

  const { publisherIds: filteredPublishers } = usePublisherIdFilter();
  const { location } = useLocation();
  const { pathname: path } = location;

  const appliedFiltersGraphQLVariable = transformToGraphqlVariable({
    selectedFilters: rawAppliedFiltersInformation,
    excludedPublisherIds,
    favoritePublisherIds,
    filteredPublishers,
    path,
  });

  const { limit, offset } = usePagination();
  const { partnersClient } = useClients();
  const { sortBySelection } = useSortBy();

  const partnersReactQueryResponse = useQuery(GET_PUBLISHERS, {
    client: partnersClient,
    skip,
    variables: {
      searchTerms,
      filters: appliedFiltersGraphQLVariable,
      sortOption: sortBySelection.value,
      limit,
      offset,
    },
    pollInterval: FIVE_MINUTES,
  });

  return partnersReactQueryResponse;
};
