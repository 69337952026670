import React, { createContext } from "react";

export const ParamsContext = createContext({
  providerInitialized: false,
});

export const ParamsProvider = ({ children, params }) => {
  return (
    <ParamsContext.Provider value={{ params, providerInitialized: true }}>
      {children}
    </ParamsContext.Provider>
  );
};
