import { Drawer as VSDrawer } from "@cjdev-internal/visual-stack-x/components/Drawer";
import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { useModal } from "@cjdev-internal/visual-stack-x/Modal.js";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { Button } from "@cjdev-internal/visual-stack-x/components/Button";
import { MDIcon } from "@cjdev-internal/visual-stack-x/components/MDIcon";
import { ButtonWithDropdown } from "components/ButtonWithDropdown";
import { Link } from "components/Link";
import { SharePublishersButton } from "components/SharePublishersButton";
import { Tooltip } from "components/Tooltip";
import { publisherProfilePath } from "constants/paths";
import {
  useAlerts,
  useAppliedFilters,
  useExcludedPublisherIds,
  useIntl,
  useSelectedPublishers,
  useUser,
} from "hooks";
import * as R from "ramda";
import React, { useState } from "react";
import { userCanMakeOffer } from "utils/authorizationUtils";
import { OfferModal } from "../../OfferModal";
import homeMessages from "../messages";
import { ExportPublishersCSVButton } from "../PartnersTable/ExportPublishersCSVButton";
import messages from "./messages";
import "./styles.css";

export const NumberOfSelectedPublisherDropup = () => {
  const intl = useIntl();
  const {
    selectedPublisherIds,
    selectedPublishers,
    togglePublishersOff,
    toggleAllPublisherIdsOff,
  } = useSelectedPublishers();
  const [expanded, setExpanded] = useState(false);

  const renderDropupButton = ({ children, expanded, ...props }) => (
    <Button
      {...props}
      uppercase={false}
      type="tertiary"
      className="selected-publishers-button-with-dropup"
    >
      <Box direction="row" justify="space-between">
        {children}
        <Text className="drawer-expand-icon">
          {!expanded ? (
            <MDIcon icon="chevron-down" size={16} inline />
          ) : (
            <MDIcon icon="chevron-up" size={16} inline />
          )}
        </Text>
      </Box>
    </Button>
  );

  const buttonContent = (selectedPublisherIds) => {
    return (
      <>
        {R.length(selectedPublisherIds) === 1 && (
          <Text className="drawer-text">
            {R.length(selectedPublisherIds)}{" "}
            {intl.formatMessage(messages.partnerSelected)}
          </Text>
        )}
        {R.length(selectedPublisherIds) > 1 && (
          <Text className="drawer-text">
            {R.length(selectedPublisherIds)}{" "}
            {intl.formatMessage(messages.partnersSelected)}
          </Text>
        )}
      </>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <ButtonWithDropdown
        expanded={expanded}
        doExpand={() => setExpanded(!expanded)}
        direction="up"
        renderButton={renderDropupButton}
        buttonContent={buttonContent(selectedPublisherIds)}
        className="selected-publishers-dropup"
        id="selected-publishers-dropup"
      >
        <Box
          padding="large"
          direction="column"
          gap="medium"
          className="drop-up-box"
        >
          {R.map(
            (selectedPublisher) => (
              <Box
                direction="row"
                key={selectedPublisher.id}
                justify="space-between"
                align="center"
              >
                <Box>
                  <Link
                    aria-label={`navigate to publisher: ${selectedPublisher.id} profile`}
                    target="_blank"
                    to={{
                      pathname: publisherProfilePath(selectedPublisher.id),
                    }}
                  >
                    <Text
                      id={selectedPublisher.id}
                      className="selected-publisher-name"
                    >
                      {selectedPublisher.name}
                      <MDIcon
                        icon="launch"
                        className="publisher-name-launch-icon"
                      />
                    </Text>
                  </Link>
                </Box>
                <Box>
                  <Button
                    className="remove-btn"
                    type="tertiary"
                    onClick={() => {
                      togglePublishersOff([selectedPublisher]);
                      setExpanded(!expanded);
                    }}
                  >
                    {intl.formatMessage(messages.remove)}
                  </Button>
                </Box>
              </Box>
            ),
            selectedPublishers
          )}
        </Box>
      </ButtonWithDropdown>
      <Box direction="row" align="center" gap="xl" paddingLeft="large">
        <Button
          type="tertiary"
          className="remove-btn"
          onClick={() => {
            toggleAllPublisherIdsOff();
            if (expanded) {
              setExpanded(false);
            }
          }}
        >
          {intl.formatMessage(messages.unselectAll)}
        </Button>
      </Box>
    </div>
  );
};

const ModalContent = ({ closeModal }) => {
  const { reapplyFilters } = useAppliedFilters();
  const { selectedPublisherIds } = useSelectedPublishers();
  const { refetch: refetchExcludedPublisherIds } = useExcludedPublisherIds();

  return (
    <OfferModal
      onClose={closeModal}
      onMultipleOfferSuccess={() => {
        refetchExcludedPublisherIds();
        reapplyFilters();
      }}
      publisherIds={selectedPublisherIds}
      onOfferMade={() => {}}
    />
  );
};

const MakeOfferButton = ({ openModal }) => {
  const intl = useIntl();
  return (
    <Button
      aria-label="make offers"
      data-testid="make-offers"
      type="primary"
      className="make-offer-button"
      onClick={openModal}
    >
      {intl.formatMessage(homeMessages.makeOffer)}
    </Button>
  );
};

const ExportButton = ({ toggleWarningAlert }) => {
  const { isSelectingPublishers, selectedPublishers } = useSelectedPublishers();

  return (
    <ExportPublishersCSVButton
      publishers={selectedPublishers}
      disabled={!isSelectingPublishers}
      toggleWarningAlert={toggleWarningAlert}
    />
  );
};

export const Drawer = ({ favorite }) => {
  const intl = useIntl();
  const {
    isSelectingPublishers,
    selectedPublisherIds,
    selectedPublishers,
  } = useSelectedPublishers();
  const { user } = useUser();
  const { toastMount, toggleSuccessAlert, toggleWarningAlert } = useAlerts();
  const [mount, openModal, closeModal] = useModal();

  const openOfferModal = () =>
    openModal(<ModalContent closeModal={closeModal} />);
  const maybeMakeOfferButton = userCanMakeOffer(user) && (
    <MakeOfferButton openModal={openOfferModal} />
  );

  return (
    <VSDrawer isOpen={isSelectingPublishers}>
      {mount}
      {toastMount}
      <Box
        direction="row"
        align="center"
        justify="space-between"
        padding="large"
        paddingLeft="xl"
        paddingRight="xl"
      >
        <Box>
          <NumberOfSelectedPublisherDropup />
        </Box>
        <Box direction="row" align="center" gap="large">
          <Box direction="row" align="center" gap="medium">
            {favorite && favorite({ selectedPublishers })}
            <Tooltip
              className="download-csv-tooltip"
              content={intl.formatMessage(messages.downloadCSVToolTip)}
            >
              <ExportButton toggleWarningAlert={toggleWarningAlert} />
            </Tooltip>
            <Tooltip content={intl.formatMessage(messages.shareTooltip)}>
              <SharePublishersButton
                publisherIds={selectedPublisherIds}
                openModal={openModal}
                closeModal={closeModal}
                toggleAlert={toggleSuccessAlert}
              />
            </Tooltip>
          </Box>
          {maybeMakeOfferButton}
        </Box>
      </Box>
    </VSDrawer>
  );
};
