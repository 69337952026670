import { useQuery } from "@tanstack/react-query";
import { authenticatedFetch } from "utils/clients";

const fetchCommissionsByCountry = (publisherId) => async () => {
  const response = await authenticatedFetch(
    `${process.env.REACT_APP_MEMBER_URL}/affapi/publisher/${publisherId}/commissionsByCountry`
  );
  return response.json();
};

export const useCommissionsByCountry = (publisherId) => {
  return useQuery(
    ["fetch commissions by country"],
    fetchCommissionsByCountry(publisherId)
  );
};
