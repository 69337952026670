import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import Case from "case";
import { NULL_STATE_METRIC_PLACEHOLDER } from "constants/metrics";
import { useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React from "react";
import "../styles.css";

export const DateMetric = ({ name, value }) => {
  const intl = useIntl();
  return (
    <Box
      align="center"
      className="social-stat date-metric"
      data-testid={name}
      direction="column"
      gap="xs"
      key={name}
    >
      <Text weight="medium" size={18} className="social-stat-center">
        {R.isNil(value)
          ? NULL_STATE_METRIC_PLACEHOLDER
          : intl.formatDate(value, {
              month: "long",
              day: "2-digit",
              year: "numeric",
            })}
      </Text>
      <Text size={12} className="social-stat-center">
        {globalMessages[name]
          ? Case.capital(intl.formatMessage(globalMessages[name]))
          : name}
      </Text>
    </Box>
  );
};
