import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Spinner } from "components/Spinner";
import React, { useEffect, useState } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { authenticatedFetch } from "utils/clients";

export const PrivateRoutes = ({ children, ...rest }) => {
  const [authenticated, setAuthenticated] = useState();
  const location = useLocation();
  useEffect(() => {
    async function verifyToken() {
      try {
        const { ok } = await authenticatedFetch(
          `https://members.cj.com/affapi/token/verify`
        );
        setAuthenticated(ok);
      } catch {
        setAuthenticated(false);
      }
    }
    verifyToken();
  }, []);

  if (authenticated === undefined) {
    return (
      <Box align="center">
        <Spinner />
      </Box>
    );
  }

  if (!authenticated) {
    const { pathname, search } = location;
    return (
      <Redirect
        to={{ pathname: "/login", state: { path: `${pathname}${search}` } }}
      />
    );
  }

  return <Route {...rest}>{children}</Route>;
};
