import React from "react";
import { AppliedFiltersProvider } from "./AppliedFiltersContext";
import { ClientsProvider } from "./ClientsContext";
import { IntlProvider } from "./IntlContext";
import { MatchingPropertiesProvider } from "./MatchingPropertiesContext";
import { PaginationProvider } from "./PaginationContext";
import { SelectedPublishersProvider } from "./ViewContext/SelectedPublishersContext";
import { UserProvider } from "./ViewContext/UserContext";

export const AppProvider = ({ children, ...rest }) => (
  <IntlProvider>
    <ClientsProvider {...rest}>
      <MatchingPropertiesProvider>
        <UserProvider user={{}}>
          <PaginationProvider {...rest}>
            <SelectedPublishersProvider>
              <AppliedFiltersProvider {...rest}>
                {children}
              </AppliedFiltersProvider>
            </SelectedPublishersProvider>
          </PaginationProvider>
        </UserProvider>
      </MatchingPropertiesProvider>
    </ClientsProvider>
  </IntlProvider>
);
