import * as R from "ramda";

export const postUnfavoriteBuilder = ({
  unfavoritedPublishersRef,
  setPublisherVisibility,
  setIndexedPublishers,
  produceNewFavorites,
  togglePublishersOff,
  toggleAlert,
  postUnfavorite,
}) => (publishers) => {
  const publisherIds = R.pluck("id")(publishers);
  unfavoritedPublishersRef.current = publisherIds;
  setPublisherVisibility(publisherIds);
  setIndexedPublishers(produceNewFavorites(publisherIds));
  togglePublishersOff(publishers);
  toggleAlert(publishers, R.head(publishers));
  return postUnfavorite(publisherIds);
};
