import { DEFAULT_PATH } from "constants/paths";
import cookies from "js-cookie";
import { useEffect } from "react";

export const OAuthCallback = ({ clientOAuth2 }) => {
  useEffect(() => {
    async function oAuthCallback() {
      try {
        const { data } = await clientOAuth2.token.getToken(
          window.location.href
        );
        const { companyId, redirectUrl } = JSON.parse(atob(data.state));

        if (companyId) {
          cookies.set("company_id", companyId);
        } else {
          cookies.remove("company_id");
        }

        window.location.href = redirectUrl || DEFAULT_PATH;
      } catch {
        window.location.href = DEFAULT_PATH;
      }
    }
    oAuthCallback();
  }, [clientOAuth2]);

  return null;
};
