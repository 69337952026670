const {
  cleanNumberStringToInt,
  formatIntToStringWithCommas,
} = require("utils/utils");

export const formatDisplayForTextInput = (
  value,
  textPlaceholderForMaxThreshold
) => {
  switch (value) {
    case Infinity:
      return textPlaceholderForMaxThreshold;
    case 0:
      return "0";
    default:
      return formatIntToStringWithCommas(value);
  }
};

export const parseInputForRangeSlider = (
  displayValue,
  textPlaceholderForMaxThreshold
) => {
  const newIntValue = cleanNumberStringToInt(displayValue);

  if (newIntValue >= 1000000000000000) {
    return 1000000000000000;
  }

  switch (displayValue) {
    case textPlaceholderForMaxThreshold:
      return Infinity;
    case "":
      return 0;
    default:
      return newIntValue;
  }
};
