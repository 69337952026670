import { defineMessages } from "react-intl";

export default defineMessages({
  desktop: {
    id: "partners.DeviceDistributionChart.desktop",
    defaultMessage: "Desktop",
  },
  mobile: {
    id: "partners.DeviceDistributionChart.mobile",
    defaultMessage: "Mobile",
  },
});
