import {
  ReactRouterDom as platformReactRouterDom,
  useLocation as usePlatformLocation,
  useNavigate as usePlatformNavigate,
  useParams as usePlatformParams,
} from "@platform/cj-platform-navigation";
import React from "react";
import { HistoryProvider } from "./HistoryContext";
import { LocationProvider } from "./LocationContext";
import { ParamsProvider } from "./ParamsContext";
import { UserProvider } from "./UserContext";

export const PlatformNavigationProvider = ({ children, user }) => {
  const location = usePlatformLocation();
  const params = usePlatformParams();
  const history = usePlatformNavigate();
  const navigationType = platformReactRouterDom.useNavigationType();

  return (
    <UserProvider user={user}>
      <LocationProvider location={location}>
        <ParamsProvider params={params}>
          <HistoryProvider historyConfiguration={{ history, navigationType }}>
            {children}
          </HistoryProvider>
        </ParamsProvider>
      </LocationProvider>
    </UserProvider>
  );
};
