import { shouldDisplay2022Nav } from "@platform/cj-platform-navigation";
import React from "react";
import ReactDOM from "react-dom";
import { LegacyNavigationApp, Nav2022App } from "./App";

// temp suppressing intl errors --------------
// eslint-disable-next-line
const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (message, ...args) => {
  if (message.code === "MISSING_TRANSLATION") {
    return;
  }
  consoleError(message, ...args);
};
// ---------------------------------------------

const memberDomain = process.env.REACT_APP_MEMBER_DOMAIN;

shouldDisplay2022Nav(memberDomain).then((display2022Nav) => {
  const root = document.getElementById("root");
  display2022Nav
    ? ReactDOM.render(<Nav2022App />, root)
    : ReactDOM.render(<LegacyNavigationApp />, root);
});
