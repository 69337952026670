import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { ExternalLink } from "components/ExternalLink";
import {
  GenerateSimilarwebData,
  getDataById,
} from "components/GenerateSimilarwebData";
import { Link } from "components/Link";
import { Spinner } from "components/Spinner";
import { TruncatedText } from "components/TruncatedText";
import { WebsiteIcon } from "components/WebsiteIcon";
import { publisherProfilePropertyDetailsPath } from "constants/paths";
import {
  useFeatures,
  useIntl,
  useLocation,
  USER_CAN_AUTHENTICATE_IN_CIQ,
  useTopWebsitesQuery,
} from "hooks";
import * as R from "ramda";
import React from "react";
import { isNullOrEmpty } from "utils/utils";
import {
  getFormattedMonthlyDesktopVisitors,
  getFormattedMonthlyMobileVisitors,
  getFormattedMonthlyTotalVisitors,
  getFormattedWebsiteUrl,
  getWebsiteUrl,
} from "utils/webUtils";
import {
  NestedTable,
  NestedTableBody,
  NestedTd,
  NestedTh,
  NestedTr,
} from "views/PublishersSearch/PartnersTable/MatchingProperties/NestedTable";
import messages from "./messages";
import "./styles.css";

export const TopWebsiteProperties = ({ publisherId }) => {
  const intl = useIntl();
  const { location } = useLocation();
  const { search } = location;
  const { hasFeature } = useFeatures();
  const hasUserCanAuthenticateInCiqFeature = hasFeature(
    USER_CAN_AUTHENTICATE_IN_CIQ
  );

  const { data, error, loading } = useTopWebsitesQuery(publisherId);
  const properties = data?.promotionalProperties?.resultList;

  if (loading) {
    return (
      <Box align="center">
        <Spinner />
      </Box>
    );
  }

  if (error || isNullOrEmpty(properties)) {
    return null;
  }

  return (
    <GenerateSimilarwebData
      properties={properties}
      calledFrom="profile"
      publisherId={publisherId}
      similarwebDependantComponent={({ similarwebData }) => {
        const propertiesWithMetrics = R.filter(
          (property) => similarwebData[property.id]?.totalMonthlyVisitors,
          properties
        );

        if (isNullOrEmpty(propertiesWithMetrics)) {
          return null;
        }

        return (
          <Box direction="column" gap="medium">
            <Text weight="medium" size={14}>
              {intl.formatMessage(messages.title)}
            </Text>

            <NestedTable className="top-websites-overview-table">
              <NestedTableBody>
                <NestedTr>
                  <NestedTh>{intl.formatMessage(messages.websiteUrl)}</NestedTh>
                  {hasUserCanAuthenticateInCiqFeature && (
                    <NestedTh className="nested-th-authentication-status"></NestedTh>
                  )}
                  <NestedTh>{intl.formatMessage(messages.totalReach)}</NestedTh>
                  <NestedTh>
                    {intl.formatMessage(messages.desktopReach)}
                  </NestedTh>
                  <NestedTh>
                    {intl.formatMessage(messages.mobileReach)}
                  </NestedTh>
                  <NestedTh></NestedTh>
                </NestedTr>
                {propertiesWithMetrics.map((property) => {
                  const websiteUrl = getWebsiteUrl(property);
                  const domain = getFormattedWebsiteUrl(property, intl);
                  const website = getDataById(similarwebData, property.id);

                  const websiteReach = getFormattedMonthlyTotalVisitors(
                    website,
                    intl
                  );
                  const desktopReach = getFormattedMonthlyDesktopVisitors(
                    website,
                    intl
                  );
                  const mobileReach = getFormattedMonthlyMobileVisitors(
                    website,
                    intl
                  );

                  return (
                    <NestedTr key={property.id}>
                      <NestedTd className="top-websites-overview-left">
                        <Box direction="row" gap="small">
                          <WebsiteIcon
                            size={18}
                            className="top-websites-icon"
                          />
                          {websiteUrl ? (
                            <ExternalLink
                              path={websiteUrl}
                              title={websiteUrl}
                              text={
                                <TruncatedText
                                  type="link"
                                  limit={15}
                                  className="top-websites-overview-table-text"
                                >
                                  {domain}
                                </TruncatedText>
                              }
                            />
                          ) : (
                            <TruncatedText
                              limit={15}
                              className="top-websites-overview-table-text"
                            >
                              {domain}
                            </TruncatedText>
                          )}
                        </Box>
                      </NestedTd>
                      {hasUserCanAuthenticateInCiqFeature && (
                        <NestedTd className="top-websites-overview-left" />
                      )}
                      <NestedTd className="top-websites-overview-left">
                        <Text className="top-websites-overview-table-text">
                          {websiteReach}
                        </Text>
                      </NestedTd>
                      <NestedTd className="top-websites-overview-left">
                        <Text className="top-websites-overview-table-text">
                          {desktopReach}
                        </Text>
                      </NestedTd>
                      <NestedTd className="top-websites-overview-left">
                        <Text className="top-websites-overview-table-text">
                          {mobileReach}
                        </Text>
                      </NestedTd>
                      <NestedTd className="top-websites-overview-right">
                        <Link
                          className="vsx-link top-websites-overview-table-text"
                          to={{
                            pathname: publisherProfilePropertyDetailsPath(
                              publisherId,
                              property.id
                            ),
                            search: search,
                          }}
                        >
                          {intl.formatMessage(messages.viewDetails)}
                        </Link>
                      </NestedTd>
                    </NestedTr>
                  );
                })}
              </NestedTableBody>
            </NestedTable>
          </Box>
        );
      }}
    />
  );
};
