import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { RangeSlider } from "components/RangeSlider";
import { useIntl } from "hooks";
import globalMessages from "messages/globalMessages";
import React from "react";
import "./styles.css";
import {
  getMetricValues,
  updateMaxBoundary,
  updateMinBoundary,
} from "./utils/reachMetricUtils";

export const PropertyReachMetricFilter = ({
  platform,
  metric,
  filters,
  setFilters,
}) => {
  const intl = useIntl();
  const steps = [
    0,
    1000,
    5000,
    10000,
    25000,
    50000,
    100000,
    250000,
    500000,
    750000,
    1000000,
  ];

  const { minValue, maxValue } = getMetricValues(filters, platform, metric);

  const onMinUpdate = (newValue) => {
    updateMinBoundary(setFilters, platform, metric, newValue);
  };

  const onMaxUpdate = (newValue) => {
    updateMaxBoundary(setFilters, platform, metric, newValue);
  };

  return (
    <div>
      <Box paddingTop="medium" paddingBottom="medium">
        <Text>
          {intl.formatMessage(globalMessages["monthlyTotalVisitorsTitle"])}
        </Text>
      </Box>
      <RangeSlider
        steps={steps}
        textPlaceholderForMaxThreshold="1M+"
        minValue={minValue}
        maxValue={maxValue}
        onMinUpdate={onMinUpdate}
        onMaxUpdate={onMaxUpdate}
        identifier={platform}
      />
    </div>
  );
};
