import {
  useAlerts,
  useFavoritedPublishers,
  useIntl,
  usePublishersSearchQuery,
  useRef,
  useSelectedPublishers,
} from "hooks";
import React, { useEffect } from "react";
import {
  checkFavoritePublishers,
  isNullOrEmpty,
  isPublisherHiddenFunctionBuilder,
  modifyFunctionToAcceptObjectsInsteadOfIds,
  returnTrue,
  setPublisherVisibilityBuilder,
} from "utils/utils";
import {
  useHiddenIdMapState,
  useIndexedPublishersResponseState,
} from "views/PublishersSearch/PartnersTable/FavoritePartnersTableState";
import {
  produceNewFavoritesBuilder,
  produceUndoPublishersBuilder,
} from "views/PublishersSearch/PartnersTable/helpers/producePublishersBuilders";
import messages from "../messages";
import { FavoritesEmptySlate } from "./FavoritesEmptySlate";
import { postUnfavoriteBuilder } from "./helpers/postUnfavoriteBuilder";
import { toggleAlertBuilder } from "./helpers/toggleAlertBuilder";
import { PartnersTableView } from "./PartnersTableView";
import "./styles.css";

export const FavoritePartnersTable = () => {
  const intl = useIntl();
  const alerts = useAlerts();
  const unfavoriteAlert = useRef();
  const unfavoritedPublishersRef = useRef([]);

  const {
    data: favoritePublishers,
    error: favoritePublishersError,
    isLoading: isLoadingFavoritePublishers,
    postFavorite,
    postUnfavorite,
  } = useFavoritedPublishers();

  const [hiddenIdMap, setHiddenIdMap] = useHiddenIdMapState({});

  const isPublisherHidden = isPublisherHiddenFunctionBuilder(hiddenIdMap);

  const { togglePublishersOff } = useSelectedPublishers();
  const shouldRenderEmptySlate = isNullOrEmpty(favoritePublishers);

  const partnersReactQueryResponse = usePublishersSearchQuery();

  const [
    indexedPublisherResponse,
    setIndexedPublishers,
  ] = useIndexedPublishersResponseState(partnersReactQueryResponse);

  useEffect(() => {
    setIndexedPublishers(partnersReactQueryResponse);
  }, [partnersReactQueryResponse, setIndexedPublishers]);

  const produceNewFavorites = produceNewFavoritesBuilder(hiddenIdMap);
  const produceUndoPublishers = produceUndoPublishersBuilder(hiddenIdMap);

  const setPublisherVisibility = setPublisherVisibilityBuilder(setHiddenIdMap);

  const toggleAlert = toggleAlertBuilder({
    unfavoriteAlert,
    setHiddenIdMap,
    toggleSuccessAlert: alerts.toggleSuccessAlert,
    unfavoritedPublishersRef,
    setIndexedPublishers,
    produceUndoPublishers,
    postFavorite,
  });

  const { toggleWarningAlert } = alerts;

  useEffect(() => {
    if (favoritePublishersError) {
      toggleWarningAlert(intl.formatMessage(messages.favoritesRetreivalAlert));
    }
  }, [favoritePublishersError, toggleWarningAlert, intl]);

  return (
    <>
      {alerts.toastMount}
      <PartnersTableView
        noResultSlate={<FavoritesEmptySlate />}
        emptySlate={<FavoritesEmptySlate />}
        checkFavoritePublisher={returnTrue}
        checkFavoritePublishers={checkFavoritePublishers}
        favoritePublishers={favoritePublishers}
        hiddenPublishersIdsMap={hiddenIdMap}
        isPublisherHidden={isPublisherHidden}
        isLoading={
          indexedPublisherResponse.loading || isLoadingFavoritePublishers
        }
        partnersReactQueryResponse={indexedPublisherResponse}
        postFavorite={modifyFunctionToAcceptObjectsInsteadOfIds(postFavorite)}
        postUnfavorite={postUnfavoriteBuilder({
          unfavoritedPublishersRef,
          setPublisherVisibility,
          setIndexedPublishers,
          produceNewFavorites,
          togglePublishersOff,
          toggleAlert,
          postUnfavorite,
        })}
        shouldRenderEmptySlate={shouldRenderEmptySlate}
      />
    </>
  );
};
