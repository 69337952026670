import React, { createContext } from "react";
import { accountsClient, partnersClient } from "utils/clients";

export const ClientsContext = createContext({
  providerInitialized: false,
});

export const ClientsProvider = ({ children, ...rest }) => {
  return (
    <ClientsContext.Provider
      value={{
        partnersClient,
        accountsClient,
        providerInitialized: true,
        ...rest,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};
