import { useQuery } from "@tanstack/react-query";
import * as R from "ramda";
import { authenticatedFetch } from "utils/clients";
import { removeUnprioritizedCountries } from "utils/utils";
import { useUser } from "hooks";

export const useCountriesQuery = () => {
  const { user } = useUser();
  const language = user.language;

  return useQuery(["countries", language], async () => {
    const response = await authenticatedFetch(
      `${process.env.REACT_APP_MEMBER_URL}/member/api/country?language=${language}`
    );
    const countries = await response.json();

    return R.pipe(
      R.filter((country) => !R.isNil(country.region)),
      R.map(removeUnprioritizedCountries)
    )(countries);
  });
};
