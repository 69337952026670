import { Clickable } from "@cjdev-internal/visual-stack-x/Clickable";
import { Tree, useTree } from "@cjdev-internal/visual-stack-x/Tree";
import { Row } from "@cjdev-internal/visual-stack-x/components/Row";
import { Stack } from "@cjdev-internal/visual-stack-x/components/Stack";
import { ChoiceInput } from "@cjdev-internal/visual-stack-x/legacy/Form";
import {
  TIKTOK_METRICS,
  selectActingCompany,
  useFeatures,
  useIntl,
} from "hooks";
import produce from "immer";
import React, { useEffect } from "react";
import messages from "../../messages";
import "./styles.css";
import { usePropertyTypesTreeSelector } from "./usePropertyTypesTreeSelector";
import * as R from "ramda";
import { useCallback } from "react";

export const PropertyTypesFilter = ({ filters, setFilters, user }) => {
  const intl = useIntl();
  const companyId = selectActingCompany(user).id;
  const { hasFeature } = useFeatures(companyId);
  const isTikTokMetricsFeatureEnabled = hasFeature(TIKTOK_METRICS);
  const treeSelector = usePropertyTypesTreeSelector(
    isTikTokMetricsFeatureEnabled
  );
  const treeProps = useTree(treeSelector);
  const { selected, setSelected } = treeProps;

  useEffect(() => {
    setFilters((previousFilters) =>
      produce(previousFilters, (draftFilters) => {
        const draftPropertyTypes = draftFilters.propertyLevel.propertyTypes;

        draftPropertyTypes.selectedMetrics.website.platformSelected = !!selected.WEBSITE;
        draftPropertyTypes.selectedMetrics.instagram.platformSelected = !!selected.INSTAGRAM;
        draftPropertyTypes.selectedMetrics.facebook.platformSelected = !!selected.FACEBOOK;
        draftPropertyTypes.selectedMetrics.twitter.platformSelected = !!selected.TWITTER;
        draftPropertyTypes.selectedMetrics.twitch.platformSelected = !!selected.TWITCH;
        draftPropertyTypes.selectedMetrics.pinterest.platformSelected = !!selected.PINTEREST;
        draftPropertyTypes.selectedMetrics.snapchat.platformSelected = !!selected.SNAPCHAT;
        draftPropertyTypes.selectedMetrics.youtube.platformSelected = !!selected.YOUTUBE;
        draftPropertyTypes.selectedMetrics.whatsapp.platformSelected = !!selected.WHATSAPP;
        draftPropertyTypes.selectedMetrics.tiktok.platformSelected = !!selected.TIKTOK;

        const draftSelected = [];

        if (selected.EMAIL) {
          draftSelected.push({ type: "EMAIL" });
        }

        if (selected.MOBILE_APP) {
          const type = { type: "MOBILE_APP", mobileAppPlatform: [] };
          if (selected.IOS) {
            type.mobileAppPlatform.push("IOS");
          }
          if (selected.ANDROID) {
            type.mobileAppPlatform.push("ANDROID");
          }
          draftSelected.push(type);
        }

        if (selected.BROWSER_EXTENSION) {
          const type = {
            type: "BROWSER_EXTENSION",
            browserExtensionBrowser: [],
          };
          if (selected.CHROME) {
            type.browserExtensionBrowser.push("CHROME");
          }
          if (selected.FIREFOX) {
            type.browserExtensionBrowser.push("FIREFOX");
          }
          if (selected.SAFARI) {
            type.browserExtensionBrowser.push("SAFARI");
          }
          if (selected.OPERA) {
            type.browserExtensionBrowser.push("OPERA");
          }
          draftSelected.push(type);
        }

        if (selected.SERVICES_AND_TOOLS) {
          draftSelected.push({ type: "SERVICES_AND_TOOLS" });
        }

        if (selected.PAID_SEARCH_ENGINE) {
          draftSelected.push({ type: "PAID_SEARCH_ENGINE" });
        }

        if (selected.PAID_DISPLAY_ADS) {
          draftSelected.push({ type: "PAID_DISPLAY_ADS" });
        }

        if (selected.OTHER) {
          draftSelected.push({ type: "OTHER" });
        }

        draftPropertyTypes.selected = draftSelected;
      })
    );
  }, [selected, setFilters]);

  const setInclude = (include) => {
    setFilters((previousFilters) =>
      produce(previousFilters, (draftFilters) => {
        draftFilters.propertyLevel.propertyTypes.include = include;
      })
    );
  };

  const selectAll = useCallback(() => {
    setSelected(treeSelector.fullSelection());
  }, [setSelected, treeSelector]);

  const unselectAll = useCallback(() => {
    setSelected(treeSelector.emptySelection());
  }, [setSelected, treeSelector]);

  const selectedMetrics = filters.propertyLevel.propertyTypes.selectedMetrics;
  const areFiltersEmpty =
    !selectedMetrics.website.platformSelected &&
    !selectedMetrics.instagram.platformSelected &&
    !selectedMetrics.facebook.platformSelected &&
    !selectedMetrics.twitter.platformSelected &&
    !selectedMetrics.twitch.platformSelected &&
    !selectedMetrics.pinterest.platformSelected &&
    !selectedMetrics.snapchat.platformSelected &&
    !selectedMetrics.youtube.platformSelected &&
    !selectedMetrics.whatsapp.platformSelected &&
    !selectedMetrics.tiktok.platformSelected &&
    R.isEmpty(filters.propertyLevel.propertyTypes.selected);

  useEffect(() => {
    if (areFiltersEmpty) {
      unselectAll();
    }
  }, [areFiltersEmpty, unselectAll]);

  return (
    <Stack gap="medium-large" className="property-types-container">
      <Row gap="large">
        <ChoiceInput
          data-testid="includePropertyType"
          name="includePropertyType"
          label={intl.formatMessage(messages.include)}
          value="include"
          checked={filters.propertyLevel.propertyTypes.include}
          onChange={() => setInclude(true)}
        />
        <ChoiceInput
          data-testid="excludePropertyType"
          name="excludePropertyType"
          label={intl.formatMessage(messages.exclude)}
          value="exclude"
          checked={!filters.propertyLevel.propertyTypes.include}
          onChange={() => setInclude(false)}
        />
      </Row>
      <Row gap="large">
        <Clickable onClick={selectAll} data-testid="tree-select-all">
          {intl.formatMessage(messages.selectAll)}
        </Clickable>
        <Clickable onClick={unselectAll} data-testid="tree-unselect-all">
          {intl.formatMessage(messages.unselectAll)}
        </Clickable>
      </Row>
      <Tree {...treeProps} />
    </Stack>
  );
};
