import { gql, useQuery } from "@apollo/client";
import { useClients, useSearchTerms } from "hooks";

export const usePropertyListQuery = (publisherId) => {
  const { partnersClient } = useClients();
  const { searchTerms } = useSearchTerms();

  return useQuery(gql(GET_INDEXED_PROMOTIONAL_PROPERTIES_QUERY), {
    client: partnersClient,
    variables: {
      publisherId,
      searchTerms,
    },
  });
};

export const GET_INDEXED_PROMOTIONAL_PROPERTIES_QUERY = `
  query topPromotionalProperties($publisherId: ID!, $searchTerms: String) {
    topPromotionalProperties(
      publisherId: $publisherId
      searchTerms: $searchTerms
    ) {
      resultList {
        id
        name
        details
        primaryPromotionalModel
        type
        authenticationStatus
        propertyTypeDetails {
          websiteUrl
          socialMediaHandle
          socialMediaPlatform
          socialMediaUrl
          emailAddress
          mobileAppName
          browserExtensionName
          browserExtensionDownloadInformation {
            browserExtensionBrowser
            browserExtensionDownloadLink
          }
          mobileAppDownloadInformation {
            mobileAppPlatform
            mobileAppDownloadLink
          }
          ciqMetrics {
            instagram {
              accountTotalImages
              followers
              engagementRate20Posts
              likesPerPost20Posts
              commentsPerPost20Posts
              lastPostDate
              engagementLabel
            }
            facebook {
              pageLikes
              postLikes
              postComments
              engagementRate20Posts
              likesPerPost20Posts
              commentsPerPost20Posts
              totalComments
              numberOfPosts
              lastPostDate
              engagementLabel
            }
            tiktok {
              followers
              lastPostDate
              totalLikes
              numberOfPosts
              engagementLabel
              likesPerPost20Posts
              commentsPerPost20Posts
              viewsPerPost20Posts
              engagementRate20Posts
            }
            twitter {
              accountLikesPerTweet
              accountTotalTweets
              followers
              engagementRate20Posts
              likesPerPost20Posts
              commentsPerPost20Posts
              sharesPerPost20Posts
              totalLikes
              lastPostDate
              engagementLabel
            }
            youtube {
              accountMonthlyViews
              accountTotalVideos
              accountTotalViews
              accountViewsPerVideo
              subscribers
              engagementRate20Posts
              likesPerPost20Posts
              commentsPerPost20Posts
              viewsPerPost20Posts
              dateLastVideoUploaded
              engagementLabel
            }
            twitch {
              accountAvgStreamViews
              accountViews
              followers
              averageVideoViews30Days
              viewsPerPost20Posts
              numberOfPosts
            }
            snapchat {
              followers
            }
            pinterest {
              accountAverageMonthlyViews
              accountPins
              followers
              engagementRate20Posts
              sharesPerPostLifetime
              lastPostDate
            }
          }
          webMetrics {
            metricSource
          }
        }
        isMatchingSearchTerm
      }
      totalCount
    }
  }
`;
