import { NoData } from "components/NoData";
import { PieChart } from "components/PieChart";
import { useIntl } from "hooks";
import React from "react";
import messages from "./messages";

export const DeviceDistributionChart = ({
  chartId,
  desktopVisitors,
  mobileVisitors,
}) => {
  const intl = useIntl();
  if (!desktopVisitors && !mobileVisitors) {
    return <NoData />;
  }

  const deviceDistribution = convertToPieChartFormat(
    desktopVisitors,
    mobileVisitors,
    intl
  );

  return <PieChart chartId={chartId} data={deviceDistribution} />;
};

const convertToPieChartFormat = (desktopVisitors, mobileVisitors, intl) => {
  const pieChartValues = [];

  if (desktopVisitors) {
    pieChartValues.push({
      category: intl.formatMessage(messages.desktop),
      value: desktopVisitors,
    });
  }

  if (mobileVisitors) {
    pieChartValues.push({
      category: intl.formatMessage(messages.mobile),
      value: mobileVisitors,
    });
  }

  return pieChartValues;
};
