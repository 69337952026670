import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "partners.TopWebsiteProperties.title",
    defaultMessage: "Top Websites",
  },
  websiteUrl: {
    id: "partners.TopWebsiteProperties.websiteUrl",
    defaultMessage: "Website URL",
  },
  totalReach: {
    id: "partners.TopWebsiteProperties.totalReach",
    defaultMessage: "Reach",
  },
  desktopReach: {
    id: "partners.TopWebsiteProperties.desktopReach",
    defaultMessage: "Desktop",
  },
  mobileReach: {
    id: "partners.TopWebsiteProperties.mobileReach",
    defaultMessage: "Mobile Web",
  },
  viewDetails: {
    id: "partners.TopWebsiteProperties.viewDetails",
    defaultMessage: "View details",
  },
});
