import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Clickable } from "@cjdev-internal/visual-stack-x/Clickable";
import { MDIcon } from "@cjdev-internal/visual-stack-x/components/MDIcon";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { useCompany, useIntl } from "hooks";
import * as R from "ramda";
import React from "react";
import { authenticatedFetch } from "utils/clients";
import messages from "./messages";

export const Attachments = ({ attachments = [] }) => {
  const intl = useIntl();
  const company = useCompany();

  const profileAttachments = attachments.filter(
    (attachment) =>
      attachment.fileIsAvailable && attachment.type === "profileAttachment"
  );

  return (
    <Box direction="column" gap="small">
      <Text weight="medium" size={14}>
        {intl.formatMessage(messages.documents)}
      </Text>
      {R.isEmpty(profileAttachments) ? (
        <Text color="secondary" italic>
          {intl.formatMessage(messages.noDocuments)}
        </Text>
      ) : (
        profileAttachments.map((attachment) => (
          <Box
            direction="row"
            key={attachment.guid}
            justify="start"
            gap="small"
          >
            <Clickable
              data-testid="download-button"
              gap="small"
              onClick={() => {
                downloadAttachment(attachment.guid, company.id);
              }}
            >
              <MDIcon
                icon="download"
                align="middle"
                color="var(--brownish-grey)"
                size={20}
                style={{ paddingRight: "6px" }}
              />
              <Text color="link">{attachment.fileName}</Text>
            </Clickable>
          </Box>
        ))
      )}
    </Box>
  );
};

const downloadAttachment = async (attachmentId, visibleTo) => {
  const response = await authenticatedFetch(
    `${process.env.REACT_APP_MEMBER_URL}/affapi/attachments/${attachmentId}?visibleTo=${visibleTo}`
  );
  const blob = await response.blob();
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl, "_blank");
};
