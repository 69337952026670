import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Footer } from "components/Footer";
import { Layout } from "components/Layout";
import { useNav2022 } from "hooks";
import React, { useCallback, useState } from "react";
import { SlidingPanel } from "./FiltersPanel/SlidingPanel";
import { Header } from "./Header";
import { PartnersTable } from "./PartnersTable";
import "./styles.css";

const PublishersSearchView = ({ partnersTable }) => {
  const { nav2022 } = useNav2022();
  const [isSlidingPanelActive, setSlidingPanelActive] = useState(true);

  const onHeaderClick = useCallback(() => {
    setSlidingPanelActive((active) => !active);
  }, [setSlidingPanelActive]);

  return (
    <Box direction="row" className="publishers-search-container">
      <Box direction="column" expand>
        <Header onClick={onHeaderClick} />
        <Box
          direction="column"
          padding={nav2022 ? "none" : "xl"}
          expand
          className="partners-table-container"
        >
          {partnersTable}
          <Footer />
        </Box>
      </Box>
      {nav2022 ? undefined : <SlidingPanel isActive={isSlidingPanelActive} />}
    </Box>
  );
};

export const PublishersSearch = ({
  onCancel,
  partnersTable = <PartnersTable />,
}) => {
  const { nav2022 } = useNav2022();

  return nav2022 ? (
    <PublishersSearchView partnersTable={partnersTable} />
  ) : (
    <Layout onCancel={onCancel} contentSize="wide">
      <PublishersSearchView partnersTable={partnersTable} />
    </Layout>
  );
};
