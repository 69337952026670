export const produceStepMarkers = (steps) => ({
  maxThreshold: steps[steps.length - 1],
  secondFromTopStep: steps[steps.length - 2],
  secondFromBottomStep: steps[1],
});

export const closestStep = (steps, value) =>
  steps.reduce(function (prev, curr) {
    const distanceToCurrent = Math.abs(curr - value);
    const distanceToPrevious = Math.abs(prev - value);
    return distanceToCurrent < distanceToPrevious ? curr : prev;
  });

export const oneStepDown = (steps, value) => {
  const sortedAscSteps = steps.sort((a, b) => a - b);
  return sortedAscSteps.reduce((prev, curr) => (curr < value ? curr : prev));
};

export const oneStepUp = (steps, value) => {
  const sortedDescSteps = steps.sort((a, b) => b - a);
  return sortedDescSteps.reduce((prev, curr) => (curr > value ? curr : prev));
};
