import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { MDIcon } from "@cjdev-internal/visual-stack-x/components/MDIcon";
import { Popover } from "@cjdev-internal/visual-stack-x/components/Popover";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { NoData } from "components/NoData";
import { PieChart } from "components/PieChart";
import { useIntl } from "hooks";
import React, { useState } from "react";
import { formatCategoryPercentages, isNullOrEmpty } from "utils/utils";
import messages from "../../messages";

export const TopCategoryCell = ({ publisher }) => {
  const intl = useIntl();
  const [visible, setVisible] = useState(false);

  if (isNullOrEmpty(publisher.lastMonthCategoryCommissions)) {
    return <NoData size={12} />;
  }

  const categoryPercentages = formatCategoryPercentages(
    publisher.lastMonthCategoryCommissions,
    intl
  );

  return (
    <Popover
      placement="bottom"
      trigger="click"
      onShow={() => {
        setVisible(true);
      }}
      onHide={() => {
        setVisible(false);
      }}
      content={
        <Box direction="column" align="start" paddingLeft="large">
          <Text weight="medium" size={12}>
            {intl.formatMessage(messages.topCategories)}
          </Text>
          <PieChart
            chartId={`${publisher.id}-category`}
            data={categoryPercentages}
            visible={visible}
          />
        </Box>
      }
    >
      <Box direction="row" align="center">
        <Text
          className="publisher-list-category-name"
          weight="medium"
          size={12}
          data-testid={`top-category-${publisher.id}`}
        >
          {categoryPercentages[0].category}
        </Text>
        <MDIcon icon="chevron-down" color="var(--brownish-grey)" size={24} />
      </Box>
    </Popover>
  );
};
