import { useLocation } from "hooks";
import * as R from "ramda";
import { useEffect } from "react";

export const Login = ({ memberUrl }) => {
  const { location } = useLocation();

  useEffect(() => {
    const path = R.path(["state", "path"])(location);
    const pathQuery = path ? `&path=${encodeURIComponent(path)}` : "";
    const appName = process.env.REACT_APP_APP_NAME;

    window.location.href = `${memberUrl}/member/login/#/?appName=${appName}${pathQuery}`;
  }, [memberUrl, location]);

  return null;
};
