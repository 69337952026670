import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Input } from "@cjdev-internal/visual-stack-x/components/Input";
import { debounce } from "debounce";
import React, { useMemo, useState } from "react";
import "./styles.css";

export const SearchInput = ({ onChange, value, ...restProps }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const onDebouncedChange = useMemo(() => debounce(onChange, 500), [onChange]);

  return (
    <Box direction="row" align="center" gap="small">
      <Input
        {...restProps}
        type="text"
        aria-label="search"
        value={currentValue}
        className="search-input"
        onChange={(e) => {
          setCurrentValue(e.target.value);
          onDebouncedChange.clear();
          onDebouncedChange(e.target.value);
        }}
      />
    </Box>
  );
};
