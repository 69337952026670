import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { useIntl } from "hooks";
import React from "react";
import messages from "../messages";
import { DateMetric, EngagementMetric, NumericMetric } from "../metrics";
import { MetricsGrid } from "../MetricsGrid";

export const InstagramMetrics = ({ metrics }) => {
  const intl = useIntl();
  return (
    <Box direction="column" gap="xl">
      <MetricsGrid>
        <NumericMetric name="followers" value={metrics.followers} />
        <DateMetric name="lastPostDate" value={metrics.lastPostDate} />
        <NumericMetric
          name="accountTotalImages"
          value={metrics.accountTotalImages}
        />
      </MetricsGrid>
      <Box direction="column" gap="medium">
        <Text weight="medium" size={14}>
          {intl.formatMessage(messages.timePeriodLast20Posts)}
        </Text>
        <MetricsGrid>
          <EngagementMetric
            label={metrics.engagementLabel}
            rate={metrics.engagementRate20Posts}
            socialPlatform="instagram"
          />
          <NumericMetric
            name="likesPerPost20Posts"
            value={metrics.likesPerPost20Posts}
          />
          <NumericMetric
            name="commentsPerPost20Posts"
            value={metrics.commentsPerPost20Posts}
          />
        </MetricsGrid>
      </Box>
    </Box>
  );
};
