import { gql, useQuery } from "@apollo/client";
import { useClients } from "./useClients";

export const GET_AUTHENTICATED_SOCIAL_MEDIA_COUNT = `query indexedPublishers($publisherId: ID!) {
  indexedPublishers(
    filters: { publisherLevel: { publisherIdsToInclude: [$publisherId] } }
  ) {
    resultList {
      id
      searchTerms
      authenticatedSocialMediaCount
    }
  }
}`;

export const useAuthenticatedSocialMediaCount = (publisherId) => {
  const { partnersClient } = useClients();
  return useQuery(gql(GET_AUTHENTICATED_SOCIAL_MEDIA_COUNT), {
    client: partnersClient,
    variables: {
      publisherId,
    },
  });
};
