import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import { formatMetricNumber } from "./ciqUtils";

export const getWebsiteUrl = R.path(["propertyTypeDetails", "websiteUrl"]);

export const isWebsite = R.either(
  R.pathSatisfies(R.equals("WEBSITE"), ["type"]),
  R.pathSatisfies(R.equals("WEBSITE"), ["propertyTypeDetails", "type"])
);

export const websiteIdsFromProperties = R.pipe(
  R.filter(isWebsite),
  R.pluck("id")
);

export const createFormattedUniqueVisitorMetricFunction = (
  reachMetric,
  intl
) => {
  const reachMetricName = intl.formatMessage(
    globalMessages["monthlyUniqueVisitors"]
  );
  const formattedMetricNumber = formatMetricNumber(reachMetric);
  return `${formattedMetricNumber} ${reachMetricName}`;
};

export const getFormattedMonthlyDesktopVisitors = (similarWebData, intl) => {
  return createFormattedUniqueVisitorMetricFunction(
    R.prop("monthlyDesktopVisitors", similarWebData),
    intl
  );
};

export const getFormattedMonthlyMobileVisitors = (similarWebData, intl) => {
  return createFormattedUniqueVisitorMetricFunction(
    R.prop("monthlyMobileVisitors", similarWebData),
    intl
  );
};

export const getFormattedMonthlyTotalVisitors = (similarwebData, intl) => {
  const reachMetric = similarwebData.totalMonthlyVisitors;
  const reachMetricName = intl.formatMessage(
    globalMessages["monthlyTotalVisitors"]
  );
  return `${formatMetricNumber(reachMetric)} ${reachMetricName}`;
};

export const getFormattedWebsiteUrl = (property, intl) => {
  const url = getWebsiteUrl(property);
  if (!url) {
    return intl.formatMessage(globalMessages["global.na"]);
  }

  // eslint-disable-next-line
  const fullUrlPattern = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im;
  // eslint-disable-next-line
  const partialUrlPattern = /^[^\.]+\.(.+\..+)$/;
  const fullUrlMatch = url.match(fullUrlPattern);
  const partialUrlMatch = url.match(partialUrlPattern);

  return (
    (fullUrlMatch && fullUrlMatch[1]) ||
    (partialUrlMatch && partialUrlMatch[1]) ||
    url
  );
};
