import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import ClientOAuth2 from "client-oauth2";
import * as R from "ramda";
import { getBearerToken } from "utils/platformAuth";

export const authenticatedFetch = async (uri, options = {}) => {
  const bearerToken = await getBearerToken();
  const optionsWithToken = R.mergeDeepRight(options, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });
  const response = await fetch(uri, optionsWithToken);

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return response;
};

export const partnersClient = new ApolloClient({
  connectToDevTools: true,
  cache: new InMemoryCache({
    typePolicies: {
      IndexedPromotionalProperty: {
        keyFields: ["id", "searchTerms"],
      },
      IndexedPublisher: {
        keyFields: ["id", "searchTerms"],
      },
    },
  }),
  link: new HttpLink({
    uri: process.env.REACT_APP_PARTNERS_API_URL,
    fetch: authenticatedFetch,
  }),
});

export const accountsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: process.env.REACT_APP_ACCOUNTS_API_URL,
    fetch: authenticatedFetch,
  }),
});

export const clientOAuth2 = new ClientOAuth2({
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  authorizationUri: process.env.REACT_APP_OAUTH_AUTHORIZATION_URI,
  redirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URI,
});
