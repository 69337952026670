import { gql, useMutation } from "@apollo/client";
import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Button, IconButton } from "@cjdev-internal/visual-stack-x/Button.js";
import { Modal } from "@cjdev-internal/visual-stack-x/Modal.js";
import {
  Tab,
  TabLayout,
  useTabLayout,
} from "@cjdev-internal/visual-stack-x/TabLayout";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { Input } from "@cjdev-internal/visual-stack-x/components/Input";
import { TextArea } from "@cjdev-internal/visual-stack-x/components/TextArea";
import { PUBLISHER_SEARCH_PATH } from "constants/paths";
import { EMPLOYEE_AS_SELF } from "constants/userTypes";
import { useClients, useCompany, useIntl, useUser } from "hooks";
import globalMessages from "messages/globalMessages";
import * as R from "ramda";
import React, { useState } from "react";
import { selectUserType } from "utils/authorizationUtils";
import { getEmailLanguage } from "utils/utils";
import { CopyButton } from "./components/CopyButton";
import {
  EmailButton,
  isValidEmailAddressInput,
} from "./components/EmailButton";
import messages from "./messages";
import "./styles.css";
import { ExternalLink } from "components/ExternalLink";

const MAX_MESSAGE_LENGTH = 2000;

const getSharableLink = (publisherIds) => {
  let { protocol, port, hostname } = window.location;
  port = R.isEmpty(port) ? "" : `:${port}`;
  const partnersBaseURL = `${protocol}//${hostname}${port}`;
  return `${partnersBaseURL}${PUBLISHER_SEARCH_PATH}?publisherIds=${R.join(",")(
    publisherIds
  )}`;
};

const SEND_EMAIL_MUTATION = gql`
  mutation sendEmail(
    $to: [String!]!
    $userName: String!
    $accountName: String!
    $customMessage: String
    $publisherIds: [ID!]!
    $language: Language!
  ) {
    sharePublishersByEmail(
      input: {
        to: $to
        userName: $userName
        accountName: $accountName
        customMessage: $customMessage
        publisherIds: $publisherIds
        language: $language
      }
    )
  }
`;

export const SharePublishersModalContent = ({
  closeModal,
  onShare,
  publisherIds,
}) => {
  const intl = useIntl();
  const [addresses, setAddresses] = useState("");
  const [message, setMessage] = useState("");
  const [addressInputError, setAddressInputError] = useState(false);
  const [selectedTabId, selectTab] = useTabLayout("url-tab");
  const { user } = useUser();
  const company = useCompany();
  const { partnersClient } = useClients();

  const [sendEmailMutation] = useMutation(SEND_EMAIL_MUTATION, {
    client: partnersClient,
  });

  const userType = selectUserType(user);
  const urlToShare = getSharableLink(publisherIds);
  const messageInputError = message.length > MAX_MESSAGE_LENGTH;
  const anyInputError = addressInputError || messageInputError;
  const messageHelpStyle = messageInputError ? "error" : "secondary";

  const modalProps = {
    onBackgroundClick: closeModal,
    onEscapeKeyUp: closeModal,
    onCloseIconClick: closeModal,
    headerTitle: intl.formatMessage(messages.sharePublishersTitle),
    body: (
      <div className="tab-container" role="dialog">
        <TabLayout
          tabLayoutId="tabLayout2"
          selectedId={selectedTabId}
          selectTab={selectTab}
        >
          <Tab id="url-tab" label={intl.formatMessage(messages.urlTabLabel)}>
            <Box gap="xl">
              <Text weight="medium" size={16}>
                {intl.formatMessage(messages.copyPrivateUrl)}
              </Text>
              <Box gap="small">
                <Text>{intl.formatMessage(messages.copyUrlLabel)}</Text>
                <Box direction="row" gap="medium">
                  <div className="share-url" data-testid="copy-url-to-share">
                    {urlToShare}
                  </div>
                </Box>
              </Box>
              <ExternalLink
                path={urlToShare}
                text={intl.formatMessage(messages.openInNewWindow)}
                newWindow
              />
              <Box direction="row" justify="end" gap="medium">
                <Button type="tertiary" onClick={closeModal}>
                  {intl.formatMessage(globalMessages.cancel)}
                </Button>
                <CopyButton
                  text={urlToShare}
                  onClick={() =>
                    onShare(intl.formatMessage(messages.copiedAlert))
                  }
                />
              </Box>
            </Box>
          </Tab>
          <Tab
            id="email-tab"
            label={intl.formatMessage(messages.emailTabLabel)}
          >
            <Box gap="xl">
              <Text weight="medium" size={16}>
                {intl.formatMessage(messages.sendByEmail)}
              </Text>
              <Box gap="small">
                <label htmlFor="share-publishers-email-address">
                  <Text>{intl.formatMessage(messages.emailAddressLabel)}</Text>
                </label>
                <Input
                  type="email"
                  multiple
                  id="share-publishers-email-address"
                  value={addresses}
                  onChange={(event) => {
                    setAddresses(event.target.value);
                    setAddressInputError(false);
                  }}
                  onBlur={() => {
                    setAddressInputError(!isValidEmailAddressInput(addresses));
                  }}
                />
                {addressInputError && (
                  <Text
                    color="critical"
                    size={12}
                    data-testid="email-address-input-error"
                  >
                    {intl.formatMessage(messages.emailAddressError)}
                  </Text>
                )}
              </Box>
              <Box direction="column" gap="small">
                <label htmlFor="share-publishers-email-message-input">
                  <Text>{intl.formatMessage(messages.emailMessageLabel)}</Text>
                </label>
                <TextArea
                  id="share-publishers-email-message-input"
                  value={message}
                  onChange={(event) => {
                    setMessage(event.target.value);
                  }}
                />
                <Text
                  color={messageHelpStyle}
                  data-testid="email-message-help"
                  size={12}
                >
                  {intl.formatMessage(messages.messageHelpText, [
                    MAX_MESSAGE_LENGTH,
                  ])}
                </Text>
              </Box>
              <Box direction="row" justify="end" gap="medium">
                <Button type="tertiary" onClick={closeModal}>
                  {intl.formatMessage(globalMessages.cancel)}
                </Button>
                {user && (
                  <EmailButton
                    addresses={addresses}
                    message={message}
                    urlToShare={urlToShare}
                    disabled={anyInputError}
                    setAddressInputError={setAddressInputError}
                    onClick={() => {
                      const companyName =
                        userType === EMPLOYEE_AS_SELF
                          ? intl.formatMessage(globalMessages.cjAffiliate)
                          : company.name;
                      sendEmailMutation({
                        variables: {
                          to: R.defaultTo([])(addresses.split(",")),
                          userName: `${user.firstName} ${user.lastName}`,
                          accountName: companyName,
                          customMessage: message,
                          publisherIds: publisherIds,
                          language: getEmailLanguage(),
                        },
                      });
                      onShare(intl.formatMessage(messages.emailSentAlert));
                    }}
                  />
                )}
              </Box>
            </Box>
          </Tab>
        </TabLayout>
      </div>
    ),
  };

  return <Modal {...modalProps} />;
};

export const SharePublishersButton = ({
  publisherIds,
  openModal,
  closeModal,
  toggleAlert,
}) => {
  const openSharePublishersModal = () => {
    openModal(
      <SharePublishersModalContent
        closeModal={closeModal}
        onShare={(message) => {
          closeModal();
          toggleAlert(message);
        }}
        publisherIds={publisherIds}
      />
    );
  };

  return (
    <IconButton
      aria-label="share publishers"
      data-testid="share-publishers-button"
      type="tertiary"
      onClick={openSharePublishersModal}
      icon="share-variant-outline"
      round
      color="#FFFFFF"
    />
  );
};
