import { gql, useQuery } from "@apollo/client";
import { useClients } from "hooks";

export const useTopWebsitesQuery = (publisherId) => {
  const { accountsClient } = useClients();

  return useQuery(gql(GET_TOP_WEBSITES_QUERY), {
    client: accountsClient,
    variables: {
      publisherId,
      limit: 5,
      sortBy: "WEBSITE_REACH",
      propertyType: "WEBSITE",
    },
  });
};

export const GET_TOP_WEBSITES_QUERY = `
  query PromotionalProperties(
    $publisherId: ID!
    $sortBy: PromotionalPropertySortOption
    $limit: Int
    $propertyType: PromotionalPropertyType
  ) {
    promotionalProperties(
      publisherId: $publisherId
      sortBy: $sortBy
      limit: $limit
      propertyType: $propertyType
    ) {
      resultList {
        id
        name
        propertyTypeDetails {
          type
          ... on PromotionalPropertyWebsiteDetails {
            websiteUrl
          }
        }
      }
    }
  }
`;
