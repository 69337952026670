import { Select } from "@cjdev-internal/visual-stack-x/Select";
import { useIntl, usePagination, useSortBy } from "hooks";
import globalMessages from "messages/globalMessages";
import React from "react";

export const SortBySelector = () => {
  const intl = useIntl();
  const { sortByOptions, sortBySelection, setSortBySelection } = useSortBy();
  const { resetPagination } = usePagination();

  return (
    <Select
      isSearchable={false}
      placeholder={intl.formatMessage(globalMessages.sortBy)}
      options={sortByOptions}
      onChange={(selection) => {
        resetPagination();
        setSortBySelection(selection);
      }}
      value={sortBySelection}
    />
  );
};
