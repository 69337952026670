import produce from "immer";
import * as R from "ramda";

export const produceNewFavoritesBuilder = (hiddenIdMap) => (publisherIds) => (
  previousResponse
) => {
  return produce(previousResponse, (draftResponse) => {
    draftResponse.data.indexedPublishers.resultList = previousResponse.data.indexedPublishers.resultList.map(
      (publisher) => {
        if (hiddenIdMap[publisher.id]) {
          hiddenIdMap[publisher.id] = false;
        }

        if (R.includes(publisher.id)(publisherIds)) {
          return {
            ...publisher,
            hidden: true,
          };
        }
        return publisher;
      }
    );

    draftResponse.data.indexedPublishers.totalCount =
      previousResponse.data.indexedPublishers.totalCount - publisherIds.length;
  });
};

export const produceUndoPublishersBuilder = (hiddenIdMap) => (
  publisherIdsToUndo
) => (previousResponse) => {
  return produce(previousResponse, (draftResponse) => {
    draftResponse.data.indexedPublishers.resultList = previousResponse.data.indexedPublishers.resultList.map(
      (publisher) => {
        if (!hiddenIdMap[publisher.id]) {
          hiddenIdMap[publisher.id] = true;
        }

        if (R.includes(publisher.id)(publisherIdsToUndo)) {
          return {
            ...publisher,
            hidden: false,
          };
        }
        return publisher;
      }
    );

    draftResponse.data.indexedPublishers.totalCount =
      previousResponse.data.indexedPublishers.totalCount +
      publisherIdsToUndo.length;
  });
};
