import { useIntl } from "hooks";
import React from "react";
import { useCountryMapAmChart } from "./useCountryMapAmChart";

export const CountryMap = ({ mapId, data, message }) => {
  const intl = useIntl();
  useCountryMapAmChart(mapId, data, intl);

  return (
    <>
      <div className="am-map-container" data-testid={`map-${mapId}`}>
        <div className="am-map-legend" id={`map-legend-${mapId}`}></div>
        <div className="am-map-element" id={`map-div-${mapId}`}></div>
      </div>
      {message && <p className="am-map-context-message">{message}</p>}
    </>
  );
};
