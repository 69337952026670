import * as R from "ramda";
import messages from "../messages";

const getSortDropDownOptions = (intl) => ({
  keywordRelevance: {
    label: intl.formatMessage(messages.keywordRelevance),
    value: "KEYWORD_RELEVANCE",
  },
  totalMonthlyVisitors: {
    label: intl.formatMessage(messages.totalMonthlyVisitors),
    value: "TOTAL_MONTHLY_VISITORS",
  },
  instagramFollowers: {
    label: intl.formatMessage(messages.instagramFollowers),
    value: "INSTAGRAM_FOLLOWERS",
  },
  tiktokFollowers: {
    label: intl.formatMessage(messages.tiktokFollowers),
    value: "TIKTOK_FOLLOWERS",
  },
  facebookPageLikes: {
    label: intl.formatMessage(messages.facebookPageLikes),
    value: "FACEBOOK_PAGELIKES",
  },
  youtubeSubscribers: {
    label: intl.formatMessage(messages.youtubeSubscribers),
    value: "YOUTUBE_SUBSCRIBERS",
  },
  twitterFollowers: {
    label: intl.formatMessage(messages.twitterFollowers),
    value: "TWITTER_FOLLOWERS",
  },
  pinterestFollowers: {
    label: intl.formatMessage(messages.pinterestFollowers),
    value: "PINTEREST_FOLLOWERS",
  },
  twitchFollowers: {
    label: intl.formatMessage(messages.twitchFollowers),
    value: "TWITCH_FOLLOWERS",
  },
  snapchatFollowers: {
    label: intl.formatMessage(messages.snapchatFollowers),
    value: "SNAPCHAT_FOLLOWERS",
  },
  countryCommissions: {
    label: intl.formatMessage(messages.countryTotalCommissions),
    value: "COUNTRY_COMMISSIONS",
  },
  categoryCommissions: {
    label: intl.formatMessage(messages.categoryTotalCommissions),
    value: "CATEGORY_COMMISSIONS",
  },
  networkEarnings: {
    label: intl.formatMessage(messages.networkEarnings),
    value: "NETWORK_EARNINGS",
  },
});

export const getSortByOptionsFromNames = (options, intl) => {
  const sortDropDownOptions = getSortDropDownOptions(intl);
  return R.values(R.pick(options, sortDropDownOptions));
};

export const getSortByOptionFromName = (name, intl) => {
  const sortDropDownOptions = getSortDropDownOptions(intl);
  return sortDropDownOptions[name];
};
