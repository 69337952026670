import { defineMessages } from "react-intl";

export default defineMessages({
  documents: {
    id: "partners.PublishersProfile.documents",
    defaultMessage: "Documents",
  },
  noDocuments: {
    id: "partners.PublishersProfile.noDocuments",
    defaultMessage: "No documents provided.",
  },
});
