import { PublisherIdFilterProvider } from "context/PublisherIdFilterContext";
import { RoutePathProvider } from "context/ViewContext/RoutePathContext";
import { SearchTermsViewProvider } from "context/ViewContext/SearchTermsContext";
import { SortByProvider } from "context/ViewContext/SortByContext";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { HistoryProvider } from "./HistoryContext";
import { LocationProvider } from "./LocationContext";
import { ParamsProvider } from "./ParamsContext";
import { UserProvider } from "./UserContext";

export const LegacyViewProvider = ({ children, features, user, ...rest }) => {
  const location = useLocation();
  const history = useHistory();
  const navigationType = history.action;
  const params = useParams();

  return (
    <UserProvider user={user}>
      <LocationProvider location={location}>
        <ParamsProvider params={params}>
          <HistoryProvider historyConfiguration={{ history, navigationType }}>
            <PublisherIdFilterProvider>
              <SearchTermsViewProvider>
                <SortByProvider>
                  <RoutePathProvider>{children}</RoutePathProvider>
                </SortByProvider>
              </SearchTermsViewProvider>
            </PublisherIdFilterProvider>
          </HistoryProvider>
        </ParamsProvider>
      </LocationProvider>
    </UserProvider>
  );
};
