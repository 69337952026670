import * as R from "ramda";

const countWhere = (condition) => R.pipe(R.filter(condition), R.keys, R.length);

const numberOfPublisherLocationsFilter = R.pipe(
  R.pathOr({}, ["publisherLevel", "publisherLocations", "selected"]),
  countWhere(R.equals(1))
);

const numberOfCommissionLocationsFilter = R.pipe(
  R.pathOr({}, ["publisherLevel", "commissionLocations", "selected"]),
  countWhere(R.equals(1))
);

const numberOfAdvertiserCategoriesFilter = R.pipe(
  R.pathOr({}, ["publisherLevel", "advertiserCategories", "selected"]),
  countWhere(R.equals(1))
);

const numberOfBadgeFilters = (data) =>
  R.add(
    R.pipe(
      R.pathOr([], ["publisherLevel", "badges", "include"]),
      R.length
    )(data),
    R.pipe(
      R.pathOr([], ["publisherLevel", "badges", "exclude"]),
      R.length
    )(data)
  );

const promotionalModelsCount = R.pipe(
  R.pathOr([], ["propertyLevel", "promotionalModels", "selected"]),
  R.length
);

const networkEarningsCount = R.pipe(
  R.pathOr([], ["publisherLevel", "networkEarningsToInclude"]),
  R.length
);

const promotionalTypesCount = R.pipe(
  R.pathOr([], ["propertyLevel", "propertyTypes", "selected"]),
  R.length
);

const selectedPlatformsCount = R.pipe(
  R.pathOr({}, ["propertyLevel", "propertyTypes", "selectedMetrics"]),
  countWhere(R.prop("platformSelected"))
);

const promotionalTypesCombinedCount = (data) =>
  R.add(promotionalTypesCount(data), selectedPlatformsCount(data));

const selectedMetricsCount = R.pipe(
  R.pathOr({}, ["propertyLevel", "propertyTypes", "selectedMetrics"]),
  countWhere(R.prop("metricsSelected"))
);

const propertyReachIncluded = R.pipe(selectedMetricsCount, R.lt(0));

const promotionalModelsIncluded = R.pathOr(false, [
  "propertyLevel",
  "promotionalModels",
  "include",
]);

const promotionalTypesIncluded = R.pathOr(false, [
  "propertyLevel",
  "propertyTypes",
  "include",
]);

const commissionLocationsIncluded = R.pathOr(false, [
  "publisherLevel",
  "commissionLocations",
  "include",
]);

const publisherLocationsIncluded = R.pathOr(false, [
  "publisherLevel",
  "publisherLocations",
  "include",
]);

const advertiserCategoriesIncluded = R.pathOr(false, [
  "publisherLevel",
  "advertiserCategories",
  "include",
]);

const unsortableNonmetricPromotionalTypeFound = R.pipe(
  R.pathOr([], ["propertyLevel", "propertyTypes", "selected"]),
  R.length,
  R.lt(0)
);

const unsortableMetricPromotionalTypeFound = R.pipe(
  R.pathOr({}, ["propertyLevel", "propertyTypes", "selectedMetrics"]),
  R.anyPass([R.pathEq(["whatsapp", "platformSelected"], true)])
);

const unsortablePromotionalTypeFound = (data) =>
  R.or(
    unsortableNonmetricPromotionalTypeFound(data),
    unsortableMetricPromotionalTypeFound(data)
  );

const reachSortOptions = R.pipe(
  R.pathOr({}, ["propertyLevel", "propertyTypes", "selectedMetrics"]),
  R.omit(["whatsapp"]),
  R.pickBy(R.prop("platformSelected")),
  R.keys
);

export const currentAppliedFiltersInformationFunctions = {
  promotionalModelsCount,
  promotionalModelsIncluded,
  promotionalTypesCount,
  selectedPlatformsCount,
  promotionalTypesCombinedCount,
  promotionalTypesIncluded,
  selectedMetricsCount,
  propertyReachIncluded,
  reachSortOptions,
  unsortableNonmetricPromotionalTypeFound,
  unsortableMetricPromotionalTypeFound,
  unsortablePromotionalTypeFound,
  networkEarningsCount,
  numberOfPublisherLocationsFilter,
  commissionLocationsIncluded,
  numberOfCommissionLocationsFilter,
  publisherLocationsIncluded,
  advertiserCategoriesIncluded,
  numberOfAdvertiserCategoriesFilter,
  numberOfBadgeFilters,
};

export const getCurrentAppliedFiltersInformation = (
  appliedFiltersInformation
) => {
  const currentAppliedFiltersInformation = {
    promotionalModels: {
      number: promotionalModelsCount(appliedFiltersInformation),
      include: promotionalModelsIncluded(appliedFiltersInformation),
    },
    promotionalTypes: {
      number: promotionalTypesCombinedCount(appliedFiltersInformation),
      include: promotionalTypesIncluded(appliedFiltersInformation),
      sortableSortOptions: reachSortOptions(appliedFiltersInformation),
      unsortableSelected: unsortablePromotionalTypeFound(
        appliedFiltersInformation
      ),
    },
    propertyReach: {
      number: selectedMetricsCount(appliedFiltersInformation),
      include: propertyReachIncluded(appliedFiltersInformation),
    },
    networkEarnings: {
      number: networkEarningsCount(appliedFiltersInformation),
    },
    commissionLocations: {
      number: numberOfCommissionLocationsFilter(appliedFiltersInformation),
      include: commissionLocationsIncluded(appliedFiltersInformation),
    },
    publisherLocations: {
      number: numberOfPublisherLocationsFilter(appliedFiltersInformation),
      include: publisherLocationsIncluded(appliedFiltersInformation),
    },
    advertiserCategories: {
      number: numberOfAdvertiserCategoriesFilter(appliedFiltersInformation),
      include: advertiserCategoriesIncluded(appliedFiltersInformation),
    },
    badges: {
      number: numberOfBadgeFilters(appliedFiltersInformation),
    },
  };

  return {
    currentAppliedFiltersInformation,
  };
};
