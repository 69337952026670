import {
  closestStep,
  oneStepDown,
  oneStepUp,
  produceStepMarkers,
} from "./rangeSliderStepUtils";
import { parseInputForRangeSlider } from "./rangeSliderUtils";

const sUsrAg = navigator.userAgent;

export const produceRangeSliderEvents = ({
  steps,
  textPlaceholderForMaxThreshold,
  minSliderValue,
  maxSliderValue,
  setMinSliderValue,
  setMaxSliderValue,
  minTextValue,
  maxTextValue,
  updateMin,
  updateMax,
}) => {
  const usingChrome = sUsrAg.indexOf("Chrome") > -1;

  const {
    maxThreshold,
    secondFromTopStep,
    secondFromBottomStep,
  } = produceStepMarkers(steps);

  const updateMaxAll = (maxIntValue, setInfinityAtMaxThreshold = true) => {
    const translatedMaxTextValueBoundary =
      setInfinityAtMaxThreshold && maxIntValue === maxThreshold
        ? Infinity
        : maxIntValue;
    setMaxSliderValue(Math.min(maxIntValue, maxThreshold));
    updateMax(translatedMaxTextValueBoundary);
  };

  const updateMinAll = (minIntValue) => {
    setMinSliderValue(Math.min(minIntValue, maxThreshold));
    updateMin(minIntValue);
  };

  const onMinTextAdjustment = (e) => {
    const newMin = e.target.value;
    updateMinAll(
      parseInputForRangeSlider(newMin, textPlaceholderForMaxThreshold)
    );
  };

  const onMinTextConfirmation = () => {
    if (minTextValue >= maxTextValue) {
      updateMaxAll(oneStepUp(steps, minTextValue), minTextValue > maxThreshold);
    }
  };

  const onMaxTextAdjustment = (e) => {
    const newMax = e.target.value;
    updateMaxAll(
      parseInputForRangeSlider(newMax, textPlaceholderForMaxThreshold),
      false
    );
  };

  const onMaxTextConfirmation = () => {
    if (maxTextValue <= minTextValue) {
      updateMinAll(oneStepDown(steps, maxTextValue));
    }
  };

  const onMinSliderAdjustment = (e) => {
    const newMinStep = closestStep(steps, parseInt(e.target.value));
    updateMinAll(Math.min(newMinStep, secondFromTopStep));

    !usingChrome && onMinSliderConfirmation();
  };

  const onMinSliderConfirmation = () => {
    if (minSliderValue >= maxSliderValue) {
      updateMaxAll(oneStepUp(steps, minSliderValue));
    }
  };

  const onMaxSliderAdjustment = (e) => {
    const newMaxStep = closestStep(steps, parseInt(e.target.value));
    updateMaxAll(Math.max(newMaxStep, secondFromBottomStep));

    !usingChrome && onMaxSliderConfirmation();
  };

  const onMaxSliderConfirmation = () => {
    if (maxSliderValue <= minSliderValue) {
      updateMinAll(oneStepDown(steps, maxSliderValue));
    }
  };

  return {
    onMinTextAdjustment,
    onMinTextConfirmation,
    onMaxTextAdjustment,
    onMaxTextConfirmation,
    onMinSliderAdjustment,
    onMinSliderConfirmation,
    onMaxSliderAdjustment,
    onMaxSliderConfirmation,
  };
};
