import produce from "immer";

export const getMetricValues = (filters, platform, metric) =>
  filters.propertyLevel.propertyTypes.selectedMetrics[platform].metrics[metric];
export const getMetricMin = (filters, platform, metric) =>
  getMetricValues(filters, platform, metric).minValue;
export const getMetricMax = (filters, platform, metric) =>
  getMetricValues(filters, platform, metric).maxValue;

export const updateMaxBoundary = (
  setFilters,
  platform,
  metric,
  newMaxValue
) => {
  setFilters((previousFilters) =>
    produce(previousFilters, (draftFilters) => {
      draftFilters.propertyLevel.propertyTypes.selectedMetrics[
        platform
      ].metrics[metric].maxValue = newMaxValue;
    })
  );
};

export const updateMinBoundary = (
  setFilters,
  platform,
  metric,
  newMinValue
) => {
  setFilters((previousFilters) =>
    produce(previousFilters, (draftFilters) => {
      draftFilters.propertyLevel.propertyTypes.selectedMetrics[
        platform
      ].metrics[metric].minValue = newMinValue;
    })
  );
};
