import { Link } from "components/Link";
import { useLocation } from "hooks";
import React from "react";

export const LinkWithQuery = ({ to, children }) => {
  const { location } = useLocation();
  return (
    <Link
      className="vsx-link"
      to={{
        pathname: to,
        search: location.search,
      }}
    >
      {children}
    </Link>
  );
};
