import { defineMessages } from "react-intl";

export default defineMessages({
  aov: {
    id: "partners.OverviewTab.aov",
    defaultMessage: "Average Order Value",
  },
  cr: {
    id: "partners.OverviewTab.cr",
    defaultMessage: "Conversion Rate",
  },
  lastThirtyDays: {
    id: "partners.OverviewTab.lastThirtyDays",
    defaultMessage: "Last 30 days",
  },
  aovTooltip: {
    id: "partners.OverviewTab.aovTooltip",
    defaultMessage:
      "The average sale amount across all sales in the last 30 days. Publishers who drive only lead-based conversions may not have an AOV to display.",
  },
  topCategory: {
    id: "partners.OverviewTab.topCategory",
    defaultMessage: "Top Performing Category",
  },
  topCategoryToolTip: {
    id: "partners.OverviewTab.topCategoryToolTip",
    defaultMessage: "Top category by commissions in the last 30 days",
  },
  topCountry: {
    id: "partners.OverviewTab.topCountry",
    defaultMessage: "Top Audience Location",
  },
  topCountryToolTip: {
    id: "partners.OverviewTab.topCountryToolTip",
    defaultMessage: "Top country by commissions in the last 30 days",
  },
  crTooltip: {
    id: "partners.OverviewTab.crTooltip",
    defaultMessage:
      "The percentage of clicks that led to a conversion in the last 30 days.",
  },
  sevenDayEpc: {
    id: "partners.OverviewTab.profile.sevenDayEpc",
    defaultMessage: "7 Day EPC",
  },
  sevenDayEpcToolTip: {
    id: "partners.OverviewTab.profile.sevenDayEpcToolTip",
    defaultMessage: "Commissions per 100 clicks over the last 7 days",
  },
  threeMonthEpc: {
    id: "partners.OverviewTab.profile.threeMonthEpc",
    defaultMessage: "3 Month EPC",
  },
  threeDayEpcToolTip: {
    id: "partners.OverviewTab.profile.threeDayEpcToolTip",
    defaultMessage: "Commissions per 100 clicks over the last 3 months",
  },
  networkEarnings: {
    id: "partners.OverviewTab.networkEarnings",
    defaultMessage: "Network Earnings",
  },
  networkEarningsToolTip: {
    id: "partners.OverviewTab.networkEarningsToolTip",
    defaultMessage:
      "The size of the publisher’s program over the last 30 days compared to the rest of the network",
  },
  topAdvertiserCategories: {
    id: "partners.OverviewTab.topAdvertiserCategories",
    defaultMessage: "Top Advertiser Categories",
  },
  topAdvertiserCategoriesToolTip: {
    id: "partners.OverviewTab.topAdvertiserCategoriesToolTip",
    defaultMessage:
      "The categories of the advertisers that the publisher has recorded commissions with in the last 30 days",
  },
  topAudienceLocation: {
    id: "partners.OverviewTab.topAudienceLocation",
    defaultMessage: "Top Audience Location",
  },
  topAudienceLocationToolTip: {
    id: "partners.OverviewTab.topAudienceLocationToolTip",
    defaultMessage:
      "Countries where the publisher has recorded commissions in the last 30 days",
  },
  promotionalModels: {
    id: "partners.OverviewTab.promotionalModels",
    defaultMessage: "Promotional Models",
  },
  propertyTypes: {
    id: "partners.OverviewTab.propertyTYpes",
    defaultMessage: "Property Types",
  },
  performanceOverview: {
    id: "partners.OverviewTab.performanceOverview",
    defaultMessage: "Performance Overview",
  },
  mapContextMessage: {
    id: "partners.PublisherSearch.mapContextMessage",
    defaultMessage: "Based on conversion data in the previous month",
  },
});
